import { useState, useEffect } from 'react'

export default function useIsLink(string) {
    const [isLink, setIsLink] = useState(false)
    useEffect(() => {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        setIsLink(res !== null)
    }, [string])

    return isLink
}