import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import HistoryProducts from '../components/products/history';



const useStyles = makeStyles((theme) => ({
    searchWrapper: {
        padding: 30,
        backgroundColor: '#f8f8f8',
        [theme.breakpoints.down('sm')]: {
            padding: 15
        }
    },
    resultHeader: {
        borderColor: 'black',
        border: 1,
        m: 1,
    },
    shopContainer: {
        minHeight: '95.5vh',
        // [theme.breakpoints.down('sm')]: {
        //     minHeight: 'auto'
        // }
    },
    shopFiltersWrapper: {
        background: '#fff',
        borderRadius: 10,
        padding: 10
    },
    headerFiltersWrapper: {
        background: '#e55031'
    },
    whiteText: {
        color: 'white'
    },
    shopHeaderWrapper: {
        height: 70,
        marginBottom: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    filterTitle: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paginationHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 10,
        color: 'white'
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
        border: 'solid',
        borderRadius: 35,
        borderWidth: 1,
        borderColor: '#d1d1d1',
        padding: 5,
        overflow: 'hidden',
        paddingRight: 10
    },
    searchField: {
        flex: 1,
        padding: 10,
        border: 'none'
    },
    searchIcon: {
        fontSize: 26
    },
    filterCard: {
        marginBottom: 10
    },
    filtersWrapper: {
        height: '35vh',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    filterCardContent: {
        paddingRight: 0
    }
}))

export default function Shop() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(48);
    const [totalProduct, setTotal] = React.useState(0);
    const ref = useRef();

    return (
        <div className={classes.searchWrapper}>
            <Grid container alignItems="stretch" className={classes.shopContainer}>

                <HistoryProducts ref={ref} title={'Browsing History'} />
            </Grid>
        </div>
    )
}
