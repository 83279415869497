import React from "react";
import { Grid, Typography, Avatar, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addRatingIcon: {
    color: "#FFCC01",
    fontSize: 22,
    cursor: "pointer",
  },
}));

export default function SingleComment({ avatarSize, review }) {
  const classes = useStyles();

  function generateDarkColorHsl() {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * (100 + 1)) + "%";
    const lightness = Math.floor(Math.random() * (100 / 2 + 1)) + "%";
    return "hsl(" + hue + ", " + saturation + ", " + lightness + ")";
  }

  const Rating = ({ rating }) => {
    return (
      <Grid container justifyContent="flex-end">
        <Icon className={classes.addRatingIcon}>
          {rating > 0 ? "star" : "star_outline"}
        </Icon>
        <Icon className={classes.addRatingIcon}>
          {rating > 1 ? "star" : "star_outline"}
        </Icon>
        <Icon className={classes.addRatingIcon}>
          {rating > 2 ? "star" : "star_outline"}
        </Icon>
        <Icon className={classes.addRatingIcon}>
          {rating > 3 ? "star" : "star_outline"}
        </Icon>
        <Icon className={classes.addRatingIcon}>
          {rating > 4 ? "star" : "star_outline"}
        </Icon>
      </Grid>
    );
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Avatar
            style={{
              backgroundColor: generateDarkColorHsl(),
              width: avatarSize === "large" ? 60 : 40,
              height: avatarSize === "large" ? 60 : 40,
            }}
            alt="Hello"
            src={null}
          >
            {review?.reviewer.display_name[0]}
          </Avatar>
        </Grid>
        <Grid item style={{ marginLeft: 10, flex: 1 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography
                style={{
                  marginBottom: 0,
                  fontSize: avatarSize === "large" ? 20 : 16,
                }}
                variant="body1"
              >
                {review?.reviewer.display_name}
              </Typography>
              <Typography
                style={{ marginTop: 0, fontSize: 12 }}
                variant="body2"
              >
                {review?.date}
              </Typography>
            </Grid>
            <Grid item>
              <Rating rating={review.rating} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 5, paddingLeft: avatarSize === "large" ? 20 : 0 }}
      >
        <Typography>"{review?.review}"</Typography>
      </Grid>
    </div>
  );
}
