import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PassCodePopUp from '../components/auth/PassCodePopUp';
import RedirectPopUp from '../components/auth/RedirectPopUp';
import Product from '../services/products';

export const ShorternRoute = ({ component: Component, ...rest }) => {
    const [loading, setLoading] = React.useState(true);
    const [redirectTo, setRedirectTo] = React.useState('')
    React.useEffect(() => {
        let url = window.location.pathname
        const courseid = atob(url.substring(url.lastIndexOf('/') + 1));
        const realURL = courseid.split(',')
        // alert(realURL[1])
        Product.getProduct(realURL[1], false).then(res => {
            // alert(encodeURI(res.data[0].course_name + '/' + realURL[1]))
            setRedirectTo('/product/view/' + encodeURI(res.data[0].course_name + '/' + realURL[1]))
            setLoading(false)
        }, error => {
            console.log(error)
        })
        // const authenticate = async () => {
        //     const token = await localStorage.getItem("jwt_token");
        //     Auth.authenticateUser(token).then(response => {
        //         setLoading(false)
        //         if (response.data.status != 'success') {
        //             localStorage.clear()
        //             setToken('na')
        //             setCurrentUser({})
        //             setUserRole(null)
        //         }
        //     })
        // };
        // authenticate();
    }, []);

    const handleGrant = () => {
        setLoading(false)
    }

    return (
        (loading) ?
            <>
                <RedirectPopUp title={"Redirecting..."} />
            </>
            :
            <>
                <Redirect to={redirectTo} />
            </>
    )
}