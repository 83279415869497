import React from 'react'
import {Select,MenuItem,TextField,FormControl,Grid,Button} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import ReactFlagsSelect from "react-flags-select";
import TeacherService from '../../../../services/teacher'
import { BookingContext } from '../../../../context/BookingContext';
import Countries from '../countries.json'

export default function Filters({handleFilter,resetFilter}) {

  const [priceRange,setPriceRange] = React.useState(0)
  const [language,setLanguage] = React.useState(0)
  const [country,setCountry] = React.useState()
  const [filter,setFilter] = React.useState()
  const CountriesList = Countries;
  const {bookedTrials} = React.useContext(BookingContext)

  const handlePriceRange = (event) => {
    setPriceRange(event.target.value)
    if(event.target.value != 0){
      setFilter({...filter,...JSON.parse(event.target.value)})
    }else {
      const {price_min,price_max,...rest} = filter
      setFilter(rest)
    }
  }

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value)
    if(event.target.value != 0){
      setFilter({...filter,speaks:event.target.value})
    } else {
      const {speaks,...rest} = filter
      setFilter(rest)
    }
  }

  const handleCountryChange = (event,value) => {
    if(value){
      setCountry(value.code)
      setFilter({...filter,country:value.code})
    }else{
      const {country,...rest} = filter
      setFilter(rest)
    }
    // console.log(value)
  }

  React.useEffect(() => {
    // console.log(filter)
    if(filter){
      if(Object.keys(filter).length === 0)
      handleResetFilter()
      else
      filterResult()
    }
    else{
      handleResetFilter()
    }
  },[filter])

  const filterResult = () => {
    TeacherService.filterTeachers(filter,40,0).then(res => {
      handleFilter(res.data.teachers,res.data.total)
    })
  }

  const handleResetFilter = () => {
    setCountry()
    setLanguage(0)
    setPriceRange(0)
    setFilter()
    resetFilter()
  }
 
  return (
    <Grid container alignItems="center">
      <Grid item>
        {/* <ReactFlagsSelect
          className="form-field country-selector"
          searchable
          autofocus
          selected={country}
          onSelect={(code) => handleCountryChange(code)}
          /> */}
        {/* <FormControl style={{marginTop:8, marginLeft:0}}>   */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={handleCountryChange}
          options={CountriesList}
          getOptionLabel={(option) => option.name}
          style={{ width: 250,marginTop:8 }}
          renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
        />
        {/* </FormControl> */}
      </Grid>
      <Grid item>
        <FormControl style={{marginTop:8, marginLeft:10,minWidth:230}}>
          {/* <InputLabel id="demo-select-small">Also speaks</InputLabel> */}
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            variant="outlined"
            value={language}
            // label="Also speaks"
            onChange={handleLanguageChange}
          >
            <MenuItem value={0}>
              <em>Also speaks</em>
            </MenuItem>
            <MenuItem value={'Chinese'}>Chinese</MenuItem>
            <MenuItem value={'English'}>English</MenuItem>
            <MenuItem value={'Dutch'}>Dutch</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl style={{marginTop:8, marginLeft:10,minWidth:230}}>
          {/* <InputLabel id="demo-select-small">Also speaks</InputLabel> */}
          <Select
            labelId="price-range"
            id="price-range"
            variant="outlined"
            value={priceRange}
            // label="Also speaks"
            onChange={handlePriceRange}
          >
            <MenuItem value={0}>
              <em>Price range</em>
            </MenuItem>
            <MenuItem value={JSON.stringify({price_min:0,price_max:1500})}>Below HKD 1500</MenuItem>
            <MenuItem value={JSON.stringify({price_min:1500,price_max:2500})}>HKD 1500 - 2500 </MenuItem>
            <MenuItem value={JSON.stringify({price_min:2500,price_max:4500})}>HKD 2500 - 4500 </MenuItem>
            <MenuItem value={JSON.stringify({price_min:4500,price_max:6500})}>HKD 4500 - 6500 </MenuItem>
            <MenuItem value={JSON.stringify({price_min:6500,price_max:8500})}>HKD 6500 - 8500 </MenuItem>
            <MenuItem value={JSON.stringify({price_min:8500,price_max:10000})}>HKD 8500 - 10,000 </MenuItem>
            <MenuItem value={JSON.stringify({price_min:10000,price_max:10000000})}>Above HKD 10,000</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {
        filter &&
        <Grid item>
          <Button style={{marginLeft:10,backgroundColor:"#1A95AC",color:'white'}} type="primary" onClick={handleResetFilter}>Reset</Button>
        </Grid>
      }
    </Grid>
  )
}
