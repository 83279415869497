import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import TeacherService from "../services/teacher";
import { Container, Grid, Typography, Dialog } from "@material-ui/core";
import Rating from "../components/teacher/List/Rating";
import Languages from "../components/teacher/List/Languages";
import Summary from "../components/teacher/List/Summary";
import Trials from "../components/teacher/List/Trials";
import ProfilePicture from "../components/teacher/List/ProfilePicture";
import TrialActions from "../components/teacher/List/TrialActions";
import { makeStyles } from "@material-ui/core/styles";
import Packages from "../components/teacher/List/Packages";
import { SingleComment } from "../components/teacher/List/Comments";
import { AllReviews } from "../components/teacher/List/Comments/AllReviews";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import SlotsDialog from "../components/teacher/List/SlotsDialog";
import { BookingContext } from "../context/BookingContext";
import ListOfLessons from "../components/teacher/Lessons/ListOfLessons";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  primaryText: {
    color: "#00C1E2",
  },
}));

export default function Teacher() {
  const classes = useStyles();
  const [info, setInfo] = useState();
  const [rating, setRating] = useState(5);
  const packageRef = useRef(null);
  const [allReviews, setAllReviews] = useState(false);
  const { teacherId } = useParams();
  const sampleReviews = [
    {
      rating: "4",
      teacher_id: "2314",
      review:
        "Teacher prepare a lot of material and have patient.She is a very nice teacher.",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Nelson",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [
        {
          is_teacher: "0",
          reply: "Thanks a lot",
          date: "2022-10-29 00:00:00",
          replier: {
            display_name: "Waleed Umer",
            profile_picture: "",
          },
        },
      ],
    },
    {
      rating: "5",
      review:
        "［Demo class / 面試課程］老師非常有耐心，很適合教小朋友，擅長鼓勵，相信在老師的帶領下，小朋友們一定可以學好英文！",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Lisa lane",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review:
        "I study first time on this lesson. Teacher is very kind and teach me. He will come to understand my level before teaching.",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Waleed Umer",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "Very nice and polite teacher",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Jhon Evanofski",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "This is another comment",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Michael John",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "I really like the way this tutor teaches",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "James michale",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
  ];
  const [reviews, setReviews] = useState(sampleReviews);

  useEffect(() => {
    TeacherService.getDetailsById(teacherId).then((res) => {
      setInfo(res.data);
      console.log(res.data);
      const allReviews = res.data.reviews.reverse();
      setReviews([...allReviews, ...reviews]);
    });
  }, []);

  const stars = [];

  React.useEffect(() => {
    const fiveStar = reviews.filter((review) => review.rating == 5).length;
    const fourStar = reviews.filter((review) => review.rating == 4).length;
    const threeStar = reviews.filter((review) => review.rating == 3).length;
    const twoStar = reviews.filter((review) => review.rating == 2).length;
    const oneStar = reviews.filter((review) => review.rating == 1).length;
    setRating(
      (fiveStar * 5 + fourStar * 4 + threeStar * 3 + twoStar * 2 + oneStar) /
        (fiveStar + fourStar + threeStar + twoStar + oneStar)
    );
  }, [reviews]);

  React.useEffect(() => {}, [rating]);

  const handleClose = () => {
    setAllReviews(false);
  };

  const StarRating = ({ i }) => {
    return rating > i ? (
      <StarIcon style={{ color: "#17C9B8" }} />
    ) : (
      <StarBorderIcon style={{ color: "#17C9B8" }} />
    );
  };

  const handleAddReview = (review, user) => {
    const newReview = {
      rating: review.rating,
      teacher_id: "2314",
      review: review.review,
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: user.details.display_name,
        profile_picture: "",
      },
      replies: [],
    };
    console.log(newReview);
    setReviews([newReview, ...reviews]);
  };

  return (
    <>
      <Dialog fullScreen open={allReviews} onClose={handleClose}>
        <AllReviews
          handleAddReview={handleAddReview}
          title={info?.title}
          handleClose={handleClose}
          reviews={reviews}
        />
      </Dialog>
      <Link to="/teachers" style={{ textDecoration: "none", color: "black" }}>
        <div
          style={{
            cursor: "pointer",
            marginTop: 20,
            marginLeft: 30,
            height: 30,
            width: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid",
            borderRadius: 20,
          }}
        >
          <ArrowBackIcon />
        </div>
      </Link>
      <Container
        style={{
          marginTop: 40,
          backgroundColor: "white",
          borderRadius: 20,
          padding: 40,
          marginBottom: 40,
        }}
        maxWidth={"lg"}
      >
        {info != null && (
          <>
            <Grid spacing={4} container alignItems="flex-start">
              <Grid item xs={12} md={8}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 20,
                    }}
                  >
                    <ProfilePicture
                      teacherId={info?.teacher_id}
                      data={info}
                      country={info?.country}
                      teacher={info?.user_details?.user_id}
                      followers={info?.followers}
                      displayName={info?.user_details?.display_name}
                      image={info?.user_details?.profile_picture}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">{info?.title}</Typography>
                    <Typography className={classes.primaryText} variant="body2">
                      {info?.sub_title}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontSize: 24, marginTop: 20, marginBottom: 20 }}
                  >
                    Introduction
                  </Typography>
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Languages languages={info?.languages} />
                  </div>
                  <Summary whole={true} summary={info?.about} />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        fontSize: 24,
                        marginTop: 20,
                        marginBottom: 20,
                        marginRight: 10,
                      }}
                    >
                      Reviews{" "}
                    </Typography>
                    <StarRating i={1} />
                    <StarRating i={2} />
                    <StarRating i={3} />
                    <StarRating i={4} />
                    <StarRating i={5} />
                  </div>
                  {reviews.map(
                    (review, index) =>
                      index < 2 && (
                        <SingleComment review={review} avatarSize={"large"} />
                      )
                  )}
                  {reviews.length > 2 && (
                    <Typography
                      onClick={() => setAllReviews(true)}
                      style={{
                        marginTop: 15,
                        marginBottom: 10,
                        cursor: "pointer",
                        color: "#17C9B8",
                      }}
                    >
                      View all {reviews.length} reviews
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  style={{ fontSize: 24, marginBottom: 20, marginTop: 0 }}
                >
                  Trials
                </Typography>
                {Array.isArray(info?.trial_prices) &&
                  info?.trial_prices.map(
                    (trial) =>
                      trial.slots.length > 0 && (
                        <div ref={packageRef}>
                          <Packages data={trial} />
                        </div>
                      )
                  )}
                {packageRef.current == null && (
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 20,
                      textAlign: "center",
                      color: "#cdcdcd",
                      padding: 15,
                      border: "solid 1px #cdcdcd",
                      borderRadius: 10,
                    }}
                  >
                    No trial available
                  </Typography>
                )}
                <Typography
                  style={{ fontSize: 24, marginBottom: 20, marginTop: 30 }}
                >
                  Lessons
                </Typography>
                <ListOfLessons teacher={info?.teacher_id} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}
