import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Image from "material-ui-image";
import DraftsIcon from "@material-ui/icons/Drafts";
import Icon from "@material-ui/core/Icon";
import ReactPlayer from "react-player";
import Button from "@material-ui/core/Button";
import { ButtonBase } from "@material-ui/core";
import HtmlToReactParser from "html-to-react";
import Chip from "@material-ui/core/Chip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import { useJwt } from "react-jwt";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import Slide from "@material-ui/core/Slide";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import { Link, Redirect } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  aboutContainer: {
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 15,
      marginBottom: 15,
    },
  },
  playIcon: {
    color: "white",
    fontSize: 29,
    marginRight: 10,
  },
  navButtons: {
    backgroundColor: "white",
    padding: 2,
    height: 25,
    fontSize: 14,
    marginRight: 5,
    width: 30,
    marginLeft: 5,
    borderRadius: 5,
  },
  searchWrapper: {
    padding: 30,
    paddingBottom: 0,
    backgroundColor: "#062c32",
    [theme.breakpoints.down("md")]: {
      padding: 15,
      paddingBottom: 0,
    },
  },
  resultHeader: {
    borderColor: "black",
    border: 1,
    m: 1,
  },
  materialTitle: {
    color: "white",
  },
  imageWrapper: {
    width: "100%",
    opacity: 0.7,
  },
  animatedBtn: {
    backgroundColor: "#e98e19",
    padding: 12,
    fontSize: 16,
    color: "white",
    borderRadius: 5,
  },
  animatedBtnBox: {
    position: "absolute",
    top: 10,
    // width: '20%',
    right: 10,
    zIndex: 5,
  },
  materialsWrapper: {
    paddingLeft: 10,
    // backgroundColor: '#000',
    height: "90%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 20,
    },
  },
  materialsActions: {
    paddingLeft: 0,
    display: "flex",
    // alignItems: 'flex-start',
    flexDirection: "column",
    // justifyContent: ''
  },
  imageHolder: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    backgroundColor: "black",
    position: "relative",
    minHeight: "90%",
  },
  heading: {
    color: "white",
    marginBottom: 0,
    backgroundColor: "#041c20",
    padding: 10,
    fontSize: 20,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      backgroundColor: "transparent",
    },
  },
  playIcon: {
    color: "white",
    fontSize: 29,
    marginRight: 10,
  },
  visibleIcon: {
    color: "#FA9C24",
    fontSize: 18,
  },
  lockIcon: {
    color: "#E0E0E0",
    fontSize: 18,
    marginLeft: 7,
  },
  materialHeading: {
    marginBottom: 10,
    paddingLeft: 0,
    color: "white",
    fontSize: 18,
  },
  item: {
    marginBottom: 0,
    padding: 5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#052a30",
    paddingRight: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  materialsInner: {
    padding: 0,
    height: "100%",
  },
  imageRipple: {
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  buttonIconText: {
    marginRight: 5,
    textAlign: "left",
  },
  removeIconText: {
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#be3030",
    color: "white",
    "&:hover": {
      backgroundColor: "#992424",
    },
    textAlign: "left",
    width: "100%",
    padding: 8,
    borderRadius: 5,
  },
  buyNowBtn: {
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fa9c24",
    color: "white",
    "&:hover": {
      backgroundColor: "#b5721d",
    },
    textAlign: "left",
    width: "100%",
    padding: 8,
    borderRadius: 5,
  },
  addToCartBtn: {
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1a95ac",
    color: "white",
    "&:hover": {
      backgroundColor: "#147588",
    },
    textAlign: "left",
    width: "100%",
    padding: 8,
    borderRadius: 5,
  },

  buttonIcon: {
    width: 60,
    minWidth: 10,
  },
  aboutWrapper: {
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: 15,
    },
  },
  description: {
    paddingTop: 15,
  },
  stats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
  },
  stasWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statIcon1: {
    fontSize: 45,
    marginBottom: 10,
    color: "#00c0e2",
  },
  statIcon2: {
    fontSize: 45,
    marginBottom: 10,
    color: "#fa9c24",
  },
  statIcon3: {
    fontSize: 45,
    marginBottom: 10,
    color: "#3f51b5",
  },
  productTagHeading: {
    marginTop: 20,
    marginBottom: 10,
  },
  tag: {
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: "#c3dfe1",
  },
  relatedProduct: {
    marginLeft: 0,
    marginTop: 30,
  },
  speedIcon: {
    color: "white",
    fontSize: 20,
  },
  videoControls: {
    position: "absolute",
    backgroundColor: "#282828e0",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: 5,
    zIndex: 10,
  },
  seekBar: {
    width: "96%",
    height: 10,
    padding: 0,
    marginLeft: "2%",
  },
  leftControls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
  },
  iconControls: {
    color: "white",
  },
  volumeControl: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
  volumeSlider: {
    flex: 1,
    marginLeft: 5,
  },
  playBtn: {
    color: "white",
  },
  playerControl: {
    marginRight: 10,
  },
  rightControls: {
    paddingRight: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 15,
      paddingRight: 10,
    },
  },
  playDuration: {
    fontSize: 14,
    color: "white",
    marginLeft: 10,
  },
  tabWrapper: {
    height: "100%",
    padding: 0,
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  swipeableWrapper: {
    padding: 0,
    height: "90%",
    backgroundColor: "#083b43",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  swipeableWrapperWhite: {
    padding: 0,
    height: "92.3%",
    backgroundColor: "#083b43",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  centreInfoWrapper: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 30,
    fontSize: 25,
    backgroundColor: "white",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  centreName: {
    fontSize: 20,
    color: "white",
  },
  subscriberFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subscribeBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    "&:hover": {
      backgroundColor: "#c3740f",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 45,
      width: 45,
    },
  },
  unSubscribeBtn: {
    backgroundColor: "#dadada",
    color: "black",
    "&:hover": {
      backgroundColor: "#dadada",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 45,
      width: 45,
    },
  },
  bellIcon: {
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  authContainer: {
    backgroundColor: "#061C20",
    height: "auto",
    paddingTop: 40,
    paddingBottom: 40,
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "white",
    right: 10,
    top: 10,
  },
  courseTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authWrapper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
  },
  checkIcon: {
    width: "15%",
  },
  textCenter: {
    textAlign: "center",
  },

  selecteditem: {
    marginBottom: 0,
    padding: 5,
    borderBottomColor: "#052a30",
    paddingRight: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0e5f6b",
  },
  pdfPreviewPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  pdfLow: {
    color: "none",
  },
  pdfHigh: {
    color: "none",
  },
  commenterName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  singleComment: {
    backgroundColor: "white",
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: "2px 2px 6px #9e9e9e1a",
  },
  commentDate: {
    fontSize: 10,
    color: "gray",
  },
  comment: {
    color: "gray",
  },
  commentsContainer: {
    backgroundColor: "#f0faff",
    borderRadius: 10,
    marginTop: 20,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  removeBtn: {
    backgroundColor: "#d55555",
    color: "white",
    borderRadius: 5,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#ad4242",
    },
  },

  playName: {
    display: "flex",
  },

  addBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    borderRadius: 12,
    fontSize: 8,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#a35e05",
    },
  },
  coursePrice: {
    fontSize: 19,
    marginRight: 10,
    color: "white",
  },
  discountedPrice: {
    textDecoration: "line-through",
    color: "gray",
    fontSize: 15,
    marginRight: 5,
  },
  downloadIcon: {
    backgroundColor: "#fa9c24",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    height: 25,
    width: 25,
    color: "white",
    cursor: "pointer",
  },
  printIcon: {
    backgroundColor: "#1a95ac",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    height: 25,
    width: 25,
    marginLeft: 5,
    color: "white",
  },
  buyBtn: {
    backgroundColor: "#f16020",
    color: "white",
    borderRadius: 12,
    fontSize: 14,
    padding: 6,
    paddingRight: 15,
    paddingLeft: 15,
    height: 35,
    "&:hover": {
      backgroundColor: "#cc501a",
    },
  },
  creditsWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "left",
  },
  credits: {
    color: "white",
    textAlign: "left",
  },
  tab: {
    fontSize: 12,
    width: "50%",
    minWidth: "100px",
    color: "white",
    padding: 0,
    "& span": {
      color: "white",
    },
  },
  pdfLoader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  playAgain: {
    background: "gray",
  },
  fullScreen: {
    color: "white",
    cursor: "pointer",
  },
  backButton: {
    marginBottom: 10,
    padding: 5,
    borderRadius: 3,
    backgroundColor: "#FA9D28",
  },
  backLink: {
    color: "white",
    textDecoration: "none",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    fontSize: 18,
    marginRight: 5,
  },
  loader: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000099",
  },
}));

export default function PurchasedMaterials(superProps) {
  let product = superProps.product;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [playingMaterial, setPlayingMaterial] = React.useState(null);
  const theme = useTheme();
  const serverUrl = process.env.REACT_APP_UPLOADS_URL;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const VideosTab = (props) => {
    let materials =
      product.materials != null
        ? product.materials.filter((item) => {
            return item.type == ".mp4";
          })
        : null;
    return (
      <TabPanel
        className={classes.tabWrapper}
        value={value}
        index={props.index}
        dir={theme.direction}
      >
        <Grid container className={classes.materialsInner}>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              {product.materials != null &&
                product.materials.map((material, index) =>
                  material.type == ".mp4" || material.type == "mp4" ? (
                    <ListItem
                      key={material.id}
                      onClick={() => {
                        if (product.subscriber_type == "teacher")
                          material.uploaded_channel == "local"
                            ? superProps.setVideo(
                                process.env.REACT_APP_FOLDER_URL +
                                  process.env.REACT_APP_MATERIAL_PATH +
                                  material.material_name
                              )
                            : superProps.setVideo(
                                "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" +
                                  material.material_name
                              );
                        else
                          superProps.setVideo(
                            "courses/materials/" +
                              material.subscriber_id +
                              "/" +
                              material.material_name
                          );
                        superProps.setView("VIDEO");
                        setPlayingMaterial(material.id);
                      }}
                      className={
                        superProps.view == "VIDEO" &&
                        playingMaterial == material.id
                          ? classes.selecteditem
                          : classes.item
                      }
                      button
                    >
                      <div className={classes.playName}>
                        <Icon className={classes.playIcon}>play_circle</Icon>
                        <ListItemText
                          className={classes.materialTitle}
                          primary={material.org_name}
                        />
                      </div>

                      {
                        // material.subscriber_id == 0 ?
                        //     <Icon onClick={() => superProps.download(material.material_name, material.org_name, material.subscriber_id)} className={classes.downloadIcon}>download</Icon>
                        //     :
                        //     <Icon onClick={() => superProps.download('courses/materials/' + material.subscriber_id + '/' + material.material_name, material.org_name, material.subscriber_id)} className={classes.downloadIcon}>download</Icon>
                      }
                    </ListItem>
                  ) : (
                    ""
                  )
                )}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const WorksheetsTab = (props) => {
    return (
      <TabPanel
        className={classes.tabWrapper}
        value={value}
        index={props.index}
        dir={theme.direction}
      >
        <Grid container className={classes.materialsInner}>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              {product.materials != null &&
                product.materials.map((material, index) =>
                  material.type == ".pdf" || material.type == "pdf" ? (
                    <div
                      className={
                        superProps.view == "PDF" &&
                        playingMaterial == material.id
                          ? classes.selecteditem
                          : classes.item
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        key={material.id}
                        onClick={() => {
                          superProps.setPageNumber(1);
                          superProps.setPdfImages(null);
                          if (material.subscriber_id == 0) {
                            material.uploaded_channel == "local"
                              ? superProps.setPdf(
                                  process.env.REACT_APP_FOLDER_URL +
                                    process.env.REACT_APP_MATERIAL_PATH +
                                    "material/" +
                                    material.material_name
                                )
                              : superProps.setPdf(
                                  "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" +
                                    material.material_name
                                );
                          } else
                            superProps.setPdf(
                              serverUrl +
                                "courses/materials/" +
                                material.subscriber_id +
                                "/" +
                                material.material_name
                            );

                          superProps.setNumPages(null);
                          superProps.setView("PDF");
                          setPlayingMaterial(material.id);
                        }}
                        className={
                          superProps.view == "PDF" &&
                          playingMaterial == material.id
                            ? classes.selecteditem
                            : classes.item
                        }
                        button
                      >
                        <div className={classes.playName}>
                          <Icon className={classes.playIcon}>description</Icon>
                          <ListItemText
                            className={classes.materialTitle}
                            primary={material.org_name}
                          />
                        </div>
                      </ListItem>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {material.subscriber_id == 0 &&
                        product.showWaterMark == "1" ? (
                          <Icon
                            onClick={() =>
                              superProps.download(
                                "material/" + material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        ) : product.showWaterMark == "1" ? (
                          <Icon
                            onClick={() =>
                              superProps.download(
                                "courses/materials/" +
                                  material.subscriber_id +
                                  "/" +
                                  material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        ) : (
                          <></>
                        )}
                        {/* {isMobile && (
                          <div className="hiddenPrintffff">
                            <Icon
                              onClick={() => {
                                material.subscriber_id == 0
                                  ? material.uploaded_channel == "local"
                                    ? superProps.printWorksheet(
                                        process.env.REACT_APP_FOLDER_URL +
                                          process.env.REACT_APP_MATERIAL_PATH +
                                          "material/" +
                                          material.material_name
                                      )
                                    : superProps.printWorksheet(
                                        "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" +
                                          material.material_name
                                      )
                                  : // superProps.printWorksheet(material.material_name, material.org_name, 'teacher')
                                    superProps.printWorksheet(
                                      "courses/materials/" +
                                        material.subscriber_id +
                                        "/" +
                                        material.material_name,
                                      material.org_name,
                                      material.subscriber_id
                                    );
                              }}
                              className={classes.printIcon}
                            >
                              print
                            </Icon>
                          </div>
                        )} */}
                      </ListItemIcon>
                    </div>
                  ) : (
                    ""
                  )
                )}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  let videoMaterial = [];
  let pdfMaterial = [];

  React.useEffect(() => {
    // alert(isMobile)
  }, []);
  if (product.materials != null && product.materials.length > 0) {
    videoMaterial = product.materials.filter((item) => {
      return item.type == ".mp4" || item.type == "mp4";
    });
    pdfMaterial = product.materials.filter((item) => {
      return item.type == ".pdf" || item.type == "pdf";
    });
  }

  return (
    <>
      <AppBar
        className="tabls-panel"
        style={{ backgroundColor: "#052a30" }}
        position="static"
        color="default"
      >
        {videoMaterial.length > 0 ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab className="tab-single" label="Videos" {...a11yProps(0)} />
            <Tab className="tab-single" label="Worksheets" {...a11yProps(1)} />
          </Tabs>
        ) : (
          // (videoMaterial.length == 0 || pdfMaterial.length > 0) &&
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab label="Worksheets" {...a11yProps(0)} />
            <Tab label="Videos" {...a11yProps(1)} />
          </Tabs>
        )}
      </AppBar>
      {(videoMaterial.length > 0 || pdfMaterial.length == 0) && (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeableWrapperWhite}
        >
          <VideosTab index={0} />
          <WorksheetsTab index={1} />
        </SwipeableViews>
      )}
      {videoMaterial.length == 0 && pdfMaterial.length > 0 && (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeableWrapperWhite}
        >
          <WorksheetsTab index={0} />
          <VideosTab index={1} />
        </SwipeableViews>
      )}
    </>
  );
}
