import React from "react";
import { Route, Redirect } from "react-router-dom";
import PassCodePopUp from "../components/auth/PassCodePopUp";
import Product from "../services/products";
import StaticLoader from "../components/shared/loaders/StaticLoader";

export const PasscodedRoute = ({ component: Component, ...rest }) => {
  const [loader, setLoader] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [codeProtected, setCodeProducted] = React.useState(true);
  React.useEffect(() => {
    let url = window.location.pathname;
    const courseid = url.substring(url.lastIndexOf("/") + 1);

    Product.getProduct(courseid, false).then(
      (res) => {
        setLoader(false);
        if (
          res.data[0].access_code == "" ||
          res.data[0].access_code == null ||
          res.data[0].access_code == "FREECOURSE"
        ) {
          setCodeProducted(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleGrant = () => {
    setLoading(false);
  };

  return loader ? (
    <StaticLoader title="Please wait..." />
  ) : loading && codeProtected ? (
    <>
      <PassCodePopUp handleGrant={handleGrant} />
    </>
  ) : (
    <>
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    </>
  );
};
