import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import './styles.css';

export default function TopBar({ adminUrl }) {
    return (
        <div className="admin-top-bar">
            <h4 style={{ margin: 0, fontWeight: 500, marginLeft: 0, marginBottom: 15 }}>Admin portal</h4>
            <Grid container alignItems="center">
                <Grid item className="nav-link">
                    <Tooltip title={"User Management"} placement="top">
                        <NavLink activeClassName='is-active' className="admin-nav-btn" to={adminUrl + "/management"}>
                            <Icon>supervisor_account</Icon>
                        </NavLink>
                    </Tooltip>
                </Grid>
                <Grid item className="nav-link">
                    <Tooltip title={"Course Management"} placement="top">
                        <NavLink activeClassName='is-active' className="admin-nav-btn" to={adminUrl + "/course-management"}>
                            <Icon>library_books</Icon>
                        </NavLink>
                    </Tooltip>
                </Grid>
                <Grid item className="nav-link">
                    <Tooltip title={"Income Management"} placement="top">
                        <NavLink activeClassName='is-active' className="admin-nav-btn" to={adminUrl + "/income-management"}>
                            <Icon>monetization_on</Icon>
                        </NavLink>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    )
}
