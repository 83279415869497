import React from 'react';
import Product from '../services/products';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import './css/search.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";
import SearchResult from '../components/products/search';

const useStyles = makeStyles({
    searchWrapper: {
        padding: 30
    },
    resultHeader: {
        borderColor: 'black',
        border: 1,
        m: 1,
    }
})

export default function Search() {
    const classes = useStyles();
    let { term } = useParams();
    return (
        <div className={classes.searchWrapper}>
            <SearchResult title={term} />
        </div>
    )
}
