import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  TextField,
  Button,
  Switch,
  FormControl,
  LinearProgress,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  FullCalender,
  CreateClass,
  EnrolmentForm,
} from "../components/classes";
import { useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Classes from "../services/classes";
import Products from "../services/products";
import TeacherService from "../services/teacher";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "material-react-toastify";
import BookingDetails from "../components/classes/BookingDetails";
import StudentBookingDetails from "../components/classes/StudentBookingDetails";
const ClassesSwitch = withStyles({
  switchBase: {
    color: "#40a5b8",
    "&$checked": {
      color: "#1A95AC",
    },
    "&$checked + $track": {
      backgroundColor: "#1A95AC",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function MyClasses({ title }) {
  const FILTER_TYPE = ["All", "Enrolled", "My classes"];

  const [newClassDate, setNewClassDate] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [event, setEvent] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isMyCourse, setIsMyCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courseEditable, setCourseEditable] = useState(true);
  const [editCourse, setEditCourse] = useState();
  const [courseId, setCourseId] = useState(0);
  const [courseName, setCourseName] = useState();
  const [filterBy, setFilterBy] = useState("All");
  const [events, setEvents] = useState([]);
  const [myClasses, setMyClasses] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [enrolledClasses, setEnrolledClasses] = useState([]);
  const { user } = React.useContext(UserContext);
  const [bookings, setBookings] = React.useState([]);
  const [singleBooking, setSingleBooking] = React.useState(null);
  const [showBookingModal, setShowBookingModal] = React.useState(false);

  const handleDateClick = (arg) => {
    setNewClassDate(arg.dateStr);
    if (user[0].role_id == 4) {
      if (isMyCourse || title == "My Classes") {
        setShowForm("CREATION");
      }
    }
  };

  const handleClossSidebar = () => {
    setNewClassDate(null);
    setShowForm(null);
  };

  let { course_id, course_title } = useParams();
  const check = (date) => {
    // getMyClasses(date)
    setCurrentDate(date);
    setEvents([]);
    if (course_id) {
      getCourseClasses(course_id, date);
    } else {
      if (filterBy == "All") {
        getMyClasses(date);
      } else if (filterBy == "Enrolled") {
        getEnrolledClasses(date);
      } else {
        getTeacherClasses(date);
      }
    }
  };

  React.useEffect(() => {
    if (title == "Classes") {
      if (course_id) {
        Products.getProduct(course_id).then((res) => {
          // if (user[0].user_id == 4 )
          setCourseId(course_id);
          setCourseEditable(false);
          setIsMyCourse(user[0].user_id == res.data[0].course_teacher);
        });
        // getCourseClasses(course_id)
      }
    } else {
      setCourseEditable(true);
      setIsMyCourse(true);
      user[0].role_id == 4
        ? getTeacherTrialBookings()
        : getStudentsTrialBookings();
    }
  }, [course_id]);

  function getCurrentMonthDates(startdate = null) {
    var firstDay = "";
    var lastDay = "";
    if (startdate == null) {
      var date = new Date();
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      setFromDate(getDateYMD(firstDay));

      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setToDate(getDateYMD(lastDay));
    } else {
      firstDay = startdate.start;
      lastDay = startdate.end;
    }
    return {
      firstDay: firstDay,
      lastDay: lastDay,
    };
  }

  const getAllClasses = (event) => {
    let currentMonthDates = getCurrentMonthDates();

    let filter = {
      startingDate: currentMonthDates["firstDay"],
      endingDate: currentMonthDates["lastDay"],
      teacher_id: events[0].teacher_id,
    };
    if (event.target.checked) {
      Classes.getAllClassesByDate(filter).then((res) => {
        organizeEvents(res.data.classes);
      });
    } else {
      getCourseClasses(course_id);
    }
  };

  const getEnrolledClasses = (date) => {
    let currentMonthDates = getCurrentMonthDates(date);
    // if (user[0].role_id == 3) {
    let filter = {
      startingDate: currentMonthDates["firstDay"],
      endingDate: currentMonthDates["lastDay"],
      student_id: user[0].user_id,
    };
    Classes.getEnrolledClasses(filter).then((res) => {
      organizeEvents(res.data.classes);
    });
    // }
  };

  const getTeacherClasses = (date) => {
    let currentMonthDates = getCurrentMonthDates(date);
    let filter = {
      startingDate: currentMonthDates["firstDay"],
      endingDate: currentMonthDates["lastDay"],
      teacher_id: user[0].user_id,
    };
    Classes.getTeacherClasses(filter).then((res) => {
      organizeEvents(res.data.classes);
    });
  };

  useEffect(() => {
    setEvents([...events, ...bookings]);
  }, [bookings]);

  const getTeacherTrialBookings = () => {
    const data = {
      teacher_id: user[0].auth_id,
    };
    TeacherService.getTrialBookings(data).then((res) => {
      if (res.data.bookings != null) {
        let availableBookings = res.data.bookings.filter(
          (booking) => booking.trial.price_id
        );
        setBookings(
          availableBookings.map((booking) => {
            const { trial_date, trial_time, trial, slot_id } = booking;
            const { duration, venue, price } = trial;
            return {
              start: trial_date + "T" + trial_time,
              end: trial_date + "T" + trial_time,
              time: trial_time,
              date: trial_date,
              title: duration,
              venue: venue,
              price: price,
              color: "#F99C24",
              className: "custom",
              class: "custom",
              eventType: "booking",
              slotId: slot_id,
            };
          })
        );
      }
    });
  };

  const getStudentsTrialBookings = () => {
    const data = {
      student_id: user[0].user_id,
    };
    TeacherService.getStudentsTrialBookings(data).then((res) => {
      console.log(res);
      if (res.data.bookings != null) {
        let availableBookings = res.data.bookings.filter(
          (booking) => booking.trial.price_id
        );
        setBookings(
          availableBookings.map((booking) => {
            const { trial_date, trial_time, trial, slot_id } = booking;
            const { duration, venue, price } = trial;
            return {
              start: trial_date + "T" + trial_time,
              end: trial_date + "T" + trial_time,
              time: trial_time,
              date: trial_date,
              title: duration,
              venue: venue,
              price: price,
              teacher: booking.teacher.display_name,
              color: "#F99C24",
              className: "custom",
              class: "custom",
              eventType: "booking",
              slotId: slot_id,
            };
          })
        );
      }
    });
  };

  const getMyClasses = (date = null) => {
    getEnrolledClasses(date);
    if (user[0].role_id == 4) {
      getTeacherClasses(date);
    }
  };

  const getCourseClasses = (course_id, startdate = null) => {
    let currentMonthDates = getCurrentMonthDates(startdate);
    let filter = {
      startingDate: currentMonthDates["firstDay"],
      endingDate: currentMonthDates["lastDay"],
      course_id: course_id,
    };
    Classes.getCourseClasses(filter).then((res) => {
      organizeEvents(res.data.classes);
    });
  };

  const organizeNewEvents = (event, classId = 0) => {
    let repeatedDates = null;
    let repeatedLessons = [];
    let classes = [event];
    let actualLessons = classes.map((lesson) => {
      lesson["start"] = lesson.date + "T" + lesson.time;
      lesson["end"] = lesson.date + "T" + lesson.end_time;
      lesson["teacher_name"] = user[0]["details"]["display_name"];
      lesson["eventType"] = "classes";
      return lesson;
    });
    let prev = events;

    prev = prev.filter((singleClass) => singleClass.class_id != classId);
    actualLessons.forEach((single) => {
      let repeatLesson = single;
      if (single.repeat_on != null) {
        let repeatedDays = single.repeat_on.split("|");
        repeatedDays.forEach((day) => {
          repeatedDates = getDaysBetweenDates(
            new Date(single.date),
            new Date(single.ending_date),
            day
          );
          repeatedDates.forEach((repeatDate) => {
            if (repeatLesson.date != getDateYMD(repeatDate)) {
              repeatedLessons[repeatedLessons.length] = {
                title: repeatLesson.title,
                start: getDateYMD(repeatDate) + "T" + repeatLesson.time,
                end: getDateYMD(repeatDate) + "T" + repeatLesson.end_time,
                class_id: repeatLesson.class_id,
                course_id: repeatLesson.course_id,
                time: repeatLesson.time,
                students_limit: repeatLesson.students_limit,
                ending_date: repeatLesson.ending_date,
                end_time: repeatLesson.end_time,
                class_price: repeatLesson.class_price,
                color: repeatLesson.color,
                teacher: repeatLesson.teacher,
                venue: repeatLesson.venue,
                zoom_link: repeatLesson.zoom_link,
                teacher_name: repeatLesson.teacher_name,
                eventType: "classes",
              };
            }
          });
        });
      }
    });

    actualLessons = actualLessons.concat(repeatedLessons);
    let allEvents = prev.concat(actualLessons);
    setEvents(allEvents);
  };

  const organizeEvents = (classes) => {
    let repeatedDates = null;
    let repeatedLessons = [];
    let actualLessons = classes.map((lesson) => {
      lesson["start"] = lesson.date + "T" + lesson.time;
      lesson["end"] = lesson.date + "T" + lesson.end_time;
      lesson["eventType"] = "classes";
      return lesson;
    });

    actualLessons.forEach((single) => {
      let repeatLesson = single;
      if (single.repeat_on != null) {
        let repeatedDays = single.repeat_on.split("|");
        repeatedDays.forEach((day) => {
          repeatedDates = getDaysBetweenDates(
            new Date(single.date),
            new Date(single.ending_date),
            day
          );
          repeatedDates.forEach((repeatDate) => {
            if (repeatLesson.date != getDateYMD(repeatDate)) {
              repeatedLessons[repeatedLessons.length] = {
                title: repeatLesson.title,
                start: getDateYMD(repeatDate) + "T" + repeatLesson.time,
                end: getDateYMD(repeatDate) + "T" + repeatLesson.end_time,
                class_id: repeatLesson.class_id,
                course_id: repeatLesson.course_id,
                time: repeatLesson.time,
                students_limit: repeatLesson.students_limit,
                ending_date: repeatLesson.ending_date,
                end_time: repeatLesson.end_time,
                class_price: repeatLesson.class_price,
                color: repeatLesson.color,
                teacher: repeatLesson.teacher,
                venue: repeatLesson.venue,
                zoom_link: repeatLesson.zoom_link,
                teacher_name: repeatLesson.teacher_name,
                eventType: "classes",
              };
            }
          });
        });
      }
    });
    actualLessons = actualLessons.concat(repeatedLessons);
    setEvents((currentArray) => [...currentArray, ...actualLessons]);
  };

  function getDateYMD(date) {
    let ymd =
      date.getFullYear() +
      "-" +
      (date.getMonth() < 10 ? 0 : "") +
      (date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? 0 : "") +
      date.getDate();
    return ymd;
  }

  function getDaysBetweenDates(start, end, dayName) {
    end.setDate(end.getDate() + 1);

    var result = [];
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    var day = days[dayName.toLowerCase().substr(0, 3)];
    // Copy start date
    var current = new Date(start);
    // Shift to next of required days
    current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
    // While less than end date, add dates to result array
    while (current < end) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
    return result;
  }

  const handleApplyFilters = (data) => {
    // console.log(data)
    // if (course_id) {
    //   getCourseClasses(course_id, data)
    // }
    // else {
    //   getMyClasses(data)
    // }
  };

  const handleEventClick = (e) => {
    const { eventType } = e.event._def.extendedProps;
    if (eventType == "classes") {
      setIsMyCourse(user[0].user_id == e.event._def.extendedProps.teacher);
      setEvent(e.event);
      setShowForm("ENROLL");
    } else {
      setShowBookingModal(true);
      setSingleBooking(e.event);
    }
  };

  const handleAfterEnrolment = (message) => {
    toast.success(message);
  };

  const handeEditCourse = (event) => {
    setShowForm("EDIT");
    setEditCourse(event);
    setCourseId(event._def.extendedProps.course_id);
    setNewClassDate(getShortDate(event.start));
  };

  function getShortDate(date) {
    date = new Date(date);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate()
    );
  }

  const handleFilterChange = (event) => {
    let value = event.target.value;
    setEvents([]);
    setFilterBy(value);
    if (value == "All") {
      getMyClasses(currentDate);
    } else if (value == "Enrolled") {
      getEnrolledClasses(currentDate);
    } else {
      getTeacherClasses(currentDate);
    }
  };

  return (
    <Container style={{ marginTop: 40, marginBottom: 40 }} maxWidth={"xl"}>
      <ToastContainer position="top-center" autoClose={4000} />
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          lg={
            showForm == "CREATION" || showForm == "ENROLL" || showForm == "EDIT"
              ? 9
              : 12
          }
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                style={{ marginBottom: 40, fontSize: 24 }}
                variant="h4"
              >
                {title == "Classes" ? "Classes for " + course_title : title}
              </Typography>
            </Grid>
            <Grid item>
              {
                title == "Classes" && events.length > 0 && (
                  <FormControlLabel
                    control={
                      <ClassesSwitch
                        onChange={getAllClasses}
                        name="display_other_classes"
                      />
                    }
                    label={
                      events.length > 0 &&
                      events[0].teacher_name + "'s other courses"
                    }
                    labelPlacement="start"
                  />
                )
                // <Button onClick={getAllClasses} style={{ marginBottom: 40, backgroundColor: "#1A95AC", color: 'white' }} >Display other courses</Button>
              }
              {title == "My Classes" && user[0].role_id == 4 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ fontSize: 18, marginBottom: 5 }} id="filter">
                    Show classes
                  </label>
                  <FormControl style={{ m: 1, width: 167 }} size="small">
                    <Select
                      labelId="filter"
                      id="filter"
                      onChange={handleFilterChange}
                      value={filterBy}
                      variant={"outlined"}
                    >
                      {
                        // FILTER_TYPE
                        FILTER_TYPE.map(function (type) {
                          return (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              )}
            </Grid>
          </Grid>
          {loading && <LinearProgress />}
          <FullCalender
            check={check}
            handleApplyFilters={handleApplyFilters}
            handleEventClick={handleEventClick}
            handleDateClick={handleDateClick}
            events={events}
          />
        </Grid>
        {showForm == "EDIT" && (
          <CreateClass
            setLoading={setLoading}
            editData={editCourse}
            courseEditable={courseEditable}
            editClass={true}
            course_id={courseId}
            organizeNewEvents={organizeNewEvents}
            classDate={newClassDate}
            handleClossSidebar={handleClossSidebar}
          />
        )}
        {showForm == "CREATION" && (
          <CreateClass
            setLoading={setLoading}
            editData={null}
            courseEditable={courseEditable}
            editClass={false}
            course_id={course_id}
            organizeNewEvents={organizeNewEvents}
            classDate={newClassDate}
            handleClossSidebar={handleClossSidebar}
          />
        )}
        {showForm == "ENROLL" && (
          <EnrolmentForm
            handeEditCourse={handeEditCourse}
            edit={isMyCourse}
            teacher={title}
            handleAfterEnrolment={handleAfterEnrolment}
            handleClossSidebar={handleClossSidebar}
            event={event}
          />
        )}
        {user[0].role_id == 4 ? (
          <BookingDetails
            closeModal={() => setShowBookingModal(false)}
            show={showBookingModal}
            bookingData={singleBooking}
          />
        ) : (
          <StudentBookingDetails
            closeModal={() => setShowBookingModal(false)}
            show={showBookingModal}
            bookingData={singleBooking}
          />
        )}
      </Grid>
    </Container>
  );
}
