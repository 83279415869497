import React, { useState, createContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = props => {
    const [open, setOpen] = useState(false);

    return (
        <AuthContext.Provider value={[open, setOpen]}>
            {props.children}
        </AuthContext.Provider>
    )
}