import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import lessons from "./lessons.json";
import { BuyNowContext } from "../../../context/BuyNowContext";
import TeacherService from "../../../services/teacher";
import { UserContext } from "../../../context/UserContext";
import { Collapse } from "antd";
import { useContext } from "react";
import { useState } from "react";
import Checkout from "../../checkout/checkout";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import { Link } from "react-router-dom";
const { Panel } = Collapse;

const ListOfLessons = ({ teacher }) => {
  const { dialog } = React.useContext(BuyNowContext);
  const [buy, setBuy] = useState(false);
  const { user } = useContext(UserContext);
  const [currentUser, setCurrentUser] = user;
  const [lessons, setLessons] = useState([]);
  const [selected, setSelected] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  const [monthWise, setMonthWise] = useState([]);
  const [monthWiseLessons, setMonthWiseLessons] = useState([]);
  const [firstMonthSlots, setFirstMonthSlots] = useState([]);
  const [secondMonthSlots, setSecondMonthSlots] = useState([]);
  const [thirdMonthSlots, setThirdMonthSlots] = useState([]);
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const secondDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const secondLastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  const thirdDay = new Date(date.getFullYear(), date.getMonth() + 2, 1);
  const thirdLastDay = new Date(date.getFullYear(), date.getMonth() + 3, 0);

  useEffect(() => {
    const dateWise = [];
    if (teacher) {
      TeacherService.getLessonsByTeacherId({
        teacher_id: teacher,
      }).then((res) => {
        // res.data.lessons.map((trial) => {
        //   trial.slots.map((slot) => {
        //     dateWise[slot.trial_date]
        //       ? (dateWise[slot.trial_date] = [
        //           ...dateWise[slot.trial_date],
        //           slot,
        //         ])
        //       : (dateWise[slot.trial_date] = [slot]);
        //   });
        // });
        // console.log(dateWise);
        // setMonthWise(dateWise);
        setLessons(
          res.data.lessons
            .filter((lesson) => lesson.slots.length > 0)
            .map((lesson) => {
              return { ...lesson, date: lesson.slots[0].trial_date };
            })
        );
      });
    }
  }, [currentUser]);

  const handleConfirmation = () => {
    setOpenModal(false);
    setShowSuccess(true);
  };

  let val = [];
  useEffect(() => {
    if (lessons.length > 0) {
      lessons.forEach((va, index) => {
        va.slots.forEach((slot) => {
          if (val[slot.trial_date]) {
            val[slot.trial_date].push({
              ...slot,
              duration: va.duration,
              price: va.price,
              teacher_id: va.teacher_id,
              price_id: va.price_id,
            });
          } else {
            val[slot.trial_date] = [
              {
                ...slot,
                duration: va.duration,
                price: va.price,
                teacher_id: va.teacher_id,
                price_id: va.price_id,
              },
            ];
          }
        });
        setMonthWise(Object.entries(val));
      });
    }
  }, [lessons]);

  useEffect(() => {
    setMonthWiseLessons(
      monthWise.map((month) => {
        return { date: month[0], slots: month[1] };
      })
    );
  }, [monthWise]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const HeaderOne = () => {
    return (
      <>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {firstDay.getDate() + " " + months[firstDay.getMonth()]}
        </span>
        <span> - </span>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {firstLastDay.getDate() +
            " " +
            months[firstLastDay.getMonth()] +
            " " +
            firstLastDay.getFullYear()}
        </span>
      </>
    );
  };
  const HeaderTwo = () => {
    return (
      <>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {
            secondDay.getDate() + " " + months[secondDay.getMonth()]
            // " " +
            // firstDay.getFullYear()
          }
        </span>
        <span> - </span>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {secondLastDay.getDate() +
            " " +
            months[secondLastDay.getMonth()] +
            " " +
            secondLastDay.getFullYear()}
        </span>
      </>
    );
  };
  const HeaderThree = () => {
    return (
      <>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {thirdDay.getDate() + " " + months[thirdDay.getMonth()]}
        </span>
        <span> - </span>
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {thirdLastDay.getDate() +
            " " +
            months[thirdLastDay.getMonth()] +
            " " +
            thirdLastDay.getFullYear()}
        </span>
      </>
    );
  };

  const SlotsTabs = ({ start, end }) => {
    console.log(start, end);
    return (
      <Collapse
        defaultActiveKey={["1"]}
        style={{ width: "100%" }}
        className="lessons-collapsable-section"
      >
        {monthWiseLessons.map((lesson, index) => {
          if (
            new Date(new Date(lesson.date).setHours(0)).getTime() >=
              new Date(start).getTime() &&
            new Date(new Date(lesson.date).setHours(0)).getTime() <=
              new Date(end).getTime()
          )
            return (
              <Panel
                style={{
                  width: "100%",
                  border: "solid 1px #F4F4F4",
                }}
                header={
                  <Box
                    sx={{
                      width: "100%",
                      padding: "5px",
                      backgroundColor: "#e6faf8",
                    }}
                  >
                    <Typography
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ color: "#02cab9", fontWeight: 600 }}>
                        {lesson.date}{" "}
                      </span>
                    </Typography>
                  </Box>
                }
                key={index + 1}
              >
                <Box>
                  <Box sx={{ padding: "7px" }}>
                    {lesson?.slots?.map((slot, key) => {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            boxShadow: "none",
                            marginTop: "5px",
                          }}
                        >
                          <Card
                            key={key}
                            sx={{
                              boxShadow: "none",
                              backgroundColor: "#f4f4f4",
                              padding: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Typography style={{ lineHeight: 1 }}>
                                  {slot.trial_time}
                                </Typography>
                                <Typography
                                  style={{
                                    lineHeight: 1,
                                    color: "#a4a4a4",
                                  }}
                                >
                                  <small>{slot.duration}</small>
                                </Typography>
                              </div>

                              <Button
                                sx={{
                                  bgcolor: "#17cab8",
                                  boxShadow: "none",
                                  textTransform: "none",
                                }}
                                variant="contained"
                                onClick={() => {
                                  setSelected({
                                    ...slot,
                                    teacher_id: slot.teacher_id,
                                    price_id: slot.price_id,
                                    price: slot.price,
                                  });
                                  setOpenModal(true);
                                }}
                              >
                                <span style={{ marginRight: 8 }}>
                                  ${slot.price}{" "}
                                </span>{" "}
                                Book Now
                              </Button>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Panel>
            );
        })}
      </Collapse>
    );
  };

  return (
    <Box>
      <Box sx={{ mt: "20px", display: "flex", flexWrap: "wrap" }}>
        <Collapse style={{ width: "100%" }} defaultActiveKey={["1"]}>
          <Panel
            style={{
              width: "100%",
              border: "solid 1px #F4F4F4",
            }}
            header={<HeaderOne />}
            key={1}
          >
            <SlotsTabs start={firstDay} end={firstLastDay} />
          </Panel>
          <Panel
            style={{
              width: "100%",
              border: "solid 1px #F4F4F4",
            }}
            header={<HeaderTwo />}
            key={2}
          >
            <SlotsTabs start={secondDay} end={secondLastDay} />
          </Panel>
          <Panel
            style={{
              width: "100%",
              border: "solid 1px #F4F4F4",
            }}
            header={<HeaderThree />}
            key={3}
          >
            <SlotsTabs start={thirdDay} end={thirdLastDay} />
          </Panel>
        </Collapse>
      </Box>
      <Box></Box>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-modal-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
          {"Confirm your booking"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {loading && (
              <h3 style={{ textAlign: "center" }}>Booking your lesson..</h3>
            )}

            {selected && (
              <Checkout
                for={"booking"}
                handleLoading={() => setLoading(true)}
                handleConfirmation={handleConfirmation}
                trials={[selected]}
                total={selected?.price}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={"xs"}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText align="center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 60,
                  backgroundColor: "#20B774",
                  border: "solid 2px white",
                  marginBottom: 10,
                }}
              >
                <DoneOutlineRoundedIcon style={{ color: "white" }} />
              </div>
            </div>
            <h3 style={{ textAlign: "center", color: "#20B774" }}>
              Your booking is confirmed
            </h3>
            <p style={{ textAlign: "center", color: "black" }}>
              You have successfully booked a trial session.
              <br />
              Click <Link to="/my/bookings">here</Link> to check the lesson
              details.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 15,
            }}
          >
            <Button
              style={{
                width: "80%",
                backgroundColor: "#20B774",
                color: "white",
              }}
              onClick={() => setShowSuccess(false)}
              color="success"
              autoFocus
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ListOfLessons;
