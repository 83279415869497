import axios from 'axios';
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Product = {
    // Get List of all products
    getTeachers: function (status, limit, offset) {
        return axios({
            "method": "GET",
            "url": url + 'teachers/' + status + '/' + limit + '/' + offset,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },
    getTeacherDocuments: function (teacher) {
        return axios({
            "method": "GET",
            "url": url + 'teacher/documents/' + teacher,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },
    getIncomeByDate: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'sales/date',
            "headers": {
                "content-type": "application/octet-stream",
            }, "data": JSON.stringify({
                "email": data.email,
                "dateFrom": data.dateFrom,
                "dateTo": data.dateTo,
            })
        })
    },
    getSalesByTeacher: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/sales',
            "headers": {
                'content-Type': 'application/json'
            }, "data": JSON.stringify(data)
        })
    },
    getPayoutsByTeacher: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/payouts',
            "headers": {
                'content-Type': 'application/json'
            }, "data": JSON.stringify(data)
        })
    },
    getIncomeByEmail: function (email) {
        return axios({
            "method": "POST",
            "url": url + 'sales/email',
            "headers": {
                'content-Type': 'application/json'
            }, "data": JSON.stringify({ "email": email })
        })
    },
    transferPayouts: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'sales/transfer',
            "headers": {
                'content-Type': 'application/json'
            }, "data": JSON.stringify(data)
        })
    },
    getIncome: function (offset, limit) {
        return axios({
            "method": "GET",
            "url": url + 'sales?offset=' + offset + '&limit=' + limit,
            "headers": {
                "content-type": "application/octet-stream",
            }
        })
    },
    createTeacherDocumentRequest: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/documents/request',
            "headers": {
                "content-type": "application/octet-stream",
            }, "data": data
        })
    },
    updateUserStatus: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/status',
            "headers": {
                "content-type": "application/octet-stream",
            }, "data": data
        })
    },
    updateRequest: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/update/request',
            "headers": {
                "content-type": "application/octet-stream",
            }, "data": data
        })
    },

    getTeacherDocumentRequest: function (teacher) {
        return axios({
            "method": "GET",
            "url": url + 'teacher/request/' + teacher,
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            }
        })
    },


}

export default Product;