import React, { useEffect, useState,useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Container,
    Typography,
    TextField,
    Button,
    Collapse,
    Modal,
    Box,
    Chip,
    Icon,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import { ToastContainer, toast } from 'material-react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import AdminService from '../services/admin';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #d9d9d9',
    boxShadow: 0,
    borderRadius: 15,
    p: 4,
};


const TransferModal = ({ date, data, handleClose, handleNotification }) => {

    // const transfer = [
    //     "teacher_id": data.teacher_id,
    //     "amount":
    // ]
    const [submitting, setSubmitting] = useState(false)
    const TransferSchema = Yup.object().shape({
        amount: Yup.string('Value should be a number').required('Amount is required'),
        remarks: Yup.string()
            .min(2, 'Too Short!')
            .required('Remarks are required'),
    });

    const formik = useFormik({
        initialValues: {
            amount: '',
            remarks: '',
        },
        validationSchema: TransferSchema,
        onSubmit: (values) => {
            setSubmitting(true)
            var transfer = {
                "teacher_id": data.teacher_id,
                "amount": values.amount,
                "remarks": values.remarks,
            }
            AdminService.transferPayouts(transfer).then(res => {
                if (res.data.success) {
                    handleClose()
                    handleNotification('success', 'Amount transferred successfuly!',data.teacher_id,transfer.amount)
                }
                else {
                    handleClose()
                    handleNotification('error', res.data.error,data.teacher_id,transfer.amount)
                }
                setSubmitting(false)
            })
        },
    });


    // AdminService.transferPayouts()

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <Box sx={style}>
                    <Grid container justifyContent="space-between" alignItems="flex-start">
                        <Grid item>
                            {data != null &&
                                <>
                                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }} id="modal-modal-title" variant="h6" component="h2">
                                        <Icon style={{ marginRight: 10 }}>payments</Icon> Transfer amount
                                    </Typography>
                                    <Typography id="modal-modal-title" variant="body1" component="p">
                                        Full Name: {data.first_name} {data.first_lastname}
                                    </Typography>
                                    <Typography id="modal-modal-title" variant="body1" component="p">
                                        Email: {data.email}
                                    </Typography>
                                </>
                            }
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{date}</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 20, marginBottom: 0 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                name="amount" fullWidth id="outlined-basic" label="Transfer Amount" variant="outlined"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                            <TextField name="remarks" style={{ marginTop: 20 }} multiline rows={4} fullWidth id="outlined-basic" label="Remarks" variant="outlined"
                                value={formik.values.remarks}
                                onChange={formik.handleChange}
                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                helperText={formik.touched.remarks && formik.errors.remarks}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                                <Button type="button" onClick={handleClose} style={{ flex: 1, margin: 5, backgroundColor: '#d9d9d9', color: '#000' }}>Cancel</Button>
                                <Button disabled={submitting} type="submit" style={{ flex: 1, margin: 5, backgroundColor: '#1A95AC', color: 'white' }}>Transfer</Button>
                            </div>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )

}

const columns = [
    { id: 'name', label: 'Name', minWidth: 70 },
    { id: 'email', label: 'Email', minWidth: 70 },
    {
        id: 'bank_name',
        label: 'Total Earning',
        minWidth: 90,
        align: 'left',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'bank',
        label: 'Payouts',
        minWidth: 90,
        align: 'left',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'bank_routing',
        label: 'Pending',
        minWidth: 90,
        align: 'left',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
];

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



function createData(name, email, bank, earning) {
    const density = '000';
    return { name, email, bank, earning };
}

const rows = [
    createData('Jhon Doe', 'johndoe@gmail.com', 123409823421, 34098),
    createData('Peter Yeh', 'peteryeh@gmail.com', 234534523421, 4298),
    createData('Lisa Wil', 'lisa@gmail.com', 209409823421, 3098),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const Sales = ({ teacher, dateFrom, dateTo }) => {
    const [sales, setSales] = useState([])
    const [totalSale, setTotalSale] = useState()
    useEffect(() => {
        var data = {
            'teacher_id': teacher
        };
        if (dateFrom != null && dateTo != null) {
            data['dateFrom'] = dateFrom
            data['dateTo'] = dateTo
        }
        AdminService.getSalesByTeacher(data).then(res => {
            setSales(res.data)
            var total = res.data.reduce(function (accumulator, currentValue) {
                return parseFloat(accumulator) + parseFloat(currentValue.sale_price);
            }, 0);
            setTotalSale(total)
        })
    }, [])

    return (
        <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
                Sales
            </Typography>
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }}>Date</TableCell>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }}>Course</TableCell>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }} align="right">Amount</TableCell>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }} align="right">Sale Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sales.length > 0 ?
                            sales.map(sale => (
                                <TableRow>
                                    <TableCell>{sale.created_at}</TableCell>
                                    <TableCell>{sale.course_name}</TableCell>
                                    <TableCell align="right">{sale.course_tution_fee}</TableCell>
                                    <TableCell align="right">{sale.sale_price / 100}</TableCell>
                                </TableRow>
                            ))
                            :
                            <TableRow>
                                <TableCell colSpan={4} align="center">No sales available</TableCell>
                            </TableRow>
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={3}></TableCell>
                        <TableCell align="right">
                            <Typography color="primary" variant="h6">Total sale: {totalSale/100}</Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    )
}

const Payouts = ({ handleTransfer, teacher, dateFrom, dateTo, income,transferredTo,index }) => {
    const [payouts, setPayouts] = useState([])
    const [totalPayouts, setTotalPayouts] = useState()

    useEffect(() => {
        var data = {
            'teacher_id': teacher
        };
        if (dateFrom != null && dateTo != null) {
            data['dateFrom'] = dateFrom
            data['dateTo'] = dateTo
        }
        AdminService.getPayoutsByTeacher(data).then(res => {
            setPayouts(res.data)
            var total = res.data.reduce(function (accumulator, currentValue) {
                return parseFloat(accumulator) + parseFloat(currentValue.amount);
            }, 0);
            setTotalPayouts(total)
        })
    })
    return (
        <Box width="100%" margin={1}>
            <Grid container style={{ marginBottom: 10 }} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6" gutterBottom component="div">
                        Payouts
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={() => handleTransfer(income,index)}>Make Payout</Button>
                </Grid>
            </Grid>
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }}>Date</TableCell>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }}>Amount</TableCell>
                        <TableCell style={{ backgroundColor: '#F5F5F5' }} align="right">Remarks</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payouts.length > 0 ?
                            payouts.map(payout => (
                                <TableRow>
                                    <TableCell>{payout.created_at}</TableCell>
                                    <TableCell>{payout.amount}</TableCell>
                                    <TableCell align="right">{payout.remarks}</TableCell>
                                </TableRow>
                            ))
                            :
                            <TableRow>
                                <TableCell colSpan={3} align="center">No payouts available</TableCell>
                            </TableRow>
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={2}></TableCell>
                        <TableCell align="right">
                            <Typography color="primary" variant="h6">Total payouts: {totalPayouts}</Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    )
}

const Row = ({ handleTransfer, income, dateFrom,index, dateTo,transferredTo }) => {
    const [openPayouts, setOpenPayouts] = React.useState(false);
    const [openSales, setOpenSales] = React.useState(false);

    return (
        <>
            <StyledTableRow hover role="checkbox" tabIndex={-1} >
                <TableCell align={'left'}>
                    {typeof income.display_name != 'undefined' && income.display_name}
                    {/* {typeof income.last_name != 'undefined' && ' ' + income.last_name} */}
                </TableCell>
                <TableCell align={'left'}>
                    {typeof income.email != 'undefined' && income.email}
                </TableCell>
                <TableCell align={'left'}>
                    <Chip style={{ backgroundColor: '#D4EDDA', color: '#397245' }} label={typeof income.total_sale != 'undefined' && '$' + (income.total_sale/100)} />
                </TableCell>
                <TableCell align={'left'}>
                    <Chip style={{ backgroundColor: '#D1ECF1', color: '#1E616C' }} label={typeof income.totalPayouts != 'undefined' && '$' + (income.totalPayouts == null ? '0' : income.totalPayouts)} />
                </TableCell>
                <TableCell align={'left'}>
                    <Chip style={{ backgroundColor: '#F8D7DB', color: '#7D2C33' }} label={typeof income.totalPayouts != 'undefined' && '$' + (income.totalPayouts != null ? (parseFloat(income.total_sale/100) - parseFloat(income.totalPayouts)) : parseFloat(income.total_sale/100))} />

                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                    <Button size="small" style={{ marginRight: 5 }} onClick={() => {setOpenPayouts(!openPayouts);setOpenSales(false)}} variant="contained" color="secondary">Payouts</Button>
                    <Button size="small" style={{ marginLeft: 5 }} onClick={() => {setOpenSales(!openSales);setOpenPayouts(false)}} variant="contained" color="primary">Sales</Button>
                    {/* <Button onClick={() => handleTransfer(income)} variant="contained" color="secondary">Transfer</Button>
                                                <Button onClick={() => handleTransfer(income)} variant="contained" color="secondary">Transfer</Button> */}
                </TableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openSales} timeout="auto" unmountOnExit>
                        {
                            openSales &&
                            <Sales dateFrom={dateFrom} dateTo={dateTo} teacher={income.teacher_id} />
                        }
                    </Collapse>
                    <Collapse in={openPayouts} timeout="auto" unmountOnExit>
                        {
                            openPayouts &&
                            <Payouts index={index} transferredTo={transferredTo} income={income} handleTransfer={handleTransfer} dateFrom={dateFrom} dateTo={dateTo} teacher={income.teacher_id} />
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default function IncomeManagement() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [incomes, setIncomes] = React.useState([]);
    const [transferIncome, setTransferIncome] = React.useState(null);
    const [transferKey, setTransferKey] = React.useState(null);
    const [openTransferModal, setOpenTransferModal] = React.useState(false)
    const [initialIncomes, setInitialIncomes] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [fromDate, setFromDate] = React.useState(null)
    const [toDate, setToDate] = React.useState(null)
    const [email, setEmail] = React.useState('')
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setOpenTransferModal(false);
    }

    const handleTransfer = (income,index) => {
        setTransferIncome(income)
        setTransferKey(index)
        setOpenTransferModal(true)
    }

    const handleEmailSearch = (event) => {
        AdminService.getIncomeByEmail(event.target.value).then(res => {
            setIncomes(res.data)
            console.log(res.data)
        })
    }

    const handleFromDateChange = (date) => {
        var dateFrom = calculateDate(date)
        setFromDate(dateFrom)
    }
    const handleToDateChange = (date) => {
        var dateTo = calculateDate(date)
        setToDate(dateTo)
    }

    const handleReset = () => {
        setFromDate(null)
        setToDate(null)
        setEmail('')
        AdminService.getIncome(0, rowsPerPage).then(res => {
            setIncomes(res.data)
            setInitialIncomes(res.data)
        })
    }

    const handleGetCustomIncome = () => {
        var data = {
            'dateFrom': fromDate,
            'dateTo': toDate,
            'email': email
        }
        
        AdminService.getIncomeByDate(data).then(res => {
            console.log(res)
            setIncomes(res.data)
        })
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleNotification = (type, message, teacher,amount) => {
        // Modify Current Income Array Here
            // var currentIncomeData = incomes
            // currentIncomeData[transferKey]['totalPayouts'] = parseFloat(currentIncomeData[transferKey]['totalPayouts']) + parseFloat(amount) ;
            // setIncomes(currentIncomeData)
            // console.log(incomes,transferKey)
            AdminService.getIncome(0, rowsPerPage).then(res => {
                setIncomes(res.data)
                setInitialIncomes(res.data)
                type == 'success' ?
                    toast.success(message)
                    :
                    toast.error(message)
            })
        // --------
    }

    const calculateDate = (date) => {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    }

    

    useEffect(() => {
        AdminService.getIncome(0, rowsPerPage).then(res => {
            setIncomes(res.data)
            setInitialIncomes(res.data)
        })
    }, [rowsPerPage])

    return (
        <Container maxWidth={'lg'} style={{ marginTop: 30, marginBottom: 100 }}>
            <ToastContainer position="top-right" autoClose={6000} />
            {
                openTransferModal &&
                <TransferModal handleNotification={handleNotification} date={today} data={transferIncome} handleClose={handleClose} />
            }
            <Typography variant="h4" style={{ marginTop: 15, marginBottom: 15, fontSize: '1.6rem' }}>Income Management</Typography>
            <Paper style={{ backgroundColor: 'whtie', borderRadius: 10, padding: 15, marginTop: 15, marginBottom: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <TextField value={email} onChange={(event) => setEmail(event.target.value)} onKeyUp={(event) => {
                                event.target.value == '' && setIncomes(initialIncomes)
                            }}
                                onKeyPress={(event) => event.code == 'Enter' && handleEmailSearch(event)} fullWidth id="outlined-basic" label="Search by email or name" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={1} justifyContent="space-even">
                                <Grid item xs={12} md={4}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-from"
                                        label="Date from"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-to"
                                        label="Date to"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div style={{display:'flex',height:'100%',width:'100%',justifyContent:'space-between'}}>
                                        <Button style={{ height: '100%',width:'45%',fontSize:12 }} variant="contained" color="primary" variant="outlined" onClick={handleReset}>Reset</Button>
                                        <Button style={{ height: '100%',width:'50%',fontSize:12 }}  variant="contained" color="primary" onClick={handleGetCustomIncome}>Get Income</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Paper>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                incomes.length > 0 &&
                                incomes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((income, index) => {
                                    return (
                                        <Row handleTransfer={handleTransfer} dateFrom={fromDate} dateTo={toDate} key={index} index={index} income={income} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={incomes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Container>
    );
}
