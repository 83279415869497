import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ProductService from '../services/products';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    cartWrapper: {
        backgroundColor: 'white',
        marginTop: 50,
        marginBottom: 50,
        padding: 20,
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    itemImageWrapper: {
        paddingRight: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#d3d3d3',
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            height: 145,
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: 15,
            height: 145,
        },
        height: 200
    },
    itemImage: {
        width: '100%',
        height: 'auto',
    },
    itemContainer: {
        paddingTop: 30,
        paddingBottom: 0,
        marginBottom: 30,
        minHeight: '60vh',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        }
    },

    removeIcon: {
        color: 'gray',
        cursor: 'pointer'
    },
    singleItem: {
        marginRight: '2%',
        marginBottom: 20,
        backgroundColor: '#f4f4f4',
        elevation: 5,
        borderRadius: 10,
        width: '23%',
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    lightBorder: {
        borderColor: 'white'
    },
    singleAmount: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10
    },
    singleValue: {
        paddingLeft: 30
    },
    cartAmount: {
        // marginBottom: 30,
    },
    cardWrapper: {
        marginTop: 50,
        padding: 20,
        backgroundColor: '#fff',
        marginLeft: 30,
        borderRadius: 15
    },
    elementWrapper: {
        marginTop: 5,
        height: 40,
        disaply: 'flex',
        alignItems: 'center'
    },
    cardele: {
        backgroundColor: 'white',
        disaply: 'flex',
        alignItems: 'center',
        padding: 15,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        border: 'solid',
        borderWidth: 1,
        borderColor: '#cacaca'
    },
    cardMarginBottom: {
        marginBottom: 20
    },
    checkoutBtn: {
        marginTop: 40
    },
    fullBtn: {
        width: '100%'
    },
    paymentIcons: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40
    },
    courseName: {
        fontSize: 18,
        marginTop: 10, marginBottom: 10,
        height: 60,
        lineHeight: 1.3,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        }
    },
    searchLink: {
        textDecoration: 'none',
        color: 'black'
    },
    subscriberName: {
        color: '#fa9c24',
        fontSize: 12,
        marginBottom: 0,
        marginLeft: 10
    },
    tag: {
        backgroundColor: '#dadada',
        marginRight: 2,
        marginBottom: 2,
        fontSize: 9
    },
    centerAvatar: {
        width: 28,
        height: 28,
        borderRadius: 30,
        overflow: 'hidden',
    },
    pageTitles: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 5
        }
    },
    noProductWrapper: {
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}));

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

export default function MyEdHub() {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [cartCount, setCartCount] = React.useState(0)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    React.useEffect(() => {
        ProductService.getProductsByUserId(localStorage.getItem('user_id')).then((res) => {
            setItems(res.data.products)
            console.log(res.data)
        })
    }, [])



    return (
        <Container maxWidth="lg" >
            <Grid container alignItems="flex-start">
                <Grid item xs={12} className={classes.cartWrapper}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <h3 className={classes.pageTitles}>My EdHub</h3>
                        <h4 className={classes.pageTitles}>{items.length} {items.length > 1 ? 'Products' : 'Product'}</h4>
                    </Grid>
                    <hr></hr>
                    <Grid container className={classes.itemContainer} justifyContent="flex-start" alignItems="flex-start">
                        {
                            items.length == 0 &&
                            <Grid item className={classes.noProductWrapper}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Typography variant="body1" className={classes.centerText}>
                                        No Products Avaiable
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {items.map((item, index) => {
                            var tagsObject = []
                            if (item.tags != null) {
                                var tags = item.tags.split(',')
                                tagsObject = tags;
                            }
                            let coverType = item.cover_type;
                            let cardMediaType = "img"
                            let courseCover = process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + item.subscriber_id + '/' + item.course_cover;
                            if (item.subscriber_id == 0) {
                                courseCover = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + item.course_cover;
                                // teacherDp = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + item.profile_picture;
                            }

                            if (item.course_cover != '' && item.course_cover != null) {
                                if (coverType == '.mp4') {
                                    cardMediaType = 'video'
                                }
                                else {
                                    cardMediaType = 'img'
                                }
                            }
                            else {
                                courseCover = '/' + pics[index]
                            }
                            let teacherDp = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + item.profile_picture;
                            return (
                                <Grid key={index} style={{ boxShadow: "1px 1px 4px rgb(158 158 158 / 50%)" }} item className={classes.singleItem}>
                                    <Link className={classes.searchLink} to={{
                                        pathname: "/view/product/" + item.course_name + '/' + item.course_id,
                                        state: {
                                            image: courseCover,
                                            id: item.course_id
                                        }
                                    }}>
                                        <Grid container flexdirection="column">
                                            <Grid item xs={12} sm={12} className={classes.itemImageWrapper}>
                                                <img src={courseCover} className={classes.itemImage} />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container flexdirection="column" justifyContent="space-between">
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.courseName} variant="h6">{item.course_name}</Typography>
                                                    </Grid>
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item xs={8}>
                                                            <Grid container alignItems="center">
                                                                <div className={classes.centerAvatar}>
                                                                    {
                                                                        item.subscriber_id == 0 ?
                                                                            <img style={{ width: '100%' }} src={teacherDp} />
                                                                            :
                                                                            <img style={{ width: '100%' }} src={process.env.REACT_APP_UPLOADS_URL + 'admin/subscriber/' + item.avatar} />
                                                                    }
                                                                </div>
                                                                {
                                                                    (item.centre_name == null || item.centre_name == '') ?
                                                                        <Typography className={classes.subscriberName} variant="subtitle1">{item.display_name}</Typography>
                                                                        :
                                                                        <Typography className={classes.subscriberName} variant="subtitle1">{item.centre_name}</Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4}>
                                                            <Typography style={{ marginRight: 5, color: 'rgb(250, 156, 36)', fontWeight: 700 }} align="right" variant="body1">${item.course_tution_fee} HK</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}