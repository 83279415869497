import React, { useState, createContext } from 'react';
import ProductService from '../services/products';

export const FavouriteContext = createContext();

export const FavouriteProvider = props => {
    const mounted = React.useRef()
    const [favouriteItems, setFavouriteItems] = useState([]);

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            let user = localStorage.getItem('user_id');
            if (user) {
                ProductService.getFavourites(user).then(res => {
                    setFavouriteItems(res.data)
                })
            }
        }
    })

    return (
        <FavouriteContext.Provider value={{ favouriteItem: [favouriteItems, setFavouriteItems] }}>
            {props.children}
        </FavouriteContext.Provider>
    )
}
