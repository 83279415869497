import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import './styles.css';
import { UserContext } from '../../../context/UserContext';
import { AuthContext } from '../../../context/AuthContext';
import { useJwt } from "react-jwt";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

export default function BottomBar() {
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { user } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const { decodedToken, isExpired } = useJwt(token);

    return (
        <div className="bottom-bar">
            <Grid justifyContent="space-evenly" alignItems="center" container>
                <Grid item xs={3}>
                    <Button onClick={() => { window.scrollTo(0, 0) }} style={{ textAlign: 'center' }}>
                        <img className="avatar" src="/assets/edHubCircle.png" />
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    {
                        !isExpired &&
                        <Link to={'/subscriptions'}>
                            <Button style={{ textAlign: 'center' }}>
                                <img src="/assets/follow.png" />
                            </Button>
                        </Link>
                    }
                </Grid>
                <Grid item xs={3}>
                    {
                        !isExpired &&
                        <Link to={'/cart'}>
                            <Button style={{ textAlign: 'center' }}>
                                <img src="/assets/shopping-cart.png" />
                            </Button>
                        </Link>
                    }
                </Grid>
                <Grid item xs={3}>
                    <Link to={'/my-edhub'}>
                        <Button style={{ textAlign: 'center' }}>
                            {
                                !isExpired &&
                                    typeof currentUser.details != 'undefined' &&
                                    currentUser.details.profile_picture != null &&
                                    currentUser.details.auth_using == 'social' ?
                                    <img className="user-avatar avatar" src={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                    // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                    :
                                    // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                    <img className="user-avatar avatar" src={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />

                            }


                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}
