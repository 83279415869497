import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Product from '../services/products';
// import { ReactSortable } from "react-sortablejs";
import './css/admin.css';


const Levels = ({ levels, handleDeleteLevel, handleAddLevel, handleAddLevelKeyEnter, subjectId }) => {
    const [newLevel, setNewLevel] = React.useState(null)
    const [levelsList, setLevelsList] = React.useState([])
    const [levelsDeleted, setLevelsDeleted] = React.useState([])

    const deleteRef = React.useRef([]);
    const deleteButtonRef = React.useRef([]);

    const handleReOrder = (e) => {
        let levels = levelsList;
        console.log(levels)
        levels.forEach(level => {
            level.subject_id = Number(level.subject_id)
            delete level.level_id
            delete level.chosen
            delete level.selected
            delete level.subscriber_id
        })
        Product.updateCourseLevel(levels, levels[0]['subject_id']).then(res => {
            console.log(res.data)
        })
    }

    React.useEffect(() => {
        setLevelsList([])
        setLevelsList(levels)
        setNewLevel('')
    }, [levels])

    return (
        <Grid container className="custom-table-wrapper">
            {/* Body */}
            <Grid container className="add-wrapper">
                <Grid item xs={12} className="add-fields-wrapper">
                    <TextField className="add-field" variant="outlined" placeholder="Add New Level" onKeyDown={(e) => {
                        handleAddLevelKeyEnter(e, e.target.value, subjectId)
                    }} value={newLevel} onChange={e => setNewLevel(e.target.value)} name="level" type="text" />
                    <Button className="add-btn" onClick={() => {
                        handleAddLevel(newLevel, subjectId)
                    }}><Icon>add_circle</Icon></Button>
                </Grid>
            </Grid>
            <Grid container>

                {/* <ReactSortable className="sortable-wrapper" list={levelsList} setList={setLevelsList}> */}
                <Grid className="sortable-wrapper" list={levelsList} setList={setLevelsList}>
                    {
                        levelsList != null &&
                        levelsList.map((level, index) => (
                            <Grid key={index} onDragEnd={handleReOrder} item xs={12} md={6} lg={6} className="table-row" >
                                <Grid className="inner-row" container alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography className="lite-text" variant="body1">{level.title}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography align="right" className="center" variant="body1">
                                            <CircularProgress size={20} ref={(element) => { deleteRef.current[index] = element }} className="hide-progress" color="white" />
                                            {
                                                () => {
                                                    deleteRef.current[index].className = "hide-progress"
                                                }
                                            }
                                            <Icon ref={(element) => { deleteButtonRef.current[index] = element }} className="delete-icon" onClick={() => {
                                                handleDeleteLevel(level.level_id)
                                            }}>cancel</Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}



const Subjects = ({ subjects, handleDeleteSubject, handleDeleteLevel, handleAddLevelKeyEnter, handleAddLevel }) => {
    const deleteRef = React.useRef([]);
    const deleteButtonRef = React.useRef([]);
    return (
        <Grid container className="custom-table-wrapper">
            {/* Header */}
            <Grid item xs={12} className="header-row">
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h6">Subjects and levels</Typography>
                    </Grid>

                </Grid>
            </Grid>
            {/* Body */}
            <Grid container spacing={3}>
                {
                    subjects != null &&
                    subjects.map((subject, index) => (
                        <Grid key={index} item xs={12} sm={4} lg={3} className="margin-bottom">
                            <div className="table-row-subject">
                                <Grid container className="inner-row-subject" alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography className="hard-text" variant="body1">{subject.title}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography align="right" className="center" variant="body1">
                                            <CircularProgress size={25} color='' className='hide-progress' ref={(element) => { deleteRef.current[index] = element }} />
                                            <Icon ref={(element) => { deleteButtonRef.current[index] = element }} className="white-icon" onClick={() => {
                                                handleDeleteSubject(subject.subject_id)
                                            }}>cancel</Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Levels handleAddLevelKeyEnter={handleAddLevelKeyEnter} subjectId={subject.subject_id} handleAddLevel={handleAddLevel} handleDeleteLevel={handleDeleteLevel} levels={subject.levels} />
                            </div>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid >
    )
}

export default function CourseManagement() {
    const [levels, setLevels] = React.useState(null)
    const [subjects, setSubjects] = React.useState(null)
    const [newLevel, setNewLevel] = React.useState(null)
    const [newSubject, setNewSubject] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        Product.getCourseLevels().then(res => {
            setLevels(res.data)
        })
        Product.getSubjects().then(res => {
            console.log(res.data)
            setSubjects(res.data)
        })
    }, [])

    const handleAddLevel = (level, subjectId) => {
        Product.addCourseLevel({ title: level, subject_id: subjectId }).then(res => {
            console.log(res.data)
            setSubjects(res.data)
        })
    }

    const handleAddLevelKeyEnter = (e, level, subjectId) => {
        if (e.key == 'Enter') {
            Product.addCourseLevel({ title: level, subject_id: subjectId }).then(res => {
                console.log(res.data)
                setSubjects(res.data)
            })
        }
    }

    const handleDeleteLevel = (id) => {
        setLoading(true)
        console.log(id)
        Product.deleteCourseLevel(id).then(res => {
            setSubjects(res.data)
            setLoading(false)
        })
    }

    const handleDeleteSubject = (id) => {
        setLoading(true)
        console.log(id)
        Product.deleteSubject(id).then(res => {
            console.log(res.data)
            setSubjects(res.data)
            setLoading(false)
        })
    }

    const handleAddSubject = () => {
        Product.addSubject(newSubject).then(res => {
            setSubjects(res.data)
            setNewSubject('')
        })
    }

    return (
        <Grid container className="container-wrapper">
            {
                loading &&
                <div class="waiting-wrapper">
                    <CircularProgress className="deleting-ring" size={60} />
                    <h3>Deleting...</h3>
                </div>
            }
            <h3 className="page-title">Course Management</h3>
            <Grid container style={{ marginTop: 30 }} justifyContent="space-evenly">
                {/* <Grid item xs={12} sm={6} lg={5}>

                    <Grid container className="add-wrapper">
                        <Grid item xs={9}>
                            <TextField className="add-field" variant="outlined" placeholder="Add New Level" fullWidth value={newLevel} onChange={e => setNewLevel(e.target.value)} name="level" type="text" />
                        </Grid>
                        <Grid item xs={3}>
                            <Button className="add-btn" onClick={handleAddLevel}>Add level</Button>
                        </Grid>
                    </Grid>

                </Grid> */}
                <Grid item xs={12}>

                    <Grid container className="add-wrapper">
                        <Grid item xs={10}>
                            <TextField className="add-field" variant="outlined" placeholder="Add New Subject" fullWidth value={newSubject} onChange={e => setNewSubject(e.target.value)} name="subject" type="text" />
                        </Grid>
                        <Grid item xs={2}>
                            <Hidden xsDown>
                                <Button className="add-subject-btn" onClick={handleAddSubject}>Add Subject</Button>
                            </Hidden>
                            <Hidden smUp>
                                <Button className="add-subject-btn" onClick={handleAddSubject}><Icon>add_circle</Icon></Button>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
                <Subjects handleAddLevelKeyEnter={handleAddLevelKeyEnter} handleAddLevel={handleAddLevel} handleDeleteSubject={handleDeleteSubject} handleDeleteLevel={handleDeleteLevel} subjects={subjects} />
            </Grid>
        </Grid>
    )
}
