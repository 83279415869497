import React from 'react';
import * as Mui from '@material-ui/core';
import * as Mul from '@material-ui/lab';
import RatingStars from '../Rating-stars/RatingStars';
import { Link } from 'react-router-dom';
import './style.css';

export default function CourseBanner(props) {

    function ProductRating(props) {
        let rating = Math.floor(props.rating)
        return (
            <>
                <Mui.Grid container alignItems="center">
                    <Mui.Icon className="star">{rating > 0 ? 'star' : 'star_outline'}</Mui.Icon>
                    <Mui.Icon className="star">{rating > 1 ? 'star' : 'star_outline'}</Mui.Icon>
                    <Mui.Icon className="star">{rating > 2 ? 'star' : 'star_outline'}</Mui.Icon>
                    <Mui.Icon className="star">{rating > 3 ? 'star' : 'star_outline'}</Mui.Icon>
                    <Mui.Icon className="star">{rating > 4 ? 'star' : 'star_outline'}</Mui.Icon>
                </Mui.Grid>
            </>
        )
    }


    const course = props.course
    let disablePricing = true;
    let courseCover = process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + course.subscriber_id + '/' + course.course_cover;
    if (course.course_teacher != null && course.course_teacher != '') {
        courseCover = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + course.course_cover;
    }

    return (
        <Mui.Grid className="banner-wrapper" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${courseCover})` }} container justifyContent="flex-start" alignItems="flex-end">
            <Mui.Grid item className="hero-info-wrapper">
                <Mui.Typography variant="h2">{course.course_name}</Mui.Typography>
                <Mui.Grid container alignItems="center">
                    <Mui.Grid item>
                        <ProductRating rating={course.rating} />
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography variant="h6">By {course.centre_name}</Mui.Typography>
                    </Mui.Grid>
                </Mui.Grid>
                {
                    props.limit == 'limited' ?
                        !disablePricing ?
                            <Mui.Button onClick={() => props.handleCheckoutOpen(course)} className="action-button">Get full access</Mui.Button>
                            :
                            <Link style={{ textDecoration: 'none' }} to={{
                                pathname: "/product/view/" + course.course_name + '/' + course.course_id,
                                state: {
                                    image: courseCover,
                                    id: course.course_id
                                }
                            }}>
                                <Mui.Button className="action-button"><Mui.Icon style={{ marginRight: 6 }}>lock</Mui.Icon> Use access code</Mui.Button>
                            </Link>
                        :
                        ''
                }
            </Mui.Grid>
        </Mui.Grid>
    )
}
