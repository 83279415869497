import React from 'react';
import * as Mui from '@material-ui/core';
import * as Mul from '@material-ui/lab';
import '../css/teacherReg.css';
import Carousel from 'react-material-ui-carousel'
import TagsInput from '../shared/controls/TagsInput';
import Auth from '../../services/auth';
import { BrowserRouter as Router, Link, useLocation, Redirect } from "react-router-dom";
import { UserContext } from '../../context/UserContext';
import LinearLoader from '../shared/loaders/linearLoader';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../helper/CroppedImage';
import ProfileCropper from '../auth/ProfileCropper';

export default function TeacherReg() {


    const [submitting, setSubmission] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)
    const [genderError, setGenderError] = React.useState(null)
    const [formErrors, setFormErrors] = React.useState([])
    const [first_name, setFirstName] = React.useState(null)
    const [last_name, setLastName] = React.useState(null)
    const [display_name, setDisplayName] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [existingEmail, setExistingEmail] = React.useState(null)
    const [teacherId, setTeacherId] = React.useState(null)
    const [openEditor, setOpenEditor] = React.useState(false)
    const [username, setUsername] = React.useState(null)
    const [showProfileCropper, setShowProfileCropper] = React.useState(false)
    const [password, setPassword] = React.useState(null)
    const { user } = React.useContext(UserContext);
    const { decoded } = React.useContext(UserContext);
    const { isExpired } = React.useContext(UserContext);
    const [currentUser, setCurrentUser] = user;
    const [confirmpassword, setConfirmPassword] = React.useState(null)
    const [phone_number, setPhoneNumber] = React.useState(null)
    const [qualification, setQualification] = React.useState([])
    const [qualificationList, setQualificationList] = React.useState([])
    const [interest, setInterest] = React.useState(null)
    const [profile_picture, setProfilePicture] = React.useState(null)
    const [gender, setGender] = React.useState(null)
    const [about, setAbout] = React.useState('')
    const [success, setSuccess] = React.useState(false)
    const formRef = React.useRef(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const genderRef = React.useRef(null)
    const firstNameRef = React.useRef(null)
    const lastNameRef = React.useRef(null)
    const displayNameRef = React.useRef(null)
    const phoneNumberRef = React.useRef(null)
    const emailRef = React.useRef(null)
    const usernameRef = React.useRef(null)
    const passwordRef = React.useRef(null)
    const confirmPasswordRef = React.useRef(null)
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    const [profilePic, setProfilePic] = React.useState(null)
    const [blobImage, setBlobImage] = React.useState(null)
    const [newProfilePicture, setNewProfilePicture] = React.useState(null)
    const [newProfilePictureBlob, setNewProfilePictureBlob] = React.useState(null)

    const [crop, setCrop] = React.useState({
        x: -20, y: 25,
    })
    const [zoom, setZoom] = React.useState(2.5)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
    const [croppedImage, setCroppedImage] = React.useState(null)

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = async () => {
        if (profile_picture != null) {
            try {
                handleFormSubmit(null)
            } catch (e) {
                console.error(e)
            }
        }
        else {
            handleFormSubmit(null)
        }
    }

    React.useEffect(() => {
        if (Object.keys(currentUser).length > 0) {
            if (!isExpired) {
                setIsLoggedIn(true)
                let details = currentUser.details;
                if (currentUser.status == 4) {

                    window.location.href = "/"

                }
                else {
                    if (details.profile_picture != null) {
                        let dp = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + details.profile_picture;
                        if (details.auth_using == 'social') {
                            dp = details.profile_picture
                        }
                        setProfilePic(dp)
                    }

                    setDisplayName(details.display_name)
                    var data = requiredFields.display_name;
                    data.value = details.display_name;
                    setRequiredFields({ ...requiredFields, display_name: data })

                    setFirstName(details.first_name)
                    var data = requiredFields.first_name;
                    data.value = details.first_name;
                    setRequiredFields({ ...requiredFields, first_name: data })

                    setLastName(details.last_name)
                    var data = requiredFields.last_name;
                    data.value = details.last_name;
                    setRequiredFields({ ...requiredFields, last_name: data })

                    setEmail(currentUser.email)
                    if (currentUser.email != null & currentUser.email != '') {
                        setExistingEmail(currentUser.email)
                    }
                    var data = requiredFields.email;
                    data.value = currentUser.email;
                    setRequiredFields({ ...requiredFields, email: data })

                    setUsername(currentUser.username)
                    var data = requiredFields.username;
                    data.value = currentUser.username;
                    setRequiredFields({ ...requiredFields, username: data })
                }
            }
        }
        // if (isLoggedIn) {

        // }
        // if (isLoggedIn) {
        // let current = currentUser;
        // current.status = 4;
        // alert(current.status)
        // setCurrentUser(current)
        // }
    }, [currentUser])

    const resetForm = () => {
        setFirstName(null)
        setLastName(null)
        setPassword(null)
        setConfirmPassword(null)
        setGender(null)
        setQualification(null)
        setEmail(null)
        setUsername(null)
        setInterest(null)
        setPhoneNumber(null)
    }

    const [requiredFields, setRequiredFields] = React.useState(
        {
            first_name: { required: true, errorMessage: 'First Name is required', isValid: null, value: first_name },
            last_name: { required: true, errorMessage: 'Last Name is required', isValid: null, value: last_name },
            display_name: { required: true, errorMessage: 'Display Name is required', isValid: null, value: display_name },
            email: { required: true, errorMessage: 'Email is required', isValid: null, value: email },
            username: { required: true, errorMessage: 'Username is required', isValid: null, value: username },
            password: { required: true, errorMessage: 'Password is required', isValid: null, value: password },
            confirmpassword: { required: true, errorMessage: 'Password should match', isValid: null, value: confirmpassword },
            phone_number: { required: true, errorMessage: 'Phone number is required', isValid: null, value: phone_number },
            gender: { required: true, errorMessage: 'Gender is required', isValid: null, value: gender }
        }
    )

    const fileIcons = {
        'pdf': '/assets/icons/pdf.png',
        'jpeg': '/assets/icons/image.png',
        'png': '/assets/icons/image.png',
        'jpg': '/assets/icons/image.png',
        'doc': '/assets/icons/doc.png',
        'docx': '/assets/icons/doc.png',
    }

    const hanleFormSubmission = (event) => {
        event.preventDefault();
    }

    const handleFormSubmit = (profileImage = null) => {
        setSubmission(true)
        setFormErrors([])
        var isFormValid = true;
        let formData = {
            first_name,
            last_name,
            display_name,
            email,
            username,
            about,
            password,
            confirmpassword,
            phone_number,
            qualification,
            profile_picture,
            gender
        }
        var scrolled = false;
        Object.entries(requiredFields).forEach(([key, data]) => {
            if (data.value == null || data.value == '') {
                data.isValid = false;
                setRequiredFields({ ...requiredFields, [key]: data })
                // console.log(data.isValid)
                isFormValid = false;
                if (key === 'gender' && scrolled === false) {
                    scrolled = true;
                    genderRef.current.scrollIntoView()
                }
                else if (key === 'first_name' && scrolled === false) {
                    scrolled = true;
                    firstNameRef.current.scrollIntoView()
                }
                else if (key === 'last_name' && scrolled === false) {
                    scrolled = true;
                    lastNameRef.current.scrollIntoView()
                }
                else if (key === 'display_name' && scrolled === false) {
                    scrolled = true;
                    displayNameRef.current.scrollIntoView()
                }
                else if (key === 'phone_number' && scrolled === false) {
                    scrolled = true;
                    phoneNumberRef.current.scrollIntoView()
                }
                else if (key === 'email' && scrolled === false) {
                    scrolled = true;
                    emailRef.current.scrollIntoView()
                }
                else if (key === 'username' && scrolled === false) {
                    scrolled = true;
                    usernameRef.current.scrollIntoView()
                }
                else if (key === 'password' && scrolled === false) {
                    scrolled = true;
                    passwordRef.current.scrollIntoView()
                }
                else if (key === 'confirmpassword' && scrolled === false) {
                    scrolled = true;
                    confirmPasswordRef.current.scrollIntoView()
                }
            }
        });

        if (isFormValid) {
            const formD = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                if (key != 'profile_picture')
                    formD.append(key, value);
                else {
                    if (newProfilePictureBlob != null)
                        formD.append(key, new File([newProfilePictureBlob], 'fileName.jpeg', { type: "image/jpeg", lastModified: new Date() }));
                }
            })
            for (let index = 0; index < formData.qualification.length; index++) {
                formD.append('qualification_files[]', formData.qualification[index]);
            }

            if (profilePic != null) {
                formD.append('profile_picture', profilePic)
                formD.append('profile_pic_changed', 'no')
            }


            Auth.registerTeacher(formD).then(res => {
                console.log(res)
                if (res.data.error) {
                    let errors = [];
                    Object.entries(res.data.errors).forEach(([key, value]) => {
                        errors.push(value)
                    })
                    setFormErrors(errors)
                }
                else {
                    setTeacherId(res.data.user)
                    formRef.current.reset()
                    setFormErrors([])
                    window.scrollTo({
                        top: 0,
                        behavior: 'auto'
                    });
                    if (isLoggedIn) {
                        let current = currentUser;
                        current.status = 4;
                        setCurrentUser(current)
                    }
                    setSuccess(true)
                    setSubmitted(true)
                    setTimeout(() => {
                        setSubmission(false)
                        setSubmitted(false)
                    }, 2000)
                }


            }, error => {
                console.log(error)
                setSubmission(false)
            })
        }
        else {
            setSubmission(false)
        }
    }

    const AlertMessage = (props) => {
        return (
            <Mui.Snackbar
                open={showAlert} onClose={() => { setShowAlert(false) }}
                autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Mul.Alert severity={props.type} sx={{ width: '100%' }} onClose={() => { setShowAlert(false) }}>
                    {props.message}
                </Mul.Alert>
            </Mui.Snackbar>
        )
    }

    const handleSelecetedTags = (items) => {
        setInterest(items)
    }

    const handleGenderChange = (val) => {
        setGender(val)
        var data = requiredFields.first_name;
        data.value = val;
        data.isValid = null;
        setRequiredFields({ ...requiredFields, gender: data })
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        var data = requiredFields.first_name;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, first_name: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.first_name;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, first_name: data })
        }
        else {
            var data = requiredFields.first_name;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, first_name: data })
        }
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        var data = requiredFields.last_name;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, last_name: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.last_name;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, last_name: data })
        }
        else {
            var data = requiredFields.last_name;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, last_name: data })
        }
    }
    const handleDisplayNameChange = (event) => {
        setDisplayName(event.target.value)
        var data = requiredFields.display_name;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, display_name: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.display_name;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, display_name: data })
        }
        else {
            var data = requiredFields.display_name;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, display_name: data })
        }
    }
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value)
        var data = requiredFields.phone_number;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, phone_number: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.phone_number;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, phone_number: data })
        }
        else {
            var data = requiredFields.phone_number;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, phone_number: data })
        }
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        var data = requiredFields.email;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, email: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.email;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, email: data })
        }
        else {
            var data = requiredFields.email;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, email: data })
        }
    }
    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
        var data = requiredFields.username;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, username: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.username;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, username: data })
        }
        else {
            var data = requiredFields.username;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, username: data })
        }
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
        var data = requiredFields.password;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, password: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.password;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, password: data })
        }
        else {
            var data = requiredFields.password;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, password: data })
        }
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
        var data = requiredFields.confirmpassword;
        data.value = event.target.value;
        setRequiredFields({ ...requiredFields, confirmpassword: data })
        if (event.target.value == null || event.target.value == '') {
            var data = requiredFields.confirmpassword;
            data.isValid = false;
            setRequiredFields({ ...requiredFields, confirmpassword: data })
        }
        else {
            var data = requiredFields.confirmpassword;
            data.isValid = null;
            setRequiredFields({ ...requiredFields, confirmpassword: data })
        }
    }
    const handleAboutChange = (event) => {
        setAbout(event.target.value)
    }
    const handleQualificationChange = (event) => {
        setQualification(event.target.files)
        for (let index = 0; index < event.target.files.length; index++) {
            setQualificationList(prev => [...prev, event.target.files[index].name])
        }
        // console.log(event.target.files)
    }

    const handleImageChange = (event) => {
        setProfilePicture(event.target.files[0])
        setProfilePic(null)
    }

    const deleteFileHandler = index => {
        var deletedFile = qualificationList.splice(index, 1)
        setQualificationList(qualificationList.filter(file => { return file != deletedFile }))

        var fileBuffer = [];
        Array.prototype.push.apply(fileBuffer, qualification);

        var newFiles = fileBuffer.filter(file => { return file != qualification[index] })
        const obj = Object.assign({}, newFiles);
        obj['length'] = newFiles.length
        setQualification(obj)
    }

    const handleResendEmail = () => {
        // console.log(teacherId)
        Auth.resendVerificationEmail(teacherId).then(res => {
            // console.log(res)
            // Here
            setShowAlert(true)
        })
    }

    const handleEditorClose = () => {
        setOpenEditor(false)
    }

    const handleNewImageUploaded = (newPicture) => {
        newPicture.url.then(res => {
            setNewProfilePicture(res)
            setProfilePic(res)
            console.log(res)
        })
        setNewProfilePictureBlob(newPicture.blob)
        // console.log(newProfilePicture)
        setShowProfileCropper(false)
    }

    const closeProfileCropper = () => {
        setShowProfileCropper(false)
    }


    return (
        <React.Fragment>
            {
                showProfileCropper &&
                <ProfileCropper closeProfileCropper={closeProfileCropper} handleNewImageUploaded={handleNewImageUploaded} profile_picture={null} />
            }
            <AlertMessage message="Email sent!" type="success" />
            {/* <ProfilePictureEditor openEditor={openEditor} handleEditorClose={handleEditorClose} crop={crop} profile_picture={profile_picture} setCrop={setCrop} setZoom={setZoom} onCropComplete={onCropComplete} /> */}
            <Mui.Grid className="form-container" container alignItems="center" justifyContent="center">
                {
                    success ?
                        <Mui.Grid item xs="11" sm="3">
                            <div className="success-card">
                                <div className="check-wrapper">
                                    <Mui.Icon className="check-icon">check</Mui.Icon>
                                </div>
                                <Mui.Typography variant="h6">Thank You for registering with us.</Mui.Typography>
                                {
                                    isLoggedIn ?
                                        <>
                                            <Mui.Typography variant="body1">We have received your application.</Mui.Typography>
                                            <Mui.Typography style={{ marginTop: 20 }} align="center" variant="body">You will be notified at</Mui.Typography>
                                            <Mui.Typography style={{ marginTop: 5 }} align="center" variant="body">"{email}"</Mui.Typography>
                                            <Mui.Typography style={{ marginTop: 5 }} align="center" variant="body">once your application is approved.</Mui.Typography>
                                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/'}>
                                                <Mui.Button className="ok-btn">
                                                    OK
                                                </Mui.Button>
                                            </Link>
                                        </>
                                        :
                                        <>
                                            <Mui.Typography variant="body1">We have sent a verification link to</Mui.Typography>
                                            <Mui.Typography variant="h6">"{email}"</Mui.Typography>
                                            <Mui.Typography variant="body2">Open your mail app and verify your email.</Mui.Typography>
                                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/'}>
                                                <Mui.Button className="ok-btn">
                                                    OK
                                                </Mui.Button>
                                            </Link>
                                            <Mui.Typography style={{ marginTop: 20 }} variant="body2">Didn't receive confirmation email?</Mui.Typography>
                                            <Mui.Typography variant="body2">
                                                <Link onClick={() => handleResendEmail()} style={{ textDecoration: 'none', color: '#3c87e7' }} href="#">Resend the email</Link>
                                            </Mui.Typography>
                                        </>
                                }
                            </div>
                        </Mui.Grid>
                        :
                        <Mui.Grid item xs="11" sm="8" lg="6" className="reg-form">

                            <Mui.Typography variant="h5" className="form-heading">Register as a Teacher</Mui.Typography>

                            <form ref={formRef} onSubmit={hanleFormSubmission}>
                                <Mui.Grid container className="slide-item">
                                    <Mui.Grid item style={{ marginBottom: 20 }}>
                                        <Mui.Grid container alignItems="flex-end">
                                            <Mui.Grid item xs={12} sm={3} className="left-section">
                                                <Mui.Grid container justifyContent="center">
                                                    {
                                                        profilePic != null ?
                                                            <div className="profile-picture-button">
                                                                <img src={profilePic} width="100%" />
                                                            </div>
                                                            :
                                                            profile_picture ?
                                                                // <img src={URL.createObjectURL(profile_picture)} width="100%" />
                                                                <div className="profile-pic-wrapper">
                                                                    <div className="test-wrapper">
                                                                        <div className="crop-container">
                                                                            <Cropper
                                                                                image={URL.createObjectURL(profile_picture)}
                                                                                crop={crop}
                                                                                zoom={zoom}
                                                                                cropShape="round"
                                                                                aspect={1}
                                                                                onCropChange={setCrop}
                                                                                // onCropComplete={onCropComplete}
                                                                                onCropComplete={onCropComplete}
                                                                                onZoomChange={setZoom}
                                                                            />
                                                                            {/* : */}
                                                                            {
                                                                                // profilePic != null &&

                                                                                // <Cropper
                                                                                //     image={profilePic}
                                                                                //     crop={crop}
                                                                                //     zoom={zoom}
                                                                                //     cropShape="round"
                                                                                //     aspect={1}
                                                                                //     onCropChange={setCrop}
                                                                                //     onCropComplete={onCropComplete}
                                                                                //     onZoomChange={setZoom}
                                                                                // />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="profile-picture-button">
                                                                    <div className="profile-wrapper">
                                                                        <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>
                                                                    </div>
                                                                </div>
                                                    }
                                                    {/* <div className="profile-picture-button"> */}
                                                    {/* <div className="profile-wrapper" ref={genderRef}>
                                                            <div className="crop-container">
                                                                {
                                                                    profile_picture ?
                                                                        // <img src={URL.createObjectURL(profile_picture)} width="100%" />
                                                                        <Cropper
                                                                            image={URL.createObjectURL(profile_picture)}
                                                                            crop={crop}
                                                                            zoom={zoom}
                                                                            cropShape="round"
                                                                            aspect={1}
                                                                            onCropChange={setCrop}
                                                                            onCropComplete={onCropComplete}
                                                                            onZoomChange={setZoom}
                                                                        />
                                                                        :
                                                                        profilePic != null ?
                                                                            // <img src={profilePic} width="100%" />
                                                                            <Cropper
                                                                                image={profilePic}
                                                                                crop={crop}
                                                                                zoom={zoom}
                                                                                cropShape="round"
                                                                                aspect={1}
                                                                                onCropChange={setCrop}
                                                                                onCropComplete={onCropComplete}
                                                                                onZoomChange={setZoom}
                                                                            />
                                                                            :
                                                                            <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>
                                                                }
                                                            </div>
                                                        </div> */}
                                                    {/* </div> */}
                                                    {/* <div className="profile-picture-button"> */}
                                                    {/* <Mui.Button
                                                        onClick={showCroppedImage}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Show Result
                                                    </Mui.Button> */}
                                                    <input accept="image/*" id="upload-profile-picture" onChange={handleImageChange} type='file' hidden />
                                                    <label htmlFor="--upload-profile-picture" >
                                                        <Mui.Button onClick={() => setShowProfileCropper(true)} component="span" className="upload-btn">
                                                            <Mui.Icon>upload</Mui.Icon>Upload Photo
                                                            </Mui.Button>
                                                    </label>
                                                    {/* </div> */}
                                                    <div ref={genderRef}>
                                                        <Mui.ButtonGroup style={{ marginTop: 20 }} variant="contained">
                                                            <Mui.Button className={gender != null && gender == 'Male' ? 'male-checked' : ''} onClick={() => handleGenderChange('Male')} fullWidth>
                                                                {
                                                                    gender != null && gender == 'Male'
                                                                    &&
                                                                    <Mui.Icon className="gender-check">check</Mui.Icon>
                                                                }
                                                                <img width="50%" src="/assets/icons/male.png" />
                                                            </Mui.Button>
                                                            <Mui.Button className={gender != null && gender == 'Female' ? 'female-checked' : ''} fullWidth onClick={() => handleGenderChange('Female')}>
                                                                {
                                                                    gender != null && gender == 'Female'
                                                                    &&
                                                                    <Mui.Icon className="gender-check">check</Mui.Icon>
                                                                }
                                                                <img width="50%" src="/assets/icons/female.png" />
                                                            </Mui.Button>
                                                        </Mui.ButtonGroup>
                                                        {
                                                            requiredFields.gender.isValid == false &&
                                                            <Mui.Typography variant="body2" className="form-error">{requiredFields.gender.errorMessage}</Mui.Typography>
                                                        }
                                                    </div>

                                                </Mui.Grid>
                                            </Mui.Grid>
                                            <Mui.Grid item xs={12} sm={9}>
                                                <Mui.Grid container spacing={2}>
                                                    <Mui.Grid item xs={12} sm={12} className="tag-header form">
                                                        <Mui.Typography className="section-heading" variant="body1">Basic Information <small>(Required)</small></Mui.Typography>

                                                    </Mui.Grid>
                                                    <Mui.Grid ref={firstNameRef} item xs={12} sm={6}>
                                                        <label>First Name*</label>
                                                        <Mui.TextField required variant="outlined" value={first_name} name="first_name" id="first_name" onChange={handleFirstNameChange} placeholder="First Name" fullWidth></Mui.TextField>
                                                        {
                                                            requiredFields.first_name.isValid == false &&
                                                            <Mui.Typography variant="body2" className="form-error">{requiredFields.first_name.errorMessage}</Mui.Typography>
                                                        }
                                                    </Mui.Grid>
                                                    <Mui.Grid ref={lastNameRef} item xs={12} sm={6}>
                                                        <label>Last Name*</label>
                                                        <Mui.TextField required variant="outlined" value={last_name} onChange={handleLastNameChange} placeholder="Last Name" fullWidth></Mui.TextField>
                                                        {
                                                            requiredFields.last_name.isValid == false &&
                                                            <Mui.Typography variant="body2" className="form-error">{requiredFields.last_name.errorMessage}</Mui.Typography>
                                                        }
                                                    </Mui.Grid>
                                                    <Mui.Grid ref={displayNameRef} item xs={12} sm={6}>
                                                        <label>Display Name*</label>
                                                        <Mui.TextField required variant="outlined" value={display_name} onChange={handleDisplayNameChange} placeholder="Display Name" fullWidth></Mui.TextField>
                                                        {
                                                            requiredFields.display_name.isValid == false &&
                                                            <Mui.Typography variant="body2" className="form-error">{requiredFields.display_name.errorMessage}</Mui.Typography>
                                                        }
                                                    </Mui.Grid>
                                                    <Mui.Grid ref={phoneNumberRef} item xs={12} sm={6}>
                                                        <label>Phone Number*</label>
                                                        <Mui.TextField required variant="outlined" onChange={handlePhoneNumberChange} placeholder="Phone Number" fullWidth></Mui.TextField>
                                                        {
                                                            requiredFields.phone_number.isValid == false &&
                                                            <Mui.Typography variant="body2" className="form-error">{requiredFields.phone_number.errorMessage}</Mui.Typography>
                                                        }
                                                    </Mui.Grid>
                                                </Mui.Grid>
                                            </Mui.Grid>
                                        </Mui.Grid>
                                    </Mui.Grid>
                                    <Mui.Grid item xs={12} sm={12}>
                                        <Mui.Grid container spacing={2}>
                                            <Mui.Grid item xs={12} sm={12} className="tag-header form">
                                                <Mui.Typography variant="body1">Account Information <small>(Required)</small></Mui.Typography>
                                            </Mui.Grid>
                                            <Mui.Grid ref={emailRef} item xs={12} sm={6}>
                                                <label>Email</label>
                                                <Mui.TextField disabled={isLoggedIn && (existingEmail != null && existingEmail != '')} required variant="outlined" value={email} onChange={handleEmailChange} placeholder="Email" fullWidth></Mui.TextField>
                                                {
                                                    requiredFields.email.isValid == false &&
                                                    <Mui.Typography variant="body2" className="form-error">{requiredFields.email.errorMessage}</Mui.Typography>
                                                }
                                            </Mui.Grid>
                                            <Mui.Grid ref={usernameRef} item xs={12} sm={6}>
                                                <label>Username</label>
                                                <Mui.TextField required disabled={isLoggedIn} variant="outlined" value={username} onChange={handleUsernameChange} placeholder="Username" fullWidth></Mui.TextField>
                                                {
                                                    requiredFields.username.isValid == false &&
                                                    <Mui.Typography variant="body2" className="form-error">{requiredFields.username.errorMessage}</Mui.Typography>
                                                }
                                            </Mui.Grid>
                                            <Mui.Grid ref={passwordRef} item xs={12} sm={6}>
                                                <label>Password*</label>
                                                <Mui.TextField required variant="outlined" onChange={handlePasswordChange} placeholder="Password" type="password" fullWidth></Mui.TextField>
                                                {
                                                    requiredFields.password.isValid == false &&
                                                    <Mui.Typography variant="body2" className="form-error">{requiredFields.password.errorMessage}</Mui.Typography>
                                                }
                                            </Mui.Grid>
                                            <Mui.Grid ref={confirmPasswordRef} item xs={12} sm={6}>
                                                <label>Confirm Password*</label>
                                                <Mui.TextField required variant="outlined" onChange={handleConfirmPasswordChange} placeholder="Confirm Password" type="password" fullWidth></Mui.TextField>
                                                {
                                                    requiredFields.confirmpassword.isValid == false &&
                                                    <Mui.Typography variant="body2" className="form-error">{requiredFields.confirmpassword.errorMessage}</Mui.Typography>
                                                }
                                            </Mui.Grid>
                                            <Mui.Grid item xs={12} sm={12} className="tag-header form">
                                                <Mui.Typography variant="body1">About</Mui.Typography>
                                            </Mui.Grid>
                                            <Mui.Grid item className="about" xs={12} sm={12}>
                                                <Mui.TextField multiline rows={5} variant="outlined" onChange={handleAboutChange} placeholder="A brief introduction about yourself to let parents and students know more about you." fullWidth></Mui.TextField>
                                            </Mui.Grid>
                                            {/* <Mui.Grid item xs={12} sm={12}>
                                                <TagsInput
                                                    selectedTags={handleSelecetedTags}
                                                    fullWidth
                                                    variant="outlined"
                                                    id="tags"
                                                    className="tags"
                                                    name="tags"
                                                    placeholder="Add Interests"
                                                    label="Field of Interests"
                                                />
                                            </Mui.Grid> */}
                                            {/* <Mui.Grid item className="qualification" xs={12} sm={6}>
                                                <Mui.TextField required variant="outlined" onChange={handleQualificationChange} placeholder="Qualification" fullWidth></Mui.TextField>
                                            </Mui.Grid> */}
                                            <Mui.Grid item xs={12} sm={12} className="tag-header form">
                                                <Mui.Typography variant="body1">Qualification <small>(Your educational qualification or any other qualification)</small></Mui.Typography>
                                            </Mui.Grid>
                                            <Mui.Grid item xs={12} sm={6}>
                                                <input accept="image/*,.pdf,.doc,.docx" multiple id="upload-documents" onChange={handleQualificationChange} type='file' hidden />
                                                <label htmlFor="upload-documents" >
                                                    <Mui.Button component="span" className="upload-document-button">
                                                        {

                                                            <div className="qualification-inner">
                                                                <Mui.Icon className="upload-icon">backup</Mui.Icon>
                                                                <p>Upload Your Documents</p>
                                                            </div>
                                                        }
                                                    </Mui.Button>
                                                </label>
                                            </Mui.Grid>
                                            <Mui.Grid item xs={12} sm={6}>
                                                <div className="files-wrapper">
                                                    {
                                                        qualificationList.map((item, index) => {
                                                            return (
                                                                <div item className="file-wrapper">
                                                                    <img src={fileIcons[item.substr(item.lastIndexOf('.') + 1)]} />
                                                                    <div className="name-wrapper">
                                                                        <span>{item}</span>
                                                                        <Mui.Icon onClick={() => deleteFileHandler(index)} className="delete-icon">close</Mui.Icon>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Mui.Grid>

                                            <Mui.Grid item xs={12} sm={12}>
                                                {
                                                    formErrors.map((error, index) => {
                                                        return (<Mui.Typography key={index} className="errors" variant="body2">{error}</Mui.Typography>)
                                                    })
                                                }
                                            </Mui.Grid>

                                            <Mui.Grid item xs={12} sm={12}>
                                                {
                                                    submitting && formErrors.length == 0 ?
                                                        <LinearLoader loaded={false} title="Updating profile, please wait..." />
                                                        :
                                                        submitted &&
                                                        <LinearLoader loaded={true} title="Completed!" />
                                                }
                                            </Mui.Grid>
                                            <Mui.Grid item xs={12} sm={12}>
                                                <Mui.Grid container justifyContent="flex-end">
                                                    {
                                                        submitting && formErrors.length == 0 ?
                                                            <Mui.Button type="button" disabled={true} className="submit-btn">Please wait...</Mui.Button>
                                                            :
                                                            <Mui.Button type="button" onClick={() => showCroppedImage()} className="submit-btn">Submit</Mui.Button>
                                                    }
                                                </Mui.Grid>
                                            </Mui.Grid>
                                        </Mui.Grid>
                                    </Mui.Grid>
                                </Mui.Grid>
                            </form>
                        </Mui.Grid>
                }

            </Mui.Grid>

        </React.Fragment >
    )
}
