import React, { useState, createContext } from 'react';
import { useJwt } from 'react-jwt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import AuthForm from '../components/auth/SingleSignIn';
import Checkout from '../components/checkout/checkout';
import Wallet from '../components/checkout/wallet';
import ProductService from '../services/products';
import { AuthContext } from '../context/AuthContext';
import { UserContext } from '../context/UserContext';
import { FavouriteContext } from '../context/FavouriteContext';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productsWrapper: {
        padding: 0,
        marginBottom: 0,
        height: '90vh',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            height: 'auto',
        }
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    titleName: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3,
        fontDecoration: 'none'
    },
    singleItem: {
        height: 320,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '95%',
        height: '100%',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginLef: 0
        }
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    authContainer: {
        backgroundColor: '#0e3d45',
        height: 'auto',
        paddingTop: 20,
        paddingBottom: 20,
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        paddingLeft: 0,
        paddingRight: 0
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
    authDialog: {
        overflowY: 'visible',
    },
    crossBtn: {
        top: 3,
        right: 3,
        position: 'absolute',
        zIndex: 5,
        cursor: 'pointer',
        color: 'red'
    }
}));

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

export const BuyNowContext = createContext();

export const BuyNowProvider = props => {
    const classes = useStyles();
    const mounted = React.useRef()
    const [favouriteItems, setFavouriteItems] = useState([]);
    const [checkoutOpen, setCheckoutOpen] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [buyProduct, setBuyProduct] = useState(null)
    const [walletsAvailable, setWalletsAvailable] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState('pay-pal')
    const [register, setRegister] = useState('signin')
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            let user = localStorage.getItem('user_id');
            if (user) {
                ProductService.getFavourites(user).then(res => {
                    setFavouriteItems(res.data)
                })
            }
        }
    }, [])

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method)
    }

    const handleWalletAvailability = (availability) => {
        setWalletsAvailable(availability)
    }

    const handleCheckoutClose = () => {
        setCheckoutOpen(false);
    };

    const handleCheckoutOpen = (props) => {
        setBuyProduct(props)
        setCheckoutOpen(true);
    };

    const handleConfirmation = () => {
        setCheckoutOpen(false)
        setConfirmDialog(true);
    };

    const handleClose = () => {
        setConfirmDialog(false)
    };

    function ConfirmDialog() {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth={'xs'}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src="/assets/check.png" className={classes.checkIcon} />
                </div>
                <span onClick={handleClose} className={classes.crossBtn}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </span>
                <DialogTitle className={classes.textCenter} id="alert-dialog-slide-title">
                    <span>Payment Processed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textCenter} id="alert-dialog-slide-description">
                        Thank You for your purchase.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: 20 }}>
                    <Grid container layout="flex" justifyContent="center">
                        <Link onClick={handleClose} className={classes.okBtn} color="primary" style={{ textDecoration: 'none' }} to={{
                            pathname: "/my-edhub"
                        }}>
                            View My Product
                        </Link>
                        {/* <Link onClick={handleClose} className={classes.okBtn} color="primary" style={{ textDecoration: 'none' }} to={{
                            pathname: "/product/view/" + (buyProduct != null ? buyProduct.course_name : ''),
                            state: {
                                image: (buyProduct != null ? (buyProduct.course_cover != null ? process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + buyProduct.subscriber_id + '/' + buyProduct.course_cover : pics[4]) : ''),
                                id: buyProduct != null ? buyProduct.course_id : 0
                            }
                        }}>
                            View My Product
                        </Link> */}
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    const showRegForm = (show) => {
        console.log(show)
        // setRegister(show)
    }

    function CheckoutDialog() {
        var lineItems = [
            {
                label: buyProduct != null ? buyProduct.course_name : '',
                amount: buyProduct != null ? (parseFloat(buyProduct.course_tution_fee) * 100) : 0
            }
        ]
        return (
            <Dialog onBackdropClick={handleCheckoutClose} className={classes.authDialog} open={checkoutOpen} fullWidth={true} maxWidth={'xs'} onClose={handleCheckoutClose} TransitionComponent={Transition}>
                <ButtonBase onClick={handleCheckoutClose} className={classes.closeIcon}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </ButtonBase>
                <Grid className={classes.authContainer} container direction="column" alignItems="center" justifyContent="center">
                    <h2 style={{ color: 'white', marginTop: 0, marginBottom: 15 }}>Buy Now</h2>
                    <Grid className={classes.authWrapper} item xs={12} sm={11}>
                        {isLoggedIn ?
                            <>
                                {
                                    paymentMethod == 'credit-card' ?
                                        <>
                                            <Checkout courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} productId={buyProduct != null ? buyProduct.course_id : 0} confirmDialog={handleConfirmation} total={buyProduct != null ? (buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? buyProduct.discounted_price : buyProduct.course_tution_fee) : 0} />

                                            <p onClick={() => handlePaymentMethodChange('pay-pal')} style={{ textAlign: 'center', color: '#1d4480', marginTop: 20, marginBottom: 20, fontSize: 16, cursor: 'pointer', fontWeight: 500 }}>Pay using wallets</p>

                                        </>
                                        :
                                        <>
                                            {/* {handlePaymentMethodChange('credit-card')} */}
                                            <Wallet courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} productId={buyProduct != null ? buyProduct.course_id : 0} type="single" handleWalletAvailability={handleWalletAvailability} handlePaymentMethodChange={handlePaymentMethodChange} lineItems={lineItems} confirmDialog={handleConfirmation} total={buyProduct != null ? ((buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? parseFloat(buyProduct.discounted_price) : parseFloat(buyProduct.course_tution_fee)) * 100) : 0} />
                                        </>
                                }
                            </>
                            :
                            <>

                                <AuthForm />
                            </>
                        }

                    </Grid>
                </Grid>
            </Dialog>
        )
    }
    return (
        <BuyNowContext.Provider value={{ buy: [buyProduct, setBuyProduct], dialog: [checkoutOpen, setCheckoutOpen], auth: [isLoggedIn, setIsLoggedIn] }}>
            <ConfirmDialog />
            <CheckoutDialog />
            {props.children}
        </BuyNowContext.Provider>
    )
}
