import React from "react";
import styled from "styled-components";

const Card = ({ title, count,color,colorTop }) => {
    
  return (
    <>
      <CardDiv color={color} colorTop={colorTop}>
        <Title colorTop={colorTop}>{title}</Title>
        <Count colorTop={colorTop}>$ {count}</Count>
      </CardDiv>
    </>
  );
};

const CardDiv = styled.div`
  background-color: ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0;
  border-radius: 0;
  border-top: solid 4px ${props => props.colorTop};
`;

const Title = styled.h4`
color: 'black';
  margin-left:15px;
  margin-bottom:0
`;

const Count = styled.h1`
  color: 'black';
  margin-top: 5px;
  margin-left:15px;
  font-weight:300
`;

export default Card;
