import React, { useState } from 'react'
import HeroSection from '../components/widgets/herosection';
import Latest from '../components/products/latest';
import Monthly from '../components/products/thismonth';
import Trending from '../components/products/trending';
import Featured from '../components/products/featured';
import Button from '@material-ui/core/Button';
import sha256 from 'crypto-js/sha256';
import { Document, Page, Text } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import $ from 'jquery';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Home() {
    const elementRef = React.useRef(null);
    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };
    const handlePrintTest = () => {
        window.printPdf()
    }

    const handleOnPageLoaded = async (pdfObject) => {
        const page = await pdfObject.getPage(1);
        // console.log(pdfObject)
        console.log(page);
        // console.log(page.height);
    }

    return (
        <div style={{ backgroundColor: '#F4F4F4' }}>
            <HeroSection />
            <Featured />
            <Trending />
            <Monthly />
            <Latest />
        </div>
    )
}
