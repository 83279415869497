import React, { useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import Auth from '../../services/auth';
import { UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '70vh',
        borderRadius: 15,
        overflow: 'hidden',
    },
    image: {
        backgroundImage: 'url(/assets/rightHero.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#0d333a',
        backgroundSize: '70%',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width: '40%',
        marginBottom: 30
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


export default function SignInSide(props) {
    const ref = useRef();
    const classes = useStyles();
    const [username, setUsername] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;

    const handleLogin = () => {
        let user = {
            "username": username,
            "password": password
        }
        Auth.loginUser(username, password).then((response) => {
            if (response.data.status == 200 && !response.data.error) {
                setToken(response.data.data.token)
                localStorage.setItem('jwt_token', response.data.data.token)
                props.onChange(false)
            }
        })
    }

    const handleAuth = (view) => {
        props.onClick(view)
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img className={classes.avatar} src="/assets/Logo.png" />
                    <Typography style={{ marginBottom: 20 }} component="h2" variant="h6">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField variant="outlined" margin="normal" required
                            onChange={(e) => setUsername(e.target.value)}
                            id="email" label="Email Address" name="email" autoComplete="email" autoFocus
                        />
                        <TextField
                            variant="outlined" margin="normal" required
                            onChange={(e) => setPassword(e.target.value)}
                            name="password" label="Password" type="password" id="password" autoComplete="current-password"
                        />

                        <Button variant="contained" color="primary" onClick={handleLogin} className={classes.submit}>
                            Sign In
                        </Button>

                        <Grid container>
                            <Grid item>
                                <Button onClick={() => handleAuth('login')}>
                                    {"Already have an account? Sign In"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
