import React, { useContext, useRef, useState, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Image from 'material-ui-image';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { AuthContext } from '../../../context/AuthContext';
import { useJwt } from "react-jwt";
import Slide from '@material-ui/core/Slide';
import SideSignIn from '../../auth/SideSignIn';
import Register from '../../auth/Register';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { FavouriteContext } from '../../../context/FavouriteContext';
import { EnrollmentNotificationContext } from '../../../context/EnrollmentNotificationContext';
import Auth from '../../../services/auth';
import Classes from '../../../services/classes';
import HeroSection from '../../widgets/herosection'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { CommonContext } from '../../../context/CommonContext'

import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useParams
} from "react-router-dom";

import './header.css';

import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';
import { useHistory } from "react-router-dom";
import ProfilePicture from '../../auth/ProfilePicture';

import Select, { components, DropdownIndicatorProps } from 'react-select';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

// const token = localStorage.getItem('jwt_token');


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        marginRight: theme.spacing(2),
        zIndex: 50
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    title: {
        flexGrow: 1,
        width: 40,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    topBar: {
        backgroundColor: '#f6f6f6',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 0,
            paddingTop: 0,
        }

    },
    searchpaper: {
        height: 'calc(100% - 64px)',
        top: 64
    },
    topBarTeacher: {
        backgroundColor: '#13134b',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 30,
        paddingRight: 30,
        '& .display-name, & .email': {
            color: 'white',
            textTransform: 'Capitalize'
        },
        '& .d-flex': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& button': {
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 0,
            paddingTop: 0,
        }

    },
    navAction: {
        minWidth: 40,
        height: 40,
        padding: 0,
        borderRadius: 30,
        backgroundColor: '#1a95ac',
        margin: 5,
        overflow: 'visible',
        '&:hover': {
            backgroundColor: '#24646F'
        },
        '& img': {
            borderRadius: 30,
        }
    },
    actionIcon: {
        color: 'white',
        fontSize: 18
    },
    herosection: {
        padding: 50,
        backgroundColor: '#0B4C57',

    },
    transparentBG: {
        backgroundColor: 'transparent'
    },
    links: {
        marginRight: 10,
        fontWeight: 500,
        color: 'white'
    },
    btnlink: {
        marginRight: 30,
        marginLeft: 10,
        fontWeight: 500,
        color: 'white',
        backgroundColor: '#f69a24',
        borderRadius: 20,
        paddingLeft: 20, paddingRight: 20, textDecoration: 'none', paddingTop: 10, paddingBottom: 10
    },
    heroText: {
        margin: 0,
        fontSize: 60,
        color: 'white',
        fontWeight: 100
    },
    heroTextStrong: {
        margin: 0,
        fontSize: 60,
        color: 'white',
        fontWeight: 300
    },
    singleLine: {
        margin: 0,
        fontSize: 22,
        color: 'white',
        fontWeight: 100
    },
    searchWrapper: {
        width: '65%',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 35,
        marginTop: 20,
        paddingRight: 20,
    },
    searchField: {
        border: 'none',
        flexGrow: 1
    },
    searchFieldTeacher: {
        border: 'none',
        flexGrow: 1,
        color: 'white'
    },
    logo: {
        width: 80,
    },
    brandlogo: {
        width: '100px',
        height: 'auto'
    },
    brandlogoSearch: {
        width: '300px',
        height: 'auto'
    },
    noBorder: {
        border: "none",
        padding: 0
    },
    searcnBtn: {
        padding: 0,
        backgroundColor: 'transparent',
        color: 'white',
        boxShadow: 'none'
    },
    authWrapper: {

    },
    authContainer: {
        backgroundColor: '#061C20',
        minHeight: '100%'
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    branding: {
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
    searchWrapperTop: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
        border: 'solid',
        borderWidth: 1,
        borderColor: '#d5d5d5',
        paddingLeft: 10,
        paddingRight: 5,
        width: '60%',
        borderRadius: 10
    },
    searchLink: {
        display: 'flex',
        alignItems: 'center'
    },
    navLink: {
        textDecoration: 'none',
        fontSize: 16,
        color: '#fff',
        marginBottom: 0,
        marginLeft: 8
    },
    navItem: {
        paddingTop: 8,
        paddingBottom: 8,
        // borderBottom: 'solid',
        // borderWidth: 1,
        // borderColor: '#1a6c7c'
    },
    navList: {
        borderBottom: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#0f5a69'
    },
    navIcon: {
        marginRight: 10, color: '#fff'
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#136575'
        }
    },
    navInitials: {
        width: 50,
        height: 50,
        backgroundColor: '#f9a72e',
        color: 'white',
        marginRight: 10,
        borderRadius: 50,
        fontSize: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        marginBottom: 10,
        border: 'solid',
        borderWidth: 2,
        borderColor: '#d3d3d3'
    },
    navUser: {
        color: 'white',
        fontSize: 18
    },
    navEmail: {
        color: 'white',
        fontSize: 14
    },
    navInfoItem: {
        display: 'flex',
        alignItems: 'center'
    },
    warningBtn: {
        backgroundColor: '#ffa129',
        color: 'white',
        fontSize: 12,
        marginLeft: 10
    },
    warningAlert: {
        display: 'flex', alignItems: 'center'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Header() {

    const mounted = React.useRef()
    const location = useLocation();
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { products } = React.useContext(UserContext)
    const [usersProducts, setUsersProducts] = products;
    const [openAwaitingEnrollments, setOpenAwaitingEnrollments] = React.useState(false);
    const { awaitingEnrollments } = React.useContext(EnrollmentNotificationContext)
    const [enrollmentNotifications, setEnrollmentNotifications] = awaitingEnrollments;
    const [searchTerm, setSearchTerm] = React.useState([]);
    const { role } = React.useContext(UserContext)
    const [userRole, setUserRole] = role;
    const { user } = React.useContext(UserContext)
    const { notification } = React.useContext(UserContext)
    const { accntNotification } = React.useContext(UserContext)
    const { someTest } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const [teacherNotification, setTeacherNotification] = notification;
    const [accountNotification, setAccountNotification] = accntNotification;
    const [test, setTest] = someTest;
    const { decodedToken, isExpired } = useJwt(token);
    const history = useHistory();
    const [query, setValue] = React.useState(null);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [text, setText] = React.useState('');
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openCourseMenu, setOpenCourseMenu] = React.useState(false);
    const { product } = useContext(CommonContext)
    const [tags, setTags] = product


    const anchorRef = React.useRef(null);
    const anchorCoursesRef = React.useRef(null);
    const [open, setOpen] = useContext(AuthContext)
    const classes = useStyles();
    const { favouriteItem } = React.useContext(FavouriteContext);
    const [favouriteItems, setFavouriteItems] = favouriteItem;
    // const [open, setOpen] = React.useState(false)
    const mobilenavs = [
        // {
        //     'text': 'Home',
        //     'url': '/',
        //     'icon': 'home',
        //     'auth': false,
        //     'role': ['All']
        // },
        {
            'text': 'Find a teacher',
            'url': '/teachers',
            'icon': 'home',
            'auth': false,
            'role': ['All']
        },
        // {
        //     'text': 'About us',
        //     'url': '/about-us',
        //     'icon': 'info',
        //     'auth': false,
        //     'role': ['All']
        // },
        {
            'text': 'Shops',
            'url': '/shops',
            'icon': 'shopping_bag',
            'auth': false,
            'role': ['All']
        },
        // {
        //     'text': 'Become a Teacher',
        //     'url': '/become-teacher',
        //     'icon': 'school',
        //     'auth': false,
        //     'role': [(userRole != 'TEACHER' && userRole != 'EdHub' && currentUser.status != 4) ? 'All' : 'Not']
        // },
        // {
        //     'text': 'Contact',
        //     'url': '/contact-us',
        //     'icon': 'phone',
        //     'auth': false
        // },
        {
            'text': 'My EdHub',
            'url': '/my-edhub',
            'icon': 'storefront',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'My Bookings',
            'url': (userRole == 'TEACHER' || userRole == 'EdHub') ? '/my/trial/bookings' : '/my/bookings',
            'icon': 'storefront',
            'auth': true,
            'role': ['ALL']
        },
        {
            'text': 'Timetable',
            'url': '/my-classes',
            'icon': 'school',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'My Courses',
            'url': '/my-products',
            'icon': 'library_books',
            'auth': true,
            'role': ['TEACHER', 'EdHub']
        },
        {
            'text': 'Admin',
            'url': '/admin/management',
            'icon': 'admin_panel_settings',
            'auth': true,
            'role': ['EdHub']
        },
        {
            'text': 'My Favourites',
            'url': '/favourites',
            'icon': 'favorite',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'Account',
            'url': '/my-account',
            'icon': 'person',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'Browsing History',
            'url': '/browsing-history',
            'icon': 'restore',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'My Followings',
            'url': '/subscriptions',
            'icon': 'notifications_active',
            'auth': true,
            'role': ['All']
        },
        {
            'text': 'Logout',
            'icon': 'power_settings_new',
            'auth': true,
            'role': ['All']
        },

    ]
    const [cartItem, setCartItem] = useContext(CartContext)
    const [index, setIndex] = React.useState(0)
    const [authView, setAuthView] = React.useState('login')
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [cartCount, setCartCount] = React.useState(0)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    React.useEffect(() => {
        const unlisten = history.listen((location) => {
            setOpenSearch(false)
        })
        return function cleanup() {
            unlisten()
        }
    }, [])

    const search = (value) => {
        // setText('')
        history.push({ pathname: query })
    }

    const handleSearchClear = () => {
        setText('')
    }

    const handleToggleMenu = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    };
    const handleCourseToggleMenu = () => {
        setOpenCourseMenu((prevOpen) => !prevOpen);
    };

    const handleCourseMenuClose = () => {
        setOpenCourseMenu(false);
    };

    const handleCloseCourseMenu = (event) => {
        if (anchorCoursesRef.current && anchorCoursesRef.current.contains(event.target)) {
            return;
        }

        setOpenCourseMenu(false);
    };


    const prevOpen = React.useRef(openMenu);
    React.useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = openMenu;

    }, []);

    const slideRenderer = ({ key, index }) => (
        <div style={{ color: '#fff' }} key={key}>
            {
                authView == 'login' ?
                    <div>
                        <SideSignIn handleClose={handleClose} onChange={handleClose} onClick={handleChangeAuth} />
                    </div>
                    :
                    <div>
                        <Register handleClose={handleClose} onChange={handleClose} onClick={handleChangeAuth} />
                    </div>
            }
        </div>
    );


    function handleChangeAuth(view) {
        setAuthView(view)
    }

    const addItemToCart = (productId) => {
        setCartCount(cartCount + 1)
    }

    // function closeAfterLogin(newValue) {
    //     setOpen(newValue)
    // }

    const logOut = (event) => {
        handleCloseMenu(event)
        localStorage.removeItem('user_id')
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('subscriber_id')
        setUserRole(null)
        setToken('')
        setCurrentUser({})
        setFavouriteItems([])
        setTeacherNotification(false)
        setAccountNotification(false)
        window.location.reload();
    }

    function LoginDialog() {

        return (
            <Dialog onBackdropClick={handleClose} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <ButtonBase onClick={handleClose} className={classes.closeIcon}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </ButtonBase>
                <Grid className={classes.authContainer} container alignItems="center" justifyContent="center">
                    <Grid className={classes.authWrapper} item xs={11} md={8} sm={10}>
                        <VirtualizeSwipeableViews
                            slideCount={2}
                            slideRenderer={slideRenderer} />
                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApproveEntry = (classId) => {
        let data = {
            'class': classId
        }
        Classes.approveExtraEnrollments(data).then(res => {
            setEnrollmentNotifications(enrollmentNotifications.filter(awaiting => awaiting.class_id != classId))
        })
    }

    const handleDeclineEntry = (classId) => {
        let data = {
            'class': classId
        }
        Classes.declineExtraEnrollments(data).then(res => {
            setEnrollmentNotifications(enrollmentNotifications.filter(awaiting => awaiting.class_id != classId))
        })
    }

    React.useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    })

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <Button variant="contained" className={classes.searcnBtn} >
                    <Link className={classes.searchLink} to={{ pathname: '/search/' + searchTerm }}>
                        <Icon style={{ marginLeft: 0, color: 'white' }}>search</Icon>
                    </Link>
                </Button>
            </components.DropdownIndicator>
        );
    };

    const customStyles = {
        container: () => ({
            position: 'relative',
            boxSizing: 'border-box',
            width: '100%',
            borderStyle: 'none',
            backgroundColor: 'transparent'
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            borderStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            backgroundColor: 'transparent'
        }),
    }

    var navKey = 0;
    const list = (anchor) => (

        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {
                    !isExpired &&
                    <ListItem className={classes.navInfo} button key={'userinfo'}>
                        <Grid container className={classes.navInfoItem} alignItems="center">
                            <Grid className={classes.navInitials}>
                                {
                                    typeof currentUser.details != 'undefined' &&
                                        currentUser.details.profile_picture != null ?
                                        currentUser.details.auth_using == 'social' ?
                                            <>
                                                <img width="50" className="user-avatar" src={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                                {
                                                    currentUser.connect_account_status == 1 &&
                                                    <span className="verified-icon">
                                                        <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                            <Icon>check</Icon>
                                                        </Tooltip>
                                                    </span>
                                                }
                                            </>
                                            // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                            :
                                            // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                            <>
                                                <img width="50" className="user-avatar" src={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                                {
                                                    currentUser.connect_account_status == 1 &&
                                                    <span className="verified-icon">
                                                        <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                            <Icon>check</Icon>
                                                        </Tooltip>
                                                    </span>
                                                }
                                            </>
                                        :
                                        <>
                                            <PersonOutlineOutlinedIcon className={classes.actionIcon} />
                                            {
                                                currentUser.connect_account_status == 1 &&
                                                <span className="verified-icon">
                                                    <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                        <Icon>check</Icon>
                                                    </Tooltip>
                                                </span>
                                            }
                                        </>
                                }
                            </Grid>
                            <Grid>
                                <Grid className={classes.navUser}>{currentUser.details != null && currentUser.details.display_name}</Grid>
                                <Grid className={classes.navEmail}>{currentUser != null && currentUser.email}</Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                }
                {
                    mobilenavs.map((nav, index) => (
                        <>

                            {
                                nav.text != 'Logout' && nav.text != 'Become a Teacher' ?
                                    nav.auth ?
                                        !isExpired &&
                                        (nav.role == 'All' || nav.role.includes(userRole)) &&
                                        <Link style={{ textDecoration: 'none' }} to={nav.url}>
                                            <ListItem className={classes.navList} button key={nav.text + navKey}>
                                                <Grid container className={classes.navItem} alignItems="center">
                                                    <Icon className={classes.navIcon}>{nav.icon}</Icon>
                                                    <Link className={classes.navLink} to={nav.url}>{nav.text}</Link>
                                                </Grid>
                                            </ListItem>
                                        </Link>
                                        :
                                        <Link style={{ textDecoration: 'none' }} to={nav.url}>
                                            <ListItem className={classes.navList} button key={nav.text + navKey}>
                                                <Grid container className={classes.navItem} alignItems="center">
                                                    <Icon className={classes.navIcon}>{nav.icon}</Icon>
                                                    <Link className={classes.navLink} to={nav.url}>{nav.text}</Link>
                                                </Grid>
                                            </ListItem>
                                        </Link>
                                    :
                                    nav.text == 'Logout' ?
                                        !isExpired &&
                                        <Link style={{ textDecoration: 'none' }} to={nav.url}>
                                            <ListItem className={classes.navList} button key={nav.text + navKey}>
                                                <Grid container className={classes.navItem} alignItems="center">
                                                    <Icon className={classes.navIcon}>{nav.icon}</Icon>
                                                    <Link className={classes.navLink} to={'/'} onClick={logOut}>{nav.text}</Link>
                                                </Grid>
                                            </ListItem>
                                        </Link>
                                        :
                                        (nav.role == 'All' || nav.role.includes(userRole)) &&
                                        <Link style={{ textDecoration: 'none' }} to={nav.url}>
                                            <ListItem className={classes.navList} button key={nav.text + navKey}>
                                                <Grid container className={classes.navItem} alignItems="center">
                                                    <Icon className={classes.navIcon}>{nav.icon}</Icon>
                                                    <Link className={classes.navLink} to={nav.url}>{nav.text}</Link>
                                                </Grid>
                                            </ListItem>
                                        </Link>
                            }
                        </>
                    ))
                }
            </List>
        </div>
    );

    const toggleSearchDrawer = (open) => {
        setOpenSearch(open)
    }



    const SearchDrawer = () => {
        return (
            <SwipeableDrawer classes={{ paper: classes.searchpaper }} anchor={'top'} open={openSearch} onClose={() => toggleSearchDrawer(false)}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center', height: '100%' }}>
                    <Icon style={{ position: 'absolute', top: 5, right: 5, fontSize: 28, cursor: 'pointer' }} onClick={() => setOpenSearch(false)}>close</Icon>
                    <Link to="/">
                        <img className={classes.brandlogoSearch} color="transparent" src="/assets/Logo.png" />
                    </Link>
                    <HeroSection closeDrawer={() => setOpenSearch(false)} />
                </div>
            </SwipeableDrawer>
        )
    }


    return (
        <div id="page-header">
            <LoginDialog />
            <SearchDrawer />
            <div>
                <React.Fragment>
                    <Drawer classes={{ paper: classes.paper }} anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                        <div>
                            {list('left')}
                        </div>
                    </Drawer>
                </React.Fragment>
            </div>
            <Grid onMouseLeave={e => handleCourseMenuClose()} container justifyContent="space-between" alignItems="center" className={userRole == "TEACHER" ? classes.topBarTeacher : classes.topBar} position="static">
                <Grid item className={classes.branding}>
                    <Hidden smUp>
                        <IconButton onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon color="white" />
                        </IconButton>
                    </Hidden>
                    <Link to="/">
                        <img className={classes.brandlogo} color="transparent" src="/assets/Logo.png" />
                    </Link>
                    {
                        location.pathname != '/' &&
                        <Button onClick={() => setOpenSearch(true)}>
                            <Icon style={{ marginLeft: 10, color: 'white', cursor: 'pointer' }} >search</Icon>
                        </Button>
                    }
                    {
                        location.pathname != '/' &&
                        <Hidden xsUp>
                            <div className={classes.searchWrapperTop}>
                                {/* <InputBase id="name" value={text} onKeyDown={(e) => { e.keyCode == 13 && search(e.target.value); }} onChange={(e) => { setValue('/search/' + e.target.value); setText(e.target.value) }} className={(userRole == 'TEACHER') ? classes.searchFieldTeacher : classes.searchField} placeholder="I want to find..." variant="outlined" />
                                <Link className={classes.searchLink} to={{ pathname: query }}>
                                    <Icon style={{ marginLeft: 10, color: '#d5d5d5', height: '100%' }}>search</Icon>
                                </Link> */}
                                <Select
                                    closeMenuOnSelect={false}
                                    components={{ DropdownIndicator }}
                                    isMulti
                                    styles={customStyles}
                                    options={tags}
                                    onChange={(item) => setSearchTerm(item.map(label => {
                                        return label.value
                                    }))}
                                />
                            </div>
                        </Hidden>
                    }
                </Grid>
                <Grid>
                    <Hidden only="xs">
                        {/* <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/">Home</Link>
                        </Button> */}
                        <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/teachers">Find a teacher</Link>
                        </Button>
                        {/* <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/about-us">About us</Link>
                        </Button> */}
                        <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/shops">Shops</Link>
                        </Button>
                        {/* <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/faqs">FAQs</Link>
                        </Button> */}
                        {/* <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                            <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/contact-us">Contat Us</Link>
                        </Button> */}
                        {/* {
                            !isExpired ?
                                (userRole != 'TEACHER' && userRole != 'EdHub' && currentUser.status != 4) &&
                                < Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                                    <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/become-teacher">Become a Teacher</Link>
                                </Button>
                                :
                                < Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                                    <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/become-teacher">Become a Teacher</Link>
                                </Button>
                        } */}
                        {
                            !isExpired &&
                            <>
                                {
                                    (userRole != 'TEACHER' && userRole != 'EdHub') &&
                                    <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                                        <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/my-edhub">My EdHub</Link>
                                    </Button>
                                }
                                {
                                    <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                                        <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/my-classes">Timetable</Link>
                                    </Button>
                                }
                                {
                                    userRole == 'EdHub' &&
                                    <Button onClick={handleSearchClear} color="inherit" className={classes.links}>
                                        <Link style={{ color: '#fa9c24', textDecoration: 'none' }} to="/admin/management">Admin</Link>
                                    </Button>
                                }
                                {
                                    (userRole == 'TEACHER' || userRole == 'EdHub') &&
                                    // <Button onClick={handleSearchClear} color="inherit" className={classes.btnlink}>
                                    //     <Link style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center' }} to="/my-products">
                                    //         My Courses
                                    //     </Link>
                                    // </Button>
                                    <>
                                        <Link to={'/my-products/all'} className={classes.btnlink}
                                            ref={anchorCoursesRef}
                                            aria-controls={openMenu ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={e => handleCourseToggleMenu()}
                                            onClick={handleCloseCourseMenu}
                                        >
                                            <span>My Course</span>
                                        </Link>
                                        <Popper style={{ zIndex: 50, marginTop: 5 }} placement="bottom-start" open={openCourseMenu} anchorEl={anchorCoursesRef.current} role={undefined} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                >
                                                    <Paper className={classes.paper}>
                                                        <ClickAwayListener onClickAway={handleCloseCourseMenu}>
                                                            <MenuList autoFocusItem={openCourseMenu} id="menu-list-grow">
                                                                <MenuItem onClick={handleCloseCourseMenu}>
                                                                    <Link style={{ color: '#000', textDecoration: 'none', display: 'flex', alignItems: 'center' }} to="/my-products/all">
                                                                        All Courses
                                                                    </Link>
                                                                </MenuItem>
                                                                <MenuItem onClick={handleCloseCourseMenu}>
                                                                    <Link style={{ color: '#000', textDecoration: 'none', display: 'flex', alignItems: 'center' }} to="/my-products/new">
                                                                        Create Course
                                                                    </Link>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </>
                                }

                            </>
                        }
                    </Hidden>
                    <Button onClick={handleSearchClear} className={classes.navAction}>
                        <Link style={{ color: 'white', textDecoration: 'none' }} to="/cart">
                            <Badge badgeContent={cartItem != null ? cartItem.length : 0} color="primary">
                                <ShoppingCartOutlinedIcon className={classes.actionIcon} />
                            </Badge>
                        </Link>
                    </Button>
                    {!isExpired &&
                        <Button onClick={handleSearchClear} className={classes.navAction}>
                            <Link style={{ display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none' }} to="/favourites">
                                <Badge badgeContent={favouriteItems != null ? favouriteItems.length : 0} color="primary">
                                    <FavoriteBorderOutlinedIcon className={classes.actionIcon} />
                                </Badge>
                            </Link>
                        </Button>
                    }
                    {/* <Hidden xsDown> */}
                    {!isExpired &&
                        <>
                            <Hidden smUp>
                                <Link to={userRole == 'TEACHER' ? '/my-profile' : '/my-account'}>
                                    <Button onClick={handleSearchClear} className={classes.navAction}
                                        ref={anchorRef}
                                        aria-controls={openMenu ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggleMenu}
                                    >
                                        {
                                    /* <PersonOutlineOutlinedIcon className={classes.actionIcon} /> */}
                                        {
                                            typeof currentUser.details != 'undefined' &&
                                                currentUser.details.profile_picture != null ?
                                                currentUser.details.auth_using == 'social' ?
                                                    <>
                                                        <img width="40" className="user-avatar" src={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                                        {
                                                            currentUser.connect_account_status == 1 &&
                                                            <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                                <Icon className="verified-icon">check</Icon>
                                                            </Tooltip>
                                                        }
                                                    </>
                                                    // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                                    :
                                                    // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                                    <>
                                                        <img width="40" className="user-avatar" src={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                                        {
                                                            currentUser.connect_account_status == 1 &&
                                                            <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                                <Icon className="verified-icon">check</Icon>
                                                            </Tooltip>
                                                        }
                                                    </>
                                                :
                                                <>
                                                    <PersonOutlineOutlinedIcon className={classes.actionIcon} />
                                                    {
                                                        currentUser.connect_account_status == 1 &&
                                                        <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                            <Icon className="verified-icon">check</Icon>
                                                        </Tooltip>
                                                    }
                                                </>
                                        }
                                    </Button>
                                </Link>
                            </Hidden>
                            <Hidden xsDown>
                                <Button onClick={handleSearchClear} className={classes.navAction}
                                    ref={anchorRef}
                                    aria-controls={openMenu ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggleMenu}
                                >
                                    {
                                    /* <PersonOutlineOutlinedIcon className={classes.actionIcon} /> */}
                                    {
                                        typeof currentUser.details != 'undefined' &&
                                            currentUser.details.profile_picture != null ?
                                            currentUser.details.auth_using == 'social' ?
                                                <>
                                                    <img width="40" className="user-avatar" src={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                                    {
                                                        currentUser.connect_account_status == 1 &&
                                                        <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                            <Icon className="verified-icon">check</Icon>
                                                        </Tooltip>
                                                    }
                                                </>
                                                // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && currentUser.details.profile_picture} />
                                                :
                                                // <ProfilePicture profile_picture={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                                <>
                                                    <img width="40" className="user-avatar" src={typeof currentUser.details != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + currentUser.details.profile_picture} />
                                                    {
                                                        currentUser.connect_account_status == 1 &&
                                                        <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                            <Icon className="verified-icon">check</Icon>
                                                        </Tooltip>
                                                    }
                                                </>
                                            :
                                            <>
                                                <PersonOutlineOutlinedIcon className={classes.actionIcon} />
                                                {
                                                    currentUser.connect_account_status == 1 &&
                                                    <Tooltip title={<h4>Your account is verified for paid courses</h4>} placement="top">
                                                        <Icon className="verified-icon">check</Icon>
                                                    </Tooltip>
                                                }
                                            </>
                                    }
                                </Button>

                                <Popper className="top-dropdown" style={{ zIndex: 50 }} placement="bottom-start" open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper className={classes.paper}>
                                                <ClickAwayListener onClickAway={handleCloseMenu}>
                                                    <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                                                        {
                                                            userRole == "TEACHER" &&
                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/my-edhub">
                                                                    <Icon style={{ marginRight: 10, color: '#FA9C24' }}>library_books</Icon>{'My EdHub'}
                                                                </Link>
                                                            </MenuItem>
                                                        }
                                                        {
                                                            userRole == "TEACHER" &&
                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/my-shop">
                                                                    <Icon style={{ marginRight: 10, color: '#FA9C24' }}>shop</Icon>{'My Shop'}
                                                                </Link>
                                                            </MenuItem>
                                                        }
                                                        
                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to={userRole == 'TEACHER' || userRole == 'EdHub' ? "/my/trial/bookings" : "/my/bookings"}>
                                                                    <Icon style={{ marginRight: 10, color: '#FA9C24' }}>list</Icon>{'My Bookings'}
                                                                </Link>
                                                            </MenuItem>
                                                        
                                                        <MenuItem onClick={handleCloseMenu}>
                                                            <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/browsing-history">
                                                                <Icon style={{ marginRight: 10, color: '#FA9C24' }}>history</Icon>{'Browsing History'}
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleCloseMenu}>
                                                            <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/subscriptions">
                                                                <Icon style={{ marginRight: 10, color: '#FA9C24' }}>notifications_active</Icon>{'My Followings'}
                                                            </Link>
                                                        </MenuItem>
                                                        {
                                                            userRole != 'TEACHER' &&
                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/my-account">
                                                                    <Icon style={{ marginRight: 10, color: '#FA9C24' }}>settings</Icon>{'Settings'}
                                                                </Link>
                                                            </MenuItem>
                                                        }
                                                        {
                                                            userRole == "TEACHER" &&
                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <Link style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none', width: '100%' }} to="/my-profile">
                                                                    <Icon style={{ marginRight: 10, color: '#FA9C24' }}>settings</Icon>{'Settings'}
                                                                </Link>
                                                            </MenuItem>
                                                        }
                                                        <MenuItem onClick={logOut}>
                                                            <Icon style={{ marginRight: 10, color: '#eb0015' }}>power_settings_new</Icon>{'Logout'}
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>

                                <Button className="user-info-header" onClick={handleToggleMenu}>
                                    <div className="d-flex">
                                        <Typography className="display-name" variant="body2">{currentUser.details != null && currentUser.details.display_name}</Typography>
                                        {/* <Typography className="email" variant="body2">{currentUser != null && currentUser.email}</Typography> */}
                                    </div>
                                </Button>
                            </Hidden>
                        </>
                    }
                    {/* </Hidden> */}

                    {
                        isExpired &&
                        <Button onClick={handleSearchClear} style={{ color: '#fa9c24' }} color="inherit" onClick={handleClickOpen} className={classes.links}>
                            Login
                        </Button>
                    }
                </Grid>
            </Grid>
            {
                teacherNotification &&
                <Alert className={classes.warningAlert} severity="warning">

                    <span>Action required. Please check your profile </span>
                    <Link style={{ textDecoration: 'none' }} to={'/my-profile'}>
                        <Button onClick={handleSearchClear} className={classes.warningBtn}>My Profile</Button>
                    </Link>

                </Alert>
            }
            {
                enrollmentNotifications.length > 0 &&
                <>
                    <Alert className={classes.warningAlert} severity="warning">

                        <span>Some enrollments are waiting for your approval. </span>
                        <Button onClick={() => setOpenAwaitingEnrollments(true)} className={classes.warningBtn}>View</Button>

                    </Alert>
                    <Dialog
                        open={openAwaitingEnrollments}
                        fullWidth={true}
                        maxWidth={'sm'}
                        onClose={() => setOpenAwaitingEnrollments(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Awaiting extra enrollments for approval"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {
                                    enrollmentNotifications.map((enrollment) => {
                                        return (
                                            <Grid container style={{ padding: 8, border: 'solid', borderRadius: 10, marginBottom: 10 }} justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body1">{enrollment.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container alignItems="center">
                                                        <Grid item>
                                                            <Typography style={{ marginRight: 10 }} variant="body1">{enrollment.pending} awaiting approvals</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip placement="top" title="Decline">
                                                                <IconButton onClick={() => handleDeclineEntry(enrollment.class_id)} size="small" style={{ padding: 3, marginRight: 5, marginLeft: 5, backgroundColor: '#E74C3C', color: 'white' }}><CloseIcon /></IconButton>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title="Approve">
                                                                <IconButton onClick={() => handleApproveEntry(enrollment.class_id)} size="small" style={{ padding: 3, marginRight: 5, marginLeft: 5, backgroundColor: '#008080', color: 'white' }}><CheckIcon /></IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenAwaitingEnrollments(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </>

            }

            {
                (currentUser.role_id == 4 && currentUser.stripe_connect_id == null) &&
                <Alert className={classes.warningAlert} severity="warning">

                    <span>Some information is missing. Please check your profile </span>
                    <Link style={{ textDecoration: 'none' }} to={'/my-profile'}>
                        <Button onClick={handleSearchClear} className={classes.warningBtn}>My Profile</Button>
                    </Link>

                </Alert>
            }
        </div >
    );
}