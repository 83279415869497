import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "./Rating";
import Languages from "./Languages";
import Summary from "./Summary";
import Trials from "./Trials";
import ProfilePicture from "./ProfilePicture";
import TrialActions from "./TrialActions";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  primaryText: {
    color: "#00C1E2",
  },
}));

export default function Single({ data }) {
  const classes = useStyles();

  const {
    display_name,
    title,
    sub_title,
    user_details,
    summary,
    about,
    country,
    followers,
    languages,
    trial_prices,
    teacher_id,
    // reviews,
  } = data;

  const reviews = [
    {
      rating: "4",
      teacher_id: "2314",
      review:
        "Teacher prepare a lot of material and have patient.She is a very nice teacher.",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Nelson",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [
        {
          is_teacher: "0",
          reply: "Thanks a lot",
          date: "2022-10-29 00:00:00",
          replier: {
            display_name: "Waleed Umer",
            profile_picture: "",
          },
        },
      ],
    },
    {
      rating: "5",
      review:
        "［Demo class / 面試課程］老師非常有耐心，很適合教小朋友，擅長鼓勵，相信在老師的帶領下，小朋友們一定可以學好英文！",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Lisa lane",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review:
        "I study first time on this lesson. Teacher is very kind and teach me. He will come to understand my level before teaching.",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Waleed Umer",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "Very nice and polite teacher",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Jhon Evanofski",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "This is another comment",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "Michael John",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
    {
      rating: "5",
      review: "I really like the way this tutor teaches",
      date: "2022-10-29 00:00:00",
      reviewer: {
        display_name: "James michale",
        profile_picture: "1665556604_f48def5379be7ea5192d.jpeg",
      },
      replies: [],
    },
  ];

  const sorted_trial_prices = Array.isArray(trial_prices)
    ? trial_prices.sort((a, b) => {
        return a["duration"].toString().localeCompare(b["duration"].toString());
      })
    : [];
  return (
    <Paper style={{ margin: "15px 0", padding: 20 }} elevation={5}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="flex-start">
            <Grid
              item
              style={{ display: "flex", justifyContent: "center" }}
              xs={12}
              md={2}
            >
              <ProfilePicture
                teacherId={teacher_id}
                follow={true}
                data={data}
                country={country}
                teacher={user_details?.user_id}
                followers={followers}
                displayName={display_name}
                image={user_details?.profile_picture}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/teachers-and-tutors/${teacher_id}`}
              >
                <Typography variant="h6">{title}</Typography>
                <Typography className={classes.primaryText} variant="body2">
                  {sub_title}
                </Typography>
              </Link>
              <Rating props={{ rating: 4.5, reviews: 44 }} />
              <Languages languages={languages} />
              <Summary reviews={reviews} whole={false} summary={about} />
            </Grid>
            <Grid item xs={12} md={3} style={{ paddingLeft: 20 }}>
              <Grid container justifyContent="space-between">
                {sorted_trial_prices.map(
                  (trial, index) =>
                    index < 2 && (
                      <Grid item>
                        <Trials trial={trial} />
                      </Grid>
                    )
                )}
              </Grid>
              <TrialActions trials={trial_prices} teacher={teacher_id} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
