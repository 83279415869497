import React, { forwardRef, useState, useImperativeHandle, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Product from '../../services/products';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import SingleProduct from './singleProduct';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { UserContext } from '../../context/UserContext';
import { FavouriteContext } from '../../context/FavouriteContext';
import { useJwt } from 'react-jwt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AuthForm from '../auth/SingleSignIn';
import Checkout from '../checkout/checkout';
import Wallet from '../checkout/wallet';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productsWrapper: {
        padding: 8,
        paddingBottom: 30,
        marginBottom: 0,
        height: '90vh',
        overflowY: 'scroll'
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    titleName: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3,
        fontDecoration: 'none'
    },
    singleItem: {
        height: 320,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '90%',
        height: '100%',
        marginBottom: 20,
        marginLeft: '5%'
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    authContainer: {
        backgroundColor: '#061C20',
        height: '100vh'
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 15
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
});

export default forwardRef(function Favourites(props, ref) {
    let [list, setValue] = React.useState([]);
    let [count, setCount] = React.useState(0);
    const mounted = React.useRef()
    const [totalProducts, setTotal] = React.useState(0);
    const [checkoutOpen, setCheckoutOpen] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);
    const [buyProduct, setBuyProduct] = useState(null)
    const { favouriteItem } = React.useContext(FavouriteContext);
    const [favouriteItems, setFavouriteItems] = favouriteItem;
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const [page, setPage] = React.useState(1);
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    };

    let things = useContext(0)
    let products = [];
    const fetchData = React.useCallback(() => {
        products = []
        let user = localStorage.getItem('user_id');
        Product.getFavourites(user)
            .then((response) => {
                console.log('fav', response)
                setCount(response.data.count)
                response.data.forEach((product, index) => {
                    products.push(
                        <SingleProduct from={'none'} handleCheckoutOpen={handleCheckoutOpen} key={index} product={product} index={index} />
                    )
                })
                setValue(products)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            fetchData()
        }
    })

    const handleChange = (event, value) => {
        setPage(value);
        products = []

        let offset = value * 48
        if (value == 1)
            offset = 0

        Product.shop(offset)
            .then((response) => {
                console.log(response)
                setCount(response.data.count)
                setTotal(response.data.count)
                response.data.products.forEach((product, index) => {
                    products.push(
                        <SingleProduct from={'none'} handleCheckoutOpen={handleCheckoutOpen} key={index} product={product} index={index} />
                    )
                })
                setValue(products)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const searchShop = (title) => {
        setPage(1);
        products = []

        Product.searchProduct(title)
            .then((response) => {
                response.data.forEach((product, index) => {
                    products.push(
                        <SingleProduct from={'none'} handleCheckoutOpen={handleCheckoutOpen} key={index} product={product} index={index} />
                    )
                })
                setValue(products)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const filterShop = (levels, tags) => {
        setPage(1);
        products = []
        Product.filterProduct(levels, tags).then((response) => {
            response.data.products.forEach((product, index) => {
                products.push(
                    <SingleProduct from={'none'} handleCheckoutOpen={handleCheckoutOpen} product={product} key={index} index={index} />
                )
            })
            setValue(products)
        }).catch(error => {
            console.log(error)
        })
    };

    const renderProduct = (products) => {

    }

    const getCount = () => {
        return totalProducts;
    };

    useImperativeHandle(ref, () => ({
        handleChange,
        searchShop,
        getCount,
        filterShop
    }));

    const handleCheckoutClose = () => {
        setCheckoutOpen(false);
    };

    const handleCheckoutOpen = (props) => {
        setBuyProduct(props)
        setCheckoutOpen(true);
    };

    const handleConfirmation = () => {
        setCheckoutOpen(false)
        setConfirmDialog(true);
    };

    function ConfirmDialog() {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth={'xs'}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src="/assets/check.png" className={classes.checkIcon} />
                </div>
                <DialogTitle className={classes.textCenter} id="alert-dialog-slide-title">
                    <span>Payment Processed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textCenter} id="alert-dialog-slide-description">
                        Thank You for your purchase.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: 20 }}>
                    <Grid container layout="flex" justifyContent="center">
                        <Link className={classes.okBtn} color="primary" style={{ textDecoration: 'none' }} to={{
                            pathname: "/view/product/" + (buyProduct != null ? buyProduct.course_name : ''),
                            state: {
                                image: (buyProduct != null ? (buyProduct.course_cover != null ? process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + buyProduct.subscriber_id + '/' + buyProduct.course_cover : pics[4]) : ''),
                                id: buyProduct != null ? buyProduct.course_id : 0
                            }
                        }}>
                            View My Product
                        </Link>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    function CheckoutDialog() {
        var lineItems = [
            {
                label: buyProduct != null ? buyProduct.course_name : '',
                amount: buyProduct != null ? (parseFloat(buyProduct.course_tution_fee) * 100) : 0
            }
        ]
        return (
            <Dialog onBackdropClick={handleCheckoutClose} open={checkoutOpen} onClose={handleCheckoutClose} TransitionComponent={Transition}>
                <ButtonBase onClick={handleCheckoutClose} className={classes.closeIcon}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </ButtonBase>
                <Grid className={classes.authContainer} container direction="column" alignItems="center" justifyContent="center">
                    <img src="/assets/Logo.png" style={{ width: '25%', marginBottom: 30 }} className={classes.checkoutLog} />
                    <h3 style={{ color: 'white', marginTop: 0 }}>Buy Now</h3>
                    <Grid className={classes.authWrapper} item xs={12} sm={6}>
                        {!isExpired ?
                            <>
                                <Checkout courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} productId={buyProduct != null ? buyProduct.course_id : 0} confirmDialog={handleConfirmation} total={buyProduct != null ? (buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? buyProduct.discounted_price : buyProduct.course_tution_fee) : 0} />
                                <p style={{ textAlign: 'center', color: '#c1c1c1', marginTop: 10, marginBottom: 10, fontSize: 14 }}>Or pay with</p>
                                <Wallet courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} lineItems={lineItems} confirmDialog={handleConfirmation} total={buyProduct != null ? ((buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? parseFloat(buyProduct.discounted_price) : parseFloat(buyProduct.course_tution_fee)) * 100) : 0} />
                            </>
                            :
                            <AuthForm />
                        }

                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    return (
        <Grid item xs={12} sm={12}>
            <ConfirmDialog />
            <CheckoutDialog />
            <div className={classes.searchWrapper}>
                <Container className={classes.productsWrapper} maxWidth="lg">
                    <Grid>
                        <h2 style={{ marginTop: 0 }}>Favourite Products</h2>
                    </Grid>
                    {
                        list.length == 0 ?
                            <Grid container alignItems="center" justifyContent="center" style={{ height: '80%' }}>
                                <Grid item>
                                    <h4 style={{ textAlign: 'center', marginTop: 30, color: 'gray', width: '100%' }}>No Favourite Product Available</h4>
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                {list}
                            </Grid>
                    }
                </Container>
            </div>
        </Grid>
    )
})

