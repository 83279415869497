import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Management from './management';
import CourseManagement from './CourseManagement';
import IncomeManagement from './IncomeManagement';
import TopBar from '../components/admin/TopBar';

export default function AdminPage() {
    const adminUrl = '/admin'
    return (
        <>
            <TopBar adminUrl={adminUrl} />
            <Route path={adminUrl + "/management"}>
                <Management />
            </Route>
            <Route path={adminUrl + "/course-management"}>
                <CourseManagement />
            </Route>
            <Route path={adminUrl + "/income-management"}>
                <IncomeManagement />
            </Route>
        </>
    )
}
