import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@material-ui/core";
import { TextField, InputAdornment } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import "../../css/passCode.css";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
export default function ShareProduct(props) {
  console.log(props);
  const filterProduct = props?.item?.filter(
    (item) => item?.course_id == props?.productID
  )[0];
  console.log(filterProduct);
  const [watermarks, setWaterMarks] = useState([]);
  const linkCopied = () => toast.success("Link copied!");
  const bothCopied = () => toast.success("Link and access copied!");
  const codeCopied = () => toast.success("Access code copied!");
  const handleCopyCodeForWaterMark = (code) => {
    navigator.clipboard.writeText(code);
    codeCopied();
  };
  useEffect(() => {
    const availableWaterMark = filterProduct?.watermarks;
    if (availableWaterMark?.length > 0 && availableWaterMark !== "") {
      const pairs = availableWaterMark?.includes("|")
        ? availableWaterMark?.split("|")
        : [availableWaterMark];
      const result = pairs.map((pair, index) => {
        const [watermarkCode, value] = pair.split(",");
        return { id: Date.now() + index, watermarkCode, value };
      });
      setWaterMarks(result);
    }
  }, [filterProduct]);
  const qrRef = useRef();
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_FOLDER_URL +
        btoa(
          props.productID.split("").reverse().join("") + "," + props.productID
        )
    );
    // navigator.clipboard.writeText(process.env.REACT_APP_FOLDER_URL + props.url.replace(/ /g, '%20'))
    linkCopied();
  };
  const handleCopyBothForWaterMark = (code) => {
    let text =
      "Course URL: " +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "\nAccess code: " +
      code;
    navigator.clipboard.writeText(text);
    bothCopied();
  };
  const handleCopyBoth = () => {
    let text =
      "Course link: " +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      // let text = 'Course link: ' + process.env.REACT_APP_FOLDER_URL + props.url.replace(/ /g, '%20') +
      "\nAccess code: " +
      props.accessCode;
    navigator.clipboard.writeText(text);
    bothCopied();
  };
  const handleCopyCode = () => {
    navigator.clipboard.writeText(props.accessCode);
    codeCopied();
  };
  const handleShareWhatsappWaterMark = (code) => {
    let text =
      "URL:" +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "%0aAccess code:" +
      code;
    let url = "https://api.whatsapp.com/send?text=" + text;
    window.open(url, "_blank");
  };
  const handleShareWhatsapp = () => {
    let text =
      "URL:" +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "%0aAccess code:" +
      props.accessCode;
    let url = "https://api.whatsapp.com/send?text=" + text;
    window.open(url, "_blank");
  };

  const QRCodeBox = ({ id, ref }) => {
    let url =
      process.env.REACT_APP_FOLDER_URL +
      "qr/" +
      btoa(
        props.productID.split("").reverse().join("") +
          "," +
          props.productID +
          "," +
          props.accessCode
      );
    return (
      <QRCodeCanvas
        bgColor="transparent"
        id={id}
        ref={ref}
        size={200}
        includeMargin={false}
        value={url}
      />
    );
  };

  const handleDownload = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />
      <Mui.Dialog
        onBackdropClick={props.closeShare}
        maxWidth={"xs"}
        fullWidth={true}
        open={true}
      >
        <Mui.DialogTitle>Share this course</Mui.DialogTitle>
        <Mui.Icon onClick={props.closeShare} className="close-modal">
          close
        </Mui.Icon>
        <Mui.DialogContent>
          <Mui.Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ marginBottom: "5px" }}
          >
            {filterProduct?.showWaterMark !== "1" && (
              <>
                <Mui.Tooltip
                  title={<h2 style={{ margin: 5 }}>Share on whatsapp</h2>}
                  placement="top"
                >
                  <Mui.Grid item>
                    <Mui.Icon
                      onClick={handleShareWhatsapp}
                      className="whats-icons share-icons"
                    >
                      <WhatsAppIcon style={{ fontSize: 18 }} />
                    </Mui.Icon>
                  </Mui.Grid>
                </Mui.Tooltip>
                <Mui.Tooltip
                  title={<h2 style={{ margin: 5 }}>Copy URL</h2>}
                  placement="top"
                >
                  <Mui.Grid item>
                    <Mui.Icon
                      onClick={handleCopyLink}
                      className="link-icons share-icons"
                    >
                      link
                    </Mui.Icon>
                  </Mui.Grid>
                </Mui.Tooltip>
                <Mui.Tooltip
                  title={<h2 style={{ margin: 5 }}>Copy access code</h2>}
                  placement="top"
                >
                  <Mui.Grid item>
                    <Mui.Icon
                      onClick={handleCopyCode}
                      className="key-icons share-icons"
                    >
                      key
                    </Mui.Icon>
                  </Mui.Grid>
                </Mui.Tooltip>
                <Mui.Tooltip
                  title={
                    <h2 style={{ margin: 5 }}>Copy URL and access code</h2>
                  }
                  placement="top"
                >
                  <Mui.Grid item>
                    <Mui.Icon
                      onClick={handleCopyBoth}
                      className="copy-icons share-icons"
                    >
                      content_copy
                    </Mui.Icon>
                  </Mui.Grid>
                </Mui.Tooltip>
              </>
            )}
          </Mui.Grid>

          {filterProduct?.showWaterMark !== "1" && (
            <Mui.Grid
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
              style={{ padding: 10 }}
            >
              <Mui.Grid item>
                <QRCodeBox id={props.productID} ref={qrRef} />
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Button
                  style={{ backgroundColor: "#10936f", color: "white" }}
                  onClick={() => handleDownload(props.productID)}
                >
                  <Mui.Icon style={{ marginRight: 5 }}>download</Mui.Icon>{" "}
                  Download QR code
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          )}

          {filterProduct?.showWaterMark === "1" &&
            watermarks?.map((watermark, index) => (
              <TextField
                key={watermark.id}
                value={watermark.value}
                variant="outlined"
                placeholder="Add Watermark"
                name={`watermark_${index}`}
                style={{ width: 400, marginBottom: "10px" }}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mui.Tooltip
                        title={<h2 style={{ margin: 5 }}>Access Code</h2>}
                        placement="top"
                      >
                        <span
                          style={{
                            padding: "0 10px",
                            background: "#f0f0f0",
                            borderRadius: "4px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            pointerEvents: "none", // Disable click events
                          }}
                        >
                          {watermark.watermarkCode}{" "}
                          {/* Display the access code */}
                        </span>
                      </Mui.Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <>
                        <Mui.Tooltip
                          title={
                            <h2 style={{ margin: 5 }}>Share on whatsapp</h2>
                          }
                          placement="top"
                        >
                          <Mui.Icon
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <WhatsAppIcon
                              style={{
                                padding: "3px",
                              }}
                              onClick={() =>
                                handleShareWhatsappWaterMark(
                                  watermark.watermarkCode
                                )
                              }
                              className="WhatsAppIcon green-icon group-icon"
                            />
                          </Mui.Icon>
                        </Mui.Tooltip>

                        <Mui.Tooltip
                          title={<h2 style={{ margin: 5 }}>Copy course URL</h2>}
                          placement="top"
                        >
                          <Icon
                            onClick={handleCopyLink}
                            className="refresh-passcode-icon red-icon group-icon"
                          >
                            link
                          </Icon>
                        </Mui.Tooltip>
                        <Mui.Tooltip
                          title={
                            <h2 style={{ margin: 5 }}>Copy access code</h2>
                          }
                          placement="top"
                        >
                          <Icon
                            onClick={() =>
                              handleCopyCodeForWaterMark(
                                watermark?.watermarkCode
                              )
                            }
                            className="refresh-passcode-icon green-icon group-icon"
                          >
                            key
                          </Icon>
                        </Mui.Tooltip>
                        <Mui.Tooltip
                          title={
                            <h2 style={{ margin: 5 }}>
                              Copy access code and URL
                            </h2>
                          }
                          placement="top"
                        >
                          <Icon
                            onClick={() =>
                              handleCopyBothForWaterMark(
                                watermark?.watermarkCode
                              )
                            }
                            className="refresh-passcode-icon blue-icon group-icon"
                          >
                            content_copy
                          </Icon>
                        </Mui.Tooltip>
                      </>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { paddingLeft: "10px" }, // Add left padding here
                  },
                }}
              />
            ))}

          {filterProduct?.showWaterMark !== "1" && (
            <div className="url-input">
              {process.env.REACT_APP_FOLDER_URL +
                btoa(
                  props.productID.split("").reverse().join("") +
                    "," +
                    props.productID
                )}
            </div>
          )}
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
}
