import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import './widget.css';
import ReactPlayer from 'react-player';


function Item(props) {
    const ref = React.createRef()
    const [volume, setVolume] = React.useState(0)

    const handleVolumeChange = (volume) => {
        setVolume(volume)
    }

    return (
        <>
            <Hidden smUp>
                {props.item.type == 'video/mp4' ?
                    <div style={{ width: '100%' }} className="slider-item slider-item-mobile video-wrapper" >
                        <Grid container>
                            <ReactPlayer
                                ref={ref}
                                playing={true}
                                playsinline={true}
                                // controls={true}
                                loop={true}
                                muted={volume == 0 ? true : false}
                                className='react-player'
                                url={`${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file}`}
                                width='100%'
                                volume={volume}
                                height='auto'
                            />
                            {
                                volume == 1 ?
                                    <Icon onClick={() => handleVolumeChange(0)} className="volumne-btn">volume_up</Icon>
                                    :
                                    <Icon onClick={() => handleVolumeChange(1)} className="volumne-btn">volume_off</Icon>
                            }
                        </Grid>
                    </div>
                    :
                    <Paper className="slider-item slider-item-mobile" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file})` }}>
                        {/* <h2 style={{ color: `${props.item.color}` }} className={props.item.heading_position + ' ' + props.item.backdrop ? 'backdrop' : 'no-backdrop'}>{props.item.name}</h2> */}
                    </Paper>
                }
            </Hidden>
            <Hidden xsDown lgUp>
                {
                    props.item.type == 'video/mp4' ?
                        <Paper className="slider-item slider-item-ipad video-wrapper" >
                            <ReactPlayer
                                playing={true}
                                controls={false}
                                loop={true}
                                className='slider-player'
                                url={`${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file}`}
                                width='100%'
                                volume={volume / 100}
                                height='auto'
                            />
                            {
                                volume == 100 ?
                                    <Icon onClick={() => handleVolumeChange(0)} className="volumne-btn">volume_up</Icon>
                                    :
                                    <Icon onClick={() => handleVolumeChange(100)} className="volumne-btn">volume_off</Icon>
                            }
                        </Paper>
                        :
                        <Paper className="slider-item slider-item-ipad" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file})` }}>
                            {/* <h2 style={{ color: `${props.item.color}` }} className={props.item.heading_position + ' ' + props.item.backdrop ? 'backdrop' : 'no-backdrop'}>{props.item.name}</h2> */}
                        </Paper>
                }
            </Hidden>
            <Hidden mdDown>
                {
                    props.item.type == 'video/mp4' ?
                        <Paper className="slider-item slider-item-laptop video-wrapper" >
                            <ReactPlayer
                                playing={true}
                                controls={false}
                                loop={true}
                                className='slider-player'
                                url={`${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file}`}
                                width='100%'
                                volume={volume / 100}
                                height='auto'
                            />
                            {
                                volume == 100 ?
                                    <Icon onClick={() => handleVolumeChange(0)} className="volumne-btn">volume_up</Icon>
                                    :
                                    <Icon onClick={() => handleVolumeChange(100)} className="volumne-btn">volume_off</Icon>
                            }
                        </Paper>
                        :
                        <Paper className="slider-item slider-item-laptop" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${props.item.file})` }}>
                            {/* <h2 style={{ color: `${props.item.color}` }} className={props.item.heading_position + ' ' + props.item.backdrop ? 'backdrop' : 'no-backdrop'}>{props.item.name}</h2> */}
                        </Paper>
                }
            </Hidden>
        </>
    )
}
export default function HeaderCarousel({ carousel }) {
    let groupedCarousel = [];
    let singleGroup = [];
    const [carouselGroup, setCarouselGroup] = React.useState([])
    React.useEffect(() => {
        carousel.map((item, index) => {
            if (index % 3 == 0 && index != 0) {
                singleGroup = [];
                singleGroup.push(item)
            }
            else {
                singleGroup.push(item)
                if (singleGroup.length == 3) {
                    groupedCarousel.push(singleGroup)
                }
            }
        })
        setCarouselGroup(groupedCarousel)
    }, [])

    return (
        <Carousel indicators={false} animation="slide" autoPlay={false} navButtonsAlwaysVisible={true}>
            {
                carousel.map((item, i) => {
                    return (
                        <Item key={i} item={item} />
                    )
                })
            }
        </Carousel>
    )
}
