import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

axios.interceptors.request.use(
  request => {
    if (request.url.includes('user/authenticate') || request.url.includes('addProduct')) {
      request.headers['authorization'] = localStorage.getItem('jwt_token')
      request.headers['USER'] = localStorage.getItem('user_id')
      request.headers['AUTH'] = localStorage.getItem('auth_id')
      // console.log(request)
    }
    return request;
  },
  error => {
    return Promise.reject(error)
  }
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
