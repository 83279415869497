import { Box, Button, Card, Typography, Icon } from "@mui/material";
import React, { useEffect } from "react";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import TeacherService from "../../../services/teacher";
import { useState } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { red } from "@material-ui/core/colors";
import Colors from "./colors.json";
import {IoMdAddCircleOutline} from "react-icons/io"
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const SingleSlot = ({
  slot,
  index,
  title,
  lesson,
  color,
  lessonIndex,
  handleRemoveSlot,
  editActutalSlot,
  date,
  handleEditSlot,
}) => {
  const [newTime, setNewTime] = useState(slot.trial_time);
  const [edit, setEdit] = useState(false);
  const format = "HH:mm";
  const handleNewTimeChange = (time) => {
    const hour = time.hour() < 10 ? "0" + time.hour() : time.hour();
    const minutes = time.minute() < 10 ? "0" + time.minute() : time.minute();

    setNewTime(`${hour}:${minutes}`);
    // alert(dayjs(new Date()));
  };
  let dates = dayjs(date).get("date");
  let month = months[dayjs(date).get("month")];
  let year = dayjs(date).get("year");
  // console.log(lessonIndex,8%2)
  // const newdate = `${dayjs(date).get("date")} ${months[dayjs(date).get("month")]} ${dayjs(date).get("year")}`
  return (
    <Box
      key={{ index, lessonIndex }}
      sx={{ display: "flex", flexWrap: "wrap" }}
      style={{
        // padding: 7,
        // border: "1px solid gray",
        // borderRadius: 5,
        marginRight: 5,
        // marginBottom: 5,
        position: "relative",
      }}
      // onClick={handleEditSlot}
    >
      <Box
        sx={{
          backgroundColor: color,
          color: "whitesmoke",
          width: "50px",
        }}
      >

         <span
              style={{
                cursor: "pointer",
              }}
              onClick={handleEditSlot}
            >
              {/* <EditIcon style={{ fontSize: "18px", color: "whitesmoke" }} /> */}
              <IoMdAddCircleOutline/>
            </span>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "1",
          }}
        >
          {dates}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: "bold",
            lineHeight: "0.5",
          }}
        >
          {month}
        </Typography>
        <Typography
          sx={{ display: "flex", justifyContent: "center", lineHeight: "1.2" }}
        >
          {year}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginLeft: "10px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex" }}>
            <AccessTimeIcon
              style={{ fontSize: 16, marginLeft: "10px", marginTop: "4px" }}
            />
            {edit ? (
              <TimePicker
                format={format}
                minuteStep={15}
                defaultValue={dayjs(slot.trial_time, format)}
                size="small"
                style={{ width: "80px", marginLeft: 5 }}
                onChange={(time) => handleNewTimeChange(time)}
              />
            ) : (
              <span style={{ marginLeft: "2px" }}>{slot.trial_time}</span>
            )}
          </Box>
          <Box
            sx={{ marginLeft: "10px" }}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GroupOutlinedIcon color={"gray"} />
            {slot.seats}
          </Box>
        </Box>
      </Box>
      <span
        style={{
          position: "absolute",
          top: 2,
          right: 2,
          cursor: "pointer",
        }}
        onClick={() =>
          handleRemoveSlot(
            lessonIndex,
            index,
            slot.trial_date,
            slot.slot_id,
            "trial"
          )
        }
      >
        <HighlightOffIcon style={{ fontSize: "18px", color: "red" }} />
      </span>

      {!edit ? (
        <span
          style={{
            position: "absolute",
            bottom: 0,
            right: 2,
            cursor: "pointer",
          }}
          onClick={() => setEdit(true)}
        >
          <EditIcon style={{ fontSize: "18px", color: "gray" }} />
        </span>
      ) : (
        <span
          style={{
            position: "absolute",
            top: 25,
            right: 2,
            cursor: "pointer",
          }}
          onClick={() => {
            editActutalSlot(
              lessonIndex,
              index,
              slot.trial_date,
              slot.slot_id,
              newTime
            );
            setEdit(false);
          }}
        >
          <SaveIcon style={{ fontSize: "18px", color: "green" }} />
        </span>
      )}
    </Box>
  );
};

const ListOfTeachersTrials = ({
  user,
  lessons,
  lessonIndex,
  handleRemoveSlot,
  handleRemoveLesson,
  editActutalSlot,
  deleteTimeSlot,
  editTimeSlot,
  randomColor,
  handleEditSlot,
}) => {
  // console.log("lesson", lessons);
  const { slots } = lessons;

  // const sortedSlots = slots.sort((a, b) => {
  //   return a.trial_time.localeCompare(b.trial_time);
  // });
  // console.log("sort", sortedSlots);
  return (
    <>
      {lessons?.slots.map((slot, lessonIndex) => {
        return (
          <Card sx={{ mt: "10px", width: "32.1%" }}>
            <SingleSlot
              key={lessonIndex}
              slot={slot}
              lessonIndex={lessonIndex}
              lessons={lessons}
              date={slot.trial_date}
              color={randomColor}
              title={lessons.duration}
              index={lessonIndex}
              editActutalSlot={editActutalSlot}
              handleRemoveSlot={handleRemoveSlot}
              handleEditSlot={() => handleEditSlot(lessons)}
            />
          </Card>
        );
      })}
    </>
  );
};

export default ListOfTeachersTrials;
