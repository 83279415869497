import React from 'react'
import TeacherRegForm from '../components/auth/TeacherReg';
import './css/becomeTeacher.css';

export default function becomeTeacher() {
    return (
        <div className="content-wrapper">
            <TeacherRegForm />
        </div>
    )
}
