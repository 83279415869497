import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core'
import {Typography} from '@material-ui/core'
import {SingleComment} from './Comments'

const useStyle = makeStyles((theme) =>({
  less:{
    height:62,
    overflow:'hidden'
  },
  more:{
    height:'auto',
  },
  readMoreBtn:{
    marginTop:10,
    color:'#00C2E2',
    cursor:'pointer',
  }
}))

export default function Summary({summary,whole,reviews}) {
    const [readMore,setReadMore] = useState(false)
    const classes = useStyle();
    const dummySummary = "🧾CAMBRIDGE (CELTA) CERTIFIED 👨‍🎓UNIVERSITY DEGREE IN LANGUAGES🎤NATIVE ENGLISH+NEUTRAL ACCENT ⚡INTERNATIONAL TRAINING & EXPERIENCE 🏆FROM TOP LANGUAGE SCHOOLS:5+ YEARS 🌎I'VE TAUGHT MANY FRENCH- SPANISH- PORTUGUESE- AND CHINESE-SPEAKING STUDENTS 🎤I SPEAK 5 LANGUAGES 😍PATIENT & ENCOURAGING🌈PUBLISHED AUTHOR: CHILDREN'S WELLBEING 🌳💚🍏 🔥 New AT teacher 70% OFF for a limited time only!🔥 Native English speaker 😎 CELTA certified🎯Specializes in teens & adults🐣Beginner- Advanced levels🤴Fluency master🦜Confidence booster✍️IELTS expert🚀Get results fast and improve your oral pronunciation😆 Come say hi!Join my class and let's have fun learning English together!"

  return (
    <div>
      {
        whole ? 
        <div className={classes.more}>
          <Typography variant="body">
            <div dangerouslySetInnerHTML={{
                __html: summary
            }}></div>
          </Typography>
        </div>
        :
        <div className={readMore ? classes.more : classes.less}>
          <Typography variant="body">{summary}</Typography>
          {
            reviews.length > 0 &&
            <>
            <Typography style={{marginTop:10,marginBottom:10,fontSize:18,fontWeight:700}}>Most helpful</Typography>
            <SingleComment review={reviews[0]} />
            </>
          }
          
        </div>
      }
      {
        !whole &&
      <Typography className={classes.readMoreBtn} onClick={() => setReadMore(!readMore)} variant="body2">{readMore ? 'Read less' : 'Read more'}</Typography>
      }
    </div>
  )
}
