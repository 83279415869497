import React, {useEffect,useState} from 'react'
import {Grid,Container,Box,Paper, Typography,Chip,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import {Modal} from 'antd'
import Fab from '@material-ui/core/Fab';
import SingleTeacher from '../components/teacher/List/Single'
import TeacherServices from '../services/teacher'
import Filters from '../components/teacher/List/Filters/Filters'
import { bounceInRight,bounce } from 'react-animations';
import { makeStyles } from '@material-ui/core/styles';
import styled, { keyframes } from "styled-components";
import Checkout from '../components/checkout/checkout';
import BookingModal from '../components/teacher/List/BookingModal';
import { Pagination,Alert } from '@material-ui/lab';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import { BookingContext } from '../context/BookingContext';
import {Link} from 'react-router-dom'
import SlotsDialog from '../components/teacher/List/SlotsDialog';

const useStyles = makeStyles((theme) => ({
  flotBtn: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    cursor:'pointer'
  },
}));

// const SlotsDialog = ({selectedSlots,openTimeSlot,setOpenTimeSlot,setOpenModal,bookedTrialsObject,selectTimeSlot}) => {
//   return(
//     <Modal
//       title="Select time slots"
//       centered
//       open={openTimeSlot}
//       onOk={() => {setOpenTimeSlot(false);setOpenModal(true)}}
//       onCancel={() => setOpenTimeSlot(false)}
//       width={800}
//     >
//       {
//         bookedTrialsObject.map(trial => (
//           <div>
//           <div style={{display:'flex',alignItems:'center',marginBottom:10}}>
//               <div style={{fontSize:20,fontWeight:500}}>{trial.duration+" "} </div>
//               <div style={{fontSize:20,fontWeight:500,marginLeft:10}}>{trial.price}<small> HKD</small></div>
//           </div>
//           {
//             trial.slots.map(slot => (
//             <div onClick={() => selectTimeSlot(slot.slot_id,slot.trial_time,slot.trial_date,trial.price_id)} 
//             style={{
//               cursor:'pointer',width:'33%',flexWrap:'wrap',display:'flex',alignItems:'center',justifyContent:'space-between',padding:8,borderRadius:5,marginBottom:10,
//               border:selectedSlots[trial.price_id] == slot.slot_id ? 'solid 2px green' : 'solid 1px #cdcdcd', backgroundColor:selectedSlots[trial.price_id] == slot.slot_id ? '#f7fef7' : 'white'
//               }}>
//                 <div style={{display:'flex',alignItems:'center'}}><DateRangeOutlinedIcon style={{marginRight:5,fontSize:18}}/> {slot.trial_date}</div>
//                 <div style={{display:'flex',alignItems:'center'}}><QueryBuilderOutlinedIcon style={{marginRight:5,fontSize:18}}/>{slot.trial_time}</div>
//             </div>
//             ))
//           }
//           </div>
//         ))
//       }
//     </Modal>
//   )
// }

export default function Teachers() {
  const classes = useStyles()
  const [teachers,setTeachers] = useState([])
  const [teachersCount,setTeachersCount] = useState(1)
  const [page,setPage] = useState(1)
  const [openModal,setOpenModal] = useState(false)
  const [showSuccess,setShowSuccess] = useState(false)
  const [openTimeSlot,setOpenTimeSlot] = useState(false)
  const [selectedSlots,setSelectedSlots] = useState({})
  const limit = 10
  const {bookedTrials,bookedTrialsObject,setBookedTrialsObject} = React.useContext(BookingContext)
  const BounceAnimation = keyframes`${bounce}`;
  const BounceDiv = styled.div`
    animation: 1s ${BounceAnimation};
  `;

  useEffect(() => {
    fetchTeachers()
  },[page])

  const fetchTeachers = () => {
    TeacherServices.getAllTeachers('active',limit,(parseInt(page)-1)*limit).then(res => {
      setTeachers(res.data.teachers)
      setTeachersCount(res.data.total < 10 ? 1 : Math.floor(res.data.total/10))
    }).catch(err => {
      console.log(err)
    })
  }

  const handleFilter = (result,total) => {
    setTeachers(result)
    setTeachersCount(total < 10 ? 1 : Math.floor(total/10))
  }

  const confirmBooking = () => {
    // setOpenModal(true) 
    setOpenTimeSlot(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handlePageChange = (event,value) => {
    setPage(value)
  }

  const handleSuccessShow = ()  => {
    setShowSuccess(true)
  }
  const handleSuccessClose = ()  => {
    setShowSuccess(false)
  }

  const ConfirmationDialog = () => {
    return(
      <Dialog
        maxWidth={'xs'}
        open={showSuccess}
        onClose={handleSuccessClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText align="center">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{textAlign:'center',width:60,height:60,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:60,backgroundColor:'#20B774',border:'solid 2px white',marginBottom:10}}>
              <DoneOutlineRoundedIcon style={{color:'white'}} />
            </div>
            </div>
            <h3 style={{textAlign:'center',color:'#20B774'}}>Your booking is confirmed</h3>
            <p style={{textAlign:'center',color:'black'}}>
              You have successfully booked a trial session.<br/>
              Click <Link to="/my/bookings">here</Link> to check the lesson details.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{display:'flex',justifyContent:'center',width:'100%',marginBottom:15}}>
            <Button style={{width:'80%',backgroundColor:'#20B774',color:'white'}} onClick={handleSuccessClose} color="success" autoFocus>
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  const selectTimeSlot = (slotId,slotTime,slotDate,trialId) => {
    selectedSlots[trialId]=slotId
    setSelectedSlots(selectedSlots)
    let index = bookedTrialsObject.findIndex(trial => trial.price_id == trialId)
    let booked = [...bookedTrialsObject]
    booked[index]['trial_time'] = slotTime
    booked[index]['trial_date'] = slotDate
    booked[index]['slot_id'] = slotId
    setBookedTrialsObject(booked)
  }

  return (
    <div>
        <ConfirmationDialog />
        <SlotsDialog selectedSlots={selectedSlots} bookedTrialsObject={bookedTrialsObject} setOpenModal={setOpenModal} selectTimeSlot={selectTimeSlot} setOpenTimeSlot={setOpenTimeSlot} openTimeSlot={openTimeSlot}/>
        <Paper style={{borderRadius:0,backgroundSize:'cover',height:300,width:'100%',backgroundImage:'url(assets/teach.png)'}} elevation={0} />
        <Container maxWidth="xl" style={{padding:40}}>
          <Grid container>
            <Filters resetFilter={fetchTeachers} handleFilter={handleFilter}/>
            <Grid item xs={12} md={9}>
              {
                teachers != null ?
                teachers.map(teacher => (
                  <SingleTeacher data={teacher}/>
                ))
                :
                <Typography variant="h3" align="center">No record found</Typography>
              }
            </Grid>
            <Grid item xs={12} md={9}>
              <div style={{display:'flex',justifyContent:'center',marginTop:20}}>
              <Pagination page={page} count={teachersCount} color="primary" size="large" onChange={handlePageChange} />
              </div>
            </Grid>
          </Grid>
        </Container>
        {
          bookedTrials.length > 0 &&
          <Box className={classes.flotBtn}>
            <Fab onClick={confirmBooking} variant="extended" color="primary" aria-label="add">
              <Chip style={{marginRight:7}} size="small" label={bookedTrials.length} /> Complete your booking
            </Fab>
          </Box>
        }
        <BookingModal onSuccess={handleSuccessShow} openModal={openModal} handleClose={handleCloseModal}/>
    </div>
  )
}
