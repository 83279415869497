import React from "react";
// New Components
import CourseBanner from "../components/products/single/Course-Banner/CourseBanner";
import PurchasedMaterials from "../components/products/single/PurchasedMaterials/PurchasedMaterials";
// import { PDFtoIMG } from 'react-pdf-to-image';
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Product from "../services/products";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Image from "material-ui-image";
import DraftsIcon from "@material-ui/icons/Drafts";
import Icon from "@material-ui/core/Icon";
import ReactPlayer from "react-player";
import SubscriptionService from "../services/subscription";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import { ButtonBase } from "@material-ui/core";
import HtmlToReactParser from "html-to-react";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import RelatedProduct from "../components/products/related";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import { Document, Page } from 'react-pdf';
import Slider from "@material-ui/core/Slider";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { useJwt } from "react-jwt";
import { useClassesAvailable } from "../hooks";
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import printJS from "print-js";
import "./css/search.css";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import SearchResult from "../components/products/search";
import Comments from "../components/products/comments";
import { pdfjs } from "react-pdf";
import "./css/printPopUp.css";
import { browserName, browserVersion } from "react-device-detect";

// const PDFJS = require("pdfjs-dist/webpack");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

// const serverUrl = 'http://localhost/elearn/edhub/';
const serverUrl = process.env.REACT_APP_UPLOADS_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  aboutContainer: {
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 15,
      marginBottom: 15,
    },
  },
  searchWrapper: {
    padding: 30,
    paddingBottom: 0,
    backgroundColor: "#062c32",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  navButtons: {
    margin: theme.spacing(1),
  },
  watermarkContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  watermarkRow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    padding: "20px",
  },

  watermark: {
    fontSize: "50px",
    color: "rgba(0, 0, 0, 0.1)",
    transform: "rotate(-45deg)",
    margin: "30px",
    textAlign: "center",
    whiteSpace: "nowrap",
  },

  resultHeader: {
    borderColor: "black",
    border: 1,
    m: 1,
  },
  materialTitle: {
    color: "white",
  },
  imageWrapper: {
    width: "100%",
    opacity: 0.7,
  },
  materialsWrapper: {
    paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 20,
    },
  },
  materialsActions: {
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageHolder: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    backgroundColor: "black",
    position: "relative",
    minHeight: "90%",
  },
  heading: {
    color: "white",
    marginBottom: 0,
    backgroundColor: "#041c20",
    padding: 10,
    fontSize: 20,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      backgroundColor: "#041c20",
    },
  },
  playIcon: {
    color: "white",
    fontSize: 29,
    marginRight: 10,
  },
  lockIcon: {
    color: "#727272",
    fontSize: 16,
    marginLeft: 9,
  },
  materialHeading: {
    marginBottom: 10,
    paddingLeft: 0,
    color: "white",
    fontSize: 18,
  },
  item: {
    marginBottom: 0,
    padding: 5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#052a30",
    paddingRight: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selecteditem: {
    marginBottom: 0,
    padding: 5,
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#052a30",
    paddingRight: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0e5f6b",
  },
  materialsInner: {
    padding: 0,
    height: "100%",
  },
  imageRipple: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  buttonIconText: {
    marginRight: 5,
  },

  buttonIcon: {
    width: 60,
    minWidth: 10,
  },
  aboutWrapper: {
    paddingRight: 20,
  },
  description: {
    paddingTop: 15,
  },
  stats: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
  },
  stasWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tagWrapper: {
    display: "flex",
    alignItems: "center",
  },
  levelTag: {
    backgroundColor: "#bebebe",
    padding: 6,
    marginRight: 4,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    fontSize: 12,
    marginBottom: 4,
  },
  levelsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    flex: "50% 0",
  },
  statIcon1: {
    fontSize: 45,
    marginBottom: 10,
    color: "#00c0e2",
  },
  statIcon2: {
    fontSize: 45,
    marginBottom: 10,
    color: "#fa9c24",
  },
  statIcon3: {
    fontSize: 45,
    marginBottom: 10,
    color: "#3f51b5",
  },
  productTagHeading: {
    marginTop: 20,
    marginBottom: 10,
  },
  tag: {
    marginRight: 5,
    marginBottom: 5,
  },
  relatedProduct: {
    marginLeft: 10,
    marginTop: 30,
    marginBottom: 20,
  },
  speedIcon: {
    color: "white",
    fontSize: 20,
  },
  videoControls: {
    position: "absolute",
    backgroundColor: "#282828e0",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: 5,
    zIndex: 10,
  },
  seekBar: {
    width: "96%",
    height: 10,
    padding: 0,
    marginLeft: "2%",
  },
  leftControls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
  },
  iconControls: {
    color: "white",
  },
  volumeControl: {
    display: "flex",
    alignItems: "center",
  },
  volumeSlider: {
    flex: 1,
    marginLeft: 5,
  },
  playBtn: {
    color: "white",
  },
  playerControl: {
    marginRight: 10,
  },
  rightControls: {
    paddingRight: 20,
  },
  playDuration: {
    fontSize: 14,
    color: "white",
    marginLeft: 10,
  },
  tabWrapper: {
    height: "100%",
    padding: 0,
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  swipeableWrapper: {
    padding: 0,
    height: "100%",
    backgroundColor: "#083b43",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  navButtons: {
    backgroundColor: "white",
    padding: 2,
    height: 25,
    fontSize: 14,
    marginRight: 5,
    width: 30,
    marginLeft: 5,
    borderRadius: 5,
  },
  downloadIcon: {
    backgroundColor: "#fa9c24",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    height: 25,
    width: 25,
    color: "white",
  },
  printIcon: {
    backgroundColor: "#1a95ac",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    height: 25,
    width: 25,
    marginLeft: 5,
    color: "white",
  },
  playName: {
    display: "flex",
  },
  centreInfoWrapper: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  tabWrapper: {
    height: "100%",
    padding: 0,
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  swipeableWrapper: {
    padding: 0,
    height: "90%",
    backgroundColor: "#083b43",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  centreInfoWrapper: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 30,
    fontSize: 25,
    backgroundColor: "white",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  centreName: {
    fontSize: 20,
    color: "white",
  },
  subscriberFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subscribeBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    "&:hover": {
      backgroundColor: "#c3740f",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 45,
      width: 45,
    },
  },
  unSubscribeBtn: {
    backgroundColor: "#dadada",
    color: "black",
    "&:hover": {
      backgroundColor: "#dadada",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 45,
      width: 45,
    },
  },
  bellIcon: {
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  authContainer: {
    backgroundColor: "#061C20",
    height: "100vh",
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "white",
    right: 10,
    top: 10,
  },
  courseTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authWrapper: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
  },
  checkIcon: {
    width: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  pdfPreviewPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  pdfLow: {
    color: "none",
  },
  pdfHigh: {
    color: "none",
  },
  commenterName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  singleComment: {
    backgroundColor: "white",
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: "2px 2px 6px #9e9e9e1a",
  },
  commentDate: {
    fontSize: 10,
    color: "gray",
  },
  comment: {
    color: "gray",
  },
  commentsContainer: {
    backgroundColor: "#f0faff",
    borderRadius: 10,
    marginTop: 20,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  removeBtn: {
    backgroundColor: "#d55555",
    color: "white",
    borderRadius: 5,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#ad4242",
    },
  },

  addBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    borderRadius: 12,
    fontSize: 8,
    padding: 6,
    height: 35,
    "&:hover": {
      backgroundColor: "#a35e05",
    },
  },
  coursePrice: {
    fontSize: 19,
    marginRight: 10,
    color: "white",
  },
  discountedPrice: {
    textDecoration: "line-through",
    color: "gray",
    fontSize: 15,
    marginRight: 5,
  },
  buyBtn: {
    backgroundColor: "#f16020",
    color: "white",
    borderRadius: 12,
    fontSize: 14,
    padding: 6,
    paddingRight: 15,
    paddingLeft: 15,
    height: 35,
    "&:hover": {
      backgroundColor: "#cc501a",
    },
  },
  creditsWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "left",
  },
  credits: {
    color: "white",
    textAlign: "left",
  },
  tab: {
    fontSize: 12,
    width: "50%",
    minWidth: "100px",
    padding: 0,
  },
  pdfLoader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#b0023a",
    height: 8,
  },
  thumb: {
    height: 13,
    width: 13,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    color: "#fff",
    height: 0,
  },
  thumb: {
    backgroundColor: "#fff",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);

export default function ProductView() {
  const location = useLocation();
  const [product, setProduct] = React.useState([]);
  const [productLevels, setProductLevels] = React.useState([]);
  const [fetched, setFetched] = React.useState(false);
  const [playingVideo, playVideo] = React.useState(false);
  const [videoUrl, setVideo] = React.useState(null);
  const [playAgain, setPlayAgain] = React.useState(false);
  const [pdfUrl, setPdf] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [seekTime, setSeekTime] = React.useState(0);
  const [playDuration, setDuration] = React.useState(0);
  const [volume, setVolume] = React.useState(30);
  const [seek, setSeek] = React.useState(0);
  const [customControls, setCustomControls] = React.useState(false);
  const [pdfWidth, setPdfWidth] = React.useState(0);
  const [pdfPrintWidth, setPdfPrintWidth] = React.useState(0);
  const [printPDF, setPrintPDF] = React.useState(false);
  const [printPages, setPrintPages] = React.useState("");
  // const [printPages, setPrintPages] = React.useState(0);
  const { courseId, title } = useParams();
  const classesAvailable = useClassesAvailable(courseId);
  const [view, setView] = React.useState(null);
  const [controlsPosition, setControls] = React.useState(0);
  const [printLoading, setPrintLoading] = React.useState(true);
  // const { image } = location.state
  let url = window.location.pathname;
  const { id } = url.substring(url.lastIndexOf("/") + 1);
  // const { id } = location.state
  const [playingMaterial, setPlayingMaterial] = React.useState(null);
  const elementRef = React.useRef(null);

  const [pdfImages, setPdfImages] = React.useState(null);

  const elementPrintRef = React.useRef(null);
  let tags = null;
  let loadRelated = false;
  let player = null;
  const classes = useStyles();
  let { term } = useParams();
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [totalSeek, setTotalSeek] = React.useState(0);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [subscriptions, setSubscription] = React.useState([2, 3]);
  const [watermarkUser, setWatermarkUser] = React.useState("");
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const mounted = React.useRef();

  const [openPrint, setOpenPrint] = React.useState(false);

  const handlePrintOpen = () => {
    setOpenPrint(true);
  };

  const handlePrintClose = () => {
    setOpenPrint(false);
    setPrintPDF(false);
    setPdfImages(null);
  };

  const getSubscriptions = () => {
    SubscriptionService.getSubscription(localStorage.getItem("user_id")).then(
      (res) => {
        res.data.map((subscription) => {
          setSubscription((prevSubscriptions) => [
            ...prevSubscriptions,
            subscription.subscribe_to,
          ]);
        });
      }
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const readFileData = (file) => {
    console.log(file);
    fetch(encodeURI(file))
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        console.log(blob);
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.onerror = (err) => {
            reject(err);
          };
          reader.readAsDataURL(blob);
        });
      });
  };

  const convertPdfToImages = (file) => {
    const images = [];
    setPrintPDF(true);
    setOpenPrint(true);
    if (pdfImages == null) {
      const prom = window.getPrintImage(encodeURI(file));
      prom.then((res) => {
        setPdfImages(res);
        console.log(res);
      });
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
    // convertPdfToImages(pdfUrl)
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const fetchData = React.useCallback(() => {
    // console.log(id)
    let url = window.location.pathname;
    const courseid = url.substring(url.lastIndexOf("/") + 1);
    Product.getProduct(courseid, false)
      .then((response) => {
        console.log(response);
        if (response.data[0].subscriber_id == 0) {
          response.data[0].subscriber_id = response.data[0].course_teacher;
          response.data[0].centre_name = response.data[0].display_name;
          response.data[0].subscriber_type = "teacher";
          if (response.data[0].auth_using == "social") {
            response.data[0].avatar = response.data[0].profile_picture;
          } else {
            response.data[0].avatar =
              process.env.REACT_APP_API_URL +
              process.env.REACT_APP_TEACHER_DP_PATH +
              response.data[0].profile_picture;
          }
        } else {
          response.data[0].subscriber_type = "centre";
          response.data[0].avatar =
            process.env.REACT_APP_UPLOADS_URL +
            "admin/subscriber/" +
            response.data[0].avatar;
          console.log("Product Details", response.data[0]);
        }
        const watermarkFromResponse = response?.data[0]?.watermarks;
        const waterAccessbyUser = localStorage?.getItem("accessCode");
        if (
          watermarkFromResponse?.length > 0 &&
          watermarkFromResponse?.watermarks !== ""
        ) {
          const pairs = watermarkFromResponse?.includes("|")
            ? watermarkFromResponse?.split("|")
            : [watermarkFromResponse];
          const result = pairs.map((pair, index) => {
            const [watermarkCode, value] = pair.split(",");
            return { watermarkCode, value };
          });
          const isWaterMarkAvailable = result?.filter(
            (w) => w.watermarkCode == waterAccessbyUser
          )[0]?.value;
          console.log(isWaterMarkAvailable);
          setWatermarkUser(isWaterMarkAvailable);
        }
        setProduct(response.data[0]);
        let levels = response.data[0].course_level.replace(/","/g, ",");
        levels = levels.replace('["', "").replace('"]', "");
        levels = levels.split(",");
        setProductLevels(levels);
        setFetched(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    Product.myIpAddress().then((data) => {
      data = data.data
        .trim()
        .split("\n")
        .reduce(function (obj, pair) {
          pair = pair.split("=");
          return (obj[pair[0]] = pair[1]), obj;
        }, {});
      let url = window.location.pathname;
      const courseid = url.substring(url.lastIndexOf("/") + 1);
      Product.storeIPAddress(courseid, data.ip).then((res) => {
        // console.log(res)
      });
    });
    // Product.
    if (!mounted.current) {
      mounted.current = true;
      fetchData();
      getSubscriptions();
      loadRelated = true;
    } else {
    }
    setPdfWidth(elementRef.current ? elementRef.current.offsetWidth : 0);
  }, [subscriptions, view]);

  const ref = React.createRef();

  const relatedProducts = (category) => {
    return <RelatedProduct category={category} />;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (rate) => {
    setAnchorEl(null);
    setPlaybackRate(rate);
  };

  const seekTo = (time) => {
    ref.current.seekTo(time);
  };

  const handleDuration = (duration) => {
    setTotalSeek(duration);
    var time = new Date(duration * 1000).toISOString().substr(11, 8);
    setDuration(time);
  };

  const handleProgress = (state) => {
    setSeek(state.playedSeconds);
    var time = new Date(state.playedSeconds * 1000).toISOString().substr(11, 8);
    setSeekTime(time);
    if (time == playDuration) {
      // setSeek(0)
      playVideo(false);
      setPlayAgain(true);
    }
  };

  const handleVolumeChange = (event, newValue) => {
    // console.log(newValue)
    setVolume(newValue);
  };

  const handleSeekChange = (event, newValue) => {
    ref.current.seekTo(newValue);
  };

  const handlePlayPauseVideo = () => {
    playVideo(playingVideo ? false : true);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const firstPage = () => {
    setPageNumber(1);
  };
  const lastPage = () => {
    setPageNumber(numPages);
  };

  const handleFastForward = () => {
    let newSeek = seek + 10;
    if (newSeek > playDuration) newSeek = playDuration;
    ref.current.seekTo(newSeek);
  };

  const handleFastRewind = () => {
    let newSeek = seek - 10;
    if (newSeek < 0) newSeek = 0;
    ref.current.seekTo(newSeek);
  };

  const removeSubcription = (subscriber_id) => {
    setSubscription(
      subscriptions.filter((subscription) => subscription !== subscriber_id)
    );
    var data = {
      user_id: localStorage.getItem("user_id"),
      subscriber_id: subscriber_id,
    };
    SubscriptionService.deleteSubscription(data).then((res) => {
      console.log(res);
    });
  };

  const handleSubcription = (subscriber_id, type) => {
    setSubscription((prevSub) => [...prevSub, subscriber_id]);
    var data = {
      user_id: localStorage.getItem("user_id"),
      subscriber_id: subscriber_id,
      type: type,
    };
    SubscriptionService.subscribeToCenter(data).then((res) => {
      console.log(res);
    });
  };

  const download = async (fileUrl, filename, subscriberId) => {
    const FileKey = fileUrl?.split("/")[1];
    if (watermarkUser) {
      const payload = {
        input_pdf_key: FileKey, // Use the passed fileUrl as input PDF key
        output_pdf_key: watermarkUser + FileKey,
        watermark_text: watermarkUser,
      };

      try {
        // Send a POST request to the watermarking API
        const response = await fetch("https://ai.edhub.school/watermark", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        console.log(response);

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        // Assuming the response contains a URL for the watermarked PDF
        const watermarkedPdfUrl = payload.output_pdf_key;

        // Now, download the watermarked PDF
        var req = new XMLHttpRequest();

        req.open(
          "GET",
          "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" +
            watermarkedPdfUrl,
          true
        );
        req.responseType = "blob";
        req.onload = function (event) {
          var blob = req.response;
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        };

        req.send();
        setTimeout(() => {
          deletePdfFromS3(payload.output_pdf_key);
        }, 500);
      } catch (error) {
        console.error("Error processing the watermark and download:", error);
      }
    } else {
      // Now, download the watermarked PDF
      var req = new XMLHttpRequest();

      req.open(
        "GET",
        "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" + FileKey,
        true
      );
      req.responseType = "blob";
      req.onload = function (event) {
        var blob = req.response;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      };
      req.send();
    }
  };
  const deletePdfFromS3 = async (pdfKey) => {
    const url = "https://ai.edhub.school/delete-pdf"; // Change this URL to your Flask server URL

    // Create the request payload
    const payload = {
      pdf_key: pdfKey,
    };

    try {
      // Send the POST request
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to delete the PDF");
      }

      // Parse the response JSON
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the PDF:", error);
    }
  };
  // Function to delete the original PDF from S3

  function detectBrowser() {
    return browserName;
  }

  let arr = [];
  const printWorksheet = (fileUrl, filename, type = "centre") => {
    // console.log('ulr', fileUrl)
    // if (type == 'teacher') {
    //     // window.printPdf(process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + fileUrl)
    //     printJS({ printable: process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + fileUrl, type: 'pdf', showModal: true })
    //     // printJS({ printable: process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + fileUrl, type: 'pdf', showModal: true })
    // }
    // else {
    //     // window.printPdf(serverUrl + fileUrl)
    //     printJS({ printable: serverUrl + fileUrl, type: 'pdf', showModal: true })
    // }
    // setPdf(fileUrl)
    // let pages = new Array(numPages)
    // arr = [...Array(numPages)]
    // let page = [];
    // {
    //     arr.map((item, index) => {
    //         page.push(<div className="page-wrapper">
    //             <Page width={elementPrintRef.current ? elementPrintRef.current.offsetWidth : 0} pageNumber={index + 1} />
    //         </div>
    //         )
    //         if (index + 1 == numPages) {
    //             setPrintPages(page)
    //             setPrintPDF(true)
    //             setOpenPrint(true)
    //         }

    //     })
    // }
    // setPrintPages(pages)
    // setTimeout(() => {
    // }, 2000);
    // console.log(arr.length)
    // alert(detectBrowser())
    detectBrowser() == "Chrome" ||
    detectBrowser() == "Mobile Safari" ||
    detectBrowser() == "Safari"
      ? convertPdfToImages(fileUrl)
      : alert("Please use Chrome or Safari browser to print worksheet.");
    // convertPdfToImages(pdfUrl)
  };

  const CourseName = (props) => {
    let product = props.product;

    return (
      <>
        <div className={classes.centreInfoWrapper}>
          <div className={classes.creditsWrapper}>
            <span className={classes.centreName}>{product.course_name}</span>
          </div>
        </div>
      </>
    );
  };

  const CentreDetails = (props) => {
    let product = props.product;

    const { jwt } = React.useContext(UserContext);
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);
    const [open, setOpen] = React.useContext(AuthContext);

    const handleAuth = () => {
      setOpen(true);
    };

    return (
      <>
        <Link to={"/teachers-and-tutors/" + props.product.teacher_id}>
          <div className={classes.centreInfoWrapper}>
            <img src={product.avatar} className={classes.avatar} />
            {/* <span className={classes.avatar}>{product.centre_name[0]}</span> */}
            <div className={classes.creditsWrapper}>
              <small className={classes.credits}>Course By</small>
              <span className={classes.centreName}>{product.centre_name}</span>
            </div>
          </div>
        </Link>
        <div style={{ display: "flex" }}>
          <Grid item style={{ marginRight: 10 }}>
            <Link to={"/shop/" + props.product.username}>
              <Button style={{ backgroundColor: "#1A95AC", color: "white" }}>
                View more courses
              </Button>
            </Link>
          </Grid>
          {classesAvailable && (
            <Grid item style={{ marginRight: 10 }}>
              {!isExpired ? (
                <Link
                  to={
                    "/classes/" + product.course_name + "/" + product.course_id
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#1A95AC", color: "white" }}
                  >
                    <Icon>schedule</Icon>View Class Schedule
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={handleAuth}
                  style={{ backgroundColor: "#1A95AC", color: "white" }}
                >
                  <Icon>schedule</Icon>View Class Schedule
                </Button>
              )}
            </Grid>
          )}
          {!isExpired ? (
            subscriptions.indexOf(product.subscriber_id) > 0 ? (
              <Button
                onClick={() => removeSubcription(product.subscriber_id)}
                className={classes.unSubscribeBtn}
              >
                <Icon className={classes.bellIcon}>notifications_deactive</Icon>
                <Hidden xsDown>Unfollow</Hidden>
              </Button>
            ) : (
              <Button
                onClick={() =>
                  handleSubcription(product.subscriber_id, "centre")
                }
                className={classes.subscribeBtn}
              >
                <Icon className={classes.bellIcon}>notifications</Icon>
                <Hidden xsDown>FOLLOW</Hidden>
              </Button>
            )
          ) : (
            <Button
              onClick={() => handleAuth()}
              className={classes.subscribeBtn}
            >
              <Icon className={classes.bellIcon}>notifications</Icon>
              <Hidden xsDown>Sign In to Follow</Hidden>
            </Button>
          )}
        </div>
      </>
    );
  };
  const TeacherDetails = (props) => {
    let product = props.product;
    return (
      <>
        <div className={classes.centreInfoWrapper}>
          <img
            src={product.avatar != null && product.avatar}
            className={classes.avatar}
          />
          {/* <span className={classes.avatar}>{product.display_name != null && product.display_name[0]}</span> */}
          <div className={classes.creditsWrapper}>
            <small className={classes.credits}>Course By</small>
            <span className={classes.centreName}>{product.display_name}</span>
          </div>
        </div>
        <Grid item style={{ marginRight: 10 }}>
          <Link
            to={"/classes/" + product.course_name + "/" + product.course_id}
            style={{ textDecoration: "none" }}
          >
            <Button style={{ backgroundColor: "#1A95AC", color: "white" }}>
              <Icon>schedule</Icon>View class dates & time
            </Button>
          </Link>
        </Grid>
        {subscriptions.indexOf(product.subscriber_id) > 0 ? (
          <Button
            onClick={() => removeSubcription(product.subscriber_id)}
            className={classes.unSubscribeBtn}
          >
            <Icon className={classes.bellIcon}>notifications_deactive</Icon>
            <Hidden xsDown>Unfollow</Hidden>
          </Button>
        ) : (
          <Button
            onClick={() => handleSubcription(product.course_teacher, "teacher")}
            className={classes.subscribeBtn}
          >
            <Icon className={classes.bellIcon}>notifications</Icon>
            <Hidden xsDown>FOLLOW</Hidden>
          </Button>
        )}
      </>
    );
  };

  let videoMaterial = [];
  let pdfMaterial = [];

  if (product.materials != null && product.materials.length > 0) {
    videoMaterial = product.materials.filter((item) => {
      return item.type == ".mp4" || item.type == "mp4";
    });
    pdfMaterial = product.materials.filter((item) => {
      return item.type == ".pdf" || item.type == "pdf";
    });
  }

  const VideosTab = (props) => {
    let materials =
      product.materials != null
        ? product.materials.filter((item) => {
            return item.type == ".mp4";
          })
        : null;
    return (
      <TabPanel
        className={classes.tabWrapper}
        value={value}
        index={props.index}
        dir={theme.direction}
      >
        <Grid container className={classes.materialsInner}>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              {product.materials != null &&
                product.materials.map((material, index) =>
                  material.type == ".mp4" || material.type == "mp4" ? (
                    <ListItem
                      key={material.id}
                      onClick={() => {
                        // setVideo('material/' + material.material_name);
                        if (product.subscriber_type == "teacher")
                          setVideo(material.material_name);
                        else
                          setVideo(
                            "courses/materials/" +
                              material.subscriber_id +
                              "/" +
                              material.material_name
                          );
                        setView("VIDEO");
                        setPlayingMaterial(material.id);
                      }}
                      className={
                        view == "VIDEO" && playingMaterial == material.id
                          ? classes.selecteditem
                          : classes.item
                      }
                      button
                    >
                      <div className={classes.playName}>
                        <Icon className={classes.playIcon}>play_circle</Icon>
                        <ListItemText
                          className={classes.materialTitle}
                          primary={material.org_name}
                        />
                      </div>

                      {
                        // <Icon onClick={() => download('material/' + material.material_name, material.org_name, material.subscriber_id)} className={classes.downloadIcon}>download</Icon>
                        material.subscriber_id == 0 ? (
                          <Icon
                            onClick={() =>
                              download(
                                material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        ) : (
                          <Icon
                            onClick={() =>
                              download(
                                "courses/materials/" +
                                  material.subscriber_id +
                                  "/" +
                                  material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        )
                      }
                    </ListItem>
                  ) : (
                    ""
                  )
                )}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const WorksheetsTab = (props) => {
    return (
      <TabPanel
        className={classes.tabWrapper}
        value={value}
        index={props.index}
        dir={theme.direction}
      >
        <Grid container className={classes.materialsInner}>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              {product.materials != null &&
                product.materials.map((material, index) =>
                  material.type == ".pdf" || material.type == "pdf" ? (
                    <ListItem
                      key={material.id}
                      onClick={() => {
                        // setPdf(process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + 'material/' + material.material_name);
                        if (material.subscriber_id == 0)
                          setPdf(
                            "https://edhubshop.s3.ap-southeast-1.amazonaws.com/" +
                              material.material_name
                          );
                        else
                          setPdf(
                            serverUrl +
                              "courses/materials/" +
                              material.subscriber_id +
                              "/" +
                              material.material_name
                          );

                        setNumPages(null);
                        setView("PDF");
                        setPlayingMaterial(material.id);
                      }}
                      className={
                        view == "PDF" && playingMaterial == material.id
                          ? classes.selecteditem
                          : classes.item
                      }
                      button
                    >
                      <div className={classes.playName}>
                        <Icon className={classes.playIcon}>description</Icon>
                        <ListItemText
                          className={classes.materialTitle}
                          primary={material.org_name}
                        />
                      </div>
                      <ListItemIcon>
                        {material.subscriber_id == 0 ? (
                          <Icon
                            onClick={() =>
                              download(
                                "material/" + material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        ) : (
                          <Icon
                            onClick={() =>
                              download(
                                "courses/materials/" +
                                  material.subscriber_id +
                                  "/" +
                                  material.material_name,
                                material.org_name,
                                material.subscriber_id
                              )
                            }
                            className={classes.downloadIcon}
                          >
                            download
                          </Icon>
                        )}
                        <Hidden mdUp>
                          <Icon
                            onClick={() => {
                              // window.printPdf('material/' + material.material_name, material.org_name, 'teacher')
                              material.subscriber_id == 0
                                ? window.printPdf(
                                    material.material_name,
                                    material.org_name,
                                    "teacher"
                                  )
                                : window.printPdf(
                                    "courses/materials/" +
                                      material.subscriber_id +
                                      "/" +
                                      material.material_name,
                                    material.org_name,
                                    material.subscriber_id
                                  );
                            }}
                            className={classes.printIcon}
                          >
                            print
                          </Icon>
                        </Hidden>
                      </ListItemIcon>
                    </ListItem>
                  ) : (
                    ""
                  )
                )}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const handleOnPrintPageLoaded = (pdf) => {
    setPrintLoading(false);
    console.log(pdf);
  };

  return (
    <div className={classes.container}>
      <div id="product-viewer">
        <Grid container style={{ marginBottom: 0 }}>
          <Grid style={{ position: "relative" }} item xs={12} sm={9}>
            {product != null && view != null ? (
              view === "VIDEO" ? (
                <div
                  style={{ width: "100%", position: "relative" }}
                  onMouseEnter={(e) => {
                    setControls(0);
                  }}
                  onMouseLeave={(e) => {
                    setControls(0);
                  }}
                >
                  {customControls && (
                    <Grid
                      container
                      className={classes.videoControls}
                      style={{ bottom: controlsPosition }}
                    >
                      <Grid item xs={12}>
                        <PrettoSlider
                          className={classes.seekBar}
                          min={0}
                          max={totalSeek}
                          value={seek}
                          // onMouseDown={this.handleSeekMouseDown}
                          onChange={handleSeekChange}
                          // onMouseUp={this.handleSeekMouseUp}
                        />
                      </Grid>
                      <Grid item xs={10} className={classes.leftControls}>
                        <div className={classes.playerControl}>
                          {playingVideo ? (
                            <Tooltip title="Pause" placement="top">
                              <ButtonBase onClick={handlePlayPauseVideo}>
                                <Icon className={classes.playBtn}>pause</Icon>
                              </ButtonBase>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Play" placement="top">
                              <ButtonBase onClick={handlePlayPauseVideo}>
                                <Icon className={classes.playBtn}>
                                  play_arrow
                                </Icon>
                              </ButtonBase>
                            </Tooltip>
                          )}
                        </div>
                        <div className={classes.playerControl}>
                          <Tooltip title="10-seconds Rewind" placement="top">
                            <ButtonBase onClick={() => handleFastRewind()}>
                              <Icon className={classes.speedIcon}>history</Icon>
                            </ButtonBase>
                          </Tooltip>
                        </div>
                        <div className={classes.playerControl}>
                          <Tooltip title="10-seconds Forward" placement="top">
                            <ButtonBase onClick={() => handleFastForward()}>
                              <Icon className={classes.speedIcon}>update</Icon>
                            </ButtonBase>
                          </Tooltip>
                        </div>
                        <div className={classes.playerControl}>
                          <Tooltip title="Play Speed" placement="top">
                            <ButtonBase
                              className={classes.speedIcon}
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              {playbackRate + "x"}
                            </ButtonBase>
                          </Tooltip>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={() => handleClose(0.5)}>
                              0.5x Slow
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(1)}>
                              1x Normal
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(1.5)}>
                              1.5x Fast
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(2)}>
                              2x Faster
                            </MenuItem>
                          </Menu>
                        </div>
                        <div className={classes.playDuration}>
                          {seekTime + " / " + playDuration}
                        </div>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        xs={6}
                        className={classes.rightControls}
                      >
                        <div className={classes.volumeControl}>
                          {volume > 0 ? (
                            <ButtonBase>
                              {volume > 50 ? (
                                <Icon className={classes.iconControls}>
                                  volume_up
                                </Icon>
                              ) : (
                                <Icon className={classes.iconControls}>
                                  volume_down
                                </Icon>
                              )}
                            </ButtonBase>
                          ) : (
                            <ButtonBase>
                              <Icon className={classes.iconControls}>
                                volume_mute
                              </Icon>
                            </ButtonBase>
                          )}
                          <div className={classes.volumeSlider}>
                            <VolumeSlider
                              color={"red"}
                              value={volume}
                              onChange={handleVolumeChange}
                              aria-labelledby="continuous-slider"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {/* process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + videoUrl */}
                  <ReactPlayer
                    ref={ref}
                    playing={playingVideo}
                    controls={!customControls}
                    playbackRate={playbackRate}
                    className="react-player"
                    url={
                      product.subscriber_type == "teacher"
                        ? videoUrl
                        : serverUrl + videoUrl
                    }
                    width="100%"
                    volume={volume / 100}
                    height="auto"
                    onDuration={handleDuration}
                    onProgress={handleProgress}
                  />
                </div>
              ) : (
                <div
                  ref={elementRef}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    height: "75vh",
                    overflowY: "scroll",
                  }}
                >
                  {numPages == null && (
                    <div className={classes.pdfLoader}>
                      <img
                        src="/assets/ringloader.gif"
                        width="110"
                        height="110"
                      />
                    </div>
                  )}
                  <Document
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                    file={{ url: pdfUrl }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                    width={800}
                  >
                    <Page width={pdfWidth} pageNumber={pageNumber} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 15,
                      }}
                    >
                      {/* Watermark Overlay */}
                      {watermarkUser && watermarkUser !== "" && (
                        <div className={classes.watermarkContainer}>
                          {Array.from({ length: 4 }).map((_, rowIndex) => (
                            <div
                              className={classes.watermarkRow}
                              key={rowIndex}
                            >
                              {Array.from({ length: 6 }).map((_, colIndex) => (
                                <div
                                  className={classes.watermark}
                                  key={colIndex}
                                >
                                  {watermarkUser}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                      <Tooltip title="First Page" placement="top">
                        <ButtonBase
                          className={classes.navButtons}
                          onClick={() => firstPage()}
                        >
                          <Icon>skip_previous</Icon>
                        </ButtonBase>
                      </Tooltip>
                      <Tooltip title="Previous Page" placement="top">
                        <ButtonBase
                          className={classes.navButtons}
                          onClick={() => prevPage()}
                        >
                          <Icon>chevron_left</Icon>
                        </ButtonBase>
                      </Tooltip>
                      <p
                        style={{
                          color: "black",
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        Page {pageNumber} of {numPages}
                      </p>
                      <Tooltip title="Next Page" placement="top">
                        <ButtonBase
                          className={classes.navButtons}
                          onClick={() => nextPage()}
                        >
                          <Icon>chevron_right</Icon>
                        </ButtonBase>
                      </Tooltip>
                      <Tooltip title="Last Page" placement="top">
                        <ButtonBase
                          className={classes.navButtons}
                          onClick={() => lastPage()}
                        >
                          <Icon>skip_next</Icon>
                        </ButtonBase>
                      </Tooltip>
                    </div>
                  </Document>
                </div>
              )
            ) : (
              <CourseBanner
                limit={"unlimited"}
                handleCheckoutOpen={null}
                course={product}
              />
            )}
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="fixed-course-info"
            >
              <CourseName product={product} />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="fixed-teacher-info"
            >
              {product.centre_name != null ? (
                <CentreDetails product={product} />
              ) : (
                <TeacherDetails product={product} />
              )}
            </Grid>
          </Grid>
          <Grid item className="dark-bg" xs={12} sm={3}>
            <PurchasedMaterials
              setPageNumber={setPageNumber}
              view={view}
              download={download}
              setPdfImages={setPdfImages}
              printWorksheet={printWorksheet}
              setNumPages={setNumPages}
              setView={setView}
              setPdf={setPdf}
              setStart={null}
              setEnd={null}
              setVideo={setVideo}
              product={product}
            />
          </Grid>
          <Grid className="about-course" item xs={12} sm={9}>
            <h2>About this course</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: product != null && product.course_description,
              }}
            ></div>
            <hr></hr>
            <Typography
              variant="h5"
              style={{ marginTop: 0, marginBottom: 20 }}
              component="h4"
            >
              Rating and Reviews
            </Typography>
            {fetched && (
              <Comments
                showForm={true}
                id={product.course_id}
                rating={product.rating}
                comments={product.comments}
              />
            )}
          </Grid>
          <Grid className="course-info" item xs={12} sm={3}>
            <h3 style={{ textAlign: "center" }}>Course Info</h3>
            <Grid
              container
              className="course-info-single"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Icon className="icon green-icon">attach_money</Icon>
                  <h5>Cost</h5>
                </Grid>
              </Grid>
              <Grid item>
                <h5>${product != null && product.course_tution_fee} HK</h5>
              </Grid>
            </Grid>
            <Grid
              container
              className="course-info-single"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Icon className="icon orange-icon">star</Icon>
                  <h5>Rating</h5>
                </Grid>
              </Grid>
              <Grid item>
                <h5>{Math.floor(product.rating)} Star</h5>
              </Grid>
            </Grid>
            <Grid
              container
              className="course-info-single"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Icon className="icon sea-blue-icon">stacked_bar_chart</Icon>
                  <h5>Level</h5>
                </Grid>
              </Grid>
              <Grid item className={classes.levelsWrapper}>
                {productLevels.map((level, index) => {
                  return (
                    <span className={classes.levelTag} key={index}>
                      {level}
                    </span>
                  );
                })}
                {productLevels.map((level, index) => {
                  return (
                    <span className={classes.levelTag} key={index}>
                      {level}
                    </span>
                  );
                })}
                {productLevels.map((level, index) => {
                  return (
                    <span className={classes.levelTag} key={index}>
                      {level}
                    </span>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              container
              className="course-info-single"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Icon className="icon purple-icon">school</Icon>
                  <h5>Grade</h5>
                </Grid>
              </Grid>
              <Grid item>
                <h5>{product.course_grade_level}</h5>
              </Grid>
            </Grid>
            <Grid
              container
              className="course-info-single no-border"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Icon className="icon">bookmark_border</Icon>
                  <h5 className="no-border">Course Tags</h5>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid container className="course-info-single" flexdirection="column"> */}
            {/* <Typography variant="h6" component="h6" className={classes.productTagHeading}>
                        <Icon className="icon purple-icon">tag</Icon>
                        <h5>Course Tags</h5>
                    </Typography> */}
            <div className={classes.tagsWrapper}>
              {product.tags != null &&
                product.tags.split(",").map((tag, index) => (
                  <Link
                    to={"/search/" + tag}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <Chip
                      style={{ cursor: "pointer" }}
                      key={index}
                      className={classes.tag}
                      key={index}
                      label={tag}
                    />
                  </Link>
                ))}
            </div>
            {/* </Grid> */}
          </Grid>
        </Grid>
      </div>
      {printPDF && (
        <Dialog
          fullScreen
          open={openPrint}
          onClose={handlePrintClose}
          TransitionComponent={Transition}
        >
          <AppBar id="print-header" sx={{ position: "relative" }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePrintClose}
                aria-label="close"
              >
                <Icon>close</Icon>
              </IconButton>
              <Button
                variant="contained"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => window.printPdf()}
              >
                <Icon>print</Icon> Print
              </Button>
            </Toolbar>
          </AppBar>
          <div className="print-pop-up">
            <>
              <div ref={elementPrintRef} id="print-area">
                {
                  // printLoading &&
                  // <div style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  //     <h1>Loading...</h1>
                  // </div>
                }

                {/* <Document
                                    style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
                                    file={{ url: pdfUrl }}
                                    options={options}
                                    onLoadSuccess={handleOnPrintPageLoaded}
                                    width={500}
                                >
                                    {
                                        printPages
                                    }
                                </Document> */}
                {pdfImages != null ? (
                  pdfImages.map((image) => <img width="100%" src={image} />)
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1>Loading...</h1>
                  </div>
                )}
              </div>
            </>
          </div>
        </Dialog>
      )}
    </div>
  );
}
