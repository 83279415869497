import React from "react";
import MainTable from './MainTable';
import { UserContext } from '../../../context/UserContext';


const EarningTable = () => {
  const { user } = React.useContext(UserContext)
  const [currentUser, setCurrentUser] = user;
  
  return <><MainTable currentUser={currentUser.user_id}/></>;
};

export default EarningTable;
