import axios from 'axios';
// const url = 'https://apiendpoints.edhub.school/public/index.php/api/'
// const corsUrl = 'https://apiendpoints.edhub.school/public/index.php/'
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Subscription = {
    // Store Subscription
    subscribeToCenter: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'subscription/store',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": JSON.stringify(data)
        })
    },

    // Get Subscription
    getSubscription: function (user) {
        return axios({
            "method": "GET",
            "url": url + 'subscription/get',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "user_id": user,
            }
        })
    },

    // Delete Subscription
    deleteSubscription: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'subscription/remove',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": JSON.stringify(data)
        })
    },


}

export default Subscription;