import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import '../css/common.css';

export default function SearchProduct(props) {
    return (
        <Grid style={{ marginTop: 15 }} item className="product-search-bar">
            <FormControl fullWidth sx={{ m: 1 }}>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    onKeyUp={(e) => props.handleSearch(e)}
                    style={{ height: 40, borderRadius: 10 }}
                    startAdornment={<InputAdornment position="start"><Icon>search</Icon></InputAdornment>}
                    placeholder="Search product by name"
                />
            </FormControl>
        </Grid>
    )
}
