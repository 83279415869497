import React from 'react'
import SignIn from '../components/auth/SingleSignIn'
import Grid from '@material-ui/core/Grid';

export default function Login() {
    return (
        <div style={{ height: '90vh' }}>
            <Grid container style={{ height: '100%' }} justifyContent="center" alignItems="center">
                <Grid style={{ background: 'white', padding: 15, borderRadius: 15 }} item xs={12} sm={8} lg={4}>
                    <SignIn redirect={'home'} />
                </Grid>
            </Grid>
        </div>
    )
}
