import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({
    root:{
        marginTop:5,
        marginBottom:5
    },
    language:{
        fontSize:14,
        marginRight:5
    },
    level: {
        fontSize:14,
        backgroundColor:"#e6faf8",
        color:'#02cab9',
        padding:3
    },
    wrapper:{
        marginRight:10
    }
}))

export default function Languages({languages}) {
    const classes = useStyle();

    const SingleLanguage = ({language,level,index}) => {
        return(
            <span className={classes.wrapper}>
            <span className={classes.language}>{language}</span>
            <span className={classes.level}>{level}</span>{languages.length-1 != index && ','}
            </span>
        )
    }

  return (
    <div className={classes.root}>
        {
            languages.length > 0 &&
            <strong>Speaks: </strong>
        }
      {
        languages.map((language,index) => (
            <SingleLanguage key={index} index={index} language={language.language} level={language.level} />
        ))
      }
    </div>
  )
}
