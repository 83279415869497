import React from 'react'
import {Button,Tooltip,Popover} from '@material-ui/core'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import TrialPackages from './TrialPackages';
import { BookingContext } from '../../../context/BookingContext';
import { AuthContext } from '../../../context/AuthContext';
import { UserContext } from '../../../context/UserContext';
import { useJwt } from "react-jwt";
import {Link} from 'react-router-dom'

const useStyle = makeStyles((theme)=>({
    root:{
        marginTop:15,
        marginBottom:5
    },
    bookingBtn:{
        backgroundColor:'#00C1E2',
        width:'100%',
        color:'white',
        marginBottom:10,
        '&:hover':{
            backgroundColor:'#00b4d2'
        }
    },
    removeBookingBtn:{
        backgroundColor:'#FFF',
        width:'100%',
        color:'#DE2910',
        marginBottom:10,
        border:'solid 1px #DE2910',
        '&:hover':{
            backgroundColor:'#FFF'
        }
    },
    contactBtn:{
        backgroundColor:'white',
        border:'solid 1px #00C1E2',
        width:'100%',
        color:'#00C1E2'
    }
}))

export default function TrialActions({teacher,trials}) {
    const classes = useStyle()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {bookedTeachers} = React.useContext(BookingContext)
    const [open, setOpen] = React.useContext(AuthContext)
    const { jwt } = React.useContext(UserContext)
    const { user } = React.useContext(UserContext)
    const [currentUser] = user
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const modalOpen = Boolean(anchorEl);
    const id = modalOpen ? teacher : undefined;
  return (
    <div className={classes.root}>
        { 
            currentUser?.role_id == 4 ?
            <Tooltip placement="top" arrow title={<h4 style={{color:'white',margin:0,fontSize:13,fontWeight:400}}>Booking isn't allowed for teachers</h4>}>
                <Button aria-describedby={teacher} className={classes.bookingBtn}>Book trial</Button>
            </Tooltip>
            :
            trials?.length > 0 ?
                <>
                {
                    isExpired ?
                    <Button aria-describedby={teacher} onClick={() => setOpen(true)} className={classes.bookingBtn}>{'Book trial'}</Button>
                    :
                    <>
                        <Button aria-describedby={teacher} onClick={handleClick} className={bookedTeachers.includes(teacher) ? classes.removeBookingBtn : classes.bookingBtn}>{bookedTeachers.includes(teacher) ? 'Remove booking' : 'Book trial'}</Button>
                        <Popover
                            open={modalOpen}
                            id={id}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                        >
                            <TrialPackages teacher={teacher} trials={trials}/>
                        </Popover>
                    </>
                }
            </>
            :
            <Tooltip placement="top" arrow title="No trial available">
                <Button aria-describedby={teacher} className={classes.bookingBtn}>Book trial</Button>
            </Tooltip>
            
        }
        <Link style={{textDecoration:'none'}} to={`teachers-and-tutors/${teacher}`} >
            <Button className={classes.contactBtn}>View more</Button>
        </Link>
    </div>
  )
}
