import React, { forwardRef, useState, useImperativeHandle, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Product from '../../services/products';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { CartContext } from '../../context/CartContext';
import { FavouriteContext } from '../../context/FavouriteContext';
import { BuyNowContext } from '../../context/BuyNowContext';
import { UserContext } from '../../context/UserContext';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useJwt } from 'react-jwt';

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0
    },
    productsWrapper: {
        padding: 10,
        marginBottom: 0
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    titleName: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1.1,
        color: 'black',
        minHeight: 38,
        height: 38,
        overflow: 'hidden',
    },
    singleItem: {
        height: 305,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    singeInner: {
        width: '98%',
        height: '99%',
        marginBottom: 20,
        marginLeft: '5%',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        }
    },
    removeBtn: {
        backgroundColor: '#d55555',
        color: 'white',
        borderRadius: 30,
        padding: 6,
        height: 35,
        '&:hover': {
            backgroundColor: '#ad4242'
        }
    },
    addBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        borderRadius: 12,
        fontSize: 8,
        padding: 6,
        height: 35,
        '&:hover': {
            backgroundColor: '#a35e05'
        }
    },
    buyBtn: {
        backgroundColor: '#f16020',
        color: 'white',
        borderRadius: 12,
        fontSize: 12,
        padding: 6,
        height: 35,
        '&:hover': {
            backgroundColor: '#cc501a'
        }
    },
    icon: {
        fontSize: 19
    },
    coursePrice: {
        fontSize: 16,
        marginRight: 10,
        color: 'red'
    },
    favouritIcon: {
        fontSize: 20,
        color: '#df3d3d',
    },
    favouriteBtn: {
        backgroundColor: '#ffffff',
        color: '#626262',
        borderRadius: 30,
        fontSize: 8,
        padding: 8,
    },
    favouriteBtnFilled: {
        backgroundColor: '#ffffff',
        color: '#df3d3d',
        borderRadius: 30,
        fontSize: 8,
        padding: 8,
    },
    subscriberName: {
        color: '#fa9c24',
        fontSize: 14,
        marginBottom: 8
    },
    cardAction: {
        paddingTop: 3,
        paddingBottom: 0
    },
    tag: {
        backgroundColor: '#f1f1f1',
        marginRight: 2,
        marginBottom: 2,
        fontSize: 9
    },
    discountedPrice: {
        textDecoration: 'line-through',
        color: 'gray',
        fontSize: 15,
        marginRight: 5
    },
    cardContent: {
        padding: 3,
        paddingBottom: 0
    },
    tagsWrapper: {
        height: 50,
        overflow: 'hidden'
    },
    centerAvatar: {
        width: 32,
        height: 32,
        borderRadius: 30,
        overflow: 'hidden'
    },
    badgeAvatar: {
        width: 24,
        height: 24,
        marginLeft: 5
    },
    ratingValue: {
        marginLeft: 6,
        color: 'black',
        fontSize: 14
    },
    ratingIcon: {
        color: '#fdd058',
        fontSize: 18
    },
    badgeWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
    }
}));

export default function SlidingProduct(props) {
    // console.log(props)
    let coverImage = null;
    const [disablePricing, setDisablePricing] = React.useState(true);
    let coverType = props.product.cover_type;
    let subscriberId = props.product.subscriber_id;
    let courseCover = process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + subscriberId + '/' + props.product.course_cover;
    let teacherDp = null
    if (props.product.subscriber_id == 0) {
        courseCover = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + props.product.course_cover;
    }
    if (props.product.auth_using == 'social') {
        teacherDp = props.product.profile_picture;
    }
    else {
        teacherDp = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + props.product.profile_picture;
    }
    const classes = useStyles();
    const [favourites, setFavorites] = useState([]);
    // States from Context
    const [cartItem, setCartItem] = useContext(CartContext);
    const { favouriteItem } = React.useContext(FavouriteContext);
    const [favouriteItems, setFavouriteItems] = favouriteItem;
    const { buy } = React.useContext(BuyNowContext);
    const { dialog } = React.useContext(BuyNowContext);
    const { auth } = React.useContext(BuyNowContext);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { products } = React.useContext(UserContext)
    const [usersProducts, setUsersProducts] = products;
    const { decodedToken, isExpired } = useJwt(token);
    const [checkoutOpen, setCheckoutOpen] = dialog;
    const [buyProduct, setBuyProduct] = buy
    const [isLoggedIn, setIsLoggedIn] = auth;
    const [bought, setBought] = React.useState(false);

    const addToCart = (item) => {
        setCartItem(preItems => [...preItems, item])
    }
    const removeFromCart = (removeItem) => {
        setCartItem(cartItem.filter(item => item.course_id != removeItem.course_id))
    }
    const addToFavourite = (course) => {
        let user = localStorage.getItem('user_id');
        Product.addToFavourite(user, course.course_id)
            .then(res => {
                setFavouriteItems(preFavourites => [...preFavourites, course])
            })
            .catch(error => {
                console.log(error)
            })
    }
    const removeFromFavourite = (courseId) => {
        let user = localStorage.getItem('user_id');
        Product.removeFavourite(user, courseId).then(res => {
            setFavouriteItems(favouriteItems.filter(item => item.course_id != courseId))
        })
    }

    function ProductRating(props) {
        return (
            <>
                <Grid container alignItems="center">
                    <Icon className={classes.ratingIcon}>{props.rating > 0 ? 'star' : 'star_outline'}</Icon>
                    <Icon className={classes.ratingIcon}>{props.rating > 1 ? 'star' : 'star_outline'}</Icon>
                    <Icon className={classes.ratingIcon}>{props.rating > 2 ? 'star' : 'star_outline'}</Icon>
                    <Icon className={classes.ratingIcon}>{props.rating > 3 ? 'star' : 'star_outline'}</Icon>
                    <Icon className={classes.ratingIcon}>{props.rating > 4 ? 'star' : 'star_outline'}</Icon>
                    <span className={classes.ratingValue}>{props.rating != null ? props.rating.toFixed(1) : 0}</span>
                </Grid>
            </>
        )
    }

    function existInCart(product) {
        if (cartItem != null) {
            return cartItem.some(function (el) {
                return el.course_id === product;
            });
        }
        return false
    }

    function existInFavourite(product) {
        if (favouriteItems != null) {
            return favouriteItems.some(function (el) {
                return el.course_id === product;
            });
        }
        return false
    }

    const handleCheckoutOpen = (product) => {
        setBuyProduct(product)
        setCheckoutOpen(true);
        setIsLoggedIn(!isExpired)
    };

    var tagsObject = []
    if (props.product.tags != null) {
        var tags = props.product.tags.split(',')
        tagsObject = tags;
    }

    let cardMediaType = "img"
    if (props.product.course_cover != '' && props.product.course_cover != null) {
        // courseCover = process.env.UPLOAD_URL+'courses/covers/'+subscriberId+'/'+courseCover;
        if (coverType == '.mp4') {
            cardMediaType = 'video'
        }
        else {
            cardMediaType = 'img'
        }
    }
    else {
        courseCover = '/' + pics[props.index]
    }

    React.useEffect(() => {
        setDisablePricing((props.product.access_code != null && props.product.access_code != '') ? true : false)
    }, [])

    // React.useEffect(() => {
    //     console.log(usersProducts)
    //     console.log(props.product, usersProducts.includes(props.product.course_id))
    // }, [usersProducts])

    return (
        <div key={props.index} className={classes.singleItem}>
            <div className={classes.singeInner}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <Link style={{ textDecoration: 'none' }}
                            onClick={() => {
                                localStorage.setItem("productId", props.product.course_id)
                                localStorage.setItem("productImage", courseCover)
                            }
                            }
                            to={{
                                pathname: props.product.access_code ? "/product/view/" + props.product.course_name + '/' + props.product.course_id : "/product/preview/" + props.product.course_name + '/' + props.product.course_id,
                                state: {
                                    image: courseCover,
                                    id: props.product.course_id,
                                    from: props.from
                                }
                            }}>
                            <CardMedia
                                component={cardMediaType}
                                alt={props.product.title}
                                height={190}
                                width="100%"
                                image={courseCover}
                                title={props.product.title}
                            />
                            <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
                                <Tooltip title={props.product.course_name} placement="top">
                                    <Typography gutterBottom className={classes.titleName} variant="h6" component="h3">
                                        {props.product.course_name.substring(0, 58)} {props.product.course_name.length > 58 && '....'}
                                    </Typography>
                                </Tooltip>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid>
                                        <ProductRating rating={props.product.rating} />
                                    </Grid>
                                    {
                                        !disablePricing &&
                                        <Grid>
                                            {props.product.discounted_price != null && props.product.discounted_price != '' && props.product.discounted_price < props.product.course_tution_fee ?
                                                <span className={classes.discountedPrice}>${props.product.course_tution_fee}</span>
                                                :
                                                <span></span>
                                            }
                                            {
                                                (props.product.discounted_price != null && props.product.discounted_price != '') && props.product.discounted_price < props.product.course_tution_fee ?
                                                    <span className={classes.coursePrice}>${props.product.discounted_price} HK</span>
                                                    :
                                                    <span className={classes.coursePrice}>${props.product.course_tution_fee} HK</span>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Link>
                    </CardActionArea>
                    <CardActions className={classes.cardAction}>
                        <Grid className={classes.cardActionInner} container justifyContent="space-between" alignItems="center">
                            <Grid className={classes.badgeWrapper}>
                                {/* <Tooltip title={'By ' + props.product.centre_name} placement="top">
                                    <div className={classes.centerAvatar}>
                                        <img style={{ width: '100%' }} src={process.env.REACT_APP_UPLOADS_URL + 'admin/subscriber/' + props.product.avatar} />
                                    </div>
                                </Tooltip> */}
                                <Link to={'/shop/' + props.product.username}>
                                    <Tooltip title={'By ' + (props.product.subscriber_id == 0 ? props.product.display_name : props.product.centre_name)} placement="top">
                                        <div className={classes.centerAvatar}>
                                            {
                                                props.product.subscriber_id == 0 ?
                                                    <img style={{ width: '100%' }} src={teacherDp} />
                                                    :
                                                    <img style={{ width: '100%' }} src={process.env.REACT_APP_UPLOADS_URL + 'admin/subscriber/' + props.product.avatar} />
                                            }
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={'Star Achiever'} placement="top">
                                    <div className={classes.badgeAvatar}>
                                        <img style={{ width: '100%' }} src="/assets/badge.png" />
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Shield Achiever'} placement="top">
                                    <div className={classes.badgeAvatar}>
                                        <img style={{ width: '100%' }} src="/assets/shield.png" />
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid>
                                <Grid container alignItems="center">
                                    {
                                        existInFavourite(props.product.course_id)
                                            ?
                                            <Tooltip title="Remove from Favorite" placement="top">
                                                <IconButton className={classes.favouriteBtnFilled} onClick={() => removeFromFavourite(props.product.course_id)} size="small" color="primary">
                                                    <Icon className={classes.icon}>favorite</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Add to Favorite" placement="top">
                                                <IconButton className={classes.favouriteBtn} onClick={() => addToFavourite(props.product)} size="small" color="primary">
                                                    <Icon className={classes.favouritIcon}>favorite_border</Icon>
                                                </IconButton>
                                            </Tooltip>
                                    }

                                    {
                                        !disablePricing &&
                                        <Grid >
                                            {
                                                !(usersProducts.includes(props.product.course_id)) &&
                                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                    {
                                                        existInCart(props.product.course_id)
                                                            ?
                                                            <Tooltip title="Remove from Cart" placement="top">
                                                                <IconButton className={classes.removeBtn} onClick={() => removeFromCart(props.product)} size="small" color="primary">
                                                                    <Icon className={classes.icon}>clear</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title={"Add to Cart ($" + (props.product.discounted_price != null && props.product.discounted_price != '' ? props.product.discounted_price : props.product.course_tution_fee) + " HK)"} placement="top">
                                                                <IconButton className={classes.addBtn} onClick={() => addToCart(props.product)} size="small" color="primary">
                                                                    <Icon className={classes.icon}>shopping_cart</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                    }
                                                    <Tooltip title={"Buy Now ($" + (props.product.discounted_price != null && props.product.discounted_price != '' ? props.product.discounted_price : props.product.course_tution_fee) + " HK)"} placement="top">
                                                        <IconButton className={classes.buyBtn} onClick={() => handleCheckoutOpen(props.product)} size="small" color="primary">
                                                            Buy Now
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            }
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </div>
        </div>
    )

}

