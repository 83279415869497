import React, { useState, useEffect } from 'react'
import { Grid, Container, Typography, TextField, Icon, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from "moment";
import Classes from '../../services/classes';
import Products from '../../services/products';
import { UserContext } from '../../context/UserContext';
import { useDropzone } from "react-dropzone";
import '../css/common.css';
import {
    InputBase,
    FormControl,
    Radio,
    Tooltip,
    Input,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    ListSubheader,
    InputAdornment,
    Paper,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import AWS from 'aws-sdk'
import { Upload } from '@aws-sdk/lib-storage'
import { S3Client, S3 } from '@aws-sdk/client-s3'

import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import {
    alpha,
    ThemeProvider,
    withStyles,
    makeStyles,
    useTheme,
    createTheme,
} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/HighlightOffRounded';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { isSameOrBeforeTime, isSameOrAfterTime, isSameOrBefore } from "../helper/Utils";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as Yup from 'yup';

import { useFormikContext } from 'formik';

const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors);
            if (keys.length > 0) {
                const selector = `[name=${keys[0]}]`;
                const errorElement = document.querySelector(selector);
                if (errorElement) {
                    errorElement.focus();
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};

const CreationSchema = Yup.object().shape({
    date: Yup.date().default(null),
    end_date: Yup.date().min(Yup.ref('date'), 'End date cannot be earlier than the start date'),
    title: Yup.string().required('Class name is required'),
    course_id: Yup.string().required('Course is required'),
    class_price: Yup.number().min(4, 'Price should be minimum 4HKD').required('Price is required'),
    zoom_link: Yup.string().required('Zoom link is required'),
    color: Yup.string().required('Color is Rrquired'),
});

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_BUCKET_REGION;
const awsCreds = {
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        marginTop: 10,
        marginBottom: 15,
        width: theme.spacing(11)
    },
    inputBase: {
        margin: theme.spacing(1),
    },
    inputBaseDisabled: {
        border: 'solid',
        borderRadius: 5,
        borderColor: '#d6d6d6',
        backgroundColor: "#adadad",
        marginTop: 10,
        marginBottom: 10,
        paddingRight: 15
    },
    inputWrapper: {
        border: 'solid',
        borderRadius: 5,
        borderColor: '#727F8A',
        backgroundColor: "white",
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center'
    },
    inputErrorWrapper: {
        border: 'solid',
        borderRadius: 5,
        borderColor: 'red',
        backgroundColor: "white",
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center'
    },
    inputNumberWrapper: {
        border: 'solid',
        borderRadius: 5,
        borderColor: '#727F8A',
        backgroundColor: "white",
        marginTop: 10,
        marginBottom: 10,
        paddingRight: 15
    },
    labelWrapper: {
        marginTop: 5,
        marginBottom: 5,
        color: 'white'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    menuPaper: {
        maxHeight: 300
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,

        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const thumbsContainer = {
    // display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
    marginTop: 10
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
};

const errorMessage = {
    color: 'red',
    marginBottom: 10
}

const activeStyle = {
    borderColor: "#2196f3"
};

const acceptStyle = {
    borderColor: "#00e676"
};

const rejectStyle = {
    borderColor: "#ff1744"
};

export default function CreateClass({ setLoading, editData, courseEditable, editClass, handleClossSidebar, classDate, organizeNewEvents, course_id }) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [selectedTime, setSelectedTime] = React.useState(new Date('2014-08-18T21:11:54'));
    const [radioValue, setRadioValue] = React.useState('Single lesson');
    const [personName, setPersonName] = React.useState([]);
    const [allCourses, setAllCourses] = React.useState([]);
    const [classDay, setClassDay] = React.useState();
    const [selectedOption, setSelectedOption] = React.useState();
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [progress, setProgress] = React.useState("");
    const [toDelete, setMaterialToDelete] = React.useState();
    const [uploadingName, setUploadingName] = React.useState('Create');
    const [classMaterials, setClassMaterials] = React.useState([]);
    const [files, setFiles] = useState([]);
    const { user } = React.useContext(UserContext)
    const theme = useTheme();
    const ref = React.useRef()
    const colors = [
        "#9c27b0",
        "#ff5722",
        "#d81b60",
        "#2196f3",
        "#009688",
        "#ffb300",
        "#fb8c00",
    ]
    const [formValues, setFormValues] = React.useState({
        "date": '',
        "end_date": new Date(classDate),
        "title": '',
        "course_id": '',
        "class_price": 4,
        "time": new Date('2014-08-18T09:00:00'),
        "end_time": new Date('2014-08-18T10:00:00'),
        "courseType": "Single lesson",
        "repeat_on": '',
        "require_approval": 'yes',
        "students_limit": '',
        "no_limit": '',
        "zoom_link": '',
        "color": '',
        "remarks": ''
    })

    const names = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const uploadFilesToS3 = (data, resetForm) => {
        setProgress(1)
        setUploadingName('Uploading files...')
        let currentProgress = 0;
        let percentages = []
        let uploaded = 0;
        if (files.length > 0) {
            files.map(async (singleFile, index) => {
                percentages.push(0)
                // var file = singleFile.file;
                // var aws_name = new Date().getTime() + '-' + singleFile.name
                const target = { Bucket: S3_BUCKET, Key: singleFile.aws_name, Body: singleFile }
                const parallelUploadS3 = new Upload({
                    client: new S3Client({ region: REGION, credentials: awsCreds }),
                    leavePartsOnError: false,
                    params: target
                });

                parallelUploadS3.on('httpUploadProgress', (evt) => {
                    currentProgress = Math.round((evt.loaded / evt.total) * 100)
                    percentages[index] = currentProgress
                    uploaded = 0;
                    percentages.map(percentage => {
                        uploaded += percentage;
                    })
                    setProgress(Math.round(uploaded / (percentages.length * 100) * 100))
                    if (Math.round(uploaded / (percentages.length * 100) * 100) == 100) {
                        setUploadingName('Creating class...')
                        storeClass(data, resetForm)
                    }
                })
                await parallelUploadS3.done(res => {
                    console.log(res)
                })
            })
        }
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: CreationSchema,
        onSubmit: (values, { resetForm }) => {
            let startTime = values.time
            startTime = (startTime.getHours() < 10 ? '0' + startTime.getHours() : startTime.getHours()) + ':' + (startTime.getMinutes() < 10 ? '0' + startTime.getMinutes() : startTime.getMinutes())
            let endTime = values.end_time
            endTime = (endTime.getHours() < 10 ? '0' + endTime.getHours() : endTime.getHours()) + ':' + (endTime.getMinutes() < 10 ? '0' + endTime.getMinutes() : endTime.getMinutes())
            let repeatedOn = null;
            if (values.courseType == 'Repeated lessons') {
                repeatedOn = formik.values.repeat_on
                // repeatedOn = personName.join('|')
            }

            let data = {
                "title": values.title,
                "course_id": values.course_id,
                "class_price": values.class_price,
                "color": values.color,
                "date": values.date,
                "time": startTime,
                "end_time": endTime,
                "repeat_on": repeatedOn,
                "ending_date": values.end_date.getFullYear() + '-' + (values.end_date.getMonth() < 9 ? 0 : '') + (values.end_date.getMonth() + 1) + '-' + values.end_date.getDate(),
                "google_meet_link": values.zoom_link,
                "require_approval": values.require_approval,
                "students_limit": values.students_limit,
                "no_limit": values.no_limit,
                "zoom_link": values.zoom_link,
                "venue": values.zoom_link,
                "teacher": user[0].user_id,
                "subscriber_id": "0",
                "materials": null
            }
            if (editClass) {
                data['class_id'] = editData._def.extendedProps?.class_id
            }

            if (files.length > 0) {
                let materials = files.map(file => {
                    return {
                        'course_id': values.course_id,
                        'type': file.type,
                        'title': file.name,
                        'aws_name': file.aws_name,
                    }
                })
                data.materials = materials
                uploadFilesToS3(data, resetForm)
            }
            else {
                storeClass(data, resetForm)
            }

        },
        validate: values => {
            // console.log(values)
        }
    });

    const storeClass = (data, resetForm) => {
        setLoading(true)
        setUploadingName('Creating...')
        Classes.create(data).then(res => {
            if (editClass) {
                data['class_id'] = editData._def.extendedProps.class_id
            } else {
                data['class_id'] = res.data.classId
            }
            data['start'] = data.date + 'T' + data.time
            data['end'] = data.date + 'T' + data.end_time
            // setEvents(prev => [
            //     ...prev,
            //     data
            // ])

            if (editClass) {
                organizeNewEvents(data, editData._def.extendedProps.class_id)
            }
            else {
                organizeNewEvents(data)
            }
            resetForm()
            handleClossSidebar()
            setUploadingName('Create')
            setProgress(0)
            setLoading(false)
        }, error => {
            console.log(error)
            setUploadingName('Create')
            setProgress(0)
        })
    }

    const containsText = (text, searchText) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const filteredCourses = React.useMemo(
        () => allCourses.filter((option) => containsText(option.course_name, searchText)),
        [searchText, allCourses]
    );

    React.useEffect(() => {
        formik.setFieldValue('date', classDate)
        // formik.setFieldValue('end_date', classDate)
        if (course_id) {
            formik.setFieldValue('course_id', course_id)
        }

        setClassDay(getDayFromDate(classDate))
        // if (formik.values.courseType == 'Repeated lessons') {
        // }
        let date = new Date(classDate)
        formik.setFieldValue('repeat_on', names[date.getDay()])
        // formik.setFieldValue('start_date', new Date(date))
    }, [classDate])

    React.useEffect(() => {
        if (editClass) {
            setUploadingName('Update')
            let data = {
                'class_id': editData._def.extendedProps?.class_id,
                'student_id': 0
            }

            Classes.getEnrolment(data).then(res => {
                console.log(res.data)
                setClassMaterials(res.data.materials)
            })
            console.log(getShortDate(editData._def.extendedProps?.time))

            formik.setFieldValue('end_date', new Date(getShortDate(editData._def.extendedProps?.ending_date)))
            formik.setFieldValue('title', editData._def?.title)
            formik.setFieldValue('class_price', editData._def.extendedProps?.class_price)
            formik.setFieldValue('students_limit', editData._def.extendedProps?.students_limit)
            formik.setFieldValue('zoom_link', editData._def.extendedProps?.zoom_link)
            formik.setFieldValue('time', new Date('2022-08-11T' + editData._def.extendedProps?.time))
            formik.setFieldValue('end_time', new Date('2022-08-11T' + editData._def.extendedProps?.end_time))
            formik.setFieldValue('courseType', editData._def.extendedProps?.repeat_on != null ? 'Repeated lessons' : 'Single lesson')
            formik.setFieldValue('color', editData.backgroundColor)
        }
    }, [])

    React.useEffect(() => {
        getProducts()
    }, [])

    React.useEffect(() => {
        if (!formik.isSubmitting) {
            return
        }

        let errors = Object.keys(formik.errors)
        if (errors.length > 0) {
            document.getElementsByName(Object.keys(formik.errors)[0])[0].focus()
        }
    }, [formik])

    function getDayFromDate(date) {
        date = new Date(date)
        return names[date.getDay()]
    }

    function getShortDate(date) {
        date = new Date(date)
        console.log(date.getMonth())
        return date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    }

    const handleFileAdded = (acceptedFiles) => {
        acceptedFiles.map((file) => {
            // Object.assign(file, {
            //     preview: URL.createObjectURL(file)
            // })

            Object.assign(file, {
                aws_name: new Date().getTime() + '-' + file.name
            })
            setFiles(prev => [...prev, file]);
        })
        document.getElementById('create-btn').focus()
    }

    function MaterialUploader(props) {

        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
        } = useDropzone({
            // accept: "image/*",
            onDrop: (acceptedFiles) => {
                handleFileAdded(acceptedFiles)
            }
        });

        const style = React.useMemo(
            () => ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {})
            }),
            [isDragActive, isDragReject, isDragAccept]
        );

        const customStyle = {
            height: 30,
            border: 'dashed',
            borderWidth: 1,
            borderColor: 'white',
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: 10,
            marginBottom: 10,
            padding: 5
        }

        const singleFile = {
            color: 'white',
            marginBottom: 10,
            backgroundColor: '#425970',
            padding: 10,
            borderRadius: 5
        }

        const thumbs = files.map((file, index) => (
            // <div style={thumb} key={file.name}>
            //     <div style={thumbInner}>
            //         <img alt="selected" src={file.preview} style={img} />
            //     </div>
            // </div>
            <div style={singleFile} key={file.name}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <DescriptionOutlinedIcon style={{ marginRight: 5, fontSize: 22 }} />
                            <Typography variant="body1">{file.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <HighlightOffOutlinedIcon onClick={() => handleRemoveFile(index)} style={{ color: '#e87b7b', fontSize: 22, cursor: 'pointer' }} />
                    </Grid>
                </Grid>
            </div>
        ));

        const handleRemoveFile = (remove) => {
            setFiles(files.filter((file, index) => index != remove))
        }

        useEffect(
            () => () => {
                // Make sure to revoke the data uris to avoid memory leaks
                files.forEach((file) => URL.revokeObjectURL(file.preview));
            },
            [files]
        );

        const check = () => {
            console.log(files)
        }

        return (
            <section className="container" >
                <aside style={thumbsContainer}>{thumbs}</aside>
                <div style={customStyle} {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                            <>
                                <PostAddIcon style={{ color: '#d6d6d6', fontSize: 24, marginRight: 10 }} />
                                <span style={{ color: '#d6d6d6' }}>Add material</span>
                            </>
                        )}
                </div>
            </section>
        );
    }

    const getProducts = (status = 1, orderBy = null, searchQuery = null, limit = 500, offset = 0) => {
        Products.getProductsByTeacherId(user[0]['user_id'], status, orderBy, searchQuery, limit, offset).then((res) => {
            setAllCourses(res.data.products)
        }, error => {

        })
    }

    const handletimeChange = (date) => {
        console.log(date)
        formik.setFieldValue('time', date)
    }

    const handleColorChange = (color) => {
        formik.setFieldValue('color', color)
    }

    const handleEndDateChange = (date) => {
        formik.setFieldValue('end_date', date)
    }

    const handleStartDateChange = (date) => {
        formik.setFieldValue('date', date)
    }

    const handleend_timeChange = (date) => {
        formik.setFieldValue('end_time', date)
    }

    const Materials = () => {
        return (
            classMaterials.map(material => (
                <>
                    <div style={{ backgroundColor: 'white', padding: 10, marginBottom: 10, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><DescriptionOutlinedIcon style={{ marginRight: 5, fontSize: 22 }} />{material.title}</div>
                        <div>
                            <Tooltip placement="top-end" title={<h4 style={{ margin: 0 }}>Delete material</h4>}>
                                <CloseOutlinedIcon onClick={() => { setMaterialToDelete(material.material_id); setOpenConfirmation(true) }} style={{ fontSize: 20, color: 'maroon', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </div>
                </>
            ))
        )
    }

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }



    const handleChange = (event) => {
        setPersonName(event.target.value);
        setSelectOpen(false)
    };

    const handleApprovalChange = (event, value) => {
        if (value != null) {
            formik.setFieldValue('require_approval', value)
        }
    };

    const handleAddStudentsNumber = () => {
        formik.setFieldValue('no_limit', false)
    }

    const handleLimitChange = (event) => {
        if (event.target.checked) {
            formik.setFieldValue('no_limit', true)
            formik.setFieldValue('students_limit', '')
        }
        else {
            formik.setFieldValue('no_limit', false)
        }
    };

    const handleConfirmDeletion = () => {
        setDeleting(true)
        Classes.deleteMaterial(toDelete).then(res => {
            setClassMaterials(classMaterials.filter(material => material.material_id != toDelete))
            setDeleting(false)
            setOpenConfirmation(false)
        }, err => {
            setDeleting(false)
        })
    }

    const ConfirmDeletion = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openConfirmation}
            >
                <DialogTitle id="confirmation-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon style={{ fontSize: 32, color: 'maroon', marginRight: 5 }} />
                        <Typography variant="h6" align="center">Delete material</Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6" align="center">Are you sure?</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleting} size="small" autoFocus onClick={() => setOpenConfirmation(false)} color='primary' variant="outlined">
                        No
                    </Button>
                    <Button disabled={deleting} size="small" onClick={handleConfirmDeletion} style={{ backgroundColor: 'maroon', color: 'white' }}>
                        {deleting ? 'Please wait...' : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Grid className="right-sidebar-scroller" style={{ height: "87vh", overflowY: 'scroll', overflowX: 'hide', backgroundColor: '#2C3F51', padding: 10, borderRadius: 10 }} item xs={12} lg={3}>
            <ConfirmDeletion />
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography align="left" variant="h6" style={{ color: 'white' }}>Create new class</Typography>
                <Icon onClick={handleClossSidebar} style={{ cursor: 'pointer', color: 'white' }}>close</Icon>
            </Grid>
            <Grid style={{ marginTop: 20 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing="2">
                        <Grid item xs="12" sm="6" lg="12">
                            {/* Start date */}
                            <>
                                <label className={classes.labelWrapper}>Start date</label>
                                <div className={classes.inputWrapper}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        {/* <InputBase
                                        name={'date'}
                                        fullWidth
                                        value={classDate}
                                        format="yyyy-MM-dd"
                                        placeholder="Select end date"
                                        onChange={formik.handleChange}
                                        // disabled={true}
                                        className={classes.inputBase}
                                    /> */}
                                        <KeyboardDatePicker
                                            margin="normal"
                                            fullWidth
                                            name={"date"}
                                            format="yyyy-MM-dd"
                                            placeholder="Select end date"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            id="time-picker"
                                            className={classes.inputBase}
                                            minDate={classDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            value={formik.values.date}
                                            onChange={handleStartDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </>
                        </Grid>

                        <Grid item xs="12" sm="6" lg="12">
                            {/* Course end date */}
                            <>
                                <label className={classes.labelWrapper}>End date*</label>
                                <div className={classes.inputWrapper}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            fullWidth
                                            name={"end_date"}
                                            minDate={formik.values.date}
                                            minDateMessage="End date should not be before the start date"
                                            format="yyyy-MM-dd"
                                            placeholder="Select end date"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            id="time-picker"
                                            className={classes.inputBase}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            value={formik.values.end_date}
                                            onChange={handleEndDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                {formik.touched.end_date && Boolean(formik.errors.end_date) ? (
                                    <div style={errorMessage}>{formik.errors.end_date}</div>
                                ) : null}
                            </>
                        </Grid>
                    </Grid>

                    {/* Select course */}
                    <>
                        <label className={classes.labelWrapper}>Select course*</label>
                        <div className={classes.inputWrapper}>
                            <Select
                                MenuProps={{ autoFocus: false, classes: { paper: classes.menuPaper } }}
                                labelId="search-select-label"
                                id="search-select"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="course_id"
                                disabled={!courseEditable ? true : false}
                                onChange={(e) => formik.setFieldValue('course_id', e.target.value)}
                                value={formik.values.course_id}
                                onClose={() => setSearchText("")}
                            // renderValue={() => formik.values.course_id}
                            >
                                <ListSubheader style={{ backgroundColor: 'white' }}>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Type to search..."
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                                {filteredCourses.map((option, i) => (
                                    <MenuItem key={i} value={option.course_id}>
                                        {option.course_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        {formik.touched.course_id && Boolean(formik.errors.course_id) ? (
                            <div style={errorMessage}>{formik.errors.course_id}</div>
                        ) : null}
                    </>

                    {/* Class Name */}
                    <>
                        <label className={classes.labelWrapper}>Class name*</label>
                        <div className={classes.inputWrapper}>
                            <InputBase
                                variant="transparent"
                                name={'title'}
                                fullWidth
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                className={classes.inputBase}
                                placeholder="Class name"
                            />
                        </div>
                        {formik.touched.zoom_link && Boolean(formik.errors.zoom_link) ? (
                            <div style={errorMessage}>{formik.errors.zoom_link}</div>
                        ) : null}
                    </>

                    <Grid container spacing="2">
                        <Grid item xs="12" sm="4" lg="12">
                            {/* Class Price */}
                            <>
                                <label className={classes.labelWrapper}>Price*</label>
                                <div className={classes.inputWrapper}>
                                    <Typography style={{ marginLeft: 5 }} variant="body2">$(HKD)</Typography>
                                    <InputBase
                                        name={'class_price'}
                                        fullWidth
                                        value={formik.values.class_price}
                                        onChange={formik.handleChange}
                                        className={classes.inputBase}
                                    />
                                </div>
                                {formik.touched.class_price && Boolean(formik.errors.class_price) ? (
                                    <div style={errorMessage}>{formik.errors.class_price}</div>
                                ) : null}
                            </>
                        </Grid>
                        <Grid item xs="12" sm="5" lg="12">
                            {/* User limit and approval */}
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <label className={classes.labelWrapper}>No. of students</label>
                                    <div className={formik.values.no_limit ? classes.inputBaseDisabled : classes.inputNumberWrapper}>
                                        <InputBase
                                            fullWidth
                                            disabled={formik.values.no_limit}
                                            type="number"
                                            name={'students_limit'}
                                            value={formik.values.students_limit}
                                            onChange={(e) => {
                                                handleAddStudentsNumber();
                                                formik.handleChange(e)
                                            }
                                            }
                                            className={classes.inputBase}
                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox style={{ color: 'white' }} name="checkedA" />}
                                        style={{ color: 'white' }}
                                        label="No limit"
                                        name={'no_limit'}
                                        checked={formik.values.no_limit}
                                        onChange={handleLimitChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="12" sm="3" lg="12">
                            {/* Required approval */}
                            <Grid>
                                <label className={classes.labelWrapper}>Require approval?</label>
                                <Paper elevation={0} className={classes.paper}>
                                    <StyledToggleButtonGroup
                                        size="small"
                                        name={'require_approval'}
                                        value={formik.values.require_approval}
                                        onChange={handleApprovalChange}
                                        exclusive
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton value={'no'} aria-label="left aligned">
                                            No
                                </ToggleButton>
                                        <ToggleButton value={'yes'} aria-label="centered">
                                            Yes
                                </ToggleButton>
                                    </StyledToggleButtonGroup>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>



                    {/* Course Color */}
                    <>
                        <label className={classes.labelWrapper}>Select color*</label>
                        <Grid style={{ marginTop: 10, marginBottom: 15 }} container justifyContent="start" alignItems="center">
                            {
                                colors.map(color => (
                                    <span onClick={() => handleColorChange(color)} style={{ margin: 2, cursor: 'pointer', width: theme.spacing(4), height: theme.spacing(4), backgroundColor: color, border: "solid", borderColor: formik.values.color == color ? 'white' : 'transparent', borderWidth: 3 }}></span>
                                ))
                            }
                        </Grid>
                        {formik.touched.color && Boolean(formik.errors.color) ? (
                            <div style={errorMessage}>{formik.errors.color}</div>
                        ) : null}
                    </>

                    {/* Start and End time */}
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs="12" sm="3" lg="6">
                            <label className={classes.labelWrapper}>Start time*</label>
                            <div className={classes.inputWrapper}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        fullWidth
                                        name={"time"}
                                        keyboardIcon={<AccessTimeIcon />}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        id="time-picker"
                                        className={classes.inputBase}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        value={formik.values.time}
                                        onChange={handletimeChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid item xs="12" sm="3" lg="6">
                            <label className={classes.labelWrapper}>End time*</label>
                            <div className={classes.inputWrapper}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        fullWidth
                                        name={"end_time"}
                                        keyboardIcon={<AccessTimeIcon />}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        id="time-picker"
                                        className={classes.inputBase}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        value={formik.values.end_time}
                                        onChange={handleend_timeChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid item xs="12" sm="6" lg="12">
                            {/* Course type */}
                            <FormControl component="fieldset">
                                <label className={classes.labelWrapper}>{"Course type"}</label>
                                <RadioGroup row aria-label="course-type" name={'courseType'} value={formik.values.courseType} onChange={(e) => {
                                    formik.handleChange(e);
                                    if (e.target.value) {
                                        formik.setFieldValue('repeat_on', classDay)
                                    }
                                }}>
                                    <FormControlLabel className={classes.labelWrapper} value={'Single lesson'} control={<Radio />} label={"Single lesson"} />
                                    <FormControlLabel className={classes.labelWrapper} value={'Repeated lessons'} control={<Radio />} label={"Repeated lessons"} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>


                    {/* Course repeat on */}
                    {
                        formik.values.courseType == 'Repeated lessons' &&
                        <FormControl style={{ width: '100%', marginTop: 10, marginBottom: 25, }} component="fieldset">
                            <label style={{ marginBottom: 0, color: 'white' }}>{"Repeat on"}</label>
                            <div className={classes.inputWrapper}>
                                <InputBase
                                    name={'repeat_on'}
                                    readOnly
                                    fullWidth
                                    value={formik.values.repeat_on}
                                    onChange={formik.handleChange}
                                    className={classes.inputBase}
                                    placeholder="Repeat on"
                                />
                            </div>
                            {/* <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                ref={ref}
                                style={{ backgroundColor: 'white', borderRadius: 5, border: "solid", borderColor: '#727F8A' }}
                                fullWidth
                                value={personName}
                                onChange={handleChange}
                                open={selectOpen}
                                onOpen={() => setSelectOpen(true)}
                                onClose={() => setSelectOpen(false)}
                                input={<Input id="select-multiple-chip" disableUnderline={true} />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            > */}
                            {/* {classDay.map((name) => ( */}
                            {/* <MenuItem key={classDay} value={classDay} style={getStyles(classDay, personName, theme)}>
                                    {classDay}
                                </MenuItem> */}
                            {/* ))} */}
                            {/* </Select> */}
                        </FormControl>
                    }

                    {/* Zoom link */}
                    <>
                        <label className={classes.labelWrapper}>Venue / Zoom link*</label>
                        <div className={classes.inputWrapper}>
                            <InputBase
                                name={'zoom_link'}
                                fullWidth
                                value={formik.values.zoom_link}
                                onChange={formik.handleChange}
                                className={classes.inputBase}
                                placeholder="Enter venue / Zoom link"
                            />
                        </div>
                        {formik.touched.zoom_link && Boolean(formik.errors.zoom_link) ? (
                            <div style={errorMessage}>{formik.errors.zoom_link}</div>
                        ) : null}
                    </>

                    {/* Remarks */}
                    <>
                        <label className={classes.labelWrapper}>Remarks</label>
                        <div className={classes.inputWrapper}>
                            <InputBase
                                name={'remarks'}
                                fullWidth
                                multiline
                                rows={3}
                                value={formik.values.remarks}
                                onChange={formik.handleChange}
                                className={classes.inputBase}
                                placeholder="Enter remarks"
                            />
                        </div>
                    </>

                    {/* Upload Material */}
                    <div>
                        <label className={classes.labelWrapper}>Materials</label>
                        <div style={{ marginTop: 10 }}>
                            <Materials />
                        </div>
                        <MaterialUploader />
                    </div>
                    {
                        // (progress > 0 && progress != null) &&
                        // <Typography style={{ marginTop: 5, marginBottom: 5, color: 'white', textAlign: 'center' }} variant="h4">{progress + '%'}</Typography>
                    }
                    <Button id="create-btn" style={{ width: '100%', borderRadius: 5, backgroundColor: '#1A95AC', color: 'white' }} type="submit">{uploadingName}</Button>
                </form>
            </Grid>
        </Grid>
    )
}