import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { BookingContext } from "../../../context/BookingContext";
import Checkout from "../../checkout/checkout";

export default function BookingModal({ openModal, handleClose, onSuccess }) {
  const { bookedTrialsObject, bookedTrials, clearSelection } =
    React.useContext(BookingContext);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let total = 0;
    bookedTrialsObject.forEach((trial) => {
      total += parseFloat(trial.price);
    });
    console.log(bookedTrialsObject);
    setTotalPrice(total);
  }, [bookedTrials]);

  const handleConfirmation = () => {
    clearSelection();
    setLoading(false);
    handleClose();
    onSuccess();
  };

  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm your trial booking"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            loading && (
              <h3 style={{ textAlign: "center" }}>Booking your trial..</h3>
            )
            // :
            // (
            // )
          }
          {totalPrice > 0 && (
            <Checkout
              for={"booking"}
              handleLoading={handleLoading}
              handleConfirmation={handleConfirmation}
              trials={bookedTrialsObject}
              total={totalPrice}
            />
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
