import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
    searchBarSmall: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'solid',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        paddingRight: 5,
        padding: 5,
        borderRadius: 25,
        marginTop: 10, marginLeft: 5, marginRight: 5
    },
    searchForm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    searchIconSmall: {
        fontSize: 20,
        color: '#d1d1d1'
    },
    searchFieldSmall: {
        width: '85%',

    },


}))

export default function SearchLevel(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);

    const onChangeHandler = event => {
        const name = event.target.name
        setValue(event.target.value)
        props.handleLevelsSearch(event.target.value)
    }

    return (
        <div className={classes.searchBarSmall}>
            <form className={classes.searchForm}>
                <input type='text' id="outlined-basic" value={value} onChange={onChangeHandler} className={classes.searchFieldSmall} placeholder="Search" />
                <Icon className={classes.searchIconSmall}>search</Icon>
            </form>
        </div>
    )
}
