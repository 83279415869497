import React from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';
import InputBase from '@material-ui/core/InputBase';
import Icon from '@material-ui/core/Icon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 60,
        minHeight: '65vh',
        paddingLeft: 0,
        paddingRight: 0
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#24646F'
    },
    headingQuestion: {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: 'bold',
        color: '#F99C24',
        marginRight: 15
    },
    pageTitle: {
        marginBottom: 50
    }
}));


export default function HeroSection() {
    const classes = useStyles();
    let [query, setValue] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Container maxWidth="md">
            <div className={classes.root}>
                <Typography variant="h3" className={classes.pageTitle} align="center">Frequency Asked Questions (FAQ)</Typography>
                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container alignItems="center">
                            <Typography className={classes.headingQuestion}>Q1.</Typography>
                            <Typography className={classes.heading}>How Can I buy a product?</Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Grid container alignItems="center">
                            <Typography className={classes.headingQuestion}>Q2.</Typography>
                            <Typography className={classes.heading}>For how long I can have an access to the products I bought?</Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Grid container alignItems="center">
                            <Typography className={classes.headingQuestion}>Q3.</Typography>
                            <Typography className={classes.heading}>What Payment Methods I can use to but the products?</Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    )
}
