import React from 'react'
import Flag from 'react-world-flags'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
    flagWrapper:{
        width:20,
        height:20,
        borderRadius:30,
        position:'absolute',
        bottom:15,
        left:-5,
        overflow:'hidden',
        display:'flex',
        justifyContent:'center',
        border:'solid 2px white'
    }
}))


export default function Country({country}) {
    const classes = useStyle()
  return (
    <div className={classes.flagWrapper}>
      <Flag code={country} height="20"  />
    </div>
  )
}
