import { DataBrew } from "aws-sdk";
import axios from "axios";
import { useJwt } from "react-jwt";
const token = localStorage.getItem("jwt_token");
const url = process.env.REACT_APP_API_URL + "api/";
const corsUrl = process.env.REACT_APP_API_URL;
const Auth = {
  getUserById: function (id) {
    return axios({
      method: "GET",
      url: url + "teacher/my-profile",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      params: {
        id: id,
      },
    });
  },

  deleteTimeSlot: function (id, teacher, trial) {
    return axios({
      method: "POST",
      url: url + "teacher/trial/slot/delete",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: {
        slot_id: id,
        teacher_id: teacher,
        trial_id: trial,
      },
    });
  },

  isCommentable: function (teacher, student) {
    return axios({
      method: "POST",
      url: url + "student/commentable",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: {
        teacher_id: teacher,
        student_id: student,
      },
    });
  },

  getFollowers: function (id) {
    return axios({
      method: "GET",
      url: url + "teacher/followers",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      params: {
        id: id,
      },
    });
  },

  getConnectDetails: function (id) {
    return axios({
      method: "POST",
      url: url + "teacher/connect/details",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: {
        user_id: id,
      },
    });
  },

  getIncome: function (teacher) {
    return axios({
      method: "POST",
      url: url + "teacher/income",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: {
        teacher_id: teacher,
      },
    });
  },

  followShop: function (data) {
    return axios({
      method: "POST",
      url: url + "following",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  unFollowShop: function (id) {
    return axios({
      method: "DELETE",
      url: url + "following/" + id,
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
    });
  },

  getFollowings: function (id) {
    return axios({
      method: "GET",
      url: url + "following/" + id + "/edit",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
    });
  },

  uploadDocuments: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/upload-documents",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  getShopByUsername: function (username) {
    return axios({
      method: "GET",
      url: url + "teacher/shop/" + username,
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
    });
  },

  getFilterProducts: function (filter) {
    return axios({
      method: "POST",
      url: url + "teacher/products/filter",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: filter,
    });
  },

  deleteQualification: function (id) {
    return axios({
      method: "GET",
      url: url + "teacher/delete/qualification",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      params: {
        qualification: id,
      },
    });
  },
  getShops: function (type) {
    return axios({
      method: "GET",
      url: url + "teachers/shops",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      params: {
        orderBy: type,
      },
    });
  },

  // Get List of all products
  getAllTeachers: function (status, limit, offset) {
    return axios({
      method: "POST",
      url: url + "teachers/list",
      headers: {
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: {
        limit: limit,
        offset: offset,
      },
    });
  },

  updateTeacherById: function (teacher) {
    return axios({
      method: "POST",
      url: url + "teacher/update",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: teacher,
    });
  },

  addLessons: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/addLessons",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  getLessonsByTeacherId: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/getLessonsByTeacherId",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },
  deleteLessonsByTeacherId: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/deleteLessonsByTeacherId",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },
  editLessonsByTeacherId: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/editLessonsByTeacherId",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  addSlider: function (slider, user) {
    return axios({
      method: "POST",
      url: url + "teacher/add/slider/" + user,
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: slider,
    });
  },

  filterTeachers: function (filter, limit, offset) {
    return axios({
      method: "POST",
      url: url + "teacher/filter",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: { ...filter, limit: limit, offset: offset },
    });
  },

  followATeacher: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/follow",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  getTrialBookings: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/trial/bookings",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },
  getBookingsByTrial: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/trial/students",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },
  updateStudentAttendance: function (data) {
    return axios({
      method: "POST",
      url: url + "student/attendance",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },
  getStudentsTrialBookings: function (data) {
    return axios({
      method: "POST",
      url: url + "student/bookings",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  unFollowATeacher: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/unfollow",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  storeBooking: function (data) {
    return axios({
      method: "POST",
      url: url + "bookings",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  deleteSlide: function (slide) {
    return axios({
      method: "POST",
      url: url + "teacher/delete/slide",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: { id: slide },
    });
  },

  deleteTrialPrice: function (price, teacher) {
    return axios({
      method: "POST",
      url: url + "teacher/delete/trial/price",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: { price: price, teacher: teacher },
    });
  },

  deleteLanguage: function (language, teacher) {
    return axios({
      method: "POST",
      url: url + "teacher/delete/language",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: { language: language, teacher: teacher },
    });
  },

  getDetailsById: function (teacher) {
    return axios({
      method: "POST",
      url: url + "teacher/details",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: { teacher_id: teacher },
    });
  },

  addComment: function (data) {
    return axios({
      method: "POST",
      url: url + "teacher/reviews",
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
      data: data,
    });
  },

  getCommentsByTeacher: function (teacher) {
    return axios({
      method: "GET",
      url: url + "teacher/reviews/" + teacher,
      headers: {
        "content-type": "application/octet-stream",
        "content-type": "application/json",
        "content-type": "text/plain",
      },
    });
  },

  getSlider: function (user) {
    return axios({
      method: "GET",
      url: url + "teacher/get/slides/" + user,
      headers: {
        "content-type": "application/json",
      },
    });
  },
};

export default Auth;
