import { Box, Button, Card, Typography, Icon, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import TeacherService from "../../../services/teacher";
import { useState } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { red } from "@material-ui/core/colors";
import Colors from "./colors.json";
import axios from "axios";
import { IoMdAddCircleOutline } from "react-icons/io";
const url = process.env.REACT_APP_API_URL + "api/";

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const SingleSlot = ({
  slot,
  index,
  title,
  lesson,
  color,
  lessonIndex,
  // handleRemoveSlot,
  handleEditSlot,
  editActutalSlot,
  date,
  listOfLessons,
  deleteTimeSlot,
}) => {
  const [newTime, setNewTime] = useState(slot.trial_time);
  const [edit, setEdit] = useState(false);
  const format = "HH:mm";
  const handleNewTimeChange = (time) => {
    const hour = time.hour() < 10 ? "0" + time.hour() : time.hour();
    const minutes = time.minute() < 10 ? "0" + time.minute() : time.minute();

    setNewTime(`${hour}:${minutes}`);
    // alert(dayjs(new Date()));
  };
  let dates = dayjs(date).get("date");
  let month = months[dayjs(date).get("month")];
  let year = dayjs(date).get("year");
  // console.log(lessonIndex,8%2)
  // const newdate = `${dayjs(date).get("date")} ${months[dayjs(date).get("month")]} ${dayjs(date).get("year")}`

  const handleRemoveSlot = () => {
    const data = {
      slot_id: slot.slot_id,
    };

    TeacherService.deleteLessonsByTeacherId(data).then((res) => {
      console.log(res);
      window.location.reload();
    });
    // console.log("slot.slot_id", slot.slot_id);
    // console.log("slot", slot);
    // console.log(lessonIndex, index, lesson.date, slot.slot_id);
    // console.log("lesson", lesson);
    // console.log("listOfLessons", listOfLessons);

    // console.log(
    //   "listOfLessons[lessonIndex][index].slot_id",
    //   listOfLessons[lessonIndex].slots[index].slot_id
    // );

    // let tempListOfLessons = listOfLessons;
    // if (listOfLessons[lessonIndex].slots[index].slot_id == slot.slot_id) {
    // console.log("tempListOfLessons before", tempListOfLessons);
    // let removed = tempListOfLessons[lessonIndex].slots.splice(index, 1);
    // console.log("removed", removed);
    // console.log("tempListOfLessons after", tempListOfLessons);
    // }
    // const {slot_id, teacher_id, trial_id } = listOfLessons[lessonIndex].slots[index];
    // console.log(slot_id, teacher_id, trial_id );
  };

  return (
    <Box
      key={{ index, lessonIndex }}
      sx={{ display: "flex", flexWrap: "wrap" }}
      style={{
        // padding: 7,
        // border: "1px solid gray",
        // borderRadius: 5,
        marginRight: 5,
        // marginBottom: 5,
        position: "relative",
      }}
      // onClick={handleEditSlot}
    >
      <Box
        sx={{
          backgroundColor: color,
          color: "whitesmoke",
          width: "50px",
          // paddingTop:"10px"
        }}
      >
        <span
          style={{
            // position: "absolute",
            // top: 0,
            // left: 2,
            cursor: "pointer",
          }}
          onClick={handleEditSlot}
        >
          {/* <EditIcon style={{ fontSize: "18px", color: "whitesmoke" }} /> */}
          <IoMdAddCircleOutline />
        </span>

        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "1",
          }}
        >
          {dates}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: "bold",
            lineHeight: "0.5",
          }}
        >
          {month}
        </Typography>
        <Typography
          sx={{ display: "flex", justifyContent: "center", lineHeight: "1.2" }}
        >
          {year}
        </Typography>
        {/* <Typography
          sx={{ display: "flex", justifyContent: "center", lineHeight: "1" }}
        >
          {slot.trial_time}
        </Typography> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginLeft: "10px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            {slot.duration}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex" }}>
            <AccessTimeIcon
              style={{ fontSize: 16, marginLeft: "10px", marginTop: "4px" }}
            />
            {edit ? (
              <TimePicker
                format={format}
                minuteStep={15}
                defaultValue={dayjs(slot.trial_time, format)}
                size="small"
                style={{ width: "80px", marginLeft: 5 }}
                onChange={(time) => handleNewTimeChange(time)}
              />
            ) : (
              <span style={{ marginLeft: "2px" }}>{slot.trial_time}</span>
            )}
          </Box>
          <Box
            sx={{ marginLeft: "10px" }}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GroupOutlinedIcon color={"gray"} />
            {slot.seats}
          </Box>
        </Box>
      </Box>
      <Tooltip placement="top" title="Delete class" arrow="true">
        <span
          style={{
            position: "absolute",
            top: 2,
            right: 2,
            cursor: "pointer",
          }}
          onClick={() =>
            handleRemoveSlot(lessonIndex, index, lesson.date, slot.slot_id)
          }
        >
          <HighlightOffIcon style={{ fontSize: "18px", color: "red" }} />
        </span>
      </Tooltip>

      {!edit ? (
        <span
          style={{
            position: "absolute",
            bottom: 0,
            right: 2,
            cursor: "pointer",
          }}
          onClick={() => setEdit(true)}
        >
          <EditIcon style={{ fontSize: "18px", color: "gray" }} />
        </span>
      ) : (
        <span
          style={{
            position: "absolute",
            top: 25,
            right: 2,
            cursor: "pointer",
          }}
          onClick={() => {
            editActutalSlot(
              lessonIndex,
              index,
              lesson.date,
              slot.slot_id,
              newTime
            );
            setEdit(false);
          }}
        >
          <SaveIcon style={{ fontSize: "18px", color: "green" }} />
        </span>
      )}
    </Box>
  );
};

const ListOfTeachersLessons = ({
  user,
  monthWiseLessons,
  handleRemoveSlot,
  handleRemoveLesson,
  editActutalSlot,
  deleteTimeSlot,
  editTimeSlot,
  handleEditSlot,
}) => {
  // console.log(monthWiseLessons);
  const [listOfLessons, setListOfLessons] = useState(monthWiseLessons);

  useEffect(() => {
    setListOfLessons(monthWiseLessons);
  }, [monthWiseLessons]);
  // const handleEditSlot = (lesson) => {
  //   console.log(lesson);
  // };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          columnGap: "20px",
        }}
      >
        {listOfLessons.map((lesson, lessonIndex) => {
          const randomColor = Colors[Math.floor(Math.random() * Colors.length)];
          return (
            <>
              {lesson.slots.map((slot, index) => (
                <Card sx={{ mt: "10px", width: "32.1%" }}>
                  <SingleSlot
                    key={index}
                    date={lesson.date}
                    title={lesson.duration}
                    slot={slot}
                    lesson={lesson}
                    color={randomColor}
                    lessonIndex={lessonIndex}
                    index={index}
                    editActutalSlot={editActutalSlot}
                    handleRemoveSlot={handleRemoveSlot}
                    listOfLessons={listOfLessons}
                    setListOfLessons={setListOfLessons}
                    handleEditSlot={() => handleEditSlot(lesson)}
                  />
                </Card>
              ))}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default ListOfTeachersLessons;
