import React from 'react'
import Checkout from './checkout'
import Wallet from './wallet'
import { useJwt } from 'react-jwt';
import AuthForm from '../auth/SingleSignIn'
import { UserContext } from '../../context/UserContext';
import Grid from '@material-ui/core/Grid';

export default function Payment({ total, itemName, handleConfirmation }) {
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);

    var lineItems = [
        {
            label: itemName,
            amount: total * 100
        }
    ]

    return (
        <Grid item xs={12} sm={12}>
            {!isExpired ?
                <>
                    <Checkout confirmDialog={handleConfirmation} total={total} />
                    <p style={{ textAlign: 'center', color: '#c1c1c1', marginTop: 10, marginBottom: 10, fontSize: 14 }}>Or pay with</p>
                    <Wallet lineItems={lineItems} confirmDialog={handleConfirmation} total={total * 100} />
                </>
                :
                <AuthForm />
            }

        </Grid>
    )
}