import React from 'react'
import "./style.css"
import { Button } from '@material-ui/core'

function BackButton({ handleClose }) {
  return (
    <div>
      <Button onClick={handleClose} variant="outlined" className='backButton'>
        Back to teacher's page
      </Button>
    </div>
  )
}

export default BackButton