import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Forgotpassword from './Forgotpassword';
import * as Mui from '@material-ui/core';
import * as Mul from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../../services/auth';
import AdminService from '../../services/admin';
import { UserContext } from '../../context/UserContext';
import { BuyNowContext } from '../../context/BuyNowContext';
import { FavouriteContext } from '../../context/FavouriteContext';
import jwt_decode from "jwt-decode";
import SocialAuth from './SocialAuth';
import SocialWidgets from './SocialWidgets';
import ProductService from '../../services/products';
import roles from '../../routes/roles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginBottom: 0,
        width: '40%'
    },
    avatarReg: {
        marginBottom: 0,
        width: '20%'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formReg: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
        width: '100%'
    },
    errorText: {
        color: 'red'
    },
    socialBtn: {
        width: '99%',
        backgroundColor: 'transparent',
        border: 'none'
    },
    socialImg: {
        width: '100%'
    },
    socialAuthWrapper: {
        width: '110%',
        marginBottom: 30,
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    socialAuth: {
        backgroundColor: 'transparent',
        border: 'none',
    },
    errorText: {
        color: 'red'
    },
    dontHaveBtn: {
        fontSize: 14,
        marginLeft: 10,
        color: '#3f51b5',
    },
    regFields: {
        margin: 0,
    },
    resentEmailText: {
        color: '#3f51b5',
        cursor: 'pointer',
    }
}));

const CheckMemo = React.memo(SocialWidgets)

export default function SignIn(props) {
    const classes = useStyles();
    const [username, setUsername] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [confirmPassword, setConfirmPassword] = React.useState(null)
    const [fullname, setFullname] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [newemail, setNewEmail] = React.useState(null)
    const [firstName, setFirstName] = React.useState(null)
    const [lastName, setLastName] = React.useState(null)
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { role } = React.useContext(UserContext)
    const [userRole, setUserRole] = role;
    const { notification } = React.useContext(UserContext)
    const [teacherNotification, setTeacherNotification] = notification;
    const { user } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const { favouriteItem } = React.useContext(FavouriteContext);
    const [favouriteItems, setFavouriteItems] = favouriteItem;
    const { auth } = React.useContext(BuyNowContext);
    const [isLoggedIn, setIsLoggedIn] = auth;
    const [notVerified, setNotVerified] = React.useState(false)
    const [notVerifiedUser, setNotVerifiedUser] = React.useState(null)
    const [sendingVerification, setSendingVerification] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(false);
    const [form, setForm] = React.useState('signin')
    const [registrationError, setRegErrors] = React.useState([])
    const [loginError, setLoginErrors] = React.useState({})
    const [loginMessage, setLoginMessage] = React.useState(null)
    const [registering, setRegistering] = React.useState(false)
    const [registeredUser, setRegisteredUser] = React.useState(null)
    const [success, setSuccess] = React.useState(false)
    const [forgotPassword, setForgotPassword] = React.useState(false)

    let checkingVal = true;
    const mounted = React.useRef(true)
    let authType = 'signin'

    const isValidationError = error => {
        console.log(typeof (error))
        try {
            JSON.parse(error);

        } catch (e) {
            return true;
        }
        return true;
    }

    const handleForgotPassword = (show) => {
        setForgotPassword(show)
    }

    const handleLogin = () => {
        setNotVerified(false)
        setLoading(true)
        setLoginMessage(null)
        let user = {
            "username": username,
            "password": password
        }
        Auth.loginUser(username, password).then((response) => {
            if (response.data.status == 200 && !response.data.error) {
                let decodedToken = jwt_decode(response.data.data.token)
                ProductService.getFavourites(decodedToken.user_id).then(res => {
                    setFavouriteItems(res.data)
                    Auth.getUserById(decodedToken.user_id).then(res => {
                        setCurrentUser(res.data)
                        setToken(response.data.data.token)
                        localStorage.setItem('user_id', decodedToken.user_id)
                        localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
                        localStorage.setItem('auth_id', decodedToken.auth_id)
                        localStorage.setItem('jwt_token', response.data.data.token)
                        setIsLoggedIn(true)
                        setUserRole(roles[decodedToken.auth_type])
                        if (decodedToken.auth_type == 4)
                            setNotifications(decodedToken.auth_id)

                        if (props.redirect == 'home')
                            window.location.href = '/'
                    })
                })
                setLoading(false)
            }
            else {
                setLoading(false)
                if (typeof (response.data.message) === 'object') {
                    setLoginErrors(response.data.message)
                }
                else {
                    setLoginMessage(response.data.messages)
                    setNotVerified(response.data.status == 405)
                    setNotVerifiedUser(response.data.user)
                }
            }
        })
    }

    const handleLoginKey = (e) => {
        if (e.keyCode == 13) {
            setNotVerified(false)
            setLoading(true)
            setLoginMessage(null)
            let user = {
                "username": username,
                "password": password
            }
            Auth.loginUser(username, password).then((response) => {
                if (response.data.status == 200 && !response.data.error) {
                    let decodedToken = jwt_decode(response.data.data.token)
                    ProductService.getFavourites(decodedToken.user_id).then(res => {
                        setFavouriteItems(res.data)
                        Auth.getUserById(decodedToken.user_id).then(res => {
                            setCurrentUser(res.data)
                            setToken(response.data.data.token)
                            localStorage.setItem('user_id', decodedToken.user_id)
                            localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
                            localStorage.setItem('auth_id', decodedToken.auth_id)
                            localStorage.setItem('jwt_token', response.data.data.token)
                            setIsLoggedIn(true)
                            setUserRole(roles[decodedToken.auth_type])
                            if (decodedToken.auth_type == 4)
                                setNotifications(decodedToken.auth_id)

                            if (props.redirect == 'home')
                                window.location.href = '/'
                        })
                    })
                    setLoading(false)


                }
                else {
                    setLoading(false)
                    if (typeof (response.data.message) === 'object') {
                        setLoginErrors(response.data.message)
                    }
                    else {
                        setLoginMessage(response.data.messages)
                        setNotVerified(response.data.status == 405)
                        setNotVerifiedUser(response.data.user)
                    }
                }
            })
        }
    }

    const setNotifications = (id) => {

        AdminService.getTeacherDocumentRequest(id).then(res => {
            console.log(res)
            if (res.data.length > 0) {
                setTeacherNotification(true)
            }
            else {
                setTeacherNotification(false)
            }
        })

    }

    const handleRegistration = () => {
        setRegistering(true)
        setRegErrors([])
        let user = {
            "username": username,
            "password": password,
            "confirmpassword": confirmPassword,
            "email": newemail,
            "first_name": firstName,
            "last_name": lastName,
            "subscriber_id": 0,
        }
        Auth.register(user).then((response) => {
            setRegistering(false)
            if (response.data.status == 200) {
                setRegisteredUser(response.data)
                // Auth.loginUser(user.username, user.password).then((response) => {
                //     if (response.data.status == 200 && !response.data.error) {
                //         // console.log(response.data.data.user)
                //         // setCurrentUser(response.data.data.user)
                //         // setToken(response.data.data.token)
                //         // console.log(response.data.data.user)
                //         // let decodedToken = jwt_decode(response.data.data.token)
                //         // localStorage.setItem('user_id', decodedToken.user_id)
                //         // localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
                //         // localStorage.setItem('auth_id', decodedToken.auth_id)
                //         // localStorage.setItem('jwt_token', response.data.data.token)
                //         // setUserRole(roles[decodedToken.auth_type])
                //         // if (decodedToken.auth_type == 4)
                //         //     setNotifications(decodedToken.auth_id)


                //     }
                // })
                setSuccess(true)
            }
            else {
                if (response.data.message)
                    setRegErrors(response.data.message)
            }
        })
    }

    const handleRegistrationKey = (e) => {
        if (e.keyCode == 13) {
            setRegErrors([])
            setRegistering(true)
            let user = {
                "username": username,
                "password": password,
                "confirmpassword": confirmPassword,
                "first_name": firstName,
                "last_name": lastName,
                "email": newemail,
                "subscriber_id": 0,
            }
            Auth.register(user).then((response) => {
                setRegistering(false)
                if (response.data.status == 200) {
                    setRegisteredUser(response.data)
                    // Auth.loginUser(user.username, user.password).then((response) => {
                    //     if (response.data.status == 200 && !response.data.error) {
                    //         setToken(response.data.data.token)
                    //         setCurrentUser(response.data.data.user)
                    //         let decodedToken = jwt_decode(response.data.data.token)
                    //         localStorage.setItem('user_id', decodedToken.user_id)
                    //         localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
                    //         localStorage.setItem('auth_id', decodedToken.auth_id)
                    //         localStorage.setItem('jwt_token', response.data.data.token)

                    //     }
                    // })
                    setSuccess(true)
                }
                else {
                    if (response.data.message)
                        setRegErrors(response.data.message)
                }
            })
        }
    }

    React.useEffect(() => {
        if (mounted.current) {
            mounted.current = false
        }
        if (form == 'register') {
            setRegErrors([])
        }

    }, [form, username, password])


    const changeAuth = (auth) => {
        setForm(auth)
    }

    const handleResendEmail = () => {
        // console.log(teacherId)
        Auth.resendVerificationEmail(registeredUser.data.user_id).then(res => {
            // console.log(res)
            // Here
            setShowAlert(true)
        })
    }
    const handleResendVerificationEmail = () => {
        // console.log(teacherId)
        setSendingVerification(true)
        Auth.resendVerificationEmail(notVerifiedUser).then(res => {
            // console.log(res)
            // Here
            setNotVerified(false)
            setSendingVerification(false)
            setLoginMessage(null)
            setShowAlert(true)
        })
    }

    const AlertMessage = (props) => {
        return (
            <Mui.Snackbar
                open={showAlert} onClose={() => { setShowAlert(false) }}
                autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Mul.Alert severity={props.type} sx={{ width: '100%' }} onClose={() => { setShowAlert(false) }}>
                    {props.message}
                </Mul.Alert>
            </Mui.Snackbar>
        )
    }

    return (

        <>
            <AlertMessage message="Email sent!" type="success" />
            {
                forgotPassword ?
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Forgotpassword handleForgotPassword={handleForgotPassword} />
                        </Grid>
                    </Grid>
                    :
                    success ?

                        <Grid item xs="11" sm="12">
                            <div className="success-card">
                                <div className="check-wrapper">
                                    <Icon className="check-icon">check</Icon>
                                </div>
                                <Typography className="text-dark" variant="h6">Thank You for registering with us.</Typography>
                                <Typography className="text-dark" variant="body1">We have sent a verification link to</Typography>
                                <Typography className="text-dark" variant="h6">"{newemail}"</Typography>
                                <Typography className="text-dark" variant="body2">Open your mail app and verify your email.</Typography>
                                <Button className="ok-btn">
                                    <Link style={{ textDecoration: 'none', color: 'white' }} to={'/'}>OK</Link>
                                </Button>
                                <Typography style={{ marginTop: 20 }} variant="body2">Didn't receive confirmation email?</Typography>
                                <Typography variant="body2">
                                    <Link onClick={() => handleResendEmail()} style={{ textDecoration: 'none', color: '#3c87e7' }} href="#">Resend the email</Link>
                                </Typography>
                            </div>
                        </Grid>
                        :
                        <Container component="main" maxWidth={form === 'signin' ? 'xs' : 'md'}>
                            <div>
                                {
                                    form === 'signin' ?

                                        <div className={classes.paper}>
                                            <img className={form === 'signin' ? classes.avatar : classes.avatarReg} src="/assets/Logo.png" />
                                            {
                                                <CheckMemo type={form} />
                                            }
                                            <Typography style={{ marginBottom: 0, fontWeight: 300 }} component="h2" variant="h6">
                                                Or Sign in
                                </Typography>
                                            <Typography className={classes.errorText} style={{ marginBottom: 20 }} variant="body1">
                                                {loginMessage}
                                            </Typography>
                                            {
                                                notVerified &&
                                                <Typography onClick={handleResendVerificationEmail} className={classes.resentEmailText} style={{ marginBottom: 20 }} variant="body2">
                                                    {
                                                        sendingVerification ?
                                                            'Sending verification email...'
                                                            :
                                                            'Resend Verification Email?'
                                                    }
                                                </Typography>
                                            }
                                            <form className={classes.form} noValidate>
                                                <TextField fullWidth variant="outlined" margin="normal" required
                                                    onChange={(e) => { setUsername(e.target.value); delete loginError.username; setLoginErrors(loginError) }}
                                                    error={loginError.hasOwnProperty('username')}
                                                    id="email" label="Username or email" name="username" autoFocus
                                                />
                                                {loginError.hasOwnProperty('username') && <small style={{ color: 'red' }}>{'Username cannot be empty'}</small>}

                                                <TextField fullWidth
                                                    onKeyDown={(e) => handleLoginKey(e)}
                                                    variant="outlined" margin="normal" required
                                                    onChange={(e) => { setPassword(e.target.value); delete loginError.password; setLoginErrors(loginError) }}
                                                    error={loginError.hasOwnProperty('password')}
                                                    name="password" label="Password" type="password" id="password" autoComplete="current-password"
                                                />
                                                {loginError.hasOwnProperty('password') && <small style={{ color: 'red' }}>{'Password cannot be empty'}</small>}

                                                <Button disabled={loading} variant="contained" color="primary" onClick={handleLogin} className={classes.submit}>
                                                    {
                                                        !loading ? 'Sign In' : 'Please wait....'
                                                    }

                                                </Button>

                                                <Grid container direction="column">
                                                    <Grid item xs>
                                                        <Link onClick={() => handleForgotPassword(true)} style={{ textDecoration: 'none', fontSize: 14 }} href="#">Forgot password?</Link>
                                                    </Grid>
                                                    <Grid item xs style={{ textAlign: 'center', marginTop: 30 }}>
                                                        <span >{"Don't have an account?"}</span>
                                                        <ButtonBase className={classes.dontHaveBtn} onClick={() => changeAuth('register')} >
                                                            {"Sign Up"}
                                                        </ButtonBase>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                        :
                                        <div className={classes.paper}>
                                            <img className={classes.avatarReg} src="/assets/Logo.png" />
                                            {
                                                <CheckMemo />
                                            }
                                            <Typography style={{ marginBottom: 0, fontWeight: 300 }} component="h2" variant="h6">
                                                Or Register
                                </Typography>

                                            <form className={classes.formReg} noValidate>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth variant="outlined" margin="normal" required
                                                            error={registrationError['first_name'] && registrationError['first_name']}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            helperText={registrationError['first_name'] && registrationError['first_name']}
                                                            id="firstName" label="First Name" name="firstName"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth variant="outlined" margin="normal" required
                                                            error={registrationError['last_name'] && registrationError['last_name']}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            helperText={registrationError['last_name'] && registrationError['last_name']}
                                                            id="lastName" label="Last Name" name="lastName"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth variant="outlined" margin="normal" required
                                                            error={registrationError['email'] && registrationError['email']}
                                                            onChange={(e) => setNewEmail(e.target.value)}
                                                            helperText={registrationError['email'] && registrationError['email']}
                                                            id="email" label="Email Address" name="email"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth variant="outlined" margin="normal" required
                                                            error={registrationError['username']}
                                                            onChange={(e) => setUsername(e.target.value)}
                                                            id="username" label="Username or email" name="username"
                                                            helperText={registrationError['username']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth
                                                            variant="outlined" margin="normal" required
                                                            error={registrationError['password']}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            name="password" label="Password" type="password" id="password"
                                                            helperText={registrationError['password']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField className={classes.regFields} fullWidth
                                                            onKeyDown={(e) => handleRegistrationKey(e)}
                                                            variant="outlined" margin="normal" required
                                                            error={registrationError['confirmpassword']}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            name="confirmpassword" label="Confirm Password" type="password" id="confirmpassword"
                                                            helperText={registrationError['confirmpassword']}
                                                        />
                                                    </Grid>
                                                </Grid>


                                                {
                                                    registering ?
                                                        <Button variant="contained" disabled color="primary" className={classes.submit}>
                                                            Please wait...
                                        </Button>
                                                        :
                                                        <Button variant="contained" color="primary" onClick={handleRegistration} className={classes.submit}>
                                                            Register
                                        </Button>
                                                }

                                                <Grid container>
                                                    <Grid item xs style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <span >{"Don't have an account?"}</span>
                                                        <ButtonBase className={classes.dontHaveBtn} onClick={() => changeAuth('signin')}>
                                                            {"Sign In"}
                                                        </ButtonBase>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                }
                            </div>
                        </Container >
            }
        </>
    );
}
