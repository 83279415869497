import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CartContext } from "../../context/CartContext";
import { UserContext } from "../../context/UserContext";
import PaymentService from "../../services/payments";
import TeacherService from "../../services/teacher";
import Slide from "@material-ui/core/Slide";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 60,
  },
  cartWrapper: {
    backgroundColor: "white",
    marginTop: 50,
    padding: 20,
    borderRadius: 15,
  },
  itemImageWrapper: {
    paddingRight: 20,
  },
  itemImage: {
    width: "100%",
  },
  itemContainer: {
    paddingTop: 30,
    paddingBottom: 0,
  },

  removeIcon: {
    color: "red",
    cursor: "pointer",
  },
  singleItem: {
    marginBottom: 20,
  },
  lightBorder: {
    borderColor: "white",
  },
  singleAmount: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  singleValue: {
    paddingLeft: 30,
  },
  cartAmount: {
    // marginBottom: 30,
  },
  cardWrapper: {
    marginTop: 50,
    padding: 20,
    backgroundColor: "#fff",
    marginLeft: 30,
    borderRadius: 15,
  },
  elementWrapper: {
    marginTop: 5,
    height: 45,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    border: "solid",
    borderWidth: 1,
    borderColor: "#cacaca",
    overflow: "hidden",
  },
  cardele: {
    backgroundColor: "white",
    disaply: "flex",
    alignItems: "center",
    padding: 15,
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  cardMarginBottom: {
    marginBottom: 20,
  },
  checkoutBtn: {
    marginTop: 0,
  },
  fullBtn: {
    width: "100%",
  },
  paymentIcons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
  },
  payWallet: {
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15,
    color: "gray",
  },
  textCenter: {
    textAlign: "center",
  },
  dialogBody: {
    overflow: "visible",
  },
  edhubBtn: {
    backgroundColor: "#efefef",
    color: "black",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#d8d7d7",
    },
  },
  okBtn: {
    backgroundColor: "#55b96d",
    color: "white",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#47a25d",
    },
  },
  checkIcon: {
    width: "15%",
  },
  paymentMethodIcon: {
    width: "15%",
  },
  paymentMethodWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 10,
    border: "solid",
    borderWidth: 1,
    borderColor: "#eaeaea",
    padding: 2,
    paddingLeft: 0,
    borderRadius: 6,
    paddingRight: 10,
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
    cursor: "pointer",
  },
  paymentCardNumber: {
    color: "#9d9d9d",
  },
  radio: {
    margin: 0,
  },
  cardElement: {
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Checkout(props) {
  const stripe = useStripe();
  const cardelements = useElements();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { updateProducts } = useContext(UserContext);
  const [currentUser, setCurrentUser] = user;
  const [updateUserProducts, setUpdateUserProducts] = updateProducts;
  const mounted = React.useRef();
  const [isLoading, setLoading] = React.useState(true);
  const [paymentThrough, setPaymentThrough] = React.useState("new");
  let subTotal = props.total;
  let stripe_connect_id = props.stripeConnect;
  const course_teacher = props.courseTeacher;
  const [cartItem, setCartItem] = useContext(CartContext);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [isProcessing, setProcessing] = React.useState(false);
  const [saveCard, setSaveCard] = React.useState(false);

  const clearCart = () => {
    setCartItem([]);
  };

  const handleSaveCardOption = (event) => {
    setSaveCard(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let stripePayouts = [];
    let type = "single";
    if (!props.productId) {
      type = "multiple";
      cartItem.forEach((item, index) => {
        let payOut = {
          amount:
            item.discounted_price != null && item.discounted_price != ""
              ? item.discounted_price
              : item.course_tution_fee,
          stripe_connect_id: item.stripe_connect_id,
        };
        stripePayouts.push(payOut);
      });
      stripe_connect_id = JSON.stringify(stripePayouts);
      console.log(stripePayouts);
    }

    if (cardelements == null) {
      return;
    }
    setProcessing(true);
    PaymentService.getPaymentIntent(
      subTotal * 100,
      currentUser.stripe_customer_id,
      stripe_connect_id,
      type
    ).then((response) => {
      console.log(response.data);
      confirmPayment(response.data.clientSecret, JSON.stringify(stripePayouts));
    });
  };

  React.useEffect(() => {
    // if (!mounted.current) {
    //     mounted.current = true;
    console.log(props);
    console.log(currentUser);
    if (currentUser.stripe_customer_id) {
      PaymentService.getSavedCards(currentUser.stripe_customer_id).then(
        (response) => {
          setPaymentMethods(response.data.data);
          setLoading(false);
        }
      );
    }
    // }
  }, [currentUser]);

  async function confirmPayment(clientSecret, accounts) {
    console.log(clientSecret);
    if (paymentThrough == "new") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardelements.getElement(CardElement),
      });
      console.log(error);
    }
    let payment_method =
      paymentThrough == "new"
        ? { card: cardelements.getElement(CardElement) }
        : paymentThrough;

    const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
      // payment_method: paymentMethod.id
      payment_method: payment_method,
      setup_future_usage: saveCard ? "off_session" : null,
    });

    if (confirmedCardPayment.paymentIntent.status == "succeeded") {
      var courses = [];
      console.log(confirmedCardPayment);
      var data = JSON.stringify({
        user_id: localStorage.getItem("user_id"),
        transaction_id: confirmedCardPayment.paymentIntent.id,
        reference_id: confirmedCardPayment.paymentIntent.payment_method,
        invoice_url: "",
        name_on_card: "",
        email: "",
        amount: confirmedCardPayment.paymentIntent.amount,
        created_at: null,
        subscriber_id: localStorage.getItem("subscriber_id"),
      });

      PaymentService.storePayment(data).then((response) => {
        if (props.for == "booking") {
          console.log(response);

          let data = [];
          props.trials.map((trial) => {
            data = [
              ...data,
              {
                teacher_id: trial.teacher_id,
                student_id: currentUser.user_id,
                user_id: currentUser.user_id,
                trial_id: trial.price_id,
                course_id: trial.price_id,
                sale_price: trial.price,
                currency: confirmedCardPayment.paymentIntent.currency,
                payment_method:
                  confirmedCardPayment.paymentIntent.payment_method,
                payment_id: response.data.transaction_id,
                status: confirmedCardPayment.paymentIntent.status,
                trial_date: trial.trial_date,
                trial_time: trial.trial_time,
                slot_id: trial.slot_id,
              },
            ];
          });
          console.log(data);
          TeacherService.storeBooking(JSON.stringify(data)).then((res) => {
            setProcessing(false);
            props.handleConfirmation();
          });
        } else {
          if (!props.productId) {
            cartItem.forEach((item, index) => {
              courses.push({
                user_id: localStorage.getItem("user_id"),
                course_id: item.course_id,
                payment_id: response.data.id,
                subscriber_id: localStorage.getItem("subscriber_id"),
                // Fields for sales
                teacher_id: item.course_teacher,
                sale_price: confirmedCardPayment.paymentIntent.amount,
                created: confirmedCardPayment.paymentIntent.created,
                currency: confirmedCardPayment.paymentIntent.currency,
                payment_method:
                  confirmedCardPayment.paymentIntent.payment_method,
                status: confirmedCardPayment.paymentIntent.status,
              });
            });
          } else if (props.productId) {
            courses.push({
              user_id: localStorage.getItem("user_id"),
              course_id: props.productId,
              payment_id: response.data.id,
              subscriber_id: localStorage.getItem("subscriber_id"),
              // Fields for sales
              teacher_id: course_teacher,
              sale_price: confirmedCardPayment.paymentIntent.amount,
              created: confirmedCardPayment.paymentIntent.created,
              currency: confirmedCardPayment.paymentIntent.currency,
              payment_method: confirmedCardPayment.paymentIntent.payment_method,
              status: confirmedCardPayment.paymentIntent.status,
            });
          }
          let dat = {
            courses: courses,
            accounts: accounts,
            paymentIntent: confirmedCardPayment.paymentIntent.id,
          };
          console.log(dat);
          PaymentService.assignProducts(
            courses,
            accounts,
            confirmedCardPayment.paymentIntent.id
          ).then((response) => {
            setProcessing(false);
            clearCart();
            props.confirmDialog();
            setUpdateUserProducts(courses);
          });
        }
      });
    }
  }

  const handlePaymentThrough = (event) => {
    setPaymentThrough(event.target.value);
  };

  const [selectedMethod, setSelectedMethod] = React.useState(null);
  const SelectPayment = (method) => {
    setSelectedMethod(method);
    setPaymentThrough(method);
  };

  const handleConfirmBooking = (event) => {
    setProcessing(true);
    props.handleLoading();
    event.preventDefault();
    PaymentService.getPaymentIntent(
      subTotal * 100,
      currentUser.stripe_customer_id,
      stripe_connect_id,
      "single"
    ).then((response) => {
      console.log(response.data);
      let stripePayouts = [];
      confirmPayment(response.data.clientSecret, JSON.stringify(stripePayouts));
    });
  };

  return isLoading ? (
    <Grid container className={classes.cardElement} justifyContent="center">
      <Typography variant="body1">Loading, Please wait..</Typography>
    </Grid>
  ) : (
    <form onSubmit={!props.for ? handleSubmit : handleConfirmBooking}>
      <Grid
        container
        className={classes.cardElement}
        justifyContent="space-between"
      >
        <Grid item xs={12} className={classes.paymentIcons}>
          <img src="/payments.png" width="50%" />
        </Grid>
        <Grid item xs={12}>
          {paymentMethods.length > 0 ? (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="via"
                name="paymentthrough"
                onChange={handlePaymentThrough}
              >
                {paymentMethods.map((method) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      className={classes.paymentMethodWrapper}
                      onClick={() => SelectPayment(method.id)}
                    >
                      <FormControlLabel
                        className={classes.radio}
                        value={method.id}
                        control={
                          <Radio checked={selectedMethod == method.id} />
                        }
                      />
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <img
                          src={"/assets/icons/" + method.card.brand + ".png"}
                          className={classes.paymentMethodIcon}
                        />
                        <Typography
                          className={classes.paymentCardNumber}
                          variant="subtitle2"
                        >
                          **** **** **** {method.card.last4}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  {/* <Typography variant="subtitle2">Card Number*</Typography> */}
                  <div className={classes.elementWrapper}>
                    <FormControlLabel
                      className={classes.radio}
                      value={"new"}
                      control={<Radio />}
                    />
                    <CardElement className={classes.cardele} />
                  </div>
                </Grid>
              </RadioGroup>
            </FormControl>
          ) : (
            <Grid item xs={12}>
              <div className={classes.elementWrapper}>
                <CardElement className={classes.cardele} />
              </div>
            </Grid>
          )}
          <FormControl component="fieldset">
            <FormControlLabel
              className={classes.cardMarginBottom}
              control={
                <Checkbox
                  checked={saveCard}
                  onChange={handleSaveCardOption}
                  color="primary"
                />
              }
              label={"Save for future use?"}
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.checkoutBtn}>
            <Button
              type="submit"
              disabled={!stripe || !cardelements}
              className={classes.fullBtn}
              style={{ backgroundColor: "#FA9C24", color: "white" }}
            >
              {isProcessing ? (
                <>
                  <img width="30" src={"/assets/ringloader.gif"} />
                  <span>Processing</span>
                </>
              ) : (
                <span>Pay ${subTotal} HK</span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
