import React, { forwardRef, useState, useEffect, useImperativeHandle, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import Product from '../../services/products';
import axios from 'axios';
import { CartContext } from '../../context/CartContext';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import Slider from "react-slick";
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";
import SingleProduct from '../products/singleProduct';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { UserContext } from '../../context/UserContext';
import { useJwt } from 'react-jwt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AuthForm from '../auth/SingleSignIn';
import Checkout from '../checkout/checkout';
import Wallet from '../checkout/wallet';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import HeroSection from '../widgets/herosection'

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productsWrapper: {
        padding: 30,
        marginBottom: 0,
        minHeight: '80vh'
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    titleName: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3
    },
    singleItem: {
        height: 320,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '90%',
        height: '100%',
        marginBottom: 20,
        marginLeft: '5%'
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    titleName: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.3,
        fontDecoration: 'none',
        color: 'black'
    },
    singleItem: {
        height: 330,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '90%',
        height: '99%',
        marginBottom: 20,
        marginLeft: '5%'
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    removeBtn: {
        backgroundColor: '#d55555',
        color: 'white',
        borderRadius: 30,
        fontSize: 8,
        padding: 8
    },

    addBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        borderRadius: 30,
        fontSize: 8,
        padding: 6,
        height: 25,
        '&:hover': {
            backgroundColor: '#a35e05'
        }
    },
    icon: {
        fontSize: 14
    },
    coursePrice: {
        fontSize: 12,
        marginRight: 10
    },
    favouritIcon: {
        fontSize: 20,
        color: '#df3d3d',
    },
    favouriteBtn: {
        backgroundColor: '#ffffff',
        color: '#626262',
        borderRadius: 30,
        fontSize: 8,
        padding: 8,
    },
    favouriteBtnFilled: {
        backgroundColor: '#ffffff',
        color: '#df3d3d',
        borderRadius: 30,
        fontSize: 8,
        padding: 8,
    },
    subscriberName: {
        color: '#fa9c24',
        fontSize: 12,
        marginBottom: 8
    },
    cardAction: {
        paddingTop: 0,
        paddingBottom: 0
    },
    tag: {
        backgroundColor: '#f1f1f1',
        marginRight: 2,
        marginBottom: 2,
        fontSize: 9
    },
    cardContent: {
        paddingTop: 10,
    },
    cardActionInner: {
        marginBottom: 5
    },
    tagsWrapper: {
        height: 50,
        overflow: 'hidden'
    },
    authContainer: {
        backgroundColor: '#061C20',
        height: '100vh'
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 15
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
});

export default function Search(props) {
    const [list, setValue] = React.useState([]);
    const [title, setTitle] = React.useState(props.title);
    const [checkoutOpen, setCheckoutOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const [searchTags, setSearchTags] = React.useState([])
    const { decodedToken, isExpired } = useJwt(token);
    const [buyProduct, setBuyProduct] = useState(null)
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    };
    // const list = [];
    const fetchData = (query) => {
        setLoading(true)
        if (query.length == 0) {
            setLoading(false)
            setValue([])
        }
        else {
            let products = [];
            axios({
                "method": "POST",
                "url": process.env.REACT_APP_API_URL + "search/product/1/" + query,
                "headers": {
                    "content-type": "application/octet-stream",
                }, "params": {
                    "language_code": "en"
                }
            })
                .then((response) => {
                    response.data.forEach((product, index) => {
                        let coverImage = null;
                        let coverType = product.cover_type;
                        let subscriberId = product.subscriber_id;
                        let courseCover = process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + subscriberId + '/' + product.course_cover;
                        let cardMediaType = "img"
                        if (product.course_cover != '' && product.course_cover != null) {
                            // courseCover = process.env.UPLOAD_URL+'courses/covers/'+subscriberId+'/'+courseCover;
                            if (coverType == '.mp4') {
                                cardMediaType = 'video'
                            }
                            else {
                                cardMediaType = 'img'
                            }
                        }
                        else {
                            courseCover = '/' + pics[index]
                        }
                        console.log(product.tags)
                        var tagsObject = []
                        if (product.tags != null) {
                            var tags = product.tags.split(',')
                            tagsObject = tags;
                        }
                        products.push(
                            <SingleProduct from={query} handleCheckoutOpen={handleCheckoutOpen} key={index} product={product} index={index} />
                        )
                    })
                    setValue(products)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const [cartItem, setCartItem] = useContext(CartContext);
    const [favourites, setFavorites] = useState([]);

    const addToCart = (item) => {
        setCartItem(preItems => [...preItems, item])
    }
    const removeFromCart = (removeItem) => {
        setCartItem(cartItem.filter(item => item != removeItem))
    }
    const addToFavourite = (courseId) => {
        setFavorites(preFavourites => [...preFavourites, courseId])
    }
    const removeFromFavourite = (courseId) => {
        setFavorites(favourites.filter(item => item != courseId))
    }



    React.useEffect(() => {
        console.log(props.title)
        fetchData(props.title)
    }, [props.title])

    const handleCheckoutClose = () => {
        setCheckoutOpen(false);
    };

    const handleCheckoutOpen = (props) => {
        setBuyProduct(props)
        setCheckoutOpen(true);
    };

    const handleConfirmation = () => {
        setCheckoutOpen(false)
        setConfirmDialog(true);
    };

    function ConfirmDialog() {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth={'xs'}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src="/assets/check.png" className={classes.checkIcon} />
                </div>
                <DialogTitle className={classes.textCenter} id="alert-dialog-slide-title">
                    <span>Payment Processed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textCenter} id="alert-dialog-slide-description">
                        Thank You for your purchase.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: 20 }}>
                    <Grid container layout="flex" justifyContent="center">
                        <Link className={classes.okBtn} color="primary" style={{ textDecoration: 'none' }} to={{
                            pathname: "/product/view/" + (buyProduct != null ? buyProduct.course_name : ''),
                            state: {
                                image: (buyProduct != null ? (buyProduct.course_cover != null ? process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + buyProduct.subscriber_id + '/' + buyProduct.course_cover : pics[4]) : ''),
                                id: buyProduct != null ? buyProduct.course_id : 0
                            }
                        }}>
                            View My Product
                        </Link>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    function CheckoutDialog() {
        var lineItems = [
            {
                label: buyProduct != null ? buyProduct.course_name : '',
                amount: buyProduct != null ? (parseFloat(buyProduct.course_tution_fee) * 100) : 0
            }
        ]
        return (
            <Dialog onBackdropClick={handleCheckoutClose} open={checkoutOpen} fullWidth={true} maxWidth={'md'} onClose={handleCheckoutClose} TransitionComponent={Transition}>
                <ButtonBase onClick={handleCheckoutClose} className={classes.closeIcon}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </ButtonBase>
                <Grid className={classes.authContainer} container direction="column" alignItems="center" justifyContent="center">
                    <img src="/assets/Logo.png" style={{ width: '25%', marginBottom: 30 }} className={classes.checkoutLog} />
                    <h3 style={{ color: 'white', marginTop: 0 }}>Buy Now</h3>
                    <Grid className={classes.authWrapper} item xs={12} sm={6}>
                        {!isExpired ?
                            <>
                                <Checkout courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} productId={buyProduct != null ? buyProduct.course_id : 0} confirmDialog={handleConfirmation} total={buyProduct != null ? (buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? buyProduct.discounted_price : buyProduct.course_tution_fee) : 0} />
                                <p style={{ textAlign: 'center', color: '#c1c1c1', marginTop: 10, marginBottom: 10, fontSize: 14 }}>Or pay with</p>
                                <Wallet courseTeacher={buyProduct != null ? buyProduct.course_teacher : 0} stripeConnect={buyProduct != null ? buyProduct.stripe_connect_id : 0} lineItems={lineItems} confirmDialog={handleConfirmation} total={buyProduct != null ? ((buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? parseFloat(buyProduct.discounted_price) : parseFloat(buyProduct.course_tution_fee)) * 100) : 0} />
                            </>
                            :
                            <AuthForm />
                        }

                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    useEffect(() => {
        setSearchTags(props.title.split(','))
    }, [props.title])

    const handleRemoveTag = (tagToRemove) => {
        const updatedTags = searchTags.filter((tag) => tag !== tagToRemove);
        setSearchTags(updatedTags)
        fetchData(updatedTags)
    };

    return (
        <div className={classes.searchWrapper}>
            <ConfirmDialog />
            <CheckoutDialog />
            <Container maxWidth="lg">
                <Grid container alignItems="center" className={classes.resultHeader}>
                    <Grid item xs={12}>
                        <HeroSection background={"transparent"} />
                    </Grid>
                    {
                        searchTags.length > 0 &&
                        <>
                            <Grid item xs={12} sm={8} >
                                <Typography variant="body1" component="body1">Search result for </Typography>
                                <div>
                                    {
                                        searchTags.map(tag => (
                                            <Chip
                                                label={tag}
                                                onDelete={() => handleRemoveTag(tag)}
                                                style={{ marginRight: 5, backgroundColor: '#1B92A9', color: 'white' }}
                                            />
                                        ))
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                                <Typography variant="body1" component="h6">{list.length} Result(s)</Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </Container>
            <Container className={classes.productsWrapper} maxWidth="lg">
                {
                    loading ?
                        <Grid container>
                            {
                                Array.from(new Array(4)).map((item, index) => (
                                    <Grid style={{ padding: 5 }} item xs={12} sm={3} >
                                        <Box key={index} marginRight={0.5} my={1}>
                                            <Skeleton style={{ borderRadius: 7 }} variant="rectangular" height={190} />
                                        </Box>
                                        <Box pt={0.5}>
                                            <Skeleton height={30} />
                                        </Box>
                                        <Box pt={0.5} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Skeleton width="30%" height={30} />
                                            <Skeleton width="30%" height={30} />
                                        </Box>
                                        <Box pt={0.5} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Skeleton width="40%" height={30} />
                                            <Skeleton width="40%" height={30} />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        :
                        list.length > 0 ?
                            <Grid container>
                                {list}
                            </Grid>
                            :
                            <Grid container style={{ height: '80vh' }} alignItems="center">
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <CloudOffIcon style={{ fontSize: 55 }} />
                                    <Typography variant="body1">No product found</Typography>
                                </Grid>
                            </Grid>
                }
            </Container>
        </div>
    )
}

