import React from 'react'
import * as Mui from '@material-ui/core';
import { BrowserRouter as Router, Link, useParams, Redirect } from "react-router-dom";
import AuthService from '../../services/auth';
import '../css/teacherReg.css';


export default function VerifyEmail() {
    let { token } = useParams();
    const [verified, setVerified] = React.useState(null)

    React.useEffect(() => {
        AuthService.verifyEmail(token).then(res => {
            console.log(res)
            setVerified(res.data.verified)
        })
    }, [])

    return (
        <>
            <div className="success-wrapper">
                <Mui.Grid container style={{ height: '100%' }} justifyContent="center" alignItems="center">
                    <Mui.Grid item xs={12} sm={4}>
                        <div className="success-card">
                            {
                                verified == null ?
                                    <>
                                        <img src="/assets/ringloader.gif" width="20%" style={{ marginBottom: 15 }} />
                                        <Mui.Typography variant="h6">Please wait, while we are verifying your email....</Mui.Typography>
                                    </>
                                    :
                                    verified == true ?
                                        <>
                                            <div className="check-wrapper">
                                                <Mui.Icon className="check-icon">check</Mui.Icon>
                                            </div>
                                            <Mui.Typography variant="h6">Thank You for verifying your email.</Mui.Typography>
                                            {/* <Mui.Typography variant="body1">Our Team is reviewing your application.</Mui.Typography>
                                            <Mui.Typography variant="body1">You will be notified soon.</Mui.Typography> */}

                                            <Link to={'/login'} className="ok-link">
                                                <Mui.Button className="ok-btn">Log in now</Mui.Button>
                                            </Link>

                                        </>
                                        :
                                        <>
                                            {/* <div className="check-wrapper">
                                                <Mui.Icon className="check-icon">close</Mui.Icon>
                                            </div>
                                            <Mui.Typography variant="h6">We're Sorry.</Mui.Typography>
                                            <Mui.Typography variant="body1">Your email can't be verified.</Mui.Typography>
                                            <Mui.Typography variant="body1">Please contact our support team.</Mui.Typography>
                                            <Mui.Button className="ok-btn"><a terget="blank" className="ok-link" href="mailto:hello@ed-hub.io">Contact Support</a></Mui.Button> */}
                                            {/* <Redirect to="/" /> */}

                                            <div className="check-wrapper">
                                                <Mui.Icon className="check-icon">check</Mui.Icon>
                                            </div>
                                            <Mui.Typography variant="h6">Your email has been verified, <Link style={{ textDecoration: 'none', color: 'primary', cursor: 'pointer' }} to={'/login'}>Log in now</Link></Mui.Typography>
                                            {/* <Mui.Typography variant="body1">Our Team is reviewing your application.</Mui.Typography>
                                            <Mui.Typography variant="body1">You will be notified soon.</Mui.Typography> */}

                                            {/* <Link to={'/login'} className="ok-link">
                                                <Mui.Button className="ok-btn">Log in now</Mui.Button>
                                            </Link> */}

                                        </>
                            }
                        </div>
                    </Mui.Grid>
                </Mui.Grid>
            </div>
        </>
    )
}
