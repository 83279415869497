import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
// Google Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function ScrollTop() {
    let location = useLocation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    return (null)
}

export default ScrollTop