import React from 'react';
import Product from '../services/products';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';
import InputBase from '@material-ui/core/InputBase';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Auth from '../services/auth';
import * as MuiL from '@material-ui/lab';
import * as Mui from '@material-ui/core';
import '../components/css/myProfile.css';
import '../components/css/teacherReg.css';
import TeacherService from '../services/teacher';
import { UserContext } from '../context/UserContext';
import SwipeableViews from 'react-swipeable-views';
import ProfileCropper from '../components/auth/ProfileCropper';
import DeleteMyAccount from '../components/widgets/DeleteMyAccount';

function ProfileFields(props) {
    console.log(props)
    const [submitting, setSubmission] = React.useState(false)
    const [viewPdf, setViewPdf] = React.useState(false)
    const [fileName, setFileName] = React.useState('')
    const [viewFile, setViewFile] = React.useState(false)
    const [genderError, setGenderError] = React.useState(null)
    const [formErrors, setFormErrors] = React.useState([])
    const [profile_picture, setProfilePicture] = React.useState(null)
    const [profile, setProfile] = React.useState(null)
    const [success, setSuccess] = React.useState(null)
    const [responseMessage, setMessage] = React.useState(null)
    const [filePath, setPath] = React.useState(null)
    const [qualifications, setQualifications] = React.useState([])
    const [qualificationsNew, setNewQualifications] = React.useState(null)
    const [state, setState] = React.useState({
        first_name: "",
        user_id: null,
        email: null,
        username: null,
        last_name: "",
        display_name: "",
        password: "",
        confirmpassword: "",
        contact_no: "",
        profile_picture: props.profile_picture,
        school: "",
        gradeLevel: "",
    })
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState(false)
    const [fullName, setFullName] = React.useState(null)
    const [firstName, setFirstName] = React.useState(null)
    const [lastName, setLastName] = React.useState(null)
    const [contact, setContact] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [confirmPassword, setConfirmPassword] = React.useState(null)
    const [editMode, setEditMode] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [centerName, setCenterName] = React.useState(null)

    React.useEffect(() => {
        
        if (props.profile != null) {
            setState({
                ...state,
                first_name: props.profile.details.first_name,
                user_id: props.profile.user_id,
                username: props.profile.username,
                email: props.profile.email,
                last_name: props.profile.details.last_name,
                display_name: props.profile.details.display_name,
                contact_no: props.profile.details.contact_no,
                school: props.profile.school,
                gradeLevel: props.profile.grade,
            })

        }
    }, [])

    const onChangeHandler = event => {
        const name = event.target.name
        const value = event.target.value
        setState({
            ...state,
            [name]: value
        })
    }

    const handleProfileUpdate = () => {
        if (state.password != state.confirmpassword) {
            setPasswordError(true)
            window.scrollTo(0, 0)
        }
        else {
            setPasswordError(false)
            setLoading(true)
            // let data = {
            //     'user_id': localStorage.getItem('user_id'),
            //     'display_name': fullName,
            //     'first_name': firstName,
            //     'last_name': lastName,
            //     'contact_no': contact,
            //     'password': password,
            //     'school': centerName
            // }
            console.log(state)
            const formD = new FormData();
            Object.entries(state).forEach(([key, value]) => {
                if (key == 'password') {
                    if (password != "") {
                        formD.append(key, value);
                    }
                }
                if (key != 'password') {
                    formD.append(key, value);
                }
            })

            if (props.newProfilePictureBlob != null) {
                formD.append('profile_picture', new File([props.newProfilePictureBlob], 'fileName.jpeg', { type: "image/jpeg", lastModified: new Date() }))
            }


            formD.append('user_id', localStorage.getItem('user_id'));

            Auth.updateUserById(formD).then(res => {
                console.log(res)
                if (res.data == 1) {
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
                window.scrollTo(0, 0)
                setEditMode(false)
                // getUserData()
                setLoading(false)
                setPassword(null)
                setConfirmPassword(null)
                setTimeout(() => {
                    setSuccess(false)
                    setError(false)
                }, 3000);
            })
        }
    }

    return (
        <Mui.Grid container>
            {
                success == true &&
                <Container maxWidth="sm">
                    <MuiL.Alert variant="filled" severity="success">
                        {"Profile updated Successful!"}
                    </MuiL.Alert>
                </Container>
            }
            {
                error == true &&
                <Container maxWidth="sm">
                    <MuiL.Alert variant="filled" severity="error">
                        {"Something went wrong. Try again later."}
                    </MuiL.Alert>
                </Container>
            }
            <Mui.Grid item xs="12" lg="6">
                <Mui.Grid container className="slide-item">
                    <Mui.Grid item xs={12} sm={12}>
                        <Mui.Grid container spacing={2}>
                            <Mui.Grid container spacing={2}>
                                <Mui.Grid item xs={12} sm={12} className="tag-header">
                                    <Mui.Typography className="section-heading" variant="body1">Basic Information</Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label className="form-label">First Name</label>
                                    <Mui.TextField name="first_name" onChange={onChangeHandler} className="form-field" required value={state.first_name} variant="outlined" placeholder="First Name" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Last Name</label>
                                    <Mui.TextField name="last_name" onChange={onChangeHandler} className="form-field" required value={state.last_name} variant="outlined" placeholder="Last Name" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Display Name</label>
                                    <Mui.TextField name="display_name" onChange={onChangeHandler} className="form-field" required value={state.display_name} variant="outlined" placeholder="Display Name" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Phone Number</label>
                                    <Mui.TextField name="contact_no" onChange={onChangeHandler} className="form-field" required value={state.contact_no} variant="outlined" placeholder="Phone Number" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Email</label>
                                    {
                                        props.profile != null ?
                                            props.profile.email == null || props.profile.email == '' ?
                                                <Mui.TextField className="form-field" variant="outlined" placeholder="Email" fullWidth></Mui.TextField>
                                                :
                                                <Mui.TextField className="form-field" disabled value={props.profile != null && props.profile.email} variant="outlined" placeholder="Email" fullWidth></Mui.TextField>
                                            :
                                            ''
                                    }
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Username</label>
                                    <Mui.TextField className="form-field" disabled value={props.profile != null && props.profile.username} variant="outlined" placeholder="Username" fullWidth></Mui.TextField>
                                </Mui.Grid>
                            </Mui.Grid>
                        </Mui.Grid>
                    </Mui.Grid>
                </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid item xs="12" lg="6">
                <Mui.Grid container className="slide-item">
                    <Mui.Grid item xs={12} sm={12}>
                        <Mui.Grid container spacing={2}>
                            <Mui.Grid container spacing={2}>
                                {/* Account & Security */}
                                <Mui.Grid item className="mt-5" xs={12} sm={12} className="tag-header">
                                    <Mui.Typography variant="body1">Account & Security</Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Password</label>
                                    <Mui.TextField name="password" onChange={onChangeHandler} className="form-field" required variant="outlined" placeholder="New Password" type="password" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>New Password</label>
                                    <Mui.TextField name="confirmpassword" onChange={onChangeHandler} className="form-field" required variant="outlined" placeholder="Confirm New Password" type="password" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                {
                                    passwordError &&
                                    <Mui.Grid item xs={12}>
                                        <Typography style={{ color: 'red' }}>Password don't match</Typography>
                                    </Mui.Grid>
                                }
                                {/* Account & Security */}
                                <Mui.Grid item className="mt-5" xs={12} sm={12} className="tag-header">
                                    <Mui.Typography variant="body1">Education</Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>School Name</label>
                                    <Mui.TextField name="school" onChange={onChangeHandler} value={state.school} className="form-field" required variant="outlined" placeholder="School Name" type="text" fullWidth></Mui.TextField>
                                </Mui.Grid>
                                <Mui.Grid item xs={12} sm={6}>
                                    <label>Grade Level</label>
                                    <Mui.TextField name="gradeLevel" value={state.gradeLevel} onChange={onChangeHandler} className="form-field" required variant="outlined" placeholder="Grade Level" type="text" fullWidth></Mui.TextField>
                                </Mui.Grid>
                            </Mui.Grid>
                        </Mui.Grid>
                    </Mui.Grid>
                </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container justifyContent="space-between" style={{marginTop:30}}>
                <Grid item style={{paddingLeft:10}}>
                    <DeleteMyAccount loggedInUser={state} />
                </Grid>
                <Grid item style={{ paddingRight: 12 }}>
                    {
                        loading ?
                            <Button disabled className="disabled-btn">Please wait...</Button>
                            :
                            <>
                            <Button onClick={() => handleProfileUpdate()} className="submit-btn">Update</Button>
                            </>
                    }
                </Grid>
            </Mui.Grid>
        </Mui.Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 0,
        // minHeight: '65vh',
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        // minHeight: '95vh',
        backgroundColor: '#11444D'
    },

    pageTitle: {
        marginBottom: 50
    },
    editWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    line: {
        flex: 1,
        marginTop: 15
    },
    editIcons: {
        width: 40,
        height: 40,
        borderRadius: 30,
        backgroundColor: '#56ACEE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer'
    },
    headWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 15,
        fontSize: 30
    },
    headTitle: {
        lineHeight: 0,
        fontSize: 24
    },
    marginTop: {
        marginTop: -10
    },
    infoWrapper: {
        marginBottom: 15
    },
    sectionWrapper: {
        marginBottom: 40
    },
    tagsWrapper: {
        marginTop: 30
    },
    chip: {
        marginRight: 10,
        marginBottom: 10,
        color: 'white',
        backgroundColor: '#f99c24'
    },
    chipIcon: {
        color: 'white'
    },
    mainWrapper: {
        backgroundColor: '#fff',
        marginTop: 10,
        position: 'relative',
        marginBottom: 10,
        borderRadius: 20,
        paddingTop: 30,
        paddingBottom: 30
    },
    updateBtn: {
        backgroundColor: '#1aac69',
        color: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: 10,
        "&:hover": {
            backgroundColor: '#168d56',
        }
    },
    primaryBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        "&:hover": {
            backgroundColor: '#e18d20',
        }
    },
    passwordWrapper: {
        marginTop: 10
    },
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffffc2',
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    loaderRing: {
        marginBottom: 0,
        width: '20%'
    }
}));

export default function Account() {

    const classes = useStyles();
    const [userData, setUserData] = React.useState(null)
    const [editMode, setEditMode] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState(false)
    const [fullName, setFullName] = React.useState(null)
    const [firstName, setFirstName] = React.useState(null)
    const [lastName, setLastName] = React.useState(null)
    const [contact, setContact] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [confirmPassword, setConfirmPassword] = React.useState(null)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [centerName, setCenterName] = React.useState(null)
    const [gradeLevel, setGradeLevel] = React.useState(null)
    const [showProfileCropper, setShowProfileCropper] = React.useState(false)
    const [showCropper, setShowCropper] = React.useState(true)
    const [newProfilePicture, setNewProfilePicture] = React.useState(null)
    const [newProfilePictureBlob, setNewProfilePictureBlob] = React.useState(null)

    const [value, setValue] = React.useState(0);
    const [profile, setProfile] = React.useState(null);
    const { user } = React.useContext(UserContext)
    const [profile_picture, setProfilePicture] = React.useState(null)
    const [gender, setGender] = React.useState(null)
    const [about, setAbout] = React.useState(null)
    const [pictureChanged, setPictureChanged] = React.useState(false)
    const formRef = React.useRef(null);
    const [currentUser, setCurrentUser] = user;

    React.useEffect(() => {
        getUserData()
    }, [])

    const getUserData = () => {
        Auth.getUserById(localStorage.getItem('user_id')).then(res => {
            console.log(res)
            let userObject = res.data;
            setUserData(res.data)
            setFirstName(res.data.details.first_name)
            setLastName(res.data.details.last_name)
            setFullName(res.data.details.display_name)
            setProfilePicture(res.data.details.profile_picture)
            setContact(res.data.details.contact_no)
            setCenterName(res.data.school)
        }, error => {
            console.log(error)
        })
        return true;
    }

    const handleSectionEdit = (section) => {
        return null
    }

    const handleCancelUpdate = () => {
        setEditMode(false)
        return null
    }

    const handleImageChange = (event) => {
        setProfilePicture(event.target.files[0])
        setPictureChanged(true)
    }

    const handleProfileUpdate = () => {

        if (password != confirmPassword) {
            setPasswordError(true)
            window.scrollTo(0, 0)
        }
        else {
            setPasswordError(false)
            setLoading(true)
            let data = {
                'user_id': localStorage.getItem('user_id'),
                'display_name': fullName,
                'first_name': firstName,
                'last_name': lastName,
                'contact_no': contact,
                'password': password,
                'school': centerName
            }
            const formD = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (key == 'password') {
                    if (password != null) {
                        formD.append(key, value);
                    }
                }
                if (key != 'password') {
                    formD.append(key, value);
                }
            })

            Auth.updateUserById(formD).then(res => {
                console.log(res)
                if (res.data == 1) {
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
                window.scrollTo(0, 0)
                setEditMode(false)
                getUserData()
                setLoading(false)
                setPassword(null)
                setConfirmPassword(null)
                setTimeout(() => {
                    setSuccess(false)
                    setError(false)
                }, 3000);
            })
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Mui.Box sx={{ p: 3 }}>
                        <Mui.Typography>{children}</Mui.Typography>
                    </Mui.Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const Heading = (props) => {
        return (
            <>
                <Mui.Grid container justifyContent="left" alignItems="center">
                    <Mui.Icon style={{ marginRight: 10 }}>{props.icon}</Mui.Icon>
                    <span>{props.heading}</span>
                </Mui.Grid>
            </>
        )
    }

    const MemoizedProfileFields = React.memo(ProfileFields);

    const ProfileTabs = (props) => {
        return (
            <Mui.Box className="profile-tabs" sx={{ bgcolor: 'background.paper', width: '100%' }}>
                <Mui.AppBar position="static" className="profile-bar">
                    <Mui.Tabs
                        value={value}
                        indicatorColor="secondary"
                        textColor="inherit"
                        classes={{ indicator: "indicator" }}
                        variant="fullWidth"
                        inkBarStyle={{ background: 'blue' }}
                        aria-label="full width tabs example"
                    >
                        <Mui.Tab label={<Heading icon="person" heading="Personal Information" />} {...a11yProps(0)} />
                    </Mui.Tabs>
                </Mui.AppBar>
                <SwipeableViews
                    axis={'x-reverse'}
                    index={value}
                >
                    <TabPanel value={value} index={0} >
                        <MemoizedProfileFields newProfilePictureBlob={newProfilePictureBlob} profile_picture={profile_picture} profile={userData} />
                    </TabPanel>
                </SwipeableViews>
            </Mui.Box >
        )
    }

    const handleNewImageUploaded = (newPicture) => {
        console.log(newPicture.blob)
        newPicture.url.then(res => {
            setNewProfilePicture(res)
        })
        setNewProfilePictureBlob(newPicture.blob)
        setShowProfileCropper(false)
    }

    const closeProfileCropper = () => {
        setShowProfileCropper(false)
    }

    return (

        <Container maxWidth="xl" className={classes.root}>
            {
                showProfileCropper &&
                <ProfileCropper closeProfileCropper={closeProfileCropper} handleNewImageUploaded={handleNewImageUploaded} profile_picture={profile_picture != null && (userData.details.auth_using == 'social' ? userData.details.profile_picture : process.env.REACT_APP_API_URL + 'uploads/profilePictures/' + userData.details.profile_picture)} />
            }
            <Mui.Grid container className="profile-page">
                <Mui.Grid item xs={12} sm={12}>
                    <div className="cover-bg"></div>
                    <Mui.Container maxWidth={"lg"}>
                        <Mui.Grid container alignItems="center" className="basic-info-wrapper" justifyContent="space-between">
                            <div className="left-wrapper">
                                {/* <div className="profile-pic">
                                    <input accept="image/*" id="upload-profile-picture" onChange={handleImageChange} type='file' hidden />
                                    <label htmlFor="upload-profile-picture" >
                                        <Mui.Tooltip placement="left" title={"Click to change"}>
                                            <Mui.Button component="span" className="profile-picture-button">
                                                {
                                                    profile_picture || pictureChanged ?
                                                        !pictureChanged ?
                                                            userData.details.auth_using == 'social' ?
                                                                <img width="100%" className="some" src={userData.details.profile_picture} />
                                                                :
                                                                <img width="100%" className="some" src={profile_picture != null && process.env.REACT_APP_API_URL + 'uploads/profilePictures/' + profile_picture} />
                                                            :
                                                            <img src={URL.createObjectURL(profile_picture)} width="100%" />
                                                        :
                                                        <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>
                                                }
                                            </Mui.Button>
                                        </Mui.Tooltip>
                                    </label>

                                </div> */}
                                <div className="profile-pic">
                                    {/* <input accept="image/*" id="upload-profile-picture" onChange={handleImageChange} type='file' hidden />
                                    <label htmlFor="upload-profile-picture" > */}
                                    <Mui.Tooltip placement="left" title={"Click to change"}>
                                        <Mui.Button onClick={() => setShowProfileCropper(true)} component="span" className="profile-picture-button">
                                            {
                                                newProfilePicture == null ?
                                                    profile_picture || pictureChanged ?
                                                        !pictureChanged ?
                                                            userData.details.auth_using == 'social' ?
                                                                <img width="100%" className="some" src={userData.details.profile_picture} />
                                                                :
                                                                <img width="100%" className="some" src={profile_picture != null && process.env.REACT_APP_API_URL + 'uploads/profilePictures/' + profile_picture} />
                                                            :
                                                            <img src={URL.createObjectURL(profile_picture)} width="100%" />
                                                        :
                                                        <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>
                                                    :
                                                    <img src={newProfilePicture} width="100%" />
                                            }
                                        </Mui.Button>
                                    </Mui.Tooltip>
                                    {/* </label> */}

                                </div>
                                <div className="basic-info">
                                    <Mui.Typography variant="h5">{userData != null && userData.details.display_name}</Mui.Typography>
                                    <Mui.Typography variant="body2">{userData != null && userData.email}</Mui.Typography>
                                </div>
                            </div>
                        </Mui.Grid>
                        {
                            (userData != null || newProfilePictureBlob != null) &&
                            <ProfileTabs newProfilePictureBlob={newProfilePictureBlob} />
                        }
                    </Mui.Container>
                </Mui.Grid>
                <Mui.Grid item xs={12} sm={12}>
                    <Mui.Container maxWidth="lg">

                    </Mui.Container>
                </Mui.Grid>
            </Mui.Grid>
            <Mui.Hidden xsUp>
                {
                    success == true &&
                    <Container maxWidth="sm">
                        <MuiL.Alert variant="filled" severity="success">
                            {"Profile updated Successfuly!"}
                        </MuiL.Alert>
                    </Container>
                }
                {
                    error == true &&
                    <Container maxWidth="sm">
                        <MuiL.Alert variant="filled" severity="error">
                            {"Something went wrong. Try again later."}
                        </MuiL.Alert>
                    </Container>
                }
                <Container maxWidth="sm" className={classes.mainWrapper}>
                    {
                        loading &&
                        <Grid item xs={12} className={classes.loader}>
                            <img src="/assets/ringloader.gif" className={classes.loaderRing} />
                            <Typography variant="h6">Please wait...</Typography>
                        </Grid>
                    }



                    <Container maxWidth="xl" className={classes.sectionWrapper}>
                        <Grid container flexdirection="column">
                            <Grid item xs={12} className={classes.headWrapper}>
                                <Icon className={classes.icon}>badge</Icon>
                                <Typography className={classes.headTitle} variant="h6">Personal Info</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                <div onClick={() => handleSectionEdit('personal-info')} className={classes.editWrapper}>
                                    <hr className={classes.line}></hr>
                                    {/* <Icon className={classes.editIcons}>edit</Icon> */}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">First Name</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                {
                                    editMode ?
                                        <TextField fullWidth variant="outlined" onChange={(e) => setFirstName(e.target.value)} value={firstName} placeholder="First Name" />
                                        :
                                        <Typography variant="body1">{userData != null && userData.details.first_name}</Typography>
                                }

                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Last Name</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                {
                                    editMode ?
                                        <TextField fullWidth variant="outlined" onChange={(e) => setLastName(e.target.value)} value={lastName} placeholder="Last Name" />
                                        :
                                        <Typography variant="body1">{userData != null && userData.details.last_name}</Typography>
                                }

                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Display Name</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                {
                                    editMode ?
                                        <TextField fullWidth variant="outlined" onChange={(e) => setFullName(e.target.value)} value={fullName} placeholder="Display Name" />
                                        :
                                        <Typography variant="body1">{userData != null && userData.details.display_name}</Typography>
                                }

                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Username</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">{userData != null && userData.username}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Email</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">{userData != null && userData.email}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Contact</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                {
                                    editMode ?
                                        <TextField fullWidth variant="outlined" onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact No." />
                                        :
                                        <Typography variant="body1">{userData != null && userData.details.contact_no}</Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Password</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1"><i>Your Selected Password</i></Typography>
                            </Grid>
                        </Grid>
                        {
                            editMode &&
                            <Grid container className={classes.passwordWrapper} spacing={3} alignItems="center">
                                <Grid item xs={6} className={classes.infoWrapper}>
                                    <Typography variant="body1">New Password</Typography>
                                    <TextField type="password" onChange={(e) => setPassword(e.target.value)} fullWidth variant="outlined" placeholder="New Password" />
                                </Grid>
                                <Grid item xs={6} className={classes.infoWrapper}>
                                    <Typography variant="body1">Confirm New Password</Typography>
                                    <TextField type="password" onChange={(e) => setConfirmPassword(e.target.value)} fullWidth variant="outlined" placeholder="Confirm New Password" />
                                </Grid>
                                {
                                    passwordError &&
                                    <Grid item xs={12}>
                                        <Typography style={{ color: 'red' }}>Password don't match</Typography>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Container>
                    <Container maxWidth="xl" className={classes.sectionWrapper}>
                        <Grid container flexdirection="column">
                            <Grid item xs={12} className={classes.headWrapper}>
                                <Icon className={classes.icon}>import_contacts</Icon>
                                <Typography className={classes.headTitle} variant="h6">Education</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                <div onClick={() => handleSectionEdit('education')} className={classes.editWrapper}>
                                    <hr className={classes.line}></hr>
                                    {/* <Icon className={classes.editIcons}>edit</Icon> */}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Center Name</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                {
                                    editMode ?
                                        <TextField fullWidth onChange={(e) => setCenterName(e.target.value)} variant="outlined" value={centerName} placeholder="Center Name" />
                                        :
                                        <Typography variant="body1">{userData != null && userData.school}</Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">Grade Level</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.infoWrapper}>
                                <Typography variant="body1">KG 6</Typography>
                            </Grid>
                        </Grid>
                    </Container>

                    {/* <Container maxWidth="xl" className={classes.sectionWrapper}>
                        <Grid container flexdirection="column">
                            <Grid item xs={12} className={classes.headWrapper}>
                                <Icon className={classes.icon}>bookmarks</Icon>
                                <Typography className={classes.headTitle} variant="h6">Interests</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.marginTop}>
                                <div onClick={() => handleSectionEdit('interests')} className={classes.editWrapper}>
                                    <hr className={classes.line}></hr>
                                    <Icon className={classes.editIcons}>edit</Icon>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.tagsWrapper}>
                            <Chip
                                icon={<Icon className={classes.chipIcon}>star</Icon>}
                                label="Grammar"
                                className={classes.chip}
                            />
                            <Chip
                                icon={<Icon className={classes.chipIcon}>star</Icon>}
                                label="Phonics"
                                className={classes.chip}
                            />
                            <Chip
                                icon={<Icon className={classes.chipIcon}>star</Icon>}
                                label="English Writing"
                                className={classes.chip}
                            />
                            <Chip
                                icon={<Icon className={classes.chipIcon}>star</Icon>}
                                label="Verbal Communication"
                                className={classes.chip}
                            />
                            <Chip
                                icon={<Icon className={classes.chipIcon}>star</Icon>}
                                label="Tenses"
                                className={classes.chip}
                            />
                        </div>
                    </Container> */}
                    <Container maxWidth="xl" className={classes.buttonWrapper}>
                        <Grid container justifyContent="flex-end">
                            {
                                editMode ?
                                    <>
                                        <Button onClick={() => handleCancelUpdate()} className={classes.primaryBtn}>Cancel</Button>
                                        <Button onClick={() => handleProfileUpdate()} className={classes.updateBtn}>Update</Button>
                                    </>
                                    :
                                    <Button onClick={() => setEditMode(true)} className={classes.primaryBtn}>Edit</Button>
                            }
                        </Grid>
                    </Container>
                </Container>
            </Mui.Hidden>
        </Container>
    )
}
