import React from 'react';
import { Route, useParams } from 'react-router-dom';
import TeacherService from '../../services/teacher';
import ShopHeader from '../../components/teacher/shop/shopHeader';
import ShopFilters from '../../components/teacher/shop/ShopFilters';
import SmallProduct from '../../components/products/SmallProduct';
import SingleProduct from '../../components/products/singleProduct';
import * as Mui from '@material-ui/core';
import '../css/shopPage.css';
import PageLoader from '../../components/shared/loaders/pageLoader';
import BottomBar from '../../components/teacher/shop/BottomBar';

export default function TeachersShop() {

    let { username } = useParams();
    const [teacherShop, setTeacherShop] = React.useState(null)
    const [teacherProducts, setTeacherProducts] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [currentFilters, setCurrentFilters] = React.useState([])

    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!",
            image: "two.jpg",
            heading_position: "top-left",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 1
        },
        {
            name: "Random Name #1",
            description: "Hello World!",
            image: "three.jpg",
            heading_position: "top-left",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 1
        },
        {
            name: "Random Name #1",
            description: "Hello World!",
            image: "three.jpg",
            heading_position: "top-left",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 1
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            image: "three.jpg",
            heading_position: "top-left",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 2
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            image: "three.jpg",
            heading_position: "top-left",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 2
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            image: "four.jpg",
            heading_position: "center-center",
            color: "white",
            backdrop: true,
            type: "image",
            pos: 2
        }
    ]

    React.useEffect(() => {
        TeacherService.getShopByUsername(username).then(res => {
            setTeacherShop(res.data)
            setTeacherProducts(res.data.products)
            setLoading(false)
        }, err => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    const handleFilterProducts = (filter) => {
        console.log(filter)
        TeacherService.getFilterProducts(filter).then(res => {
            setTeacherProducts(res.data.products)
            console.log(res.data.levels)
            setCurrentFilters(filter.level)
        })
    }

    return (
        <>
            {
                loading ?
                    <PageLoader />
                    :
                    teacherShop != null ?
                        <>
                            <ShopHeader slider={teacherShop.sliders} teacher={teacherShop} />
                            <ShopFilters levels={currentFilters} handleFilterProducts={handleFilterProducts} teacher={teacherShop.user_id} />
                            <Mui.Container className="shop-products-container" maxWidth={'lg'}>
                                <Mui.Hidden xsDown>
                                    <Mui.Grid className="shop-products-wrapper" justifyContent="center" alignItems="flex-start" container>
                                        {
                                            teacherProducts.length > 0 ?
                                                teacherProducts.map((item, index) => {
                                                    return (
                                                        // <Mui.Grid item xs={6}>
                                                        <SingleProduct from={'teacher-shop'} product={item} key={index} index={index} />
                                                        // </Mui.Grid>
                                                    )
                                                })
                                                :
                                                <h3 style={{ textAlign: 'center',paddingTop:50,color:'#bababa' }}>No products found</h3>
                                        }
                                    </Mui.Grid>
                                </Mui.Hidden>
                                <Mui.Hidden smUp>
                                    <Mui.Grid className="shop-products-wrapper" alignItems="flex-start" container>
                                        {
                                            teacherProducts.length > 0 ?
                                                <>
                                                    <Mui.Grid item xs={6}>
                                                        {
                                                            teacherProducts.map((item, index) => {
                                                                if (index % 2 === 0) {
                                                                    return (
                                                                        <SmallProduct from={'none'} product={item} key={index} index={index} />
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Mui.Grid>
                                                    <Mui.Grid item xs={6}>
                                                        {
                                                            teacherProducts.map((item, index) => {
                                                                if (index % 2 != 0) {
                                                                    return (
                                                                        <SmallProduct from={'none'} product={item} key={index} index={index} />
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Mui.Grid>
                                                </>
                                                :
                                                <h5 style={{ textAlign: 'center', width: '100%', marginTop: 20, fontSize: 24 }}>No products found</h5>
                                        }
                                    </Mui.Grid>
                                </Mui.Hidden>
                            </Mui.Container>
                        </>
                        :
                        <Mui.Grid container alignItems="center" justifyContent="center" style={{ height: '80vh' }}>
                            <Mui.Grid item xs={12}>
                                <h1 style={{ textAlign: 'center', fontWeight: 300 }}>No data found on this shop!</h1>
                            </Mui.Grid>
                        </Mui.Grid>
            }
            <Mui.Hidden smUp>
                <BottomBar />
            </Mui.Hidden>
        </>
    )
}
