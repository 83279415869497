import React from 'react';
import {
    Container,
    Grid,
    Button,
    Icon,
    Image,
    Typography,
    Hidden
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import './css/static.css';

import { spacing } from '@material-ui/system';
import BackToTop from '../components/helper/BackToTop'

export default function Aboutus() {
    return (
        <Container maxWidth="md">
            {/* Hero banner */}
            <Hidden smDown>
                <Grid container alignItems="center" justifyContent="center">
                    <a className="top-nav" href="#who-we-are">
                        <Button color="primary">Who we are</Button>
                    </a>
                    <a className="top-nav" href="#who-we-help">
                        <Button color="primary">Who we help</Button>
                    </a>
                    <a className="top-nav" href="#what-we-offer">
                        <Button color="primary">What we offer</Button>
                    </a>
                    <a className="top-nav" href="#how-it-works">
                        <Button color="primary">How it works</Button>
                    </a>
                    <a className="top-nav" href="#legal-terms">
                        <Button color="primary">Legal terms</Button>
                    </a>
                </Grid>
            </Hidden>
            <Grid style={{ paddingTop: 20, paddingBottom: 20, marginBottom: 40, marginTop: 40 }} container spacing={4} alignItems="center">
                <Grid item xs={6} sm={6}>
                    <img src="/assets/Logo.png" width="70%" />
                    <Typography variant="h5">Marketplace</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <img src="/assets/righthero.png" width="100%" />
                </Grid>
            </Grid>
            {/* Who are we  */}
            <Grid id="who-we-are" container spacing={4} alignItems="center">
                <Grid item xs={12} sm={5}>
                    <img src="/assets/about/about-main.jpg" width="100%" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Typography className="primary-head" variant="h5">Who we are?</Typography>
                    <Typography style={{ marginTop: 20, marginBottom: 20 }} color="#fff" component="h4" variant="body2">We're a global learning platform that wants to empower everyone, including children, students, and employees, to unlock their full learning potentials.
                    </Typography>

                    <Typography style={{ marginBottom: 20 }} color="#fff" component="h4" variant="body2">
                        Our platform makes it easy for any individual or corporation to create, share, and learn from multiple courses arround the globe. It can be used anywhere, in person or virtually, using any device with internet access.
                    </Typography>

                    <Typography color="#fff" component="h4" variant="h6">
                        We are committed to
                    </Typography>
                    <Typography color="#fff" component="h4" variant="body2">
                        1. Increase access to high-quality education for everyone, everywhere.
                    </Typography>
                    <Typography color="#fff" component="h4" variant="body2">
                        2. Enhance teaching and learning on campus and online.
                    </Typography>
                    <Typography color="#fff" component="h4" variant="body2">
                        3. Advance teaching and learning opportunities.
                    </Typography>
                </Grid>
            </Grid>
            {/* Who we help */}
            <Grid id="who-we-help" container style={{ marginTop: 60, marginBottom: 60 }} justifyContent="center" spacing={2} >
                <Grid item xs={12}>
                    <div style={{ marginTop: 20, marginBottom: 60 }}>
                        <Typography className="primary-head" align="center" variant="h5">Who do we help?</Typography>
                        <Typography style={{ marginTop: 10 }} align="center" variant="body1">
                            EdHub introduces fast, flexible and advanced capabilities to make learning more interesting and enjoyable.
                        </Typography>
                        <Typography align="center" variant="body1">
                            It's a concept that engages inperson and distance learning for teachers, students and parents.
                        </Typography>
                    </div>

                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons">
                        <span className="order-count">1</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Students</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Our mission is to help children everywhere lay a solid foundation for academic success and a lifelong love of learning.</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10, }} item xs={12} sm={4}>
                    <div className="help-icons">
                        <span className="order-count">2</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Parents</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">We make it easy for parents to understand and develop effective use of technology to help their child achieve the best learning experience.</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons">
                        <span className="order-count">3</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Teachers</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Our platform allows teachers and teaching assistants to maximize and develop the potential for individualised classroom learning curves and teaching styles.</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* What do we offer */}
            <Grid id="what-we-offer" container style={{ marginTop: 60, marginBottom: 60 }} spacing={4} >
                <Grid item xs={12}>
                    <div style={{ marginTop: 20, marginBottom: 30 }}>
                        <Typography className="primary-head" align="center" variant="h5">What do we offer?</Typography>
                        <Typography style={{ marginTop: 10 }} align="center" variant="body1">
                            EdHub Marketplace is free for everyone, everywhere. Teachers can create free or paid courses with just a few clicks of the mouse.
                        </Typography>
                        <Typography align="center" variant="body1">
                            Students from all over the world can take free courses or buy competitively-priced courses.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{ backgroundColor: 'white' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Create a Course in just a few clicks
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Free courses for all students
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Premium or paid courses
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Private courses with access codes
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Share private courses with students and parents
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ backgroundColor: 'white' }}>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Create a playlist with multiple recorded videos
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Attach multiple PDF worksheets
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Print worksheets option for mobile users
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Mobile friendly UI/UX operating systems
                    </Typography>
                    <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10 }} variant="body1">
                        <Icon style={{ fontSize: 26, marginRight: 10, color: '#1A95AC' }}>check_circle</Icon> Includes the option to download and print your worksheets efffortlessly
                    </Typography>
                </Grid>
            </Grid>
            {/* How It Works */}
            <Grid id="how-it-works" justifyContent="center" container style={{ marginTop: 60, marginBottom: 60 }} spacing={2}>
                <Grid item xs={12}>
                    <div style={{ marginTop: 20, marginBottom: 60 }}>
                        <Typography className="primary-head" align="center" variant="h5">How it works</Typography>
                        <Typography style={{ marginTop: 10 }} align="center" variant="body1">
                            We created this concept with one goal in mind user-friendliness and accessibility.
                        </Typography>
                        <Typography align="center" variant="body1">
                            Simply follow the steps below to kickstart your revolutionary Edhub Marketplace experience.
                        </Typography>
                    </div>

                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">1</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Get Registered</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Register by entering your email and creating a password or simply link
                                Edhub to your existing social media accout seamlessly using our platform's social media authentication capability.</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10, }} item xs={12} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">2</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Verify your identity</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Easily and quickly verify your email address using the verfication link sent to your registered email account.</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">3</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Create Courses</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Start creating your courses easily in just a few simple steps. You can attach multiple resources. i.e PDF worksheets or a recorded video to your course. </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">4</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Share your course</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Create a free or a paid course or simply develop a private course with access code and share it with your students via email, Whatsapp or Facebook.</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid style={{ marginTop: 10 }} item xs={12} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">5</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Get feedback</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Gather valuable feedback on each course that will help you to refine your course content and strategies.</Typography>
                        </div>
                    </div>
                </Grid>
                {/* <Grid style={{ marginTop: 10 }} item xs={6} sm={4}>
                    <div className="help-icons how">
                        <span className="order-count">6</span>
                        <div className="help-card-inner">
                            <Typography style={{ marginTop: 10 }} variant="h6">Stay happy</Typography>
                            <Typography style={{ marginTop: 10 }} variant="body2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Accusantium doloremque laudantium, totam rem aperiam</Typography>
                        </div>
                    </div>
                </Grid> */}
            </Grid>
            {/* Legal Terms */}
            <Grid id="legal-terms" container style={{ marginTop: 60, marginBottom: 60 }} spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <div style={{ marginTop: 20 }}>
                        <Typography className="primary-head" align="center" variant="h5">Legal Terms</Typography>
                        <Typography style={{ marginTop: 10 }} align="center" variant="body1">
                            The following terms apply to your use of the services and our platform.
                        </Typography>
                        <Typography align="center" variant="body1">
                            We encourage you to read them carefully.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Link style={{ textDecoration: 'none' }} to="/terms-service">
                            <Button color="primary">Terms & Conditions</Button>
                        </Link>
                        <Link style={{ textDecoration: 'none' }} to="/privacy-policy">
                            <Button color="primary">Privacy Policy</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            {/* Back to top button */}
            <BackToTop />
        </Container>
    )
}
