import axios from 'axios';
// const url = 'https://apiendpoints.edhub.school/public/index.php/api/'
// const corsUrl = 'https://apiendpoints.edhub.school/public/index.php/'
// const url = 'http://localhost:8080/api/'
// const corsUrl = 'http://localhost:8080/'
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Payments = {
    // Get List of all products
    getPaymentIntent: function (amount, customer, stripe_connect_id, type) {
        return axios({
            "method": "POST",
            "url": url + 'payment/intent',
            "headers": {
                "content-type": "application/octet-stream",
            }, "params": {
                "amount": amount,
                "customer": customer,
                "stripe_connect_id": stripe_connect_id,
                "type": type,
            }
        })
    },

    getSavedCards: function (customer) {
        return axios({
            "method": "POST",
            "url": url + 'payment/methods',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": JSON.stringify({ "customer": customer })
        })
    },

    storePayment: function (payment) {
        return axios({
            "method": 'POST',
            "url": url + "payment/store",
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": payment
        })
    },

    assignProducts: function (products, accounts, paymentIntent) {
        return axios({
            "method": 'POST',
            "url": url + "product/assign",
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": {
                products: products,
                accounts: accounts,
                paymentIntent: paymentIntent
            }
        })
    },

    createAliPayIntent: function (params) {
        return axios({
            "method": 'POST',
            "url": url + "payment/generatesignature",
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": params
        })
    },

    verifyAliPayIntent: function (ref, user, courses, subscriber, purchaseType) {

        return axios({
            "method": 'POST',
            "url": url + "payment/verifyalipaypayment",
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": {
                "paymentRequestId": ref,
                "courses": courses,
                "user": user,
                "subscriber": subscriber,
                "purchaseType": purchaseType
            }
        })
    }


}

export default Payments;