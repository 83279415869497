import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
    statusWrapper:{
        width:10,
        height:10,
        borderRadius:30,
        position:'absolute',
        bottom:15,
        rigth:-5,
        overflow:'hidden',
        display:'flex',
        justifyContent:'center',
    },
    dot:{
        width:10,
        height:10,
        borderRadius:10,
        color:'red'
    }
}))


export default function OnlineStatus() {
    const classes = useStyle()
  return (
    <div className={classes.statusWrapper}>
      <div className={classes.dot}></div>
    </div>
  )
}
