import React from 'react'

import './modalStyle.css';

export default function ImageViewer(props) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_QUALIFICATION_PATH + props.url
    return (
        <img width="100%" src={url} />
    )
}
