import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { useJwt } from 'react-jwt';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ProductService from '../../services/products';
import LinearProgress from '@material-ui/core/LinearProgress';
let sampleComments = [
    // {
    //     "name": 'Erik Steinbeck',
    //     "date": '22 Aug 2021',
    //     "rating": 5,
    //     "comment": "Dawn was hands down the best instructor I've ever worked with! Her knowledge, professionalism and presentation skills are simply world class!"
    // },
    // {
    //     "name": 'Chris Passaro',
    //     "date": '12 Aug 2021',
    //     "rating": 4,
    //     "comment": "An extremely helpful and informative course, especially in conjunction with multi-modal training. Training materials were well organized and provided good case studies. Instructor was extremely professional and pleasant to learn from."
    // }
]
const useStyles = makeStyles((theme) => ({
    commenterName: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5
    },
    singleComment: {
        backgroundColor: 'white',
        borderRadius: 10,
        marginBottom: 10,
        boxShadow: "2px 2px 6px #9e9e9e1a"
    },
    commentDate: {
        fontSize: 10,
        color: 'gray'
    },
    comment: {
        color: 'gray'
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 30,
        fontSize: 25,
        backgroundColor: '#ededed',
        color: '#cdcdcd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15
    },
    commentField: {
        width: '100%',
    },
    sendBtn: {
        marginTop: 10,
        backgroundColor: '#1a95ac',
        color: 'white',
        "&:hover": {
            backgroundColor: '#136a7a'
        }
    },
    earnedRating: {
        lineHeight: 1,
        fontWeight: 500,
        fontSize: 48
    },
    totalRating: {
        lineHeight: 1,
        fontWeight: 500,
        fontSize: 28
    },
    ratingIcon: {
        color: '#f2a602',
        fontSize: 32,
        marginTop: 10
    },
    smallRatingIcon: {
        color: '#f2a602',
        fontSize: 18,
    },
    addRatingIcon: {
        color: '#f2a602',
        fontSize: 24,
        cursor: 'pointer',
    },
    ratingQuestion: {
        fontWeight: 400
    },

    starsWrapper: {
        width: '40%'
    },
    progressWrapper: {
        width: '60%',
        display: 'flex',
        alignItems: 'center',
    },
    linerSingle: {
        height: 10,
        borderRadius: 5,
    },
    linearWrapper: {
        width: '90%',
        marginRight: 10
    },
    starsContainer: {
        padding: 20,
        paddingLeft: 30,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: 20
        }
    },
    singleRate: {
        marginBottom: 5
    },
    loginBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        '&:hover': {
            backgroundColor: '#c47818'
        }
    },
    rateBox: {
        paddingLeft: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0
        }
    },
    reviewsWrapper: {
        height: 250,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 20,
            height: 'auto'
        }
    },
    ratingForm: {
        marginBottom: 20, paddingLeft: 20, paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 5, paddingRight: 5,
        }
    },

}))

const CommentForm = (props) => {
    const classes = useStyles();
    const [newComment, setNewComment] = React.useState("")
    return (
        <Box boxShadow={1} display="flex" p={3} className={classes.singleComment}>
            <Box flexGrow={1}>
                <form>
                    <TextField
                        className={classes.commentField}
                        label="Add Comment"
                        multiline
                        value={newComment}
                        maxRows={4}
                        rows={3}
                        name="newComment"
                        variant="outlined"
                        onChange={e => setNewComment(e.target.value)}
                    />
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Button onClick={() => { props.handleAddComment(newComment); setNewComment("") }} className={classes.sendBtn} color="primary">Submit</Button>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default function Comments(props) {
    let comments = props.comments
    const isCalledRef = React.useRef(false);
    const classes = useStyles();
    // const ratingTypeKeys = ["content", "understanding", "relativity", "material"]
    const ratingTypeKeys = ["content"]
    const ratingTypes = ["Rate this course"]
    // const ratingTypes = ["Course Content", "Understanding", "Relativity", "Material"]
    // Array.prototype.push.apply(sampleComments, props.comments)
    const [usersComments, addComment] = useState([]);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const [open, setOpen] = React.useContext(AuthContext);
    const ratings = {
        "content": 0,
    }
    // const ratings = {
    //     "content": 0,
    //     "understanding": 0,
    //     "relativity": 0,
    //     "material": 0
    // }
    const [courseRatings, setCourseRatings] = React.useState(ratings)
    const { decodedToken, isExpired } = useJwt(token);
    const [newComment, setNewComment] = React.useState(null)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const handleAddComment = (userComment) => {
        let currentDate = new Date()
        let avgRating = 0;
        let sumRating = 0;
        console.log(Object.values(courseRatings))
        Object.values(courseRatings).forEach((value, index) => {
            sumRating += parseInt(value);
        })
        console.log(Object.values(courseRatings).length)
        avgRating = sumRating;
        // avgRating = sumRating / Object.values(courseRatings).length;


        let review = {
            "user_id": localStorage.getItem('user_id'),
            "course_id": props.id,
            "course_content": courseRatings['content'],
            // "understanding": courseRatings['understanding'],
            // "relativity": courseRatings['relativity'],
            // "material": courseRatings['material'],
            "rating": Math.floor(avgRating),
            "comment": userComment,
            "added_at": new Date(),
            "subscriber_id": localStorage.getItem('subscriber_id')
        }
        ProductService.addReview(review).then(res => {
            console.log(res.data)
            let comment = {
                "name": res.data.name,
                "username": res.data.name,
                "date": currentDate.getDate() + ' ' + months[currentDate.getMonth()] + ' ' + currentDate.getFullYear(),
                "rating": Math.floor(avgRating),
                "comment": userComment
            }
            addComment(prevComments => [...prevComments, comment])
        })
    }

    const handleAuth = () => {
        setOpen(true)
    }

    const handleRatings = (key, value) => {
        let newRating = { ...courseRatings };
        newRating[key] = value;
        setCourseRatings(newRating)
    }

    const RatingForm = () => {
        return (
            <div className={classes.ratingForm}>
                {
                    ratingTypeKeys.map((value, index) => {
                        return (
                            <Grid container key={index} justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" className={classes.ratingQuestion}>{ratingTypes[index]}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Icon onClick={() => handleRatings(value, 1)} className={classes.addRatingIcon}>{courseRatings[value] > 0 ? 'star' : 'star_outline'}</Icon>
                                        <Icon onClick={() => handleRatings(value, 2)} className={classes.addRatingIcon}>{courseRatings[value] > 1 ? 'star' : 'star_outline'}</Icon>
                                        <Icon onClick={() => handleRatings(value, 3)} className={classes.addRatingIcon}>{courseRatings[value] > 2 ? 'star' : 'star_outline'}</Icon>
                                        <Icon onClick={() => handleRatings(value, 4)} className={classes.addRatingIcon}>{courseRatings[value] > 3 ? 'star' : 'star_outline'}</Icon>
                                        <Icon onClick={() => handleRatings(value, 5)} className={classes.addRatingIcon}>{courseRatings[value] > 4 ? 'star' : 'star_outline'}</Icon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </div>
        )
    }

    const handleCommentChange = (event) => {
        event.preventDefault();
        setNewComment(event.target.value)
    }



    // const CommentField = React.memo(CommentForm)

    const UserRating = (props) => {
        return (
            <Grid container alignItems="center">
                <Icon className={classes.smallRatingIcon}>{props.rating > 0 ? 'star' : 'star_outline'}</Icon>
                <Icon className={classes.smallRatingIcon}>{props.rating > 1 ? 'star' : 'star_outline'}</Icon>
                <Icon className={classes.smallRatingIcon}>{props.rating > 2 ? 'star' : 'star_outline'}</Icon>
                <Icon className={classes.smallRatingIcon}>{props.rating > 3 ? 'star' : 'star_outline'}</Icon>
                <Icon className={classes.smallRatingIcon}>{props.rating > 4 ? 'star' : 'star_outline'}</Icon>
            </Grid>
        )
    }

    const RatingsStats = (props) => {
        let ratingCount = usersComments.filter(comment => comment.rating == props.rating).length
        let percent = ((ratingCount / usersComments.length) * 100).toFixed(0)
        let stars = []
        for (let i = 0; i < props.rating; i++) {
            stars.push(
                <Icon key={i} className={classes.smallRatingIcon}>star</Icon>
            )
        }
        return (
            <Grid container className={classes.singleRate} alignItems="center" justifyContent="space-between">
                <div className={classes.starsWrapper}>
                    {stars}
                </div>
                <div className={classes.progressWrapper}>
                    <div className={classes.linearWrapper}>
                        <LinearProgress className={classes.linerSingle} variant="determinate" value={parseInt(percent)} />
                    </div>
                    <span>{ratingCount}</span>
                </div>
            </Grid>
        )
    }

    const getComments = () => {

        // let comments = props.comments;
        // comments.forEach((comment, index) => {
        //     console.log(comment.rating)
        // })
        // addComment(sampleComments)
    }

    React.useEffect(() => {
        sampleComments = []
        console.log(props.comments)
        if (props.comments) {
            if (props.comments.length > 0) {
                props.comments.forEach((comment, index) => {
                    sampleComments.push(comment)
                })
                addComment(sampleComments)
            }
            else {
                addComment([])
            }
        }


    }, [])

    return (
        <>
            <Grid container alignItems="flex-start">
                <Grid item xs={12} sm={props.showForm ? 6 : 12}>
                    <Grid container maxWidth={'sm'} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Grid container alignItems="flex-end" style={{ fontFamily: 'Circular-Loom' }}>
                                <span className={classes.earnedRating}>{Math.floor(props.rating)}</span>
                                <span variant="h4" className={classes.totalRating}>/5</span>
                            </Grid>
                            <Grid container alignItems="center">
                                <Icon className={classes.smallRatingIcon}>{props.rating > 0 ? 'star' : 'star_outline'}</Icon>
                                <Icon className={classes.smallRatingIcon}>{props.rating > 1 ? 'star' : 'star_outline'}</Icon>
                                <Icon className={classes.smallRatingIcon}>{props.rating > 2 ? 'star' : 'star_outline'}</Icon>
                                <Icon className={classes.smallRatingIcon}>{props.rating > 3 ? 'star' : 'star_outline'}</Icon>
                                <Icon className={classes.smallRatingIcon}>{props.rating > 4 ? 'star' : 'star_outline'}</Icon>
                            </Grid>
                            <Typography variant="body2">{Math.floor(props.rating)} star rating and {usersComments.length} reviews</Typography>
                        </Grid>
                        <Grid className={classes.starsContainer} item xs={12} sm={8}>
                            <RatingsStats rating={5} />
                            <RatingsStats rating={4} />
                            <RatingsStats rating={3} />
                            <RatingsStats rating={2} />
                            <RatingsStats rating={1} />
                        </Grid>
                    </Grid>
                    {
                        usersComments.length == 0 &&
                        <Grid container className={classes.reviewsWrapper} justifyContent="center" alignItems="center">
                            <Grid item>
                                <span>No Reviews</span>
                            </Grid>
                        </Grid>
                    }
                    {usersComments.map((comment, index) => {
                        let commentDate = new Date(comment.date)
                        return (
                            <Grid key={index}>
                                <Box boxShadow={1} display="flex" p={1} className={classes.singleComment}>
                                    <Box>
                                        <div className={classes.avatarWapper}>
                                            <Icon className={classes.avatar}>person</Icon>
                                        </div>
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Grid container alignItems="flex-start" justifyContent="space-between" className={classes.commenterHeader}>
                                            <span className={classes.commenterName}>{comment.username}</span>
                                            <span className={classes.commentDate}>{commentDate.getDate() + ' ' + months[commentDate.getMonth()] + ' ' + commentDate.getFullYear()}</span>
                                        </Grid>
                                        <UserRating rating={comment.rating} />
                                        <Typography variant="body2" className={classes.comment}>{comment.comment}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.rateBox}>
                    {
                        // !isExpired && props.showForm &&
                        props.showForm ?
                            <>
                                <h3>How would you rate this course?</h3>
                                <RatingForm />
                                <CommentForm handleAddComment={handleAddComment} newComment={newComment} handleCommentChange={handleCommentChange} />
                            </>
                            :
                            <Grid container justifyContent="center" alignItems="center" direction="column">
                                {/* <Button onClick={handleAuth} className={classes.loginBtn}>Login</Button> */}
                                {/* <h3>Login to add your comment and review the product.</h3>
                            <Button onClick={handleAuth} className={classes.loginBtn}>Login</Button> */}
                            </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}