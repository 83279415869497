import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    root: {
        width: '100%',
        "& .MuiLinearProgress-root.MuiLinearProgress-determinate": {
            backgroundColor: "#F6F6F6",
        },
        "& .progress  .MuiLinearProgress-bar.MuiLinearProgress-bar1Determinate": {
            backgroundColor: "#FA9C24",
        },
        "& .completed  .MuiLinearProgress-bar.MuiLinearProgress-bar1Determinate": {
            backgroundColor: "#60974A",
        },
        borderRadius: 0,
        overflow: 'hidden'
    },
});

export default function RingLoader(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(10);

    return (
        <Grid container className="ring-loader" style={{ marginTop: 20 }} alignItems="flex-start" justifyContent="center">
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box width="25%" mr={1}>
                    <img src="/assets/ringloader.gif" width="100%" />
                </Box>
                <Box>
                    <Typography variant="h5" style={{ marginBottom: 30 }} color="textSecondary">{props.title}</Typography>
                </Box>
            </Box>
        </Grid>
    );
}