import React, {useState} from 'react'
import {Button} from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function BackToTop() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
      
      window.addEventListener('scroll', toggleVisible);

    return (
        <Button onClick={scrollToTop} style={{display: visible ? 'flex' : 'none',alignItems:'center',justifyContent:'center',backgroundColor:'orange',padding:8,position:'fixed',bottom:30,left:20}}>
            <KeyboardArrowUpIcon style={{color:'white',fontSize:28}}  />
        </Button>
    )
}
