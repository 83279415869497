import React from 'react'
import {Modal} from 'antd'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';

export default function SlotsDialog({selectedSlots,openTimeSlot,setOpenTimeSlot,setOpenModal,bookedTrialsObject,selectTimeSlot}) {
  return (
    <Modal
      title="Select time slots"
      centered
      open={openTimeSlot}
      onOk={() => {setOpenTimeSlot(false);setOpenModal(true)}}
      onCancel={() => setOpenTimeSlot(false)}
      width={800}
    >
      {
        bookedTrialsObject.map(trial => (
          <div>
          <div style={{display:'flex',alignItems:'center',marginBottom:10}}>
              <div style={{fontSize:20,fontWeight:500}}>{trial.duration+" "} </div>
              <div style={{fontSize:20,fontWeight:500,marginLeft:10}}>{trial.price}<small> HKD</small></div>
          </div>
          {
            trial.slots.map(slot => (
            <div onClick={() => selectTimeSlot(slot.slot_id,slot.trial_time,slot.trial_date,trial.price_id)} 
            style={{
              cursor:'pointer',width:'33%',flexWrap:'wrap',display:'flex',alignItems:'center',justifyContent:'space-between',padding:8,borderRadius:5,marginBottom:10,
              border:selectedSlots[trial.price_id] == slot.slot_id ? 'solid 2px green' : 'solid 1px #cdcdcd', backgroundColor:selectedSlots[trial.price_id] == slot.slot_id ? '#f7fef7' : 'white'
              }}>
                <div style={{display:'flex',alignItems:'center'}}><DateRangeOutlinedIcon style={{marginRight:5,fontSize:18}}/> {slot.trial_date}</div>
                <div style={{display:'flex',alignItems:'center'}}><QueryBuilderOutlinedIcon style={{marginRight:5,fontSize:18}}/>{slot.trial_time}</div>
            </div>
            ))
          }
          </div>
        ))
      }
    </Modal>
  )
}
