import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Box width="25%" mr={1}>
                <LinearProgress color="red" style={{ height: 20, borderRadius: 0 }} variant="determinate" {...props} />
            </Box>
            <Box minWidth={5}>
                <Typography variant="h6" style={{ marginTop: 10 }} color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        "& .MuiLinearProgress-root.MuiLinearProgress-determinate": {
            backgroundColor: "#F6F6F6",
        },
        "& .MuiLinearProgress-bar.MuiLinearProgress-bar1Determinate": {
            backgroundColor: "#FA9C24",
        },
        borderRadius: 0,
        overflow: 'hidden'
    },
});

export default function StaticLoader(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(10);
    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 5 : prevProgress + 5));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="loading-wrapper">
            <Grid container className="ring-loader-full" style={{ marginTop: 20 }} alignItems="flex-start" justifyContent="center">
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <Box width="50%" mr={1}>
                        <img src="/assets/ringloader.gif" width="100%" />
                    </Box>
                    <Box>
                        <Typography variant="h5" style={{ marginBottom: 30 }} color="textSecondary">{props.title}</Typography>
                    </Box>
                </Box>
            </Grid>
        </div>
    );

    // return (
    //     <div className="loading-wrapper">
    //         <img src="/assets/ringloader.gif" />
    //         <p>10%</p>
    //     </div>
    // )
}