import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyle = makeStyles((theme)=>({
    root:{
        marginTop:5,
        marginBottom:5
    },
    pircesWrapper:{
        
    },
    priceText:{
        fontSize:16
    }
}))

export default function Trials({trial}) {
    const classes = useStyle()
  return (
    <div>
      <div className={classes.pircesWrapper}>
        <Typography className={classes.priceText} variant="h6">{trial.price} <small>HKD</small></Typography>
        <Typography variant="body2">{trial.duration}</Typography>
      </div>
    </div>
  )
}
