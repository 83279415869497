import React from 'react'
import StarIcon from '@material-ui/icons/Star';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        alignItems:'center',
        marginTop:5,
        marginBottom:5
    },
    star:{
        color:'#00C1E2',
        fontSize:20
    },
    rating:{
        fontSize:14,
        marginLeft:5,
    },
    reviews:{
        fontSize:14,
        marginLeft:5,
    },
    separator:{
        fontSize:5,
        marginLeft:5
    }

}))

export default function Rating({props}) {
    const {rating,reviews} = props
    const classes = useStyles()

  return (
    <div className={classes.root}>
      <StarIcon className={classes.star}/>
      <span className={classes.rating}>{rating}</span>
      <FiberManualRecordIcon className={classes.separator}/>
      <span className={classes.reviews}>{reviews} reviews</span>
    </div>
  )
}
