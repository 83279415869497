import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

const download = url => {
    return fetch(url).then(resp => resp.blob());
};

const downloadByGroup = (urls, files_per_group = 5) => {
    return Promise.map(
        urls,
        async url => {
            console.log(url)
            return await download(url);
        },
        { concurrency: files_per_group }
    );
}

const downloadMany = (urls, teacher) => {
    return Promise.all(urls.map(url => download(process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_QUALIFICATION_PATH + url.doc_path)))
}


const exportZip = (blobs, files, teacher) => {
    const zip = JsZip();
    blobs.forEach((blob, i) => {
        zip.file(`EdHub_teacher_docs_${teacher}/${files[i]['title']}`, blob);
    });
    zip.generateAsync({ type: 'blob' }).then(zipFile => {
        const currentDate = new Date().getTime();
        const fileName = `EdHub_teacher_docs_${teacher}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
    });
}

export const downloadAndZip = (urls, teacher) => {
    return downloadMany(urls, teacher).then(blob => exportZip(blob, urls, teacher));
}
// import JSZip from "jszip";
// import { saveAs } from "file-saver";



// const download = (teacher, zip) => {
//     zip.generateAsync({ type: "blob" }).then(function (blob) {
//         saveAs(blob, `EdHub_Teacher_${teacher}.zip`);

//     });
// };

// export const generateZip = (arr, teacher) => {
//     console.log(arr)
//     var zip = JSZip();
//     let index = 0;
//     if (arr.length) {
//         for (const element of arr) {
//             index += 1
//             zip.file(`EdHub_Teacher_${teacher}/Doc_${element.title}`, element.file);
//             if (index == arr.length)
//                 download(teacher, zip);
//         }

//     }
// };