export const TableData = [
  {
    sale_id: 39,
    teacher_id: 2260,
    sale_price: 2354,
    currency: "hkd",
    updated_at: "2022-05-14 21:50:14",
    created_at: "2022-05-13 00:00:00",
    email: "waleedumer42@gmail.com",
    first_name: "Waleed",
    last_name: "Umer",
    display_name: "Waleed Umer",
    total_sale: 3354,
    totalPayouts: 2000,
    date: "2022-05-12",
  },
  {
    sale_id: 40,
    teacher_id: 2261,
    sale_price: 2355,
    currency: "hkd",
    updated_at: "2022-05-16 22:50:14",
    created_at: "2022-05-15 00:00:00",
    email: "hamzambf@gmail.com",
    first_name: "Hamza",
    last_name: "Siddique",
    display_name: "Hamza Siddique",
    total_sale: 3355,
    totalPayouts: 2001,
    date: "2022-03-23",
  },
  {
    sale_id: 41,
    teacher_id: 2262,
    sale_price: 2356,
    currency: "hkd",
    updated_at: "2022-05-18 22:50:14",
    created_at: "2022-05-17 00:00:00",
    email: "talhaaltaf5545@gmail.com",
    first_name: "Talha",
    last_name: "Altaf",
    display_name: "Talha Altaf",
    total_sale: 3356,
    totalPayouts: 2002,
    date: "2022-02-10",
  },
  {
    sale_id: 42,
    teacher_id: 2260,
    sale_price: 2354,
    currency: "hkd",
    updated_at: "2022-05-14 21:50:14",
    created_at: "2022-05-13 00:00:00",
    email: "waleedumer42@gmail.com",
    first_name: "Waleed",
    last_name: "Umer",
    display_name: "Waleed Umer",
    total_sale: 3354,
    totalPayouts: 2000,
    date: "2022-04-17",
  },
  {
    sale_id: 43,
    teacher_id: 2261,
    sale_price: 2355,
    currency: "hkd",
    updated_at: "2022-05-16 22:50:14",
    created_at: "2022-05-15 00:00:00",
    email: "hamzambf@gmail.com",
    first_name: "Hamza",
    last_name: "Siddique",
    display_name: "Hamza Siddique",
    total_sale: 3355,
    totalPayouts: 2001,
    date: "2022-01-31",
  },
  {
    sale_id: 44,
    teacher_id: 2262,
    sale_price: 2356,
    currency: "hkd",
    updated_at: "2022-05-18 22:50:14",
    created_at: "2022-05-17 00:00:00",
    email: "talhaaltaf5545@gmail.com",
    first_name: "Talha",
    last_name: "Altaf",
    display_name: "Talha Altaf",
    total_sale: 3356,
    totalPayouts: 2002,
    date: "2021-07-11",
  },
  {
    sale_id: 45,
    teacher_id: 2262,
    sale_price: 2356,
    currency: "hkd",
    updated_at: "2022-05-18 22:50:14",
    created_at: "2022-05-17 00:00:00",
    email: "aliahmed2234@gmail.com",
    first_name: "Ali",
    last_name: "Ahmed",
    display_name: "Ali Ahmed",
    total_sale: 3356,
    totalPayouts: 2002,
    date: "2021-09-24",
  },
  {
    sale_id: 46,
    teacher_id: 2262,
    sale_price: 2356,
    currency: "hkd",
    updated_at: "2022-05-18 22:50:14",
    created_at: "2022-05-17 00:00:00",
    email: "Haiderali008@gmail.com",
    first_name: "Haider",
    last_name: "Ali",
    display_name: "Haider Ali",
    total_sale: 3356,
    totalPayouts: 2002,
    date: "2021-10-09",
  },
];

export const newData = [
  {
    title: "Setting",
    earnings: 85,
    downloaded: 120,
    uploaded: "2019-05-23",
  },
  {
    title: "Mountain",
    earnings: 56,
    downloaded: 80,
    uploaded: "2021-04-17",
  },
  {
    title: "Conservation",
    earnings: 40,
    downloaded: 60,
    uploaded: "2022-03-11",
  },
  {
    title: "Music Apps",
    earnings: 38,
    downloaded: 48,
    uploaded: "2019-06-01",
  },
];
