import React, { useState, useEffect, useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import { BuyNowContext } from '../../context/BuyNowContext';
import { UserContext } from '../../context/UserContext';
import PaymentService from '../../services/payments';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export default function Wallet(props) {

    const { buy } = useContext(BuyNowContext)
    const { user } = useContext(UserContext);
    const [currentUser, setCurrentUser] = user;
    const [buyProduct, setBuyProduct] = buy;
    let orderReferenceID = 0;
    // let loading = false;
    let subTotal = props.total;
    const stripe_connect_id = props.stripeConnect
    const course_teacher = props.courseTeacher
    let lineItems = props.lineItems;
    const [cartItem, setCartItem] = useContext(CartContext);

    const clearCart = () => {
        setCartItem([])
    }


    const stripePayment = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);

    async function confirmWalletPayment(e, clientSecret) {
        const { error, paymentIntent } = await stripePayment.confirmCardPayment(clientSecret, {
            payment_method: e.paymentMethod.id
        }, {
            handleActions: false
        }
        )
        if (error) {
            e.complete('fails');
            return
        }

        e.complete('success')
        console.log(e.data)
        var data = JSON.stringify({
            "user_id": localStorage.getItem('user_id'),
            "transaction_id": paymentIntent.id,
            "reference_id": paymentIntent.payment_method,
            "invoice_url": '',
            "name_on_card": '',
            "email": '',
            "amount": paymentIntent.amount,
            "created_at": null,
            "subscriber_id": localStorage.getItem('subscriber_id')
        })
        var courses = [];
        props.confirmDialog()
        // PaymentService.storePayment(data).then(response => {
        //     console.log(response)
        //     if (props.type == 'single') {
        //         courses.push(
        //             {
        //                 user_id: localStorage.getItem('user_id'),
        //                 course_id: props.productId,
        //                 payment_id: response.data.id,
        //                 subscriber_id: localStorage.getItem('subscriber_id'),
        //                 // Fields for sales
        //                 teacher_id: course_teacher,
        //                 sale_price: paymentIntent.amount,
        //                 created: paymentIntent.created,
        //                 currency: paymentIntent.currency,
        //                 payment_method: paymentIntent.payment_method,
        //                 status: paymentIntent.status,
        //             }
        //         )
        //     }
        //     if (props.type == 'cart') {
        //         cartItem.forEach((item, index) => {
        //             courses.push(
        //                 {
        //                     user_id: localStorage.getItem('user_id'),
        //                     course_id: item.course_id,
        //                     payment_id: response.data.id,
        //                     subscriber_id: localStorage.getItem('subscriber_id'),
        //                     // Fields for sales
        //                     teacher_id: course_teacher,
        //                     sale_price: paymentIntent.amount,
        //                     created: paymentIntent.created,
        //                     currency: paymentIntent.currency,
        //                     payment_method: paymentIntent.payment_method,
        //                     status: paymentIntent.status,
        //                 }
        //             )
        //         })
        //     }
        //     PaymentService.assignProducts(courses).then(response => {
        //         clearCart()
        //         props.confirmDialog()
        //     }, error => {
        //         alert('Error')
        //     })
        // }, error => {
        //     alert('Something went wrong',error)
        // })
    }

    useEffect(() => {
        if (!stripePayment) {
            return;
        }

        const pr = stripePayment.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            displayItems: lineItems,
            total: {
                label: 'Total',
                amount: subTotal,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        console.log(pr)
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            console.log('r', result)
            setFetching(false)
            if (result) {
                setPaymentRequest(pr);
                // setPaymentMethod('wallets')
            }

        });

        pr.on('paymentmethod', async (e) => {
            console.log('method')
            PaymentService.getPaymentIntent(subTotal, currentUser.stripe_customer_id, stripe_connect_id, props.type).then((response) => {
                confirmWalletPayment(e, response.data.clientSecret)
            })
        })
    }, [stripePayment]);

    const handleAliPayPayment = () => {
        var amount = 0;
        var courseIds = [];
        if (props.type == 'single') {
            amount = (buyProduct.discounted_price != null && buyProduct.discounted_price != '' ? buyProduct.discounted_price : buyProduct.course_tution_fee) * 100
            courseIds.push(buyProduct.course_id)
        }
        if (props.type == 'cart') {
            cartItem.forEach((item, index) => {
                amount += parseFloat((item.discounted_price != null && item.discounted_price != '' ? item.discounted_price : item.course_tution_fee))
                courseIds.push(item.course_id)
            })
            amount = amount * 100
            courseIds = courseIds
        }
        setLoading(true);
        orderReferenceID = "MP-" + (new Date()).getTime()
        var data = {
            referenceOrderId: orderReferenceID,
            currency: "HKD",
            amount: amount,
            notifyUrl: "https://www.alipay.com/notify",
            redirectUrl: "https://www.alipay.com/notify",
            terminal: "WEB",
            courseIds: courseIds,
            purchaseType: props.type,
            userId: localStorage.getItem('user_id'),
            subscriberId: localStorage.getItem('subscriber_id')
        }
        PaymentService.createAliPayIntent(data).then((response) => {
            console.log(response)
            setLoading(false);
            // var windowReference = window.open();
            if (response.data.status == 'Success') {
                const link = response.data.intent.redirectActionForm.redirectUrl;

                const a = document.createElement("a");
                a.setAttribute('href', link);
                a.setAttribute('target', '_blank');
                a.click();
                // window.location.assign(response.data.intent.redirectActionForm.redirectUrl);
                // window.open(response.data.intent.redirectActionForm.redirectUrl)
            }
        })
    }

    const testWallet = () => {
        var data = JSON.stringify({
            "user_id": localStorage.getItem('user_id'),
            "transaction_id": 'paymentIntent.id',
            "reference_id": 'paymentIntent.payment_method',
            "invoice_url": '',
            "name_on_card": '',
            "email": '',
            "amount": 1000,
            "created_at": null,
            "subscriber_id": localStorage.getItem('subscriber_id')
        })
        var courses = [];
        PaymentService.storePayment(data).then(response => {
            console.log(response)
            courses.push(
                {
                    user_id: localStorage.getItem('user_id'),
                    course_id: 232,
                    payment_id: 'p20000000',
                    subscriber_id: localStorage.getItem('subscriber_id'),
                    // Fields for sales
                    teacher_id: 231,
                    sale_price: 1000,
                    created: 123456,
                    currency: 'hkd',
                    payment_method: 'pm_rt54e',
                    status: 'tested',
                }
            )
            PaymentService.assignProducts(courses).then(response => {
                clearCart()
                console.log(response)
                props.confirmDialog()
            }, error => {
                alert('Error')
            })
        }, error => {
            alert('Something went wrong', error)
        })
    }

    // return <>
    //     <Grid container spacing="2" alignItems="center" justifyContent="center">
    //         <Grid item xs={4}>
    //             <ButtonBase>
    //                 <img width="100%" src="/assets/icons/googlepay.png" />
    //             </ButtonBase>
    //         </Grid>
    //         <Grid item xs={4}>
    //             <ButtonBase>
    //                 <img width="100%" src="/assets/icons/applepay.png" />
    //             </ButtonBase>
    //         </Grid>
    //         <Grid item xs={4}>
    //             {
    //                 loading ?
    //                     <span>Redirecting...</span>
    //                     :
    //                     <ButtonBase onClick={handleAliPayPayment}>
    //                         <img width="100%" src="/assets/icons/alipay.png" />
    //                     </ButtonBase>
    //             }
    //         </Grid>
    //     </Grid>
    // </>
    return (
        <>
            {/* <Button onClick={testWallet}>Test</Button> */}
            {
                fetching ?
                    <p style={{ textAlign: 'center', cursor: 'pointer', marginTop: 40, marginBottom: 0, fontSize: 16, color: '#1d4480', fontWeight: 500, marginLeft: 20, marginRight: 20 }}>Loading...</p>
                    :
                    paymentRequest ?
                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                            {/* {props.handleWalletAvailability(true)} */}
                            <p style={{ textAlign: 'center', color: '#000', fontWeight: 500, marginTop: 20, marginBottom: 30, fontSize: 18 }}>Select your payment method</p>
                            <PaymentRequestButtonElement options={{ paymentRequest }} />
                            <p onClick={() => props.handlePaymentMethodChange('credit-card')} style={{ textAlign: 'center', cursor: 'pointer', marginTop: 40, marginBottom: 0, fontSize: 16, color: '#1d4480', fontWeight: 500 }}>Pay via credit card</p>
                        </div>
                        :
                        <>
                            <div style={{ marginLeft: 30, marginRight: 30 }}>
                                <p style={{ textAlign: 'center', cursor: 'pointer', marginTop: 40, marginBottom: 0, fontSize: 20, color: '#cdcdcd', width: '100%', fontWeight: 500 }}>No wallet available</p>
                                {
                                    props.type == 'single' &&
                                    <p onClick={() => props.handlePaymentMethodChange('credit-card')} style={{ textAlign: 'center', cursor: 'pointer', marginTop: 40, marginBottom: 0, fontSize: 16, color: '#1d4480', fontWeight: 500 }}>Pay via credit card</p>
                                }
                            </div>
                        </>

            }
        </>
    )

    return null;
}