import React from 'react'

export const BookingContext = React.createContext()

export const BookingProvider = props => {

    const [bookedTrials,setBookedTrials] = React.useState([])
    const [bookedTrialsObject,setBookedTrialsObject] = React.useState([])
    const [bookedTeachers,setBookedTeachers] = React.useState([])

    const addTrials = (trialId,teacherId,trial) => {
        setBookedTrials([...bookedTrials,trialId])
        setBookedTeachers([...bookedTeachers,teacherId])
        setBookedTrialsObject([...bookedTrialsObject,trial])
    }

    const removeTrials = (trialId,teacherId) => {
        setBookedTrials(bookedTrials.filter(trial => trial != trialId))
        setBookedTeachers(bookedTeachers.filter(teacher => teacher != teacherId))
        setBookedTrialsObject(bookedTrialsObject.filter(trial => trial.price_id != trialId))
    }

    const clearSelection = () => {
        setBookedTrials([])
        setBookedTeachers([])
        setBookedTrialsObject([])
    }

    React.useEffect(() => {
        console.log(bookedTeachers,bookedTrialsObject,bookedTrials)
    },[bookedTeachers]) 

  return (
    <BookingContext.Provider value={{bookedTrials,addTrials,removeTrials,setBookedTrialsObject,bookedTeachers,bookedTrialsObject,clearSelection}}>
        {props.children}
    </BookingContext.Provider>
  )
}
