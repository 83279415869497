import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";

function StudentBookingDetails({ bookingData, show, closeModal }) {
  const date = new Date(bookingData?.startStr);

  const handleCancel = () => {
    closeModal();
  };

  const Footer = () => {
    return bookingData?.extendedProps.venue != null &&
      bookingData?.extendedProps.venue != "" ? (
      <a href={bookingData?.extendedProps.venue} target="blank">
        <Button
          style={{
            backgroundColor: "#1A95AC",
            color: "white",
            border: "none",
            borderRadius: 5,
          }}
          type="primary"
        >
          Join lesson
        </Button>
      </a>
    ) : null;
  };

  return (
    <>
      <Modal
        footer={<Footer />}
        width={"50%"}
        title={bookingData?.title}
        open={show}
        onCancel={handleCancel}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <PersonOutlineOutlinedIcon
            style={{ marginRight: "10px", fontSize: 30 }}
          />
          <h2 style={{ margin: 0 }}>{bookingData?.extendedProps.teacher}</h2>
        </div>
        <hr style={{ marginTop: 10, marginBottom: 0, opacity: 0.2 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <EventAvailableOutlinedIcon style={{ marginRight: "10px" }} />
              <h3 style={{ margin: 0 }}>Date</h3>
            </div>
            <h4>
              {date.getDate() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getFullYear()}
            </h4>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ScheduleOutlinedIcon style={{ marginRight: "10px" }} />
              <h3 style={{ margin: 0 }}>Time</h3>
            </div>
            <h4>{bookingData?.extendedProps.time}</h4>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MonetizationOnOutlinedIcon style={{ marginRight: "10px" }} />
              <h3 style={{ margin: 0 }}>Price</h3>
            </div>
            <h4>HKD {bookingData?.extendedProps.price}</h4>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#d8efff",
            padding: 10,
            borderRadius: 5,
            marginBottom: 10,
          }}
        >
          <h3 style={{ display: "flex", alignItems: "center" }}>
            <VideocamOutlinedIcon
              style={{ marginRight: 10, marginBottom: 0 }}
            />{" "}
            <span>{bookingData?.extendedProps.venue}</span>
          </h3>
        </div>
      </Modal>
    </>
  );
}

export default StudentBookingDetails;
