import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useJwt } from "react-jwt";
import { UserContext } from '../context/UserContext';
import PageLoader from '../components/shared/loaders/pageLoader';
import Auth from '../services/auth';
import jwt_decode from "jwt-decode";
import roles from '../routes/roles';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { role } = React.useContext(UserContext)
    const [userRole, setUserRole] = role;
    const { user } = React.useContext(UserContext)
    const { isModified } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const { decodedToken, isExpired } = useJwt(token);
    const [loading, setLoading] = React.useState(true);

    // if (isExpired) {
    //     setToken('na')
    //     setCurrentUser({})
    //     localStorage.clear()
    // }

    React.useEffect(() => {
        const authenticate = async () => {
            const localtoken = await localStorage.getItem("jwt_token");
            Auth.authenticateUser(localtoken).then(response => {
                setLoading(false)
                if (response.data.status != 'success') {
                    localStorage.clear()
                    setToken('na')
                    setCurrentUser({})
                    setUserRole(null)
                }
            })
        };
        authenticate();
    }, []);

    return (
        isExpired ?
            <Route {...rest}
                render={props => {

                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }}
                    />

                }}
            />
            :
            loading ?
                <>
                    <PageLoader />
                </>
                :
                <>
                    <Route {...rest}
                        render={props => {
                            if (!isExpired) {
                                return <Component {...props} />
                            }
                            else {
                                return <Redirect to={{
                                    pathname: "/",
                                    state: {
                                        from: props.location
                                    }
                                }}
                                />
                            }
                        }}
                    />
                </>
    )
}