import React from 'react';
import * as Mui from '@material-ui/core';
import '../css/passCode.css';

export default function RedirectPopUp(props) {
    return (
        <div className="body-wrapper">
            <Mui.Grid container justifyContent="center" alignItems="center" maxWidth="sm">
                <Mui.Grid item className="" xs={12} sm={8} md={4}>
                    <h2 style={{ textAlign: 'center' }}>{props.title}</h2>
                </Mui.Grid>
            </Mui.Grid>
        </div>
    )
}
