import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useJwt } from "react-jwt";
import { UserContext } from '../context/UserContext';
import PageLoader from '../components/shared/loaders/pageLoader';
import Auth from '../services/auth';
import jwt_decode from "jwt-decode";
import roles from './roles';

export const AdminRoute = ({ component: Component, ...rest }) => {
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { role } = React.useContext(UserContext)
    const [userRole, setUserRole] = role;
    const { user } = React.useContext(UserContext)
    const { isModified } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const [jwtModified, setJwtModified] = isModified;
    const { decodedToken, isExpired } = useJwt(token);
    const [loading, setLoading] = React.useState(true);
    const [accessible, setAccessible] = React.useState(null);

    // if (isExpired) {
    //     setToken('na')
    //     setCurrentUser({})
    //     localStorage.clear()
    // }

    React.useEffect(() => {

        const authenticate = async () => {
            const token = await localStorage.getItem("jwt_token");
            // console.log(token)
            Auth.authenticateAdmin(token).then(response => {
                console.log(response.data)
                if (response.data.status != 'invalid') {
                    if (roles[response.data.response.auth_type] == rest.scope) {
                        setAccessible(true);
                        setLoading(false)
                    }

                    Auth.authenticateAdmin(token).then(response => {

                        if (roles[response.data.response.auth_type] == rest.scope) {
                            setAccessible(true);
                            setLoading(false)
                        }
                        else {
                            setAccessible(false)
                            setLoading(false)
                            // if (response.data.status !== 'success') {
                            //     localStorage.clear()
                            //     setToken('na')
                            //     setCurrentUser({})
                            // }
                        }
                    })
                }
            })
        };
        authenticate();
    }, []);

    return (
        isExpired ?
            <Route {...rest}
                render={props => {

                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }}
                    />

                }}
            />
            :
            loading ?
                <>
                    <PageLoader />
                </>
                :
                <>
                    <Route {...rest}
                        render={props => {
                            // if (accessible) {
                            if (!isExpired && accessible) {
                                return <Component {...props} />
                            }
                            else {
                                return <Redirect to={{
                                    pathname: "/",
                                    state: {
                                        from: props.location
                                    }
                                }}
                                />
                            }
                        }}
                    />
                </>
    )
}