import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import Icon from '@material-ui/core/Icon';
import './styles.css';

export default function DropZoneUploader({ title, sub_titles, icon, handler }) {
    // const [selectedFiles, setSelectedFiles] = React.useState([])
    const onDrop = useCallback(files => {
        handler(files)
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
        <div className="drop-zone-wrapper" {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon className="upload">{icon}</Icon>
            <h3>{title}</h3>
            <h5>{sub_titles}</h5>
        </div>
    )
}
