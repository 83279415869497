import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function activeTabs(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ClassesTabs({ actual, trials,handleEditSlot }) {
  const classes = useStyles();
  let { tab } = useParams();
  const [value, setValue] = React.useState(tab === "trial" ? 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleEditSlot()
  };

  return (
    <div className={classes.root}>
      <Box position="static" sx={{ backgroundColor: "whitesmoke" }}>
        <Tabs
          value={value}
          onChange={(e, value) => (
            handleChange(e, value)
          )}
          aria-label="simple tabs example"
        >
          <Tab label="Class" {...activeTabs(0)} />
          <Tab label="Trial Class" {...activeTabs(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {actual}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {trials}
      </TabPanel>
    </div>
  );
}
