import React from "react";
import SingleComment from "../SingleComment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/FormControl";
import SortIcon from "@material-ui/icons/Sort";

function CommentsList({ reviews }) {
  const [sortBy, setSortBy] = React.useState("1");
  const [comments, setComments] = React.useState([]);
  React.useEffect(() => {
    setComments(
      reviews.sort((a, b) => parseInt(b.rating) - parseInt(a.rating))
    );
  }, [reviews]);

  const handleSortBy = (e) => {
    setSortBy(e.target.value);
    if (e.target.value === "1") {
      setComments(
        reviews.sort((a, b) => parseInt(b.rating) - parseInt(a.rating))
      );
    } else if (e.target.value === "2") {
      setComments(reviews.sort((a, b) => b.review.length - a.review.length));
    }
  };

  const SortControl = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
        }}
      >
        <FormControl variant="filled" size="small">
          <Select
            size="small"
            value={sortBy}
            variant="outlined"
            labelId="sort-by"
            onChange={handleSortBy}
            startAdornment={
              <InputAdornment position="start" style={{ paddingRight: 10 }}>
                <SortIcon />
              </InputAdornment>
            }
          >
            <MenuItem value={"1"}>Highest to lowest </MenuItem>
            <MenuItem value={"2"}>Longest review to shortest</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div>
      <SortControl />
      <div className="commentList">
        {comments.map((review, index) => (
          <SingleComment key={index} avatarSize={"large"} review={review} />
        ))}
      </div>
    </div>
  );
}

export default CommentsList;
