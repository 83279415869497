import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid';
import { useMediaQuery } from 'react-responsive';
import './calendar.css'

export default function FullCalender({ check, events, handleDateClick, handleEventClick, handleApplyFilters }) {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <div class={isMobile ? "mobile-calendar" : "desktop-calendar"}>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                initialView={isMobile ? "dayGridMonth" : "dayGridMonth"}
                themeSystem="standard"
                selectable={true}
                eventTimeFormat={{
                    hour: '2-digit', //2-digit, numeric
                    minute: '2-digit', //2-digit, numeric
                    meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
                    hour12: false //true, false
                }}
                timeFormat='H(:mm)'
                headerToolbar={{
                    center: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                events={events}
                datesSet={(e) => check(e)}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
            />
        </div>
    )
}