import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Typography} from '@material-ui/core'

function Header({handleClose,title}) {
  return (
    <div>
        <div className='header'>
            <ArrowBackIosIcon onClick={handleClose} />
            <Typography variant="body1">{title}</Typography>
        </div>

    </div>
  )
}

export default Header