import React, { useContext, useRef } from 'react'
import Grid from '@material-ui/core/Grid';
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Image from 'material-ui-image';
import InputBase from '@material-ui/core/InputBase';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from "react-router-dom";
import { CommonContext } from '../../context/CommonContext'
import Select, { components, DropdownIndicatorProps } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        width: 40,
    },
    topBar: {
        backgroundColor: '#0B4C57',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30,

    },
    navAction: {
        minWidth: 40,
        height: 40,
        borderRadius: 30,
        backgroundColor: '#24646F',
        margin: 5
    },
    actionIcon: {
        color: 'white',
        fontSize: 18
    },
    herosection: {
        padding: 20,
        // height: '93vh',
        // backgroundColor: '#0B4C57',
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            padding: 20,
            // height: '93vh'
        }
    },
    herosectionTransparent: {
        padding: 20,
        // height: '93vh',
        // backgroundColor: '#0B4C57',
        backgroundColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            padding: 20,
            // height: '93vh'
        }
    },
    hideOnIpad: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    transparentBG: {
        backgroundColor: 'transparent'
    },
    links: {
        marginRight: 10,
        fontWeight: 300,
    },
    heroText: {
        margin: 0,
        fontSize: 60,
        color: 'white',
        fontWeight: 100,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        }
    },
    heroTextStrong: {
        margin: 0,
        fontSize: 60,
        color: 'white',
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        }
    },
    singleLine: {
        margin: 0,
        fontSize: 22,
        color: 'white',
        fontWeight: 100
    },
    brandWrapper: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    brandlogo: {
        width: '30%',
        marginBottom: 40,
    },
    searchWrapper: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 35,
        marginTop: 0,
        paddingRight: 4,
        border: 'solid',
        borderWidth: 2,
        borderColor: '#d9d9d9',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 0,
            paddingRight: 5,
        },
    },
    searchField: {
        border: 'none',
        flexGrow: 1,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 10,

    },
    logo: {
        width: 80,
    },
    noBorder: {
        border: "none",
        padding: 15
    },
    searcnBtn: {
        borderRadius: 30,
        backgroundColor: '#2A9AB2',
        color: 'white',
        padding: 5,
        // border: 'solid',
        // borderWidth: 2,
        // borderColor: '#28889d',
        marginRight: 0
    },
    searchLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white',
        textDecoration: 'none'
    },
    heroImg: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 15,
        }
    }
}));


export default function HeroSection({ closeDrawer, background }) {
    const classes = useStyles();
    const [query, setValue] = React.useState(null);
    // const [menuOpen, setMenuOpen] = React.useState(false);
    let menuOpen = false
    const [searchTerm, setSearchTerm] = React.useState([]);
    const history = useHistory();
    const searchRef = useRef();
    const historySearch = useHistory();
    const { product } = useContext(CommonContext)
    const [tags, setTags] = product
    const search = (value) => {
        history.push({ pathname: query })
    }

    const DropdownIndicator = (props) => {
        return (null
            // <Link className={classes.searchLink} to={{ pathname: '/search/' + searchTerm }}>
            // <components.DropdownIndicator {...props}>
            //     <ButtonBase onClick={() => historySearch.push('/search/' + searchTerm)} variant="contained" className={classes.searcnBtn} >
            //         <Icon style={{ marginLeft: 0 }}>search</Icon>
            //     </ButtonBase>
            // </components.DropdownIndicator>
            // </Link>
        );
    };

    const customStyles = {
        container: () => ({
            position: 'relative',
            boxSizing: 'border-box',
            width: '100%',
            borderStyle: 'none',
            backgroundColor: 'transparent'
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            borderStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            backgroundColor: 'transparent'
        }),
    }

    const handleSearch = () => {
        // closeDrawer()
        historySearch.push('/search/' + searchTerm)
    }

    const handleKeySearch = (e) => {
        if (e.key == 'Enter' && !menuOpen) {
            e.preventDefault()
            historySearch.push('/search/' + searchTerm)
        }
    }


    return (
        <React.Fragment>
            <Grid container alignItems="center" justifyContent="center" className={background == 'transparent' ? classes.herosectionTransparent : classes.herosection}>

                <div className={classes.brandWrapper}>
                    {/* <img className={classes.brandlogo} color="transparent" src="/assets/Logo.png" /> */}
                    <div className={classes.searchWrapper}>
                        <Select
                            components={{ DropdownIndicator }}
                            isMulti
                            role="searchbox"
                            closeMenuOnSelect={true}
                            onKeyDown={handleKeySearch}
                            styles={customStyles}
                            onMenuClose={() => menuOpen = false}
                            onMenuOpen={() => menuOpen = true}
                            options={tags}
                            placeholder="I want to find.."
                            onChange={(item) => setSearchTerm(item.map(label => {
                                return label.value
                            }))}
                        />
                        <ButtonBase style={{ margin: 5 }} onClick={() => historySearch.push('/search/' + searchTerm)} variant="contained" className={classes.searcnBtn} >
                            <Icon style={{ marginLeft: 0 }}>search</Icon>
                        </ButtonBase>
                        {/* <InputBase onKeyDown={(e) => { e.keyCode == 13 && search(e.target.value); }} onChange={(e) => { setValue('/search/' + e.target.value); }} className={classes.searchField} id="standard-basic" placeholder="I want to find…" variant="outlined" /> */}
                        {/* <Button variant="contained" className={classes.searcnBtn}>
                            <Link className={classes.searchLink} to={{ pathname: query }}>
                                Search
                                    <Icon style={{ marginLeft: 10 }}>search</Icon>
                            </Link>
                        </Button> */}
                    </div>
                </div>
            </Grid>
        </React.Fragment >
    )
}
