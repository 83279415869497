import React, { createContext, useState, useEffect } from 'react'
import ProductService from '../services/products'

export const CommonContext = createContext()

export const CommonProvider = props => {
    const [tags, setTags] = useState([])

    useEffect(() => {
        ProductService.getTags().then(res => {
            const tagIds = res.data.map(tag => tag.tag)
            const tags = (res.data.map(tag => {
                return { value: tag.tag, label: tag.tag }
            }))

            ProductService.getDisplayNames().then(res => {
                const displayNames = (res.data.map((user, index, arr) => {
                    return { value: user.display_name, label: user.display_name }
                }))
                const allTags = [...tags, ...displayNames]
                const seen = new Set();
                const filteredArr = allTags.filter(el => {
                    const duplicate = seen.has(el.value);
                    seen.add(el.value);
                    return !duplicate;
                });
                setTags(filteredArr)
            })
        })
    }, [])

    return (
        <CommonContext.Provider value={{ product: [tags, setTags] }}>
            {props.children}
        </CommonContext.Provider>
    )
}