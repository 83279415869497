import React, { useState } from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


const Header = ({ setToggle }) => {
const [types,setTypes]=useState('')

  

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
          
          <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>
            <FormControl variant="outlined" style={{width:200,marginRight:20}}>
              <InputLabel id="types-label">
                Types
              </InputLabel>
              <Select
                labelId="types-label"
                id="types"
                //   value={age}
                label="Type"
                defaultValue="sales"
                onChange={(e) => {setToggle(e.target.value)
                setTypes(e.target.values)
                }}
              >
                <MenuItem value="sales" selected>
                  Sales
                </MenuItem>
                <MenuItem value="payouts">Payouts</MenuItem>
              </Select>
            </FormControl>
          
            <FormControl variant="outlined" style={{width:200}}>
              <InputLabel id="demo-simple-select-label">
                For
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                label="For"
                defaultValue="all-time"
              >
                <MenuItem value="all-time">All time</MenuItem>
                <MenuItem value="month">This Month</MenuItem>
              </Select>
            </FormControl>
          </div>
      </Grid>
    </>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin-bottom: 20px;
  .types {
    width: 200px;
  }
  .year {
    width: 200px;
    margin-left: 4px;
    margin-right: 6px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    .types {
      width: 100%;
      margin-top: 5px;
    }
    .year {
      width: 100%;
      margin-top: 5px;
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
`;


const RightDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Icon = styled.span`
  border: 1px solid #e6e6e3;
  background-color: #e6e6e3;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  top: 9px;
  margin-right: 10px;
  .icon {
    position: relative;
    top: 2px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-top: 5px;
    left: 10px;
  }
`;

export default Header;
