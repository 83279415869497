import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuList from '@material-ui/core/MenuList';
// import Button from '@mui/material/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
// import Stack from '@material-ui/core/Stack';
// import Avatar from '@material-ui/core/Avatar';
import Teachers from '../services/teacher';
import './css/shops.css';

const SingleShop = ({ size, shop }) => {
    return (
        <Grid item xs={size} sm={4} lg={3}>
            <Link to={'/shop/' + shop.username} className="link-text">
                <Box className="single-shop-wrapper">
                    {
                        shop.auth_using == 'social' ?
                            <img className="shop-avatar" src={shop.profile_picture} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/user.png";
                            }} />
                            :
                            <img className="shop-avatar" onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/user.png";
                            }} src={process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + shop.profile_picture} />
                    }
                    <Typography variant="body1" className="shop-name">{shop.first_name} {shop.last_name}</Typography>
                    <Typography variant="body2" className="shop-username">{shop.username}</Typography>
                </Box>
            </Link>
        </Grid>
    )
}

export default function TeachersShop() {
    const [shops, setShops] = React.useState([])
    const [sorterOpen, setSorterOpen] = React.useState(false)
    const anchorRef = React.useRef(null);

    React.useEffect(() => {
        Teachers.getShops('newest').then(res => {
            setShops(res.data)
        })
    }, [])

    const handleSorting = (event, type) => {
        console.log(type)
        Teachers.getShops(type).then(res => {
            setShops(res.data)
        })
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setSorterOpen(false);
    }

    const handleToggle = () => {
        setSorterOpen((sorterOpen) => !sorterOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setSorterOpen(false);
    };

    return (
        <div>
            <Container maxWidth="lg" className="shops-container">
                <Grid container alignItems="center" justifyContent="space-between" className="sort-header">
                    <Grid item>
                        <Grid container alignItems="center" className="page-header">
                            <Grid item>
                                <h1>
                                    <Icon className="page-icon">storefront</Icon>
                                </h1>
                            </Grid>
                            <Grid item>
                                <h1>Shops</h1>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/* <InputLabel id="select-label">Sort by</InputLabel> */}
                        <Button
                            ref={anchorRef}
                            id="sort-button"
                            aria-controls={sorterOpen ? 'sort-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={sorterOpen ? 'true' : undefined}
                            onClick={handleToggle}
                            className="sort-button"
                        >
                            <Icon>sort</Icon>
                        </Button>
                        <Popper
                            open={sorterOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-end"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        backgroundColor: 'white',
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >

                                    <MenuList id="sort-menu" placeholder="Sort by" name="sort-by" >
                                        <MenuItem onClick={(e) => handleSorting(e, 'newest')}>Newest to Oldest</MenuItem>
                                        <MenuItem onClick={(e) => handleSorting(e, 'alphabetical')}>Alphabetical</MenuItem>
                                    </MenuList>
                                </Grow>
                            )}
                        </Popper>

                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid container alignItems="center" justifyContent="center" className="shops-wrapper">
                        {
                            shops.map((shop, index) => (
                                <SingleShop size={6} shop={shop} />
                            ))
                        }
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid container justifyContent="center" className="shops-wrapper">
                        <Grid item xs={6}>
                            {
                                shops.map((shop, index) => {
                                    if (index % 2 === 0) {
                                        return (<SingleShop size={12} shop={shop} />)
                                    }
                                })
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {
                                shops.map((shop, index) => {
                                    if (index % 2 != 0) {
                                        return (<SingleShop size={12} shop={shop} />)
                                    }
                                })
                            }
                        </Grid>
                    </Grid>
                </Hidden>

            </Container>
        </div>
    )
}
