import { Button, Modal, Select, Table } from 'antd';
import React, { useState } from 'react';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TeacherService from '../../services/teacher'

function BookingDetails({bookingData,show,closeModal}) {

    const [isModalOpen, setIsModalOpen] = useState(true);
    const date = new Date(bookingData?.startStr)
    const [students,setStudents] = useState([])
    const { Option } = Select
    const Attendance = ({booking,slotId,studentId,value}) => {
        const [attend,setAttend] = useState(value)
        const [loading,setLoading] = useState(false)
        const updateAttendance = (attendance) => {
            setAttend(attendance)
            const data = {
                booking:booking,
                attendance:attendance
            }
            setLoading(true)
            TeacherService.updateStudentAttendance(data).then(res => {
                setLoading(false)
            })
        }

        return (
            
                loading ? 
                <h6>{'Updating...'}</h6>
                :
            <Select onChange={updateAttendance} style={{ width: 180 }} defaultValue={attend!=null ? attend : 'No attendance'}>
                <Option value="1"><FiberManualRecordIcon style={{marginRight:5,color:'green',fontSize:10}}/>Present</Option>
                <Option value="2"><FiberManualRecordIcon style={{marginRight:5,color:'orange',fontSize:10}}/> Late</Option>
                <Option value="0"><FiberManualRecordIcon style={{marginRight:5,color:'maroon',fontSize:10}}/> Absent</Option>
            </Select>
            
        )
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        closeModal();
    };

    const data = [
        {
            key: '1',
            student_name: 'John Brown',
            email: "example@email.com",
            attendance: <Attendance />,
        },
    ];
    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'student_name',
            key: 'student name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Attendance',
            dataIndex: 'attendance',
            key: 'attendance',
            width:200
        },
    ];

    React.useEffect(() => {
        console.log(bookingData?.extendedProps)
        if(show){
            let data = {
                slot_id:bookingData?.extendedProps.slotId
            }
            TeacherService.getBookingsByTrial(data).then(res => {
                const studentsList = res.data.bookings.map((booking,index) => {
                    return {
                        key : index,
                        student_name:booking.student.display_name,
                        email:booking.student_user.email,
                        attendance: <Attendance value={booking.student_attandance} booking={booking.booking_id} slotId={booking.slot_id} studentId={booking.student.user_id} />
                    }
                })
                setStudents(studentsList)
            })
        }
    },[show,bookingData])

    const Footer = () => {
        return(
            (bookingData?.extendedProps.venue != null && bookingData?.extendedProps.venue != "") ?
            <a href={bookingData?.extendedProps.venue} target="blank">
                <Button style={{backgroundColor:"#1A95AC",color:'white',border:'none',borderRadius:5}} type="primary">Start lesson</Button>
            </a>
            :
            null
        )
    }

    return (
        <>
            <Modal footer={<Footer />} width={"50%"} title={bookingData?.title} open={show} onCancel={handleCancel}>
                
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                        <div>
                            <h3>Date</h3>
                            <h4>{date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear()}</h4>
                        </div>
                        <div>
                            <h3>Time</h3>
                            <h4>{bookingData?.extendedProps.time}</h4>
                        </div>
                        <div>
                            <h3>Price</h3>
                            <h4>HKD {bookingData?.extendedProps.price}</h4>
                        </div>
                    </div>
                    <div style={{display:'flex',alignItems:'center',backgroundColor:'#d8efff',padding:10,borderRadius:5,marginBottom:10}}>
                        <h3 style={{display:'flex',alignItems:'center'}}><VideocamOutlinedIcon style={{marginRight:10,marginBottom:0}}/> <span>{bookingData?.extendedProps.venue}</span></h3>
                    </div>
                
                <h3>Participants</h3>
                <Table size='small' columns={columns} dataSource={students} pagination={false} />
            </Modal>
        </>
    );
};

export default BookingDetails;
