import React from "react";
import StarIcon from '@material-ui/icons/Star';
import BackButton from "./BackButton";
import { LinearProgress } from "@material-ui/core";
import AddReview from "./AddReview";




function Ratings({ handleClose, reviews, handleAddReview }) {
  const fiveStar = reviews.filter(review => review.rating == 5).length / reviews.length * 100
  const fourStar = reviews.filter(review => review.rating == 4).length / reviews.length * 100
  const threeStar = reviews.filter(review => review.rating == 3).length / reviews.length * 100
  const twoStar = reviews.filter(review => review.rating == 2).length / reviews.length * 100
  const oneStar = reviews.filter(review => review.rating == 1).length / reviews.length * 100
  return (
    <div>
      <div className="rating">
        <div>
          <h2>
            Reviews <span className="totalComments">{reviews.length}</span>
          </h2>
        </div>
        <div className="rating_box">
          <div className="star-wrapper"><StarIcon className="star" />
            <span>5</span>
            <span>Excellent</span>
          </div>
          <div className="progressBar">
            <div style={{ width: "90%" }}>
              <LinearProgress style={{ height: 15, backgroundColor: '#D6EDF2' }} variant="determinate" value={fiveStar} />
            </div>
            <span>{fiveStar.toFixed(0)}%</span>
          </div>
        </div>
        <div className="rating_box">

          <div className="star-wrapper"><StarIcon className="star" />
            <span>4</span>
            <span>Very Good</span>
          </div>
          <div className="progressBar">
            <div style={{ width: "90%" }}>
              <LinearProgress style={{ height: 15, backgroundColor: '#D6EDF2' }} variant="determinate" value={fourStar} />
            </div>
            <span>{fourStar.toFixed(0)}%</span>
          </div>
        </div>
        <div className="rating_box">

          <div className="star-wrapper"><StarIcon className="star" />
            <span>3</span>
            <span>Good</span>
          </div>
          <div className="progressBar">
            <div style={{ width: "90%" }}>
              <LinearProgress style={{ height: 15, backgroundColor: '#D6EDF2' }} variant="determinate" value={threeStar} />
            </div>
            <span>{threeStar.toFixed(0)}%</span>
          </div>
        </div>
        <div className="rating_box">

          <div className="star-wrapper"><StarIcon className="star" />
            <span>2</span>
            <span>Unsatisfied</span>
          </div>
          <div className="progressBar">
            <div style={{ width: "90%" }}>
              <LinearProgress style={{ height: 15, backgroundColor: '#D6EDF2' }} variant="determinate" value={twoStar} />
            </div>
            <span>{twoStar.toFixed(0)}%</span>
          </div>
        </div>
        <div className="rating_box">

          <div className="star-wrapper"><StarIcon className="star" />
            <span>1</span>
            <span>Disappointed</span>
          </div>
          <div className="progressBar">
            <div style={{ width: "90%" }}>
              <LinearProgress style={{ height: 15, backgroundColor: '#D6EDF2' }} variant="determinate" value={oneStar} />
            </div>
            <span>{oneStar.toFixed(0)}%</span>
          </div>

        </div>


        <div style={{ marginTop: "35px" }}>
          <AddReview handleAddReview={handleAddReview} />
        </div>
        <div style={{ marginTop: "20px" }}>
          <BackButton handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
}

export default Ratings;
