import React from 'react'
import MyProfile from '../components/teacher/myProfile'
import * as Mui from '@material-ui/core';

export default function ProfileManagement() {
    return (
        <>
            <MyProfile />
        </>
    )
}
