import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import styled from "styled-components";

function Row(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="left">{row.earnings}</TableCell>
        <TableCell align="left">{row.downloaded}</TableCell>
        <TableCell align="left">{row.uploaded}</TableCell>
        <TableCell align="left">{row.earnings}</TableCell>
        <TableCell align="left">{row.downloaded}</TableCell>
        <TableCell align="left">{row.uploaded}</TableCell>
      </TableRow>
      <TableRow></TableRow>
    </React.Fragment>
  );
}

const Heading = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const SubHeading = styled.span`
  font-weight: bold;
`;

const PayoutBtn = styled.button`
  background-color: #ed214d;
  color: white;
  text-transform: uppercase;
  padding: 4px 8px 4px 8px;
  border: none;
  border-radius: 3px;
  margin-right: 6px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

const SaleBtn = styled.button`
  background-color: #2a04c2;
  color: white;
  text-transform: uppercase;
  padding: 4px 8px 4px 8px;
  border: none;
  border-radius: 3px;
`;

export default Row;
