import React from 'react'
import {Avatar,Button,DialogTitle,Dialog} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Country from './Country'
import OnlineStatus from './OnlineStatus';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { UserContext } from '../../../context/UserContext';
import TeacherService from '../../../services/teacher'
import { useJwt } from "react-jwt";
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    largeAvatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    primaryText:{
        color:'#00C1E2'
    },
    followIcon:{
        fontSize:18,
        marginRight:5
    },
    followBtn:{
        marginTop:10,
        border:'solid 1px gray',
        '&:hover':{
            "& $followIcon":{
                color:'#FF5F5F',
            }
        }
    },
    followedIcon:{
      color:'#FF5F5F',
      fontSize:18,
        marginRight:5
    },
    avatarWrapper:{
        position:'relative'
    },
    btnPrimary:{
      marginLeft:5,
      backgroundColor:'black',
      color:'white'
    },
    btnBasic:{
      marginRight:5,
      backgroundColor:'#e9e9e9',
      color:'black'
    }
}));

export default function ProfilePicture({teacherId,follow,data,image,displayName,followers,teacher,country}) {
  const classes = useStyles();
  const { user } = React.useContext(UserContext)
  const [teacherFollowers,setTeacherFollowers] = React.useState(followers)
  const { jwt } = React.useContext(UserContext)
  const [token, setToken] = jwt;
  const { decodedToken, isExpired } = useJwt(token);
  const [openDialog,setOpenDialog] = React.useState(false);

  const handleFollow = () => {
    let data = {
      user_id: user[0].user_id,
      subscriber_id: teacher
    }
    
    TeacherService.followATeacher(data).then(res => {
      if(res.data.status === 200) {
        setTeacherFollowers([...teacherFollowers,user[0].user_id])
      }
    })
  }

  const handleUnFollow = () => {
    let data = {
      user_id: user[0].user_id,
      subscriber_id: teacher
    }
    
    TeacherService.unFollowATeacher(data).then(res => {
      if(res.data.status === 200) {
        setTeacherFollowers(teacherFollowers.filter(follower => follower != user[0].user_id))
        setOpenDialog(false)
      }
    })
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const UnFollowDialog = () =>{
    return(
      <Dialog onClose={handleCloseDialog} aria-labelledby="simple-dialog-title" open={openDialog}>
      <DialogTitle id="simple-dialog-title">Unfollow {data?.user_details?.display_name}</DialogTitle>
      <div style={{display:'flex',justifyContent:'center',padding:10}}>
      <Button className={classes.btnBasic} onClick={handleCloseDialog}>No</Button>
      <Button className={classes.btnPrimary} onClick={handleUnFollow}>Yes</Button>
      </div>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <UnFollowDialog />
      <div className={classes.avatarWrapper}>
      <Link style={{textDecoration:'none',color:'black'}} to={`/teachers-and-tutors/${teacherId}`} >
          <Avatar className={classes.largeAvatar} src={process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + image}/>
          <Country country={country}/>
        </Link>
      </div>
      {
        !isExpired && follow &&
        (
        teacherFollowers.includes(user[0].user_id) ?
        <Button onClick={handleOpenDialog} className={classes.followBtn} size="small">
          <FavoriteIcon className={classes.followedIcon} /> Followed
        </Button>
        :
        <Button onClick={handleFollow} className={classes.followBtn} size="small">
          <FavoriteIcon className={classes.followIcon} /> Follow
        </Button>
        )
      }
    </div>
  )
}
