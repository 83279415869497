import React from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';
import InputBase from '@material-ui/core/InputBase';
import Icon from '@material-ui/core/Icon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 60,
        minHeight: '65vh',
        paddingLeft: 60,
        paddingRight: 60,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#24646F'
    },
    headingQuestion: {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: 'bold',
        color: '#F99C24',
        marginRight: 15
    },
    pageTitle: {
        marginBottom: 20,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20,
            fontSize: 26,
            textAlign: 'left'
        }
    },
    pageWrapper: {
        backgroundColor: 'rgb(9,42,48)',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    contactField: {
        width: '100%',
        marginBottom: 20
    },
    contactWrapper: {
        backgroundColor: 'white',
        marginTop: 30,
        padding: 30,
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
            marginTop: 10
        }
    },
    addressWrapper: {
        marginTop: 30,
        padding: 30,
        borderRadius: 15,
    },
    cardTitle: {
        marginBottom: 30
    },
    sendBtn: {
        marginTop: 30,
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 0,
            height: 45
        }
    },
    paramWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
            display: 'flex',
            alignItems: 'center'
        }
    },
    iconWrapper: {
        backgroundColor: '#FA9C24',
        width: 40,
        height: 40,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            width: 30,
            height: 30
        }
    },
    icon: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        }
    },
    contactText: {
        color: 'white'
    },
    contactTextHeading: {
        color: '#FA9C24'
    },
    addressWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    contacttextWrapper: {
        [theme.breakpoints.down('sm')]: {
            flex: 1
        }
    }

}));


export default function Contact() {
    const classes = useStyles();
    let [query, setValue] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Container maxWidth="xl" className={classes.pageWrapper}>
            <Container maxWidth="lg">
                <div className={classes.root}>
                    <Typography variant="h3" className={classes.pageTitle} align="center">Contact Us</Typography>
                    <Hidden smDown>
                        <Typography variant="h6" className={classes.pageTitle} align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.</Typography>
                    </Hidden>
                    <Grid container alignItems="stretch">
                        <Grid item xs={12} sm={6}>
                            <Container className={classes.addressWrapper} flexDirection="column" justifyContent="space-between" maxWidth="xl">
                                <div className={classes.paramWrapper}>
                                    <div className={classes.iconWrapper}>
                                        <Icon className={classes.icon}>place</Icon>
                                    </div>
                                    <div className={classes.contacttextWrapper}>
                                        <Typography variant="body1" className={classes.contactTextHeading}>Address</Typography>
                                        <Typography variant="body1" className={classes.contactText}>678 ABC Street Some Area Name</Typography>
                                        <Typography variant="body1" className={classes.contactText}>HongKong</Typography>
                                    </div>
                                </div>
                                <div className={classes.paramWrapper}>
                                    <div className={classes.iconWrapper}>
                                        <Icon className={classes.icon}>phone</Icon>
                                    </div>
                                    <div className={classes.contacttextWrapper}>
                                        <Typography variant="body1" className={classes.contactTextHeading}>Phone</Typography>
                                        <Typography variant="body1" className={classes.contactText}>+1 (234) 567 8910</Typography>
                                    </div>
                                </div>
                                <div className={classes.paramWrapper}>
                                    <div className={classes.iconWrapper}>
                                        <Icon className={classes.icon}>email</Icon>
                                    </div>
                                    <div className={classes.contacttextWrapper}>
                                        <Typography variant="body1" className={classes.contactTextHeading}>Email</Typography>
                                        <Typography variant="body1" className={classes.contactText}>help@edhub.io</Typography>
                                    </div>
                                </div>
                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Container className={classes.contactWrapper} maxWidth="xl">
                                <Typography variant="h5" className={classes.cardTitle}>Send Message</Typography>
                                <TextField variant="outlined" className={classes.contactField} id="standard-basic" label="Full Name" />
                                <TextField variant="outlined" className={classes.contactField} id="standard-basic" label="Email" />
                                <TextField variant="outlined" className={classes.contactField} id="standard-basic" label="Your Message" />
                                <Button variant="contained" color="primary" className={classes.sendBtn}>Send</Button>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Container>
    )
}
