import React from 'react';
import { Grid, Button, Icon, SwipeableDrawer, Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TeacherService from '../../../services/teacher';
import { UserContext } from '../../../context/UserContext';
import './styles.css';
import useScrollingUp from '../../../hooks/useScrollingUp';
import { AuthContext } from '../../../context/AuthContext';
import { useJwt } from 'react-jwt';

function Levels({ handleFilterProducts, teacher, subject, currentLevels }) {
    const productLevels = ['Pre-K', 'K1', 'K2', 'K3', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'University', 'IELTS', 'TOEFL', 'Cambridge', 'Trinity', 'Others']
    const productSubjects = ['Chinese', 'English', 'Math', 'Others']

    const [selectedLevels, setSelectedLevels] = React.useState(null)
    let levels = []
    const handleLevels = (event, newLevel) => {
        if (newLevel == 'Select-all-values') {
            setSelectedLevels(null)
            levels.push([])
        }
        else {
            levels.push(newLevel)
            if (newLevel.length == 0)
                setSelectedLevels(null)
            else
                setSelectedLevels(newLevel)
        }
        let filter = {
            'user_id': teacher,
            "subject": subject,
            "level": levels
        }
        handleFilterProducts(filter)
    }

    React.useEffect(() => {
        console.log(currentLevels[0])
        setSelectedLevels(currentLevels[0])
    }, [])

    return (
        <>
            <ToggleButtonGroup value={selectedLevels == null ? 'Select-all-values' : ''} onChange={handleLevels} aria-label="text formatting">
                <ToggleButton className="level-btn" value={'Select-all-values'} aria-label="left aligned">
                    {'All'}
                </ToggleButton>
            </ToggleButtonGroup>
            {
                productLevels.map((level, index) => {
                    return (
                        <ToggleButtonGroup key={index} value={selectedLevels} onChange={handleLevels} aria-label="text formatting">
                            <ToggleButton className="level-btn" value={level} aria-label="left aligned">
                                {level}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )
                })
            }
        </>
    )
}

const Subjects = (props) => {
    const productSubjects = ['Chinese', 'English', 'Math', 'Others']
    const [selectedSubjects, setSelectedSubjects] = React.useState()

    const handleSubjects = (event, newLevel) => {
        setSelectedSubjects(newLevel)
        if (newLevel != null) {
            props.setSubject(newLevel)
            props.toggleDrawer(true)

            let filter = {
                'user_id': props.user,
                "subject": newLevel,
                "level": [[]]
            }
            console.log(filter)
            props.handleFilterProducts(filter)
        }
        else {
            let filter = {
                'user_id': props.user,
                "subject": null,
                "level": [[]]
            }
            props.handleFilterProducts(filter)
        }
    }

    return (
        <>
            <ToggleButtonGroup exclusive value={selectedSubjects} onChange={handleSubjects} aria-label="text formatting">
                {
                    productSubjects.map((subject, index) => {
                        return (
                            <ToggleButton key={index} className="level-btn" value={subject} aria-label="left aligned">
                                {subject}
                            </ToggleButton>
                        )
                    })
                }
            </ToggleButtonGroup>
        </>
    )
}



export default function ShopFilters({ teacher, handleFilterProducts, levels }) {
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [subject, setSubject] = React.useState();
    const { user } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const [isFollowing, setIsFollowing] = React.useState(false)
    const [followId, setFollowId] = React.useState()
    const [followed, setFollowed] = React.useState()
    const [open, setOpen] = React.useContext(AuthContext);
    const scrolled = useScrollingUp()
    const toggleDrawer = (newOpen) => {
        setOpenDrawer(newOpen);
    };

    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);

    const handleSubjectChange = (newSubject) => {
        setSubject(newSubject);
    };

    React.useEffect(() => {
        TeacherService.getFollowings(currentUser.user_id).then(res => {
            let following = [];
            following = res.data.filter(shop => shop.subscriber_id == teacher)
            if (following.length > 0) {
                setFollowId(following[0].id)
            }
            setIsFollowing(following.length > 0)
        })
    }, [currentUser, followed])

    const handleFollowShop = () => {
        let data = {
            "subscriber_id": teacher,
            "user_id": currentUser.user_id
        }
        TeacherService.followShop(data).then(res => {
            setFollowed(true)
        })
    }

    const handleUnFollowShop = () => {
        TeacherService.unFollowShop(followId).then(res => {
            setFollowed(false)
        })
    }

    const handleAuth = () => {
        setOpen(true)
    }

    return (
        <div class={`${scrolled ? 'filters-wrapper sticky' : 'filters-wrapper'}`}>
            <Grid container justifyContent="space-between" className="filter-header" alignItems="center">
                <Grid item>
                    <h6 className="p-heading"><Icon>filter_alt</Icon> Filter by subject</h6>
                </Grid>
                {
                    isExpired ? 
                        <Grid item>
                            <Button variant="outlined" onClick={() => handleAuth()}>
                                <Icon >notifications</Icon>
                                Sign In to Follow
                            </Button>
                        </Grid>
                        :
                        <Grid item>
                            {
                                isFollowing ?
                                    <Button onClick={handleUnFollowShop} className="follow-button">Unfollow</Button>
                                    :
                                    <Button onClick={handleFollowShop} className="follow-button">Follow</Button>
                            }
                            {
                                subject &&
                                <Button className="expand-more-button" onClick={() => toggleDrawer(true)}>
                                    <span>Levels</span><Icon>expand_more</Icon>
                                </Button>
                            }
                        </Grid>
                }
            </Grid>
            <div className="subjects-wrapper" >
                <Subjects setSubject={handleSubjectChange} user={teacher} handleFilterProducts={handleFilterProducts} toggleDrawer={toggleDrawer} />
            </div>
            <div className="filter-drawer">
                <SwipeableDrawer
                    anchor={'top'}
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                >
                    <h6 className="p-level-heading"><Icon>filter_alt</Icon> Filter by level</h6>
                    <Box style={{ marginTop: 20, marginBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <Levels currentLevels={levels} subject={subject} user={teacher} handleFilterProducts={handleFilterProducts} toggleDrawer={toggleDrawer} />
                        <div className="collapse-drawer">
                            <Button onClick={() => toggleDrawer(false)} className="collapse-btn">
                                <Icon>expand_less</Icon>
                            </Button>
                        </div>
                    </Box>
                </SwipeableDrawer>
            </div>
        </div>
    )
}
