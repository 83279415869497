import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../../services/auth';
import { UserContext } from '../../context/UserContext';
import { BuyNowContext } from '../../context/BuyNowContext';
import { FavouriteContext } from '../../context/FavouriteContext';
import jwt_decode from "jwt-decode";
import SocialAuth from './SocialAuth';
import ProductService from '../../services/products';
import { AuthContext } from '../../context/AuthContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginBottom: 0,
        width: '40%'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
        width: '100%'
    },
    errorText: {
        color: 'red'
    },
    fbBtn: {
        width: '99%',
        backgroundColor: 'transparent',
        border: 'none',
        height: 40,
        backgroundColor: 'transparent',
        borderRadius: 10
    },
    gBtn: {
        width: '99%',
        backgroundColor: 'transparent',
        border: 'none',
        height: 40,
        backgroundColor: 'transparent',
        borderRadius: 10
    },
    socialImg: {
        width: '100%'
    },
    socialAuthWrapper: {
        width: '110%',
        marginBottom: 30,
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    socialAuthRegWrapper: {
        width: '65%',
        marginBottom: 30,
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    socialAuth: {
        backgroundColor: 'transparent',
        border: 'none',
    },
    errorText: {
        color: 'red'
    }
}));

export default function SocialWidgets(props) {
    const classes = useStyles();
    const [username, setUsername] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { auth } = React.useContext(BuyNowContext);
    const [isLoggedIn, setIsLoggedIn] = auth;
    const [form, setForm] = React.useState('signin')
    const { user } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;
    const { favouriteItem } = React.useContext(FavouriteContext);
    const [favouriteItems, setFavouriteItems] = favouriteItem;
    const [open, setOpen] = React.useContext(AuthContext)

    const handleSocialLogin = (user) => {
        var social = {
            "access_token": user._token.accessToken,
            "email": user._profile.email,
            "provider": "facebook",
            "user_id": user._profile.id,
            "first_name": user._profile.firstName,
            "last_name": user._profile.lastName,
            "display_name": user._profile.name,
            "profile_picture": user._profile.profilePicURL
        };
        Auth.socialAuth(social).then((response) => {
            console.log(response.data)
            if (response.data.status == 200 && !response.data.error) {
                finishAuth(response)
            }
        })
    }
    const handleGoogleLogin = (user, err) => {
        var social = {
            "access_token": user._token.accessToken,
            "email": user._profile.email,
            "provider": "gmail",
            "first_name": user._profile.firstName,
            "last_name": user._profile.lastName,
            "display_name": user._profile.name,
            "profile_picture": user._profile.profilePicURL
        };
        Auth.socialAuth(social).then((response) => {
            console.log(response.data)
            if (response.data.status == 200 && !response.data.error) {
                finishAuth(response)
            }
        })
    }
    const handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    const finishAuth = (response) => {
        let decodedToken = jwt_decode(response.data.data.token)
        ProductService.getFavourites(decodedToken.user_id).then(res => {
            setFavouriteItems(res.data)
            Auth.getUserById(decodedToken.user_id).then(res => {
                setCurrentUser(res.data)
                console.log(res.data)
                setToken(response.data.data.token)
                localStorage.setItem('user_id', decodedToken.user_id)
                localStorage.setItem('auth_id', decodedToken.auth_id)
                localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
                localStorage.setItem('jwt_token', response.data.data.token)
                setIsLoggedIn(true)
                setOpen(false)
            })
        })
        // setLoading(false)
    }

    return (
        <>
            <h4 style={{ fontWeight: 300, color: '#757575' }}>Link your social media account</h4>
            <div className={props.type == 'signin' ? classes.socialAuthWrapper : classes.socialAuthRegWrapper}>
                <ButtonBase style={{ width: '100%' }} className={classes.gBtn}>
                    <SocialAuth
                        provider="google"
                        appId="327155937090-kktv63fqt3o5imsof5mupqipqdt2cups.apps.googleusercontent.com"
                        onLoginSuccess={handleGoogleLogin}
                        autoLogin={false}
                        onLoginFailure={handleSocialLoginFailure}
                        className={classes.socialAuth}
                    >
                        <img className={classes.socialImg} src="/assets/login-with-g.png" />
                    </SocialAuth>
                </ButtonBase>
                <ButtonBase style={{ width: '100%' }} className={classes.fbBtn}>
                    <SocialAuth
                        provider="facebook"
                        appId="565617981409692" //edhub
                        // appId="2493286747365617"
                        autoLogin={false}
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className={classes.socialAuth}
                    >
                        <img className={classes.socialImg} src="/assets/login-with-fbB.png" />
                    </SocialAuth>
                </ButtonBase>
            </div>
        </>
    );
}
