import axios from 'axios';
// const url = 'https://apiendpoints.edhub.school/public/index.php/api/'
// const corsUrl = 'https://apiendpoints.edhub.school/public/index.php/'
// const url = 'http://localhost:8080/api/'
// const corsUrl = 'http://localhost:8080/'
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const History = {
    // Store History
    storeHistory: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'history/store',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            },
            "data": data
        })
    },

    // Get History
    getHistory: function (user, month, year) {
        return axios({
            "method": "GET",
            "url": url + 'history/get',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "user_id": user,
                "month": month,
                "year": year
            }
        })
    },

    // Clear History
    clearHistory: function (user) {
        return axios({
            "method": "GET",
            "url": url + 'history/clear',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "user_id": user
            }
        })
    },

    // Remove Item from History
    removeItemFromHistory: function (id) {
        return axios({
            "method": "GET",
            "url": url + 'history/remove/item',
            "headers": {
                "content-type": "application/octet-stream",
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "params": {
                "history": id,
                "user": localStorage.getItem('user_id')
            }
        })
    },


}

export default History;