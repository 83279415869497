import React, { useState } from 'react'

export function useForm(fieldValues) {
    const [productValueData, setProductValueData] = useState(fieldValues);
    const [validationsErrors, setValidationsErrors] = useState({});
    const handleInputChange = e => {
        console.log(e)
        const { name, value } = e.target;

        if (name == "productCover") {
            const selectedFiles = e.target.files[0];
            setProductValueData({
                ...productValueData,
                [name]: selectedFiles
            })
        } else if (name == "productMaterial") {
            const selectedFiles = e.target.files;
            setProductValueData({
                ...productValueData,
                [name]: selectedFiles
            })
        } else {
            setProductValueData({
                ...productValueData,
                [name]: value
            })
        }
    }

    const resetForm = () => {
        setProductValueData(fieldValues);
        setValidationsErrors({});
    }

    return {
        productValueData,
        setProductValueData,
        validationsErrors,
        setValidationsErrors,
        handleInputChange,
        resetForm
    }
}

