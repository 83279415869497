import React, { forwardRef, useState, useImperativeHandle, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Product from '../../services/products';
import History from '../../services/history';
import Subscription from '../../services/subscription';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';

import SingleProduct from './singleProduct';
import { AuthContext } from '../../context/AuthContext';
import { CartContext } from '../../context/CartContext';
import { UserContext } from '../../context/UserContext';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { shadows } from '@material-ui/system';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AuthForm from '../../components/auth/SingleSignIn';
import Checkout from '../../components/checkout/checkout';
import Wallet from '../../components/checkout/wallet';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import { useJwt } from 'react-jwt';

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productsWrapper: {
        padding: 30,
        marginBottom: 0,
        height: '90vh',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            height: 'auto',
            overflowY: 'visible',
        }
    },
    heading: {
        paddingLeft: 40,
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            fontSize: 16
        }
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    shopFiltersWrapper: {
        background: '#fff',
        borderRadius: 10,
        padding: 10
    },
    details: {
        flexDirection: 'column'
    },
    titleName: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3,
        fontDecoration: 'none'
    },
    singleItem: {
        height: 320,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '90%',
        height: '100%',
        marginBottom: 20,
        marginLeft: '5%'
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    yearHeading: {
        marginBottom: 0,
        color: "#FA9C24"
    },
    expandIcon: {
        padding: 0
    },
    accHeading: {
        margin: 0
    },
    monthName: {
        marginBottom: 5,
        fontSize: 16,
        cursor: 'pointer',
        backgroundColor: '#f7f7f7',
        padding: 5,
        borderRadius: 5
    },
    dateHeading: {
        paddingRight: 30
    },
    pageHeading: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#ededed',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
            borderRadius: 10,
            height: 60
        }
    },
    centerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        marginTop: 10,
        marginBottom: 5,
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        border: 'solid',
        borderWidth: 1,
        borderColor: '#e6e6e6',
        borderRadius: 10
    },
    activecenterWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        marginTop: 10,
        marginBottom: 5,
        backgroundColor: '#e1e1e1',
        cursor: 'pointer',
        border: 'solid',
        borderWidth: 1,
        borderColor: '#59b0c0',
        borderRadius: 10,
    },
    bellIcon: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        backgroundColor: '#f99c24',
        borderRadius: 30,
        color: 'white'
    },
    unfollow: {
        backgroundColor: '#dadada',
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            width: '5%',
            marginRight: 10,
        }
    },
    unfollowIcon: {
        fontSize: 16,
        marginLeft: 5,
        color: '#de1d3c',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    filtersButton: {
        width: '20%',
        marginLeft: 10,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        backgroundColor: '#f99c25',
    },
    filtersIcon: {
        color: 'white',
    },
    filterHeading: {
        textAlign: 'center',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: 0
        }
    },
    authContainer: {
        backgroundColor: '#061C20',
        height: '100vh'
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 15
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
    centerTextFull: {
        width: '100%',
        textAlign: 'center', marginTop: 30
    }
}));

export default forwardRef(function Shop(props, ref) {
    const isInitialMount = React.useRef();
    let [list, setValue] = React.useState([]);
    let [count, setCount] = React.useState([]);
    const [totalProducts, setTotal] = React.useState(100);
    const [years, setYears] = React.useState([]);
    const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    const [page, setPage] = React.useState(1);
    const [historyFor, setHistoryFor] = React.useState((new Date().getMonth() + 1) + ' ' + new Date().getFullYear())
    const [historyMonth, setHistoryMonth] = React.useState((new Date().getMonth() + 1))
    const [activeCenter, setActiveCenter] = React.useState()
    const [activeCenterId, setActiveCenterId] = React.useState()
    const [activeCenterType, setActiveCenterType] = React.useState()
    const [historyYear, setHistoryYear] = React.useState(new Date().getFullYear())
    const [openFilters, setOpenFilters] = React.useState(false)
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    };
    const [subscriptions, setSubscriptions] = React.useState([])
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);
    const [cartItem, setCartItem] = React.useContext(CartContext);
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const [buyProduct, setBuyProduct] = useState(null)

    let products = [];
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    const fetchData = () => {
        products = []
        if (activeCenterId > 0 && typeof activeCenterType != 'undefined') {
            console.log(activeCenterId, activeCenterType, activeCenter)
            Product.getProductsByCenter(activeCenterId, activeCenterType)
                .then((response) => {
                    console.log(response)
                    setCount(response.data.count)
                    response.data.forEach((product, index) => {
                        products.push(
                            <SingleProduct from={'teacher-shop'} key={index} product={product} index={index} />
                        )
                    })
                    setValue(products)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            setValue([])
        }
    }

    const getSubscriptions = () => {
        Subscription.getSubscription(localStorage.getItem('user_id')).then(res => {
            console.log(res.data)
            if (res.data.length > 0) {
                res.data.map(subscription => {
                    setSubscriptions(prev => [...prev, subscription])
                })
                console.log(res.data)
                setActiveCenterId(res.data[0].subscribe_to)
                setActiveCenterType(res.data[0].subscription_type)
                setActiveCenter(res.data[0].subscription_type == 'teacher' ? res.data[0].display_name : res.data[0].centre_name)
            }
            else {
                setSubscriptions([])
                setValue([])
                setActiveCenterId(null)
                setActiveCenterType(null)
                setActiveCenter(null)
            }
        })

    }

    React.useEffect(() => {
        if (!isInitialMount.current) {
            isInitialMount.current = true;
            getSubscriptions()
        }
    }, [subscriptions])

    React.useEffect(() => {
        fetchData()
    }, [activeCenterId, activeCenterType])

    const handleCenterChange = (center, type, name) => {
        console.log(center, type)
        setActiveCenterId(center)
        setActiveCenterType(type)
        setActiveCenter(name)
        setOpenFilters(false);
    }

    const handleUnfollow = () => {
        var data = {
            "user_id": localStorage.getItem('user_id'),
            "subscriber_id": activeCenterId
        }
        Subscription.deleteSubscription(data).then(res => {
            setSubscriptions([])
            getSubscriptions()
        })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenFilters(open);
    };

    const MyFollowings = () => {
        return (
            <Grid style={{ boxShadow: "4px 5px 8px rgb(158 158 158 / 8%)" }} className={classes.shopFiltersWrapper} item md={2}>
                <h4 className={classes.filterHeading}>My Followings</h4>
                {
                    subscriptions.length > 0 &&
                    subscriptions.map((subscription, index) => {
                        return (
                            <div key={index} onClick={() => handleCenterChange(subscription.subscribe_to, subscription.subscription_type, subscription.subscription_type == 'centre' ? (subscription.centre_name != null ? subscription.centre_name : subscription.display_name) : subscription.display_name)} className={activeCenterId == subscription.subscribe_to ? classes.activecenterWrapper : classes.centerWrapper}>
                                {
                                    subscription.subscription_type == 'centre' ?
                                        <>
                                            <img src={process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + (subscription.avatar != null ? subscription.avatar : subscription.profile_picture)} className={classes.bellIcon} />
                                            <span className={classes.centerName}>{subscription.centre_name != null ? subscription.centre_name : subscription.display_name}</span>
                                        </>
                                        :
                                        <>
                                            {
                                                subscription.auth_using == 'social' ?
                                                    <img src={subscription.profile_picture} className={classes.bellIcon} />
                                                    :
                                                    <img src={process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + subscription.profile_picture} className={classes.bellIcon} />
                                            }
                                            <span className={classes.centerName}>{subscription.display_name}</span>
                                        </>
                                }
                            </div>
                        )
                    })

                }
            </Grid>
        )
    }

    function existInCart(product) {
        if (cartItem != null) {
            return cartItem.some(function (el) {
                return el.course_id === product;
            });
        }
        return false
    }

    return (
        <>
            <React.Fragment key={'left'} style={{ width: '60%' }}>
                <Drawer anchor={'left'} open={openFilters} onClose={toggleDrawer('left', false)}>
                    <MyFollowings style={{ width: '70%' }} />
                </Drawer>
            </React.Fragment>
            <Hidden smDown>
                <MyFollowings />
            </Hidden>
            <Grid item sm={12} md={10} xs={12}>
                <div className={classes.searchWrapper}>
                    <Grid container alignItems="center" className={classes.pageHeading} justifyContent="space-between">
                        <Grid item xs={8}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Hidden mdUp>
                                    <ButtonBase onClick={() => setOpenFilters(true)} className={classes.filtersButton}>
                                        <Icon className={classes.filtersIcon}>tune</Icon>
                                    </ButtonBase>
                                </Hidden>
                                <Typography variant="h6" className={classes.heading}>{activeCenter}</Typography>
                            </Grid>
                        </Grid>

                        <Button className={classes.unfollow} onClick={() => handleUnfollow()}>
                            <Hidden xsDown>
                                Unfollow
                            </Hidden>
                            <Icon className={classes.unfollowIcon}>notifications_off</Icon>
                        </Button>
                    </Grid>
                    <Container className={classes.productsWrapper} maxWidth="lg">
                        <Grid container>
                            {
                                list.length > 0 ? list : <Typography variant="h5" className={classes.centerTextFull}>No Followings Found</Typography>
                            }
                        </Grid>
                    </Container>
                </div>
            </Grid>
        </>
    )
})

