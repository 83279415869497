import React from 'react'
import { useEffect, useState } from 'react'
import { off, on } from '../utils'

export default function useScrollingUp() {
    let prevScroll
    if (process.browser) {
        prevScroll = window.pageYOffset
    }
    const [scrollingUp, setScrollingUp] = useState(false)
    const handleScroll = () => {
        const currScroll = window.pageYOffset
        // const isScrolled = prevScroll > currScroll
        const isScrolled = prevScroll > 300
        setScrollingUp(isScrolled)
        if (currScroll == 0) {
            setScrollingUp(false)
        }
        prevScroll = currScroll
    }
    useEffect(() => {
        on(window, 'scroll', handleScroll, { passive: true })
        return () => {
            off(window, 'scroll', handleScroll, { passive: true })
        }
    }, [])
    return scrollingUp
}
