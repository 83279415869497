import React from "react";
import Header from "./Header";
import BackButton from "./BackButton";
import CommentsList from "./CommentsList";
import Ratings from "./Ratings";
import { Grid } from "@material-ui/core";

function AllReviews({ reviews, handleClose, title, handleAddReview }) {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12}>
          <Header title={title} handleClose={handleClose} />
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <Ratings reviews={reviews} handleClose={handleClose} handleAddReview={handleAddReview} />
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <CommentsList reviews={reviews} />
        </Grid>
      </Grid>
    </div>
  );
}

export default AllReviews;
