import React, { forwardRef, useState, useImperativeHandle, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Product from '../../services/products';
import History from '../../services/history';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { CartContext } from '../../context/CartContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HistoryService from '../../services/history';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SingleProduct from './singleProduct';
import Drawer from '@material-ui/core/Drawer';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { shadows } from '@material-ui/system';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AuthForm from '../../components/auth/SingleSignIn';
import Checkout from '../../components/checkout/checkout';
import Wallet from '../../components/checkout/wallet';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import { useJwt } from 'react-jwt';

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    productsWrapper: {
        padding: 30,
        marginBottom: 0,
        height: '90vh',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            height: 'auto',
            overflowY: 'visible',
        }
    },
    heading: {
        paddingLeft: 40,
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 18
        }
    },
    media: {
        height: "auto"
    },
    sectionName: {
        marginBottom: 25,
        marginTop: 30,
        fontSize: 30,
        fontWeight: 700,
    },
    shopFiltersWrapper: {
        background: '#fff',
        borderRadius: 10,
        padding: 10,

    },
    details: {
        flexDirection: 'column'
    },
    titleName: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3,
        fontDecoration: 'none'
    },
    singleItem: {
        height: 320,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    singeInner: {
        width: '90%',
        height: '100%',
        marginBottom: 20,
        marginLeft: '5%'
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    yearHeading: {
        marginBottom: 0,
        color: "#FA9C24"
    },
    expandIcon: {
        padding: 0
    },
    accHeading: {
        margin: 0
    },
    monthName: {
        marginBottom: 5,
        fontSize: 16,
        cursor: 'pointer',
        backgroundColor: '#f7f7f7',
        padding: 5,
        borderRadius: 5
    },
    dateHeading: {
        paddingRight: 30
    },
    pageHeading: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#ededed',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    browsingHeading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 20
    },
    deleteIcon: {
        fontSize: 20,
        color: '#e13838'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#ddd',
        borderRadius: 10,
        textAlign: 'center',
        width: '30%',
        boxShadow: 5,
        padding: 15,
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    simpleButton: {
        backgroundColor: '#fff',
        marginRight: 10
    },
    dangerButton: {
        backgroundColor: '#c23e3e',
        marginLeft: 10,
        color: 'white'
    },
    pageTitle: {
        marginBottom: 15
    },
    filtersButton: {
        width: '10%',
        marginLeft: 10,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '65%',
        backgroundColor: '#f99c25'
    },
    filtersIcon: {
        color: 'white',
    },
    authContainer: {
        backgroundColor: '#061C20',
        height: '100vh'
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 15
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
}));

export default forwardRef(function Shop(props, ref) {
    const isInitialMount = React.useRef();
    let [list, setValue] = React.useState([]);
    let [count, setCount] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [totalProducts, setTotal] = React.useState(100);
    const [years, setYears] = React.useState([]);
    const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    const [page, setPage] = React.useState(1);
    const [historyFor, setHistoryFor] = React.useState((new Date().getMonth() + 1) + ' ' + new Date().getFullYear())
    const [historyMonth, setHistoryMonth] = React.useState((new Date().getMonth() + 1))
    const [historyYear, setHistoryYear] = React.useState(new Date().getFullYear())
    const [openFilters, setOpenFilters] = React.useState(false)
    const classes = useStyles();
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);
    const [cartItem, setCartItem] = React.useContext(CartContext);
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenFilters(open);
    };

    let products = [];
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    const fetchData = () => {
        products = []

        History.getHistory(localStorage.getItem('user_id'), historyMonth, historyYear)
            .then((response) => {
                console.log(response)
                setCount(response.data.count)
                response.data.products.forEach((product, index) => {
                    products.push(
                        <SingleProduct from={'none'} fetchData={() => fetchData()} history={true} key={index} product={product} index={index} />
                    )
                })
                setValue(products)
                setYears(response.data.years)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    React.useEffect(() => {
        if (!isInitialMount.current) {
            isInitialMount.current = true;
            fetchData()
        }
        else {
            fetchData()
        }
    }, [historyMonth])

    const handleMonthChange = (year, month) => {
        setHistoryYear(year)
        setHistoryMonth(month)
        setOpenFilters(false);
    }

    const confirmModal = () => {
        setOpenModal(true)
    }

    const handleClearHistory = () => {
        HistoryService.clearHistory(localStorage.getItem('user_id')).then(res => {
            fetchData()
        })
        setOpenModal(false)
    }

    function DeleteConfirmation() {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <Typography variant="h6" style={{ marginBottom: 35, fontWeight: 500 }} >
                            {"Are you sure to delete?"}
                        </Typography>
                        <Grid>
                            <Button className={classes.simpleButton} onClick={() => setOpenModal(false)}>No</Button>
                            <Button className={classes.dangerButton} onClick={handleClearHistory}>Yes</Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        )
    }

    const HistoryFilters = () => {
        return (
            <Grid style={{ boxShadow: "4px 5px 8px rgb(158 158 158 / 8%)" }} className={classes.shopFiltersWrapper} item xs={12} md={2}>
                <div className={classes.browsingHeading}>
                    <Typography variant="body1" style={{ padding: 0, width: '12rem' }} className={classes.heading}>{props.title}</Typography>
                    <Tooltip title="Delete browsing history" placement="top">
                        <ButtonBase onClick={() => confirmModal()} className={classes.clearAll}><Icon className={classes.deleteIcon}>auto_delete</Icon></ButtonBase>
                    </Tooltip>
                </div>
                {
                    years != null &&
                    years.map((item) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h6" className={classes.yearHeading}>{item.year}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.details}>
                                    <div>
                                        {
                                            item.months.map((month) => {
                                                return (

                                                    <Typography variant="body1" onClick={() => handleMonthChange(item.year, month.month)} className={classes.monthName}>{months[month.month - 1]}</Typography>
                                                )
                                            })
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })

                }
            </Grid>
        )
    }

    const addToCart = (product) => {
        setCartItem(prevItems => [...prevItems, product])
    }

    const removeFromCart = (course_id) => {
        setCartItem(cartItem.filter(item => item.course_id !== course_id))
    }

    function existInCart(product) {
        if (cartItem != null) {
            return cartItem.some(function (el) {
                return el.course_id === product;
            });
        }
        return false
    }

    return (
        <>
            <React.Fragment key={'left'} style={{ width: '60%' }}>
                <Drawer anchor={'left'} open={openFilters} onClose={toggleDrawer('left', false)}>
                    <h3 style={{ marginLeft: 10, marginTop: 10, marginBottom: 5 }}>Filter by month</h3>
                    <HistoryFilters style={{ width: '70%' }} />
                </Drawer>
            </React.Fragment>
            <Hidden smDown>
                <HistoryFilters />
            </Hidden>
            <Grid item sm={12} md={10} xs={12}>
                {/* <Typography variant="h5" className={classes.pageTitle}>Browsing History</Typography> */}
                <div className={classes.searchWrapper}>
                    <Grid container alignItems="center" className={classes.pageHeading} justifyContent="space-between">
                        <Hidden mdUp>
                            <ButtonBase onClick={() => setOpenFilters(true)} className={classes.filtersButton}>
                                <Icon className={classes.filtersIcon}>tune</Icon>
                            </ButtonBase>
                        </Hidden>
                        <Typography variant="body1" className={classes.heading}>{months[historyMonth - 1]} {historyYear}</Typography>
                    </Grid>
                    <Container className={classes.productsWrapper} maxWidth="lg">
                        <Grid container>
                            {list}
                        </Grid>
                    </Container>
                </div>
            </Grid>
            <DeleteConfirmation />
        </>
    )
})

