import React from "react";
// import "./index.css";
import { Calendar } from "antd";
import dayjs from "dayjs";



const LessonsCalendar = ({ onChange }) => {
  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);

  };

  const dateCellRender = (value) => {
    if (value.get("date") === dayjs().get("date")) {
      return <span>S</span>;
    }
  };
  // const onChange = (value) => {
  //   console.log(dayjs(value).format('DD/MM/YYYY'))

  // }
  return (
    <div className="site-calendar-demo-card">
      <Calendar
        min
        // dateCellRender={dateCellRender}
        fullscreen={false}
        onPanelChange={onPanelChange}
        onChange={onChange}
      />
    </div>
  );
};

export default LessonsCalendar;
