import React from 'react';
import SliderUploader from '../../components/teacher/sliderUploader';
import TeacherService from '../../services/teacher';
import { makeStyles } from '@material-ui/core/styles';
import * as Mui from '@material-ui/core';
import '../css/admin.css'
import ReactPlayer from 'react-player';

export default function MyShop() {
    const [slides, setSlides] = React.useState([])
    const [previousSlides, setPreviousSlides] = React.useState([])
    const [uploading, setUploading] = React.useState(false)

    const handleMaterialChange = (slide) => {
        // let file = URL.createObjectURL(slide.file)
        setSlides(slides => [...slides, slide])
    }

    React.useEffect(() => {
        TeacherService.getSlider(localStorage.getItem('user_id')).then(res => {
            setPreviousSlides(res.data)
        }, err => {
            console.log(err)
        })

    }, [])

    const handleUploadSlider = () => {
        setUploading(true)
        const dataFile = new FormData()
        for (let index = 0; index < slides.length; index++) {
            dataFile.append('files[' + index + ']', slides[index]['file']);
            dataFile.append('data[' + index + ']', slides[index]['type']);
        }
        TeacherService.addSlider(dataFile, localStorage.getItem('user_id')).then(res => {
            setUploading(false)
            setPreviousSlides(res.data)
            setSlides([])
        }, error => {
            console.log(error)
            setUploading(false)
        })
    }

    const handleSlideDelete = (ind) => {
        let updatedSlides = previousSlides.filter((item, index) => item.id != ind)
        TeacherService.deleteSlide(ind).then(res => {
            console.log(res)
            setPreviousSlides(updatedSlides)
        })
    }

    const handleSlideRemove = (ind) => {
        let updatedSlides = slides.filter((item, index) => index != ind)
        setSlides(updatedSlides)
    }

    const Slide = ({ slide, index, newSlide }) => {
        return (
            <Mui.Grid item xs={12} md={4} lg={3} >
                <div className="slide-wrapper">
                    {
                        slide.type == 'video/mp4' ?
                            <ReactPlayer
                                playing={false}
                                controls={false}
                                className='react-player'
                                url={
                                    newSlide ?
                                        // URL.createObjectURL(slide.file)
                                        slide.url
                                        :
                                        `${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${slide.file}`
                                }
                                width='100%'
                                volume={0 / 100}
                                height='auto'
                            />
                            :
                            <img src={newSlide ? slide.url : `${process.env.REACT_APP_API_URL}uploads/teacher/sliders/${slide.file}`} width="100%" />

                    }
                    {
                        newSlide ?
                            <Mui.Icon onClick={() => handleSlideRemove(index)} className="delete-slide-btn">close</Mui.Icon>
                            :
                            <Mui.Icon onClick={() => handleSlideDelete(slide.id)} className="delete-slide-btn">close</Mui.Icon>
                    }
                </div>
            </Mui.Grid>
        )
    }

    return (
        <>
            <Mui.Container className="my-shop-container" maxWidth={'md'} style={{ padding: '30px', minHeight: '80vh', marginTop: 20, marginBottom: 20 }}>
                <Mui.Grid container spacing={2} direction="column" alignItems="flex-end" justifyContent="space-between">
                    <Mui.Grid item xs={12}>
                        <Mui.Grid container spacing={2} alignItems="center">
                            <Mui.Grid item xs={12}>
                                <SliderUploader updateSlides={handleMaterialChange} title={'Add slide'} />
                            </Mui.Grid>
                            {
                                slides.map((file, index) => {
                                    console.log(file)
                                    return (
                                        <Slide newSlide={true} key={index} index={index} slide={file} />
                                    )
                                })
                            }
                            {
                                previousSlides.map((file, index) => {
                                    return (
                                        <Slide newSlide={false} key={index} index={index} slide={file} />
                                    )
                                })
                            }
                        </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                        <Mui.Button disabled={uploading ? true : false} className={uploading ? 'upload-slider-btn-deactivate' : 'upload-slider-btn'} onClick={handleUploadSlider}>{uploading ? 'Uploading....' : 'Update Slider'}</Mui.Button>
                    </Mui.Grid>
                </Mui.Grid>
            </Mui.Container>
        </>
    )
}
