import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { AuthContext } from '../../../context/AuthContext';
import Badge from '@material-ui/core/Badge';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { bounceInRight } from 'react-animations';
import { bounce } from 'react-animations';


const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        padding: 30,
        paddingTop: 0,
        paddingBottom: 50,
        backgroundColor: '#092a30',
    },
    footerLogo: {
        width: '30%',
        marginBottom:20
    },
    footerHeading: {
        color: '#a1a1a1',
        marginBottom: 15
    },
    footerLink: {
        color: '#fff',
        marginBottom: 10
    },
    footerAbout: {
        width: '70%',
        color: '#a1a1a1',
        lineHeight: 1.5
    },
    footerOuter: {
        paddingTop: 60,
        marginTop: 1,
        backgroundColor: '#092a30'
    },
    footerSocial: {
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: '#061c20'
    },
    footerIcon: {
        fontSize: 36,
        color: '#939393',
        marginLeft: 5
    },
    copyright: {
        color: '#939393'
    },
    footerIconWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    flotCart: {
        position: 'fixed',
        bottom: 30,
        right: 30
    },
    fabCart: {
        backgroundColor: '#FA9C24',
    },
    cartIcon: {
        color: '#fff'
    }
}));

export default function Header() {
    const classes = useStyles();
    const [cartItem, setCartItem] = React.useContext(CartContext)
    const [animation, setAnimation] = React.useState(1)
    const BounceInAnimation = keyframes`${bounceInRight}`;
    const BounceInDiv = styled.div`
      animation: 1s ${BounceInAnimation};
    `;
    const BounceAnimation = keyframes`${bounce}`;
    const BounceDiv = styled.div`
      animation: ${animation}s ${BounceAnimation};
    `;

    React.useEffect(() => {
        // setAnimation(0)
        // setAnimation(1)
    }, [cartItem])

    return (
        <div id="print-footer" className={classes.footerOuter}>
            {
                cartItem.length > 0 &&
                <Box className={classes.flotCart} sx={{ '& > :not(style)': { m: 1 } }}>
                    <BounceDiv>
                        <Link style={{ color: 'white', textDecoration: 'none' }} to="/cart">
                            <Fab className={classes.fabCart} aria-label="add">
                                <Badge badgeContent={cartItem != null ? cartItem.length : 0} color="primary">
                                    <Icon className={classes.cartIcon}>shopping_cart</Icon>
                                </Badge>
                            </Fab>
                        </Link>
                    </BounceDiv>
                </Box>
            }
            <Grid container justifyContent="space-between" className={classes.footerWrapper}>
                <Grid className={classes.footerBrand} item xs={12} sm={5}>
                    <img className={classes.footerLogo} src="/assets/Logo.png" />
                    {/* <p className={classes.footerAbout}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p> */}
                </Grid>
                <Grid flexdirection="column" item xs={12} sm={2}>
                    {/* <p className={classes.footerHeading}>Solutions</p>
                    <p className={classes.footerLink}>EdHub School</p>
                    <p className={classes.footerLink}>EdHub Quiz Suite</p>
                    <p className={classes.footerLink}>EdHub Learning</p> */}
                    <Link style={{ color: 'white', textDecoration: 'none' }} to="/">
                        <p className={classes.footerLink}>Home</p>
                    </Link>
                    <Link style={{ color: 'white', textDecoration: 'none' }} to="/about-us">
                        <p className={classes.footerLink}>About us</p>
                    </Link>
                    <Link style={{ color: 'white', textDecoration: 'none' }} to="/become-teacher">
                        <p className={classes.footerLink}>Become a teacher</p>
                    </Link>
                </Grid>
                {/* <Grid flexdirection="column" item xs={12} sm={2}> */}
                {/* <p className={classes.footerHeading}>Company</p> */}
                {/* <p className={classes.footerLink}>About Us</p> */}
                {/* <p className={classes.footerLink}>Events</p> */}
                {/* <p className={classes.footerLink}>Contact Us</p> */}
                {/* <p className={classes.footerLink}>Privacy Policy</p> */}
                {/* <p className={classes.footerLink}>Terms of Services</p> */}
                {/* </Grid> */}
                {/* <Grid flexdirection="column" item xs={12} sm={3}>
                    <p className={classes.footerHeading}>Subscribe</p>
                </Grid> */}
            </Grid>
            <Grid alignItems="center" container className={classes.footerSocial}>
                <Grid item xs={12} sm={8}>
                    <span className={classes.copyright}>© {new Date().getFullYear()} EdHub. All Right Reserved.</span>
                </Grid>
                <Grid className={classes.footerIconWrapper} item xs={12} sm={4}>
                    <Link to="/terms-service" style={{ color: 'white', textDecoration: 'none', marginRight: 25 }}>Terms & Services</Link>
                    <Link to="/privacy-policy" style={{ color: 'white', textDecoration: 'none' }}>Privacy Policy</Link>
                    {/* <Icon className={classes.footerIcon}>facebook</Icon>
                    <Icon className={classes.footerIcon}>facebook</Icon>
                    <Icon className={classes.footerIcon}>facebook</Icon> */}
                </Grid>
            </Grid>
        </div>
    );
}