import React from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../helper/CroppedImage';
import '../css/myProfile.css';
import '../css/teacherReg.css';
import * as Mui from '@material-ui/core';

export default function ProfileCropper(props) {
    const [blobImage, setBlobImage] = React.useState(null)
    const [crop, setCrop] = React.useState({
        x: 0, y: 0,
    })
    const [zoom, setZoom] = React.useState(1)
    const [croppedImage, setCroppedImage] = React.useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixelsValue) => {
        setCroppedAreaPixels(croppedAreaPixelsValue)
    }, [])
    const [newPicture, setNewPicture] = React.useState(null)

    const handleImageUpload = (event) => {
        // console.log('event.target.files[0]')
        setNewPicture(event.target.files[0])
        // setPictureChanged(true)
    }

    const showCroppedImage = async () => {
        // console.log(newPicture, croppedAreaPixels)
        if (newPicture != null) {
            try {
                const croppedImage = await getCroppedImg(
                    URL.createObjectURL(newPicture),
                    'blob',
                    croppedAreaPixels,
                    0
                ).then(res => {
                    console.log(res)
                    props.handleNewImageUploaded(res)
                })
            } catch (e) {
                console.error(e)
            }
        }
        else {
            props.closeProfileCropper()
        }
    }

    return (
        <>
            <Mui.Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-heading">
                    <Mui.Button onClick={props.closeProfileCropper} className="close-btn-circle"><Mui.Icon>close</Mui.Icon></Mui.Button>
                </div>
                <Mui.DialogTitle id="alert-dialog-title" >
                    <span>Change profile picture</span>
                </Mui.DialogTitle>
                <Mui.DialogContent>
                    <Mui.DialogContentText id="alert-dialog-description">
                        <div className="test-wrapper">
                            <div className="profile-pic new-pic">
                                <div className="crop-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {
                                        newPicture != null ?
                                            <Cropper
                                                image={URL.createObjectURL(newPicture)}
                                                crop={crop}
                                                zoom={zoom}
                                                cropShape="round"
                                                aspect={1}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                            :
                                            <div className="profile-picture-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {props.profile_picture != null ? <img width="100%" src={props.profile_picture} /> : <Mui.Icon className="picture-icon">photo_camera</Mui.Icon>}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <input accept="image/*" id="upload-new-picture" onChange={handleImageUpload} type='file' hidden />
                            <label htmlFor="upload-new-picture" >
                                <Mui.Tooltip placement="left" title={"Click to change"}>
                                    <Mui.Button component="span" className="profile-picture-btn">
                                        <Mui.Icon>upload</Mui.Icon> Upload Picture
                                </Mui.Button>
                                </Mui.Tooltip>
                            </label>
                        </div>
                    </Mui.DialogContentText>
                </Mui.DialogContent>
                <Mui.DialogActions>
                    <Mui.Button onClick={showCroppedImage} className="confirm-btn-circle"><Mui.Icon>check</Mui.Icon></Mui.Button>
                </Mui.DialogActions>
            </Mui.Dialog>

        </>
    )
}
