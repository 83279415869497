import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { BookingContext } from "../../../context/BookingContext";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import BookingModal from "./BookingModal";
import { useJwt } from "react-jwt";
import { Link } from "react-router-dom";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import SlotsDialog from "../List/SlotsDialog";

const useStyle = makeStyles((theme) => ({
  package: {
    marginBottom: 10,
    // border: "solid 1px #17c9b8",
    border: "1px solid #1A95AC",
    borderRadius: 10,
    cursor: "pointer",
    "& :hover": {
      backgroundColor: "#eefffd",
    },
  },
}));

export default function Packages({ data }) {
  const classes = useStyle();
  const { addTrials, clearSelection } = React.useContext(BookingContext);
  const [open, setOpen] = React.useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { jwt } = React.useContext(UserContext);
  const [token, setToken] = jwt;
  const { decodedToken, isExpired } = useJwt(token);
  const [openTimeSlot, setOpenTimeSlot] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState({});
  const { bookedTrials, bookedTrialsObject, setBookedTrialsObject } =
    React.useContext(BookingContext);

  React.useEffect(() => {
    clearSelection();
    console.log(data);
  }, []);

  React.useEffect(() => {
    return () => {
      clearSelection();
    };
  }, []);

  const ConfirmationDialog = () => {
    return (
      <Dialog
        maxWidth={"xs"}
        open={showSuccess}
        onClose={handleSuccessClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText align="center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  width: 60,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 60,
                  backgroundColor: "#20B774",
                  border: "solid 2px white",
                  marginBottom: 10,
                }}
              >
                <DoneOutlineRoundedIcon style={{ color: "white" }} />
              </div>
            </div>
            <h3 style={{ textAlign: "center", color: "#20B774" }}>
              Your booking is confirmed
            </h3>
            <p style={{ textAlign: "center", color: "black" }}>
              You have successfully booked a trial session.
              <br />
              Click <Link to="/my/bookings">here</Link> to check the lesson
              details.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 15,
            }}
          >
            <Button
              style={{
                width: "80%",
                backgroundColor: "#20B774",
                color: "white",
              }}
              onClick={handleSuccessClose}
              color="success"
              autoFocus
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const BookTrial = (trial) => {
    clearSelection();
    addTrials(trial.price_id, trial.teacher_id, trial);
    setOpenTimeSlot(true);
    // setOpenModal(true)
  };

  const handleSuccessShow = () => {
    setShowSuccess(true);
  };
  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const selectTimeSlot = (slotId, slotTime, slotDate, trialId) => {
    selectedSlots[trialId] = slotId;
    setSelectedSlots(selectedSlots);
    let index = bookedTrialsObject.findIndex(
      (trial) => trial.price_id == trialId
    );
    let booked = [...bookedTrialsObject];
    booked[index]["trial_time"] = slotTime;
    booked[index]["trial_date"] = slotDate;
    booked[index]["slot_id"] = slotId;
    setBookedTrialsObject(booked);
  };

  return (
    <div className={classes.package}>
      <SlotsDialog
        selectedSlots={selectedSlots}
        bookedTrialsObject={bookedTrialsObject}
        setOpenModal={setOpenModal}
        selectTimeSlot={selectTimeSlot}
        setOpenTimeSlot={setOpenTimeSlot}
        openTimeSlot={openTimeSlot}
      />
      <ConfirmationDialog />
      <Grid
        onClick={() => (isExpired ? setOpen(true) : BookTrial(data))}
        style={{
          padding: 10,
          borderRadius: 10,
          cursor: "pointer",
          // border: "1px solid #1A95AC",
        }}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            style={{
              color: "#1A95AC",
              // color: "#17c9b8",
              fontSize: 20,
              fontWeight: 400,
            }}
          >
            HKD ${data.price}
          </Typography>
          <Typography
            style={{ color: "#1A95AC", fontSize: 14, fontWeight: 400 }}
          >
            {data.duration}
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRightIcon style={{ color: "#1A95AC", fontSize: 28 }} />
        </Grid>
      </Grid>
      <BookingModal
        onSuccess={handleSuccessShow}
        openModal={openModal}
        handleClose={handleCloseModal}
      />
    </div>
  );
}
