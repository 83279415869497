import React, { useState, createContext } from 'react'
import { UserContext } from './UserContext';
import Classes from '../services/classes';

export const EnrollmentNotificationContext = createContext();

export const EnrollmentNotificationProvider = props => {

    const { user } = React.useContext(UserContext)
    const [enrollmentNotifications, setEnrollmentNotifications] = useState([]);

    React.useEffect(() => {
        if (user[0]) {
            if (user[0].role_id == 4) {
                let data = { 'teacher': user[0].user_id }
                Classes.getAwaitingEnrollments(data).then(res => {
                    console.log(res.data.awaiting)
                    setEnrollmentNotifications(res.data.awaiting)
                })
            }
        }
    }, [user])

    return (
        <EnrollmentNotificationContext.Provider value={{ awaitingEnrollments: [enrollmentNotifications, setEnrollmentNotifications] }}>
            {props.children}
        </EnrollmentNotificationContext.Provider>
    )
}
