import React from "react";
import {
  Select,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BookingContext } from "../../../context/BookingContext";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 15,
    display: "flex",
    flexDirection: "column",
  },
  singleItem: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  singleItemStrike: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    textDecoration: "line-through",
  },
  selectBox: {
    borderRadius: 5,
    width: 300,
  },
  bookingBtn: {
    marginTop: 10,
    width: 300,
    backgroundColor: "#91AE45",
    color: "white",
    "&:hover": {
      backgroundColor: "#74933F",
    },
  },
  textField: {
    marginTop: 20,
  },
}));

export default function TrialPackages({ teacher, trials }) {
  const classes = useStyle();
  const { bookedTrials, addTrials, removeTrials } =
    React.useContext(BookingContext);
  const [selectedPackage, setSelectedPackage] = React.useState(0);
  const [selectedPackageObject, setSelectedPackageObject] = React.useState(
    trials[0]
  );
  const [trialDate, setTrialDate] = React.useState();
  const menuItemsRef = React.useRef();
  const Item = ({ trial }) => {
    return (
      <div
        className={
          trial.slots == 0 ? classes.singleItemStrike : classes.singleItem
        }
      >
        <div>{trial.duration}</div>
        <div>
          {trial.price}
          <small> HKD</small>
        </div>
      </div>
    );
  };

  const handlePackageSelect = (e) => {
    setSelectedPackage(e.target.value);
    setSelectedPackageObject(
      ...trials.filter((trial) => trial.price_id == e.target.value)
    );
  };

  const handleDateChange = (e) => {
    setTrialDate(e.target.value);
  };

  React.useEffect(() => {
    trials.forEach((trial) => {
      console.log(trials[0].price_id);
      if (bookedTrials.includes(trial.price_id))
        setSelectedPackage(trial.price_id);
    });
  }, [trials, bookedTrials]);
  console.log(trials);
  return (
    <div className={classes.root}>
      <Typography style={{ marginBottom: 10 }} variant="body1">
        Select package
      </Typography>
      {trials.length > 2 ? (
        <Select
          disabled={
            bookedTrials.includes(selectedPackage) ||
            menuItemsRef.current?.childNodes.length == 0
          }
          onChange={handlePackageSelect}
          value={selectedPackage}
          variant="outlined"
          className={classes.selectBox}
        >
          <MenuItem disabled value={0}>
            <span>Select package</span>
          </MenuItem>

          {trials.map(
            (trial, index) =>
              trial.slots.length > 0 && (
                <MenuItem
                  disabled={trial.slots.length == 0}
                  key={trial.price_id}
                  value={trial.price_id}
                >
                  <Item
                    trial={{
                      duration: trial.duration,
                      price: trial.price,
                      slots: trial.slots.length,
                    }}
                  />
                </MenuItem>
              )
          )}
        </Select>
      ) : (
        <Typography style={{ color: "lightgray" }}>No trial lessons</Typography>
      )}
      {/* <TextField
                id="date"
                label="Trial date"
                type="datetime-local"
                defaultValue=""
                onChange={handleDateChange}
                className={classes.textField}
                variant="outlined"
                value={trialDate}
                InputLabelProps={{
                shrink: true,
                }}
            /> */}
      {bookedTrials.includes(selectedPackage) ? (
        <Button
          onClick={() => removeTrials(selectedPackage, teacher)}
          className={classes.bookingBtn}
        >
          Remove from booking
        </Button>
      ) : (
        <Button
          disabled={selectedPackage == 0}
          onClick={() =>
            addTrials(selectedPackage, teacher, selectedPackageObject)
          }
          className={classes.bookingBtn}
        >
          Book Now
        </Button>
      )}
    </div>
  );
}
