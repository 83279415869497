import React from 'react';
import TeacherInfo from '../teacherInfo';
import HeaderCarousel from '../../widgets/HeaderCarousel';
import * as Mui from '@material-ui/core';
import '../shopstyle.css';

export default function ShopHeader({ slider, teacher }) {

    return (
        <>
            <Mui.Grid container className="shop-header">
                <Mui.Grid item xs={12}>
                    <HeaderCarousel carousel={slider} />
                    {/* <TeacherInfo details={teacher} /> */}
                </Mui.Grid>
            </Mui.Grid>
        </>
    )
}
