import React from 'react'

export default function income() {

    return (
        <div style={{ height: '80vh', backgroundColor: '#F4F4F4' }}>
            <h1>My Incomes</h1>
        </div>
    )
}
