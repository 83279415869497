import React, { useEffect, useState } from 'react'
import Classes from '../services/classes'

export default function useClassesAvailable(courseId) {
    const [classesAvailable, setClassesAvailable] = useState()

    useEffect(() => {
        let filter = {
            'startingDate': '',
            'endingDate': '',
            'course_id': courseId
        }
        console.log(filter)
        Classes.getCourseClasses(filter).then(res => {
            setClassesAvailable(res.data.classes.length > 0)
        })
    }, [courseId])

    return classesAvailable
}