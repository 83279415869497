import React, { useState, useEffect } from 'react';
import TeacherService from '../../../services/teacher';
import { UserContext } from '../../../context/UserContext';
import { Grid, Button, Icon, Avatar, Hidden } from '@material-ui/core';

export default function Followers() {

    const [followers, setFollowers] = useState([]);
    const { user } = React.useContext(UserContext)
    const [currentUser, setCurrentUser] = user;

    useEffect(() => {
        TeacherService.getFollowers(currentUser.user_id).then(res => {
            console.log(res)
            setFollowers(res.data)
        })
    }, [currentUser])

    return (
        <div>
            {
                followers.length > 0 &&
                followers.map((follower) => {
                    return (
                        <>
                            <Grid style={{ backgroundColor: '#dff9fb', borderRadius: 10, padding: 10, marginBottom: 20 }} container alignItems="center">
                                <Grid item style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
                                    <Avatar alt={follower.display_name} className="user-avatar" src={typeof follower.profile_picture != 'undefined' && process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + follower.profile_picture} />
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Hidden xsDown>
                                            <Grid item style={{ marginRight: 70 }}>
                                                <Grid container direction="column">
                                                    <Grid item><strong>First name</strong></Grid>
                                                    <Grid item>{follower.first_name}</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ marginRight: 70 }}>
                                                <Grid container direction="column">
                                                    <Grid item><strong>Last name</strong></Grid>
                                                    <Grid item>{follower.last_name}</Grid>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                        <Grid item style={{ marginRight: 40 }}>
                                            <Grid container direction="column">
                                                <Grid item><strong>Email</strong></Grid>
                                                <Grid item>{follower.email}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <h1>{follower.display_name ?? null}</h1> */}
                        </>
                    )
                })
            }
        </div>
    )
}

