import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Icon,
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { AuthContext } from "../../../../../context/AuthContext";
import { UserContext } from "../../../../../context/UserContext";
import { useJwt } from "react-jwt";
import TeacherService from "../../../../../services/teacher";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  commenterName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5,
  },
  singleComment: {
    backgroundColor: "#eefafc",
    borderRadius: 10,
    marginBottom: 10,
    boxShadow: "2px 2px 6px #9e9e9e1a",
  },
  commentDate: {
    fontSize: 10,
    color: "gray",
  },
  comment: {
    color: "gray",
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 30,
    fontSize: 25,
    backgroundColor: "#ededed",
    color: "#cdcdcd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  commentField: {
    width: "100%",
  },
  sendBtn: {
    marginTop: 10,
    backgroundColor: "#1a95ac",
    color: "white",
    "&:hover": {
      backgroundColor: "#136a7a",
    },
  },
  earnedRating: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: 48,
  },
  totalRating: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: 28,
  },
  ratingIcon: {
    color: "#FFCC01",
    fontSize: 32,
    marginTop: 10,
  },
  smallRatingIcon: {
    color: "#FFCC01",
    fontSize: 18,
  },
  addRatingIcon: {
    color: "#FFCC01",
    fontSize: 32,
    cursor: "pointer",
  },
  ratingQuestion: {
    fontWeight: 400,
  },

  starsWrapper: {
    width: "40%",
  },
  progressWrapper: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  linerSingle: {
    height: 10,
    borderRadius: 5,
  },
  linearWrapper: {
    width: "90%",
    marginRight: 10,
  },
  starsContainer: {
    padding: 20,
    paddingLeft: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 20,
    },
  },
  singleRate: {
    marginBottom: 5,
  },
  loginBtn: {
    backgroundColor: "#FA9C24",
    color: "white",
    "&:hover": {
      backgroundColor: "#c47818",
    },
  },
  rateBox: {
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },
  reviewsWrapper: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      marginBottom: 20,
      height: "auto",
    },
  },
  ratingForm: {
    marginBottom: 20,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
}));

export default function AddReview({ handleAddReview }) {
  const classes = useStyles();
  const commentRef = React.useRef();
  const ratingTypeKeys = ["content"];
  const { user } = React.useContext(UserContext);
  const [currentUser, setCurrentUser] = user;
  const [isCommentable, setIsCommentable] = useState(false);
  const ratingTypes = ["Rate this course"];
  const ratings = {
    content: 5,
  };
  const { teacherId } = useParams();
  const [courseRatings, setCourseRatings] = React.useState(ratings);
  const { jwt } = React.useContext(UserContext);
  const [token, setToken] = jwt;
  const [open, setOpen] = React.useContext(AuthContext);
  const { decodedToken, isExpired } = useJwt(token);
  const [newComment, setNewComment] = React.useState(null);

  const CommentForm = (props) => {
    const classes = useStyles();
    const [newComment, setNewComment] = React.useState("");
    return (
      <Box boxShadow={1} display="flex" p={3} className={classes.singleComment}>
        <Box flexGrow={1}>
          <form>
            <TextField
              className={classes.commentField}
              label="Add Comment"
              multiline
              value={newComment}
              maxRows={4}
              rows={3}
              ref={commentRef}
              name="newComment"
              variant="outlined"
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Grid container justifyContent="flex-end" alignItems="center">
              <Button
                onClick={() => {
                  props.handleAddComment(newComment);
                  setNewComment("");
                  setCourseRatings(ratings);
                }}
                className={classes.sendBtn}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Box>
      </Box>
    );
  };

  const handleRatings = (key, value) => {
    let newRating = { ...courseRatings };
    newRating[key] = value;
    setCourseRatings(newRating);
  };

  const RatingForm = () => {
    return (
      <div className={classes.ratingForm}>
        {ratingTypeKeys.map((value, index) => {
          return (
            <Grid
              container
              key={index}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: 20 }}
            >
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.ratingQuestion}>
                  {ratingTypes[index]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Icon
                    onClick={() => handleRatings(value, 1)}
                    className={classes.addRatingIcon}
                  >
                    {courseRatings[value] > 0 ? "star" : "star_outline"}
                  </Icon>
                  <Icon
                    onClick={() => handleRatings(value, 2)}
                    className={classes.addRatingIcon}
                  >
                    {courseRatings[value] > 1 ? "star" : "star_outline"}
                  </Icon>
                  <Icon
                    onClick={() => handleRatings(value, 3)}
                    className={classes.addRatingIcon}
                  >
                    {courseRatings[value] > 2 ? "star" : "star_outline"}
                  </Icon>
                  <Icon
                    onClick={() => handleRatings(value, 4)}
                    className={classes.addRatingIcon}
                  >
                    {courseRatings[value] > 3 ? "star" : "star_outline"}
                  </Icon>
                  <Icon
                    onClick={() => handleRatings(value, 5)}
                    className={classes.addRatingIcon}
                  >
                    {courseRatings[value] > 4 ? "star" : "star_outline"}
                  </Icon>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }} item xs={12}>
                {courseRatings.content < 4 && (
                  <Alert severity="warning">
                    Your feedback will significantly affect the teacher's
                    profile. Are you sure?
                  </Alert>
                )}
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  const handleAddComment = (comment) => {
    const data = {
      reviewer_id: currentUser.user_id,
      rating: courseRatings.content,
      teacher_id: teacherId,
      review: comment,
    };
    handleAddReview(data, currentUser);
    TeacherService.addComment(data).then((res) => {
      // handleAddReview(res.data.reviews)
    });
  };
  const handleCommentChange = () => {};

  useEffect(() => {
    TeacherService.isCommentable(teacherId, currentUser.user_id).then((res) => {
      setIsCommentable(res.data.isCommentable);
    });
  }, []);

  return (
    <div>
      <>
        {currentUser.role_id == 3 &&
          (isCommentable ? (
            <>
              <RatingForm />
              <CommentForm
                handleAddComment={handleAddComment}
                newComment={newComment}
                handleCommentChange={handleCommentChange}
              />
            </>
          ) : (
            <Alert severity="warning">
              Only students who have attended a lesson can leave their comments
            </Alert>
          ))}
      </>
    </div>
  );
}
