import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Mui from '@material-ui/core';
import { useDropzone } from 'react-dropzone'

const useStyles = makeStyles((theme) => ({
    redText: {
        color: 'red'
    },
    cartWrapper: {
        backgroundColor: 'white',
        marginTop: 50,
        marginBottom: 50,
        padding: 20,
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    pageTitles: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 5
        },
        color: '#E48F2B'
    },
    productAdd: {
        "& .MuiFormControl-root": {
            width: '100%',
            marginBottom: theme.spacing(1)
        },
        "& .MuiGrid-spacing-xs-1": {
            margin: '0px'
        },
        "& .MuiInputLabel-outlined": {
            zIndex: '0'
        },
        "& .MuiFormHelperText-root": {
            color: '#f44336',
            fontSize: '0.75rem'
        }

    },
    buttonSpacing: {
        width: "20%",
        backgroundColor: '#E48F2B',
        color: 'white',
        borderColor: 'transparent',
        "&:hover": {
            backgroundColor: '#c4771d',
            color: 'white',
            borderColor: 'transparent',
        }
    },
    btnWidth: {
        width: '30%'
    },
    coverDropzone: {
        maxHeight: theme.spacing(14),
        minHeight: theme.spacing(14),
        "& .MuiDropzoneArea-icon": {
            display: 'none'
        }
    },
    materialDropzone: {
        maxHeight: theme.spacing(42),
        minHeight: theme.spacing(42),
        "& .MuiDropzoneArea-icon": {
            display: 'none'
        }
    },
    fontSizeDropzone: {
        fontSize: '16px'
    },
    actionButtonItems: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    coverPhotoSection: {
        width: '100%',
        // height: theme.spacing(40),
        marginBottom: 20,
        background: "#f1f1f1",
        border: "dashed",
        borderWidth: "1px",
        overflow: "hidden",
        padding: "0"
    },
    materialPhotoSection: {
        width: theme.spacing(35),
        height: theme.spacing(40),
        background: "rgb(227, 227, 227)",
        border: "dashed",
        borderWidth: "1px",
        overflow: "hidden",
        padding: "0"
    },
    uploadwrapper: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: theme.spacing(0.5)
    },
    documentwrapper: {
        minHeight: '65%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        '& .uploadButtonLabel': {
            width: '100%',
            display: 'block',
            position: 'absolute',
            bottom: '0'
        },
        '& .MuiButton-outlined': {
            display: 'block',
            margin: theme.spacing(0.5)
        },
    },
    creatableSelect: {
        minHeight: theme.spacing(7),
        '& > div:nth-of-type(1)': {
            minHeight: theme.spacing(7),
        }
    },
    selectPlaeholder: {
        '& em': {
            color: 'hsl(0, 0%, 50%)',
            fontStyle: 'normal'
        }
    },
    errorFieldPadding: {
        paddingTop: '1px !important',
        "& .MuiFormHelperText-root": {
            marginTop: '0px'
        }
    },
    fileWrapper: {
        height: '60vh'
    },
    itemContainer: {
        padding: theme.spacing(7),
    },
    marginRight: {
        marginRight: theme.spacing(2)
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    qualificationInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        width: '98%',
        height: '100%',
        marginTop: 7
    },
    btnAdjustment: {
        width: '100%',
        height: theme.spacing(18),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'dashed',
        borderWidth: 1,
        borderRadius: 10,
        justifyContent: 'center',
        '& .MuiButton-label': {
            width: '100%',
            textAlign: 'center',
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'gray'
    },
    coverWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    pictureIcon: {
        marginBottom: 10,
        color: '#acacac',
        fontSize: theme.spacing(4)
    },
    noMaterialAttached: {
        marginTop: theme.spacing(5),
        textAlign: 'center',
        color: '#A1A1A1'
    },
    documentsScroller: {
        overflowY: 'scroll',
        height: '82%'
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        color: '#ce2727',
        right: 23,
        border: 'solid 1px #ce2727',
        borderRadius: 20,
        cursor: 'pointer'
    },
    switchWrapper: {
        '& svg': {
            display: 'none'
        }
    }

}));
export default function SliderUploader(props) {
    const onDrop = useCallback(acceptedFiles => {
        let newSlides = []
        acceptedFiles.map(slide => {
            let file = {
                "file": slide,
                "url": URL.createObjectURL(slide),
                "type": slide.type,
                "name": slide.name
            }
            // newSlides.push(file)
            handleMaterialChange(file)
        })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const classes = useStyles();
    const handleMaterialChange = (files) => {
        props.updateSlides(files)
    }

    return (
        <>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {

                    <Mui.Button component="span" className={classes.btnAdjustment} variant="outlined">
                        <div className={classes.buttonContainer}>
                            <Mui.Icon style={{ fontSize: 40 }}>add_circle</Mui.Icon>
                            <Mui.Typography variant="body1">{props.title}</Mui.Typography>
                            <small style={{ fontSize: 12 }}>Drag 'n' drop some files here, or click to select files</small>
                            <small style={{ fontSize: 10 }}>Suggested dimension (1:2)</small>
                        </div>
                    </Mui.Button>
                }
            </div>
            {/* <form>

                <input accept="video/*,image/*" id="upload-documents" type='file' hidden onChange={handleMaterialChange} />
                <label htmlFor="upload-documents" className="uploadButtonLabel">
                    <Mui.Button component="span" className={classes.btnAdjustment} variant="outlined">
                        <div className={classes.buttonContainer}>
                            <Mui.Icon style={{ fontSize: 40 }}>add_circle</Mui.Icon>
                            <Mui.Typography variant="body1">{props.title}</Mui.Typography>
                            <small style={{ fontSize: 10 }}>Suggested dimension (1:2)</small>
                        </div>
                    </Mui.Button>
                </label>

            </form> */}

        </>
    )
}
