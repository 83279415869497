import React from 'react'
import HeroSection from '../components/widgets/herosection';
import Latest from '../components/products/latest';
import Monthly from '../components/products/thismonth';
import Trending from '../components/products/trending';
import Featured from '../components/products/featured';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import PaymentService from '../services/payments';
import { CartContext } from '../context/CartContext';
// import Auth from '../../services/auth';
// import { UserContext } from '../../context/UserContext';

import sha256 from 'crypto-js/sha256';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '70vh',
        borderRadius: 15,
        overflow: 'hidden',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        height: 80,
        borderRadius: 100,
        marginBottom: 15
    },
    successIcon: {
        backgroundColor: '#00C1E2'
    },
    dangerIcon: {
        backgroundColor: 'red'
    },
    icon: {
        color: 'white',
        fontSize: 58,
    },
    cardWrapper: {
        background: 'white',
        padding: 30,
        paddingTop: 40,
        paddingBottom: 40,
        borderRadius: 15,
        width: '100%'
    },
    button: {
        marginTop: 30,
        background: '#FA9C24',
        textDecoration: 'none',
        color: 'white',
        padding: 15,
        borderRadius: 10
    }
}));

const pics = [

    "/assets/class1.jpeg",
    "/assets/class2.jpeg",
    "/assets/class3.jpeg",
    "/assets/class4.jpeg",
    "/assets/class5.jpeg"
];

export default function Success() {
    const classes = useStyles();
    const mounted = React.useRef()
    const [loading, setLoading] = React.useState(true)
    const [success, setSuccess] = React.useState(null)
    const [response, setResponse] = React.useState(null)
    const [heading, setHeading] = React.useState('Processing')
    const [cartItem, setCartItem] = React.useContext(CartContext);
    const [subHeading, setSubHeading] = React.useState('Please wait! while we process your payment.')
    let { ref } = useParams();
    let { user } = useParams();
    let { course } = useParams();
    let { subscriber } = useParams();
    let { purchaseType } = useParams();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            PaymentService.verifyAliPayIntent(atob(ref), atob(user), atob(course), atob(subscriber), atob(purchaseType)).then(res => {
                console.log(res)
                setResponse(res)
                if (res.data.purchaseType == 'cart')
                    setCartItem([])

                setLoading(false)
                if (res.data.status == 'Success') {
                    setSuccess(true)
                    setHeading('Payment Verified!')
                    setSubHeading('Your payment has been verified successfuly.')
                }
                if (res.data.status == 'Error') {
                    setSuccess(false)
                    setHeading('Invalid Payment!')
                    setSubHeading("We cannot verify this payment. Please contact support.")
                }
            }, error => {
                console.log(atob(course))
            })
        }
    }, [])

    return (
        <div style={{ backgroundColor: '#F4F4F4', height: '70vh', minHeight: '80vh' }}>
            <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                <Grid item xs="12" sm="6" lg="3">
                    <Grid container alignItems="center" justifyContent="center" direction="column" className={classes.cardWrapper}>
                        {
                            loading ?
                                <img width="35%" src="/assets/spinner.gif" />
                                :
                                success ?
                                    <div className={`${classes.iconWrapper} ${classes.successIcon}`}>
                                        <Icon className={classes.icon}>check</Icon>
                                    </div>
                                    :
                                    <div className={`${classes.iconWrapper} ${classes.dangerIcon}`}>
                                        <Icon className={classes.icon}>close</Icon>
                                    </div>
                        }
                        <Typography style={{ marginBottom: 30 }} variant="h5">{heading}</Typography>
                        <Typography variant="body1">{subHeading}</Typography>
                        {
                            success ?
                                response.data.purchaseType == 'single' ?
                                    <Link to={{
                                        pathname: "/view/product/" + (response != null ? response.data.course.course_name + '/' + response.data.course.course_id : ''),
                                        state: {
                                            image: (response != null ? (response.data.course.course_cover != null ? process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + response.data.course.subscriber_id + '/' + response.data.course.course_cover : pics[3]) : ''),
                                            id: response != null ? response.data.course.course_id : 0
                                        }
                                    }} className={classes.button}>View My Product</Link>
                                    :
                                    <Link to="/my-edhub" className={classes.button}>View My Product</Link>
                                :
                                null
                        }
                    </Grid>
                </Grid>
            </Grid>

        </div >
    )
}
