import axios from 'axios';
import { useJwt } from "react-jwt";
const token = localStorage.getItem('jwt_token');
const url = process.env.REACT_APP_API_URL + 'api/'
const corsUrl = process.env.REACT_APP_API_URL
const Classes = {

    getClasses: function (id) {
        return axios({
            "method": "GET",
            "url": url + 'classes',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }
        })
    },

    getClassById: function (id) {
        return axios({
            "method": "POST",
            "url": url + 'class/single',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": id,
        })
    },

    updateClassById: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'class/update',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    create: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'classes',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    enroll: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'class/enroll',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getEnrolment: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'get/enrolment',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    approveExtraEnrollments: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'approve/enrollments',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    declineExtraEnrollments: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'decline/enrollments',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getClassesMaterials: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'get/classes/materials',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getTeacherClasses: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'teacher/classes',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getCourseClasses: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'course/classes',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getAwaitingEnrollments: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'get/awaiting/enrollments',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    getEnrolledClasses: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'my/classes',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

    deleteMaterial: function (material_id) {
        console.log(material_id)
        return axios({
            "method": "POST",
            "url": url + 'delete/class/material',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": {
                "material_id": material_id
            },
        })
    },

    getAllClassesByDate: function (data) {
        return axios({
            "method": "POST",
            "url": url + 'classes/all',
            "headers": {
                "content-type": "application/json",
                "content-type": "text/plain",
            }, "data": data,
        })
    },

}

export default Classes;