import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams } from 'react-router-dom';
import DropZoneUploader from '../../components/shared/DropZone/DropZoneUploader';
import TeacherService from '../../services/teacher';
import { ToastContainer, toast } from 'material-react-toastify';
import './uploader.css';

export default function UploadMoreDocuments() {
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [uploading, setUploading] = React.useState(false)
    let filesArray = [];

    const docUploaded = () => toast.success("Documents uploaded successfuly!");
    const docError = () => toast.danger("Something went wrong!");

    const handler = (files) => {
        files.map(file => {
            setSelectedFiles(prevFiles => [...prevFiles, file])
        })
    }
    let { userId } = useParams();

    const icons = {
        'image/png': '/assets/icons/png.png',
        'image/jpeg': '/assets/icons/jpeg.png',
        'image/jpg': '/assets/icons/jpg.png',
        'application/pdf': '/assets/icons/pdf.png',
        'application/doc': '/assets/icons/doc.png',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '/assets/icons/doc.png',
    }


    const FileItem = ({ file, index }) => {
        return (
            <div className="upload-wrapper">
                <img src={icons[file.type]} />
                <p>{file.name}</p>
                <Icon onClick={() => handleFileDelete(index)} className="remove-icon">close</Icon>
            </div>
        )
    }

    const handleFileDelete = (index) => {
        let newFiles = selectedFiles.filter((item, ind) => ind != index)
        setSelectedFiles(newFiles)
    }

    const handleUploadDocuments = () => {
        setUploading(true)
        const formD = new FormData();

        selectedFiles.map(file => {
            formD.append('qualification_files[]', file)
        })
        formD.append('user_id', atob(userId));
        TeacherService.uploadDocuments(formD).then(res => {
            setUploading(false)
            docUploaded()
            setTimeout(() => {
                setSelectedFiles([])
                window.location.href = '/'
            }, 2000)
        }, error => {
            setUploading(false)
            docError()
        })
    }

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '90vh' }}>
            <ToastContainer position="top-right" autoClose={2000} />
            <Grid style={{ marginTop: 20, marginBottom: 20, backgroundColor: 'white', borderRadius: 10 }} item xs={12} sm={6}>
                <Grid container>
                    <Grid style={{ paddingLeft: 10 }} item xs={12}>
                        <h2 style={{ marginBottom: 0 }}>Upload documents</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <DropZoneUploader handler={handler} icon="upload_file" title={'Drop files'} sub_titles={"Drag 'n' drop some files here, or click to select files"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="stretch" style={{ padding: 10 }} spacing={2}>
                            {
                                selectedFiles &&
                                selectedFiles.map((file, index) => (
                                    <Grid key={index} item xs={6} sm={4} lg={3}>
                                        <FileItem file={file} index={index} />
                                    </Grid>
                                ))
                            }
                            {
                                selectedFiles.length > 0 &&
                                <Grid item xs={12}>
                                    {
                                        uploading ?
                                            <Box sx={{ width: '100%', height: 30, position: 'relative' }}>
                                                <LinearProgress style={{ height: 30 }} className="progress-bar" color="success" />
                                                <p style={{ margin: 0 }} className="loading-text">Uploading...</p>
                                            </Box>
                                            :
                                            <Button disabled={uploading} onClick={handleUploadDocuments} className={!uploading ? 'primary-btn-full' : 'primary-btn-full-disabled'}>{uploading ? 'Submitting...' : 'Submit'}</Button>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
