import React from "react";
import Grid from "@material-ui/core/Grid";

import Card from "./Card";
import styled from "styled-components";
import PlaceholderLoading from 'react-placeholder-loading'
import Teacher from '../../../services/teacher'

const CardContainer = ({teacher}) => {
  const [summary,setSummary] = React.useState(null)
  const data = [
      {
        title: "Total Sales",
        count: 3400.13,
        colorTop:'#83C3BD',
        color:'#E9F7F7',
        key:'totalSales'
      },
      {
        title: "Total Payouts",
        count: 1300.01,
        colorTop:'#B7B8D0',
        color:'#EAECF6',
        key:'totalPayouts'
      },
      {
        title: "Pending Payouts",
        count: 1245.06,
        colorTop:'#E3E1B3',
        color:'#F7F5E9',
        key:'pendingPayouts'
      }
  ];

  React.useEffect(() => {
    Teacher.getIncome(teacher).then(res => {
      setSummary(res.data)
      console.log(res.data)
    })
  }, [])

  return (
    <>
      <Section>
        <Grid style={{marginBottom:20}} container spacing={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {
            summary != null ?
            data.map((val, index) => (
              <>
                <Grid item xs={12} sm={3} key={index}>
                  <Card color={val.color} colorTop={val.colorTop} title={val.title} count={summary[val.key] != null ? summary[val.key] : 0} />
                </Grid>
              </>
            ))
            :
            <>
                <Grid item xs={12} sm={3} >
                  <PlaceholderLoading shape="rect" width={'100%'} height={150} />
                </Grid>
                <Grid item xs={12} sm={3} >
                  <PlaceholderLoading shape="rect" width={'100%'} height={150} />
                </Grid>
                <Grid item xs={12} sm={3} >
                  <PlaceholderLoading shape="rect" width={'100%'} height={150} />
                </Grid>
            </>
          }
        </Grid>
      </Section>
    </>
  );
};

const Section = styled.div`
  padding: 0 20px 0 20px;
`;

export default CardContainer;
