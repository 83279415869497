import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import './css/search.css';
import Hidden from '@material-ui/core/Hidden';
import ButtonBase from '@material-ui/core/ButtonBase';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import ShopProducts from '../components/products/shop';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Products from '../services/products';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import SearchLevel from '../components/common/searchLevel';
import SearchTags from '../components/common/searchTags';
import Product from '../services/products';

const useStyles = makeStyles((theme) => ({
    searchWrapper: {
        padding: 10,
        backgroundColor: '#f8f8f8',
        [theme.breakpoints.down('sm')]: {
            padding: 15
        }
    },
    resultHeader: {
        borderColor: 'black',
        border: 1,
        m: 1,
    },
    shopContainer: {
        // height: '91.5vh'
    },
    shopFiltersWrapper: {
        background: '#fff',
        borderRadius: 10,
        padding: 10
    },
    headerFiltersWrapper: {
        background: '#e55031'
    },
    whiteText: {
        color: 'white'
    },
    shopHeaderWrapper: {
        height: 70,
        marginBottom: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    filterTitle: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paginationHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTablePagination-input': {
            display: 'none'
        },
        '& .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters .MuiTypography-body2:nth-child(2)': {
            display: 'none',
        }
    },
    icon: {
        marginRight: 10,
        color: 'white'
    },
    filtersIcon: {
        color: 'white'
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
        border: 'solid',
        borderRadius: 35,
        borderWidth: 1,
        borderColor: '#d1d1d1',
        padding: 5,
        overflow: 'hidden',
        paddingRight: 10
    },
    searchBarMobile: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginLeft: 0,
        marginRight: 0,
        border: 'solid',
        borderRadius: 35,
        borderWidth: 1,
        borderColor: '#d1d1d1',
        backgroundColor: '#fff',
        padding: 0,
        overflow: 'hidden',
        paddingRight: 10,
        marginBottom: 10
    },
    searchField: {
        flex: 1,
        padding: 10,
        border: 'none'
    },
    searchIcon: {
        fontSize: 26
    },
    filterCard: {
        marginBottom: 10
    },
    filtersWrapper: {
        minHeight: '40vh',
        // overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        marginLeft: 10,
        marginTop: 10
    },
    filterCardContent: {
        paddingRight: 0,
        paddingLeft: 0, paddingTop: 0
    },
    searchFilter: {
        marginRight: 15,
        marginTop: 10, marginBottom: 15
    },
    searchBarSmall: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'solid',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        paddingRight: 5,
        padding: 5,
        borderRadius: 25,
        marginTop: 10
    },
    searchIconSmall: {
        fontSize: 20,
        color: '#d1d1d1'
    },
    searchFieldSmall: {
        width: '85%',

    },
    filterHeader: {
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    filterHeading: {
        color: 'rgb(249 156 36)',
        marginTop: 13, marginBottom: 13
    },
    filtersButton: {
        width: '12%',
        marginLeft: 10,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '55%',
        backgroundColor: '#f99c25'
    }
}))



function SearchProductLevels(props) {
    const classes = useStyles();
    return (
        <div className={classes.searchBarSmall}>
            <TextField id="outlined-basic" onKeyDown={(text) => props.handleLevelsSearch(text)} className={classes.searchFieldSmall} placeholder="Search" />
            <Icon className={classes.searchIconSmall}>search</Icon>
        </div>
    )
}

function GetTags(props) {
    const classes = useStyles();
    const [tags, setTags] = React.useState([])
    const [allTags, setAllTags] = React.useState([])
    const [checked, setChecked] = React.useState([])
    const [clear, setClear] = React.useState(false)
    const ref = useRef();
    const [selectedCount, setSelectedCount] = React.useState(0)
    const checkboxInput = useRef(null);
    let checkedArray = []

    const handleTagsSearch = (text) => {
        // console.log(text.target.value)
        if (text == null || text == '') {
            setTags(allTags)
        }
        else {
            let allFilters = allTags;
            let filteredTags = allFilters.filter(tag => tag.value.toLowerCase().indexOf(text.toLowerCase()) > -1)
            setTags(filteredTags)
        }
    }

    React.useEffect(() => {
        setTags([])
        Products.getTags().then((res) => {
            setTags(res.data)
            setAllTags(res.data)
            // console.log(res.data)
            // setLoading(false)
        })
    }, [clear])

    const clearFilters = () => {
        setSelectedCount(0)
        setTags(allTags)
        handleTagsSearch(null)
        setClear(clear ? false : true)
        props.clearTagsFilters()
    }

    const unCheck = () => {
        console.log(checkboxInput.current.checked)
    }

    return (
        <div style={{ position: 'relative' }}>
            <SearchTags handleTagsSearch={handleTagsSearch} />
            {
                selectedCount > 0 &&
                <FormLabel onClick={clearFilters} style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: -30, right: 5 }}>
                    <Tooltip title="Clear filters" placement="top">
                        <Icon>close</Icon>
                    </Tooltip>
                </FormLabel>
            }
            <div className={classes.filtersWrapper} aria-label="position">
                {
                    tags.length == 0 ?
                        <FormLabel style={{ textAlign: 'center', marginTop: 30 }}>Loading..</FormLabel>
                        :
                        tags.map((tag, index) => (
                            <FormControlLabel
                                key={index}
                                value={tag.value}
                                control={<Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedCount(selectedCount + 1);
                                    }
                                    else {
                                        setSelectedCount(selectedCount - 1);
                                    }
                                    props.applyFilterTags(e)
                                }} className="checkbox" color="primary" />}
                                label={tag.value}
                                labelPlacement="end"
                            />
                        ))
                }
            </div>
        </div>
    )
}

function GetLevels(props) {
    const classes = useStyles();
    const [levels, setLevels] = React.useState([])
    const [allLevels, setAllLevels] = React.useState([])
    const [check, setCheck] = React.useState(null)
    const [clear, setClear] = React.useState(false)
    const [selectedCount, setSelectedCount] = React.useState(0)

    React.useEffect(() => {
        setLevels([])
        Products.getLevels().then((res) => {
            setLevels(res.data)
            setAllLevels(res.data)
        })
    }, [clear])

    const clearFilters = () => {
        setSelectedCount(0)
        setLevels(allLevels)
        handleLevelsSearch(null)
        setClear(clear ? false : true)
        props.clearLevelsFilters()
    }

    function handleLevelsSearch(text) {

        if (text == null || text == '') {
            setLevels(allLevels)
        }
        else {

            let allFilters = allLevels;
            let filteredLevels = allFilters.filter(level => level.title.toLowerCase().indexOf(text.toLowerCase()) > -1)
            // console.log(filteredLevels)
            console.log(allLevels)
            setCheck(text)
            setLevels(filteredLevels)

        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <SearchLevel handleLevelsSearch={handleLevelsSearch} />
            {
                selectedCount > 0 &&
                <FormLabel onClick={clearFilters} style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: -30, right: 5 }}>
                    <Tooltip title="Clear filters" placement="top">
                        <Icon>close</Icon>
                    </Tooltip>
                </FormLabel>
            }
            {/* {
                check &&
                <FormLabel>Clear All</FormLabel>
            } */}
            <div className={classes.filtersWrapper} aria-label="position" >
                {
                    levels.length == 0 ?
                        <FormLabel style={{ textAlign: 'center', marginTop: 30 }}>Loading..</FormLabel>
                        :
                        levels.map((level, index) => (
                            <FormControlLabel
                                value={level.value}
                                key={index}
                                control={<Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedCount(selectedCount + 1);
                                    }
                                    else {
                                        setSelectedCount(selectedCount - 1);
                                    }
                                    props.applyFilterLevels(e);
                                }} color="primary" />}
                                label={level.value}
                                labelPlacement="end"
                            />
                        ))
                }
            </div>
        </div>
    )
}

function GetSubjects(props) {
    const classes = useStyles();
    const [levels, setLevels] = React.useState([])
    const [allLevels, setAllLevels] = React.useState([])
    const [check, setCheck] = React.useState(null)
    const [clear, setClear] = React.useState(false)
    const [selectedCount, setSelectedCount] = React.useState(0)

    React.useEffect(() => {
        setLevels([])
        Products.getLevels().then((res) => {
            setLevels(res.data)
            setAllLevels(res.data)
        })
    }, [clear])

    const clearFilters = () => {
        setSelectedCount(0)
        setLevels(allLevels)
        handleLevelsSearch(null)
        setClear(clear ? false : true)
        props.clearLevelsFilters()
    }

    function handleLevelsSearch(text) {

        if (text == null || text == '') {
            setLevels(allLevels)
        }
        else {

            let allFilters = allLevels;
            let filteredLevels = allFilters.filter(level => level.title.toLowerCase().indexOf(text.toLowerCase()) > -1)
            // console.log(filteredLevels)
            setCheck(text)
            setLevels(filteredLevels)

        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <SearchLevel handleLevelsSearch={handleLevelsSearch} />
            {
                selectedCount > 0 &&
                <FormLabel onClick={clearFilters} style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: -30, right: 5 }}>
                    <Tooltip title="Clear filters" placement="top">
                        <Icon>close</Icon>
                    </Tooltip>
                </FormLabel>
            }
            {/* {
                check &&
                <FormLabel>Clear All</FormLabel>
            } */}
            <div className={classes.filtersWrapper} aria-label="position" >
                {
                    levels.length == 0 ?
                        <FormLabel style={{ textAlign: 'center', marginTop: 30 }}>Loading..</FormLabel>
                        :
                        levels.map((level, index) => (
                            <FormControlLabel
                                value={level.title}
                                key={index}
                                control={<Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedCount(selectedCount + 1);
                                    }
                                    else {
                                        setSelectedCount(selectedCount - 1);
                                    }
                                    props.applyFilterLevels(e);
                                }} color="primary" />}
                                label={level.title}
                                labelPlacement="end"
                            />
                        ))
                }
            </div>
        </div>
    )
}

export default function Shop() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(48);
    const [totalProduct, setTotal] = React.useState(0);
    const [products, setProducts] = React.useState(null);
    const [offset, setOffset] = React.useState(0);
    const [checked, setChecked] = React.useState(false);

    const [allLevels, setAllLevels] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [openFilters, setOpenFilters] = React.useState(false)

    const [resetTags, setResetTags] = React.useState(false)
    const [resetLevels, setResetLevels] = React.useState(false)
    const [filteredLevels, setFilteredLevels] = React.useState([])
    const [filteredTags, setFilteredTags] = React.useState([])
    let filterLevels = [];
    let filterTags = [];

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    let { term } = useParams();

    const ref = useRef();

    const handleChangePage = (event, newPage) => {
        setProducts(null)
        setPage(newPage);
        setOffset((newPage * rowsPerPage))
        // ref.current.handleChange(null, newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // useEffect(() => {

    // });

    // const MemoizedSearchLevel = React.memo(SearchLevel);
    let sidelevels = []
    const fetchTags = React.useCallback(() => {



    }, [])

    React.useEffect(() => {
        // setTotal(ref.current.getCount())
        // console.log(offset)
        Product.shop(offset)
            .then((response) => {
                // console.log(response)
                // setCount(response.data.count)
                setProducts(response.data.products)
                setTotal(response.data.count)
            })
            .catch((error) => {
                console.log(error)
            })
        // fetchTags()
    }, [offset])


    const getCounts = (count) => {
        setTotal(count)
    }

    const searchProduct = (e) => {
        setTimeout(() => {
            if (e.target.value.length > 0) {
                ref.current.searchShop(e.target.value);
            }
            else {
                // setPage(1);
                ref.current.handleChange(null, 1);
            }
        }, 300);
    }



    const [selectedLevels, setSelectedLevels] = React.useState([])
    const [selectedTags, setSelectedTags] = React.useState([])
    const applyFilterLevels = (event) => {
        console.log(event.target.value)
        if (event.target.checked) {
            filterLevels.push(event.target.value)
        }
        else {
            filterLevels.splice(filterLevels.indexOf(event.target.value), 1)
        }
        // setFilteredLevels(filterLevels)
        ref.current.filterShop(filterLevels, filterTags)
    }

    const applyFilterTags = (event) => {
        if (event.target.checked) {
            filterTags.push(event.target.value)
        }
        else {
            filterTags.splice(filterTags.indexOf(event.target.value), 1)
        }
        // setFilteredTags(filterTags)
        ref.current.filterShop(filterLevels, filterTags)
    }

    const clearLevelsFilters = React.useCallback(() => {
        filterLevels = []
        // console.log(filterLevels)
        ref.current.filterShop(filterLevels, filterTags)
    })
    const clearTagsFilters = React.useCallback(() => {
        filterTags = []
        // console.log(filterTags)
        ref.current.filterShop(filterLevels, filterTags)
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenFilters(open);
    };

    const FiltersList = () => {
        return (
            <Grid style={{ boxShadow: "4px 5px 8px rgb(158 158 158 / 8%)" }} className={classes.shopFiltersWrapper} item sm={3} md={2}>
                <Card className={classes.filterCard} variant="outlined">
                    <div className={classes.filterHeader}>
                        <FormLabel className={classes.filterHeading} component="legend">Subjects</FormLabel>
                    </div>
                    <CardContent className={classes.filterCardContent}>
                        <GetSubjects clearLevelsFilters={clearLevelsFilters} resetLevels={resetLevels} applyFilterLevels={applyFilterLevels} />
                    </CardContent>
                </Card>
                {/* <Card className={classes.filterCard} variant="outlined">
                    <div className={classes.filterHeader}>
                        <FormLabel className={classes.filterHeading} component="legend">Product Tags</FormLabel>
                    </div>
                    <CardContent className={classes.filterCardContent}>
                        <GetTags clearTagsFilters={clearTagsFilters} applyFilterTags={applyFilterTags} />
                    </CardContent>
                </Card> */}
            </Grid>
        )
    }


    return (
        <div className={classes.searchWrapper}>
            <React.Fragment key={'left'}>
                <Drawer anchor={'left'} open={openFilters} onClose={toggleDrawer('left', false)}>
                    <h3 style={{ marginLeft: 10, marginTop: 10, marginBottom: 5 }}>Filters</h3>
                    <FiltersList />
                </Drawer>
            </React.Fragment>
            <Hidden smUp>
                <div className={classes.searchBarMobile}>
                    <input id="outlined-basic" onChange={(e) => { searchProduct(e) }} className={classes.searchField} placeholder="Search" />
                    <Icon className={classes.searchIcon}>search</Icon>
                </div>
            </Hidden>
            <Grid style={{ boxShadow: "4px 5px 8px rgb(158 158 158 / 12%)" }} container alignItems="stretch" className={classes.shopHeaderWrapper}>
                <Hidden xsDown>
                    <Grid className={classes.headerFiltersWrapper} item md={2} sm={3}>
                        <div className={classes.filterTitle}>
                            <Icon className={classes.icon}>tune</Icon>
                            <Typography variant="h6" className={classes.whiteText} align={"center"}>Filters</Typography>
                        </div>
                    </Grid>
                </Hidden>
                <Grid className={classes.paginationHeader} item xs={12} sm={9} md={10}>
                    <Hidden xsDown>
                        <div className={classes.searchBar}>
                            <input id="outlined-basic" onChange={(e) => { searchProduct(e) }} className={classes.searchField} placeholder="Search" />
                            <Icon className={classes.searchIcon}>search</Icon>
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <ButtonBase onClick={() => setOpenFilters(true)} className={classes.filtersButton}>
                            <Icon className={classes.filtersIcon}>tune</Icon>
                        </ButtonBase>
                    </Hidden>
                    {/* <TablePagination
                        component="div"
                        count={totalProduct}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[50, 100]}
                        style={{ flex: 1 }}
                    /> */}
                </Grid>
            </Grid>
            <Grid container alignItems="stretch" className={classes.shopContainer}>
                <Hidden xsDown>
                    <FiltersList />
                </Hidden>
                {
                    products != null ?
                        <ShopProducts products={products} ref={ref} title={'Shop'} />
                        :
                        <Typography variant="body1" style={{ textAlign: 'center', marginTop: 20 }}>Getting Products...</Typography>
                }
            </Grid>

        </div>
    )
}
