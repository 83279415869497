import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";
import HomePage from './pages/home';
import Aboutus from './pages/Aboutus';
import Teacher from './pages/Teacher';
import SuccessPage from './pages/success';
import AccountPage from './pages/account';
import MyShop from './pages/teachers/myShop';
import UploadMoreDocuments from './pages/teachers/UploadMoreDocuments';
import SearchPage from './pages/search';
import Cart from './pages/cart';
import ShopPage from './pages/shop';
import TeachersShops from './pages/TeachersShop';
import FAQsPage from './pages/faqs';
import VerifyEmail from './pages/verifyEmail';
import TeachersShop from './pages/teachers/teachersShop';
import TeachersPage from './pages/Teachers';
import ContactPage from './pages/contact';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsServices from './pages/TermsServices';
import MyEdHub from './pages/myEdhub';
import MyProducts from './pages/myProducts';
import Management from './pages/management';
import CourseManagement from './pages/CourseManagement';
import AdminPage from './pages/AdminPage';
import MyClasses from './pages/MyClasses';
import ProductView from './pages/productView';
import BrowsingHistory from './pages/history';
import ProductPreView from './pages/productPreView';
import Favourites from './pages/favourites';
import Subscription from './pages/subscription';
import Header from './components/shared/header/header'
import Footer from './components/shared/footer/footer'
import Login from './pages/Login'
import BecomeTeacher from './pages/becomeTeacher';
// Teachers Pages
import Income from './pages/income';
import ProfileManagement from './pages/profileManagement';
import {StudentsBooking,TeachersBooking} from './pages/bookings'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CartProvider } from './context/CartContext';
import { CommonProvider } from './context/CommonContext';
import { BuyNowProvider } from './context/BuyNowContext';
import { UserProvider } from './context/UserContext';
import { FavouriteProvider } from './context/FavouriteContext';
import { ProtectedRoute } from './routes/protected.route';
import { PasscodedRoute } from './routes/passcoded.route';
import { ShorternRoute } from './routes/shortern.route';
import { QRCodeRoute } from './routes/qrcode.route';
import { TeachersRoute } from './routes/teachers.route';
import { AdminRoute } from './routes/admin.route';
import { AuthProvider } from './context/AuthContext';
import { BookingProvider } from './context/BookingContext';
import { EnrollmentNotificationProvider } from './context/EnrollmentNotificationContext';
import ScrollTop from './routes/scrolltop';

import './App.css';
import './Custom.css';


// My Account
// const stripePromise = loadStripe('pk_test_51FJJDOBHbro2M23KW7NVdpcqTM5K1um0DoXkwbQBTBdfVqE79JYagiJ2zsjA7m7ueWhYEMxbNtAsD9SF808vLCVt00wkpg8mV6');

// Live EdHub
// const stripePromise = loadStripe('pk_live_51IMXtPCOH41oJfZ8IhyIqjDs4ttNWcucoUpGcwYrmeviUcuc39ucZpz1gnpH0MXjgpOaakr6oqkgh2cnCOP9laJY00NxDvmlzw');
// Test EdHub
const stripePromise = loadStripe('pk_test_51IMXtPCOH41oJfZ81vTZWI569OstO74UFOXuhFfJDtkEtsx7i91OD1aFCZek953mYA0DOT549GDO1Hz8hfTtwYNe00eV4UIXuK');

function App() {

  return (
    <AuthProvider>
      <CommonProvider>
        <CartProvider>
          <UserProvider>
            <EnrollmentNotificationProvider>
              <FavouriteProvider>
                <Router>
                  <ScrollTop />
                  <div className="root" style={{ backgroundColor: '#F4F4F4' }}>
                    {/* Stripe Element Wrapper */}
                    <Elements stripe={stripePromise}>
                      <BuyNowProvider>
                        <Header />
                        {/* Router Switch */}
                        <Switch>
                          {/* Public Routes */}
                          <Route exact path="/">
                            <HomePage />
                          </Route>
                          <Route exact path="/about-us">
                            <Aboutus />
                          </Route>
                          <Route exact path="/login">
                            <Login />
                          </Route>
                          <Route exact path="/become-teacher">
                            <BecomeTeacher />
                          </Route>
                          <Route exact path="/shop/:username">
                            <TeachersShop />
                          </Route>
                          <Route exact path="/products/all">
                            <ShopPage />
                          </Route>
                          <Route exact path="/teachers">
                            <BookingProvider>
                              <TeachersPage />
                            </BookingProvider>
                          </Route>
                          <Route exact path="/email/verify/:token">
                            <VerifyEmail />
                          </Route>
                          <Route exact path="/alipay/success/:ref/:user/:course/:subscriber/:purchaseType">
                            <SuccessPage />
                          </Route>
                          <Route path="/search/:term">
                            <SearchPage />
                          </Route>
                          <Route path="/shops">
                            <TeachersShops />
                          </Route>
                          <Route path="/product/preview/:title/:courseId">
                            <ProductPreView />
                          </Route>
                          <Route path="/cart">
                            <Cart />
                          </Route>
                          <Route path="/favourites">
                            <Favourites />
                          </Route>
                          <Route path="/faqs">
                            <FAQsPage />
                          </Route>
                          <Route path="/contact-us">
                            <ContactPage />
                          </Route>
                          <Route path="/privacy-policy">
                            <PrivacyPolicy />
                          </Route>
                          <Route path="/terms-service">
                            <TermsServices />
                          </Route>
                          <Route path="/teacher/upload-more-documents/:userId">
                            <UploadMoreDocuments />
                          </Route>
                          <Route path="/teachers-and-tutors/:teacherId">
                            <BookingProvider>
                              <Teacher />
                            </BookingProvider>
                          </Route>


                          {/* Teacher's Routes */}
                          <TeachersRoute scope={["TEACHER"]} path="/my-profile" component={ProfileManagement}></TeachersRoute>
                          <TeachersRoute scope={["TEACHER"]} path="/my-income" component={Income}></TeachersRoute>
                          <TeachersRoute scope={["TEACHER","EdHub"]} path="/my/trial/bookings" component={TeachersBooking}></TeachersRoute>
                          <TeachersRoute scope={["TEACHER", 'EdHub']} token={localStorage.getItem('jwt_token')} path="/my-products/:addNew?" component={MyProducts}></TeachersRoute>

                          {/* Protected Routes */}
                          <ProtectedRoute path="/my-account" component={AccountPage}></ProtectedRoute>
                          <ProtectedRoute path="/my-classes" component={() => (<MyClasses title={"My Classes"} />)}></ProtectedRoute>
                          <ProtectedRoute path="/classes/:course_title/:course_id" component={() => (<MyClasses title={"Classes"} />)}></ProtectedRoute>
                          <ProtectedRoute path="/view/product/:title" component={ProductView}></ProtectedRoute>
                          <ProtectedRoute path="/browsing-history" component={BrowsingHistory}></ProtectedRoute>
                          <ProtectedRoute path="/my-edhub" component={MyEdHub}></ProtectedRoute>
                          <ProtectedRoute path="/my-shop" component={MyShop}></ProtectedRoute>
                          <ProtectedRoute path="/subscriptions" component={Subscription}></ProtectedRoute>
                          <ProtectedRoute path="/my/bookings" component={StudentsBooking}></ProtectedRoute>

                          {/* Passcode Protected Route */}
                          <PasscodedRoute path="/product/view/:title/:courseId" component={ProductView}></PasscodedRoute>

                          {/* Admin ROute */}
                          <AdminRoute scope="EdHub" token={localStorage.getItem('jwt_token')} path="/admin" component={AdminPage}></AdminRoute>
                          <ShorternRoute exact path="/:encodedCode"></ShorternRoute>
                          <QRCodeRoute exact path="/qr/:encodedCode"></QRCodeRoute>
                        </Switch>
                        <Footer />
                      </BuyNowProvider>
                    </Elements>
                  </div>
                </Router>
              </FavouriteProvider>
            </EnrollmentNotificationProvider>
          </UserProvider>
        </CartProvider>
      </CommonProvider>
    </AuthProvider>
  );
}


export default App;
