import React from 'react'
import {
    InputBase,
    Icon,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';

import {
    alpha,
    ThemeProvider,
    withStyles,
    makeStyles,
    createTheme,
} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    inputBase: {
        margin: theme.spacing(1),
    },
    inputWrapper: {
        border: 'solid',
        borderRadius: 5,
        borderColor: '#727F8A',
        backgroundColor: "white",
        marginTop: 10,
        marginBottom: 10
    },
    labelWrapper: {
        marginTop: 5,
        marginBottom: 5,
        color: 'white'
    }
}));

export default function ClassInput({ setFormValues, handleChange, formValues, name, placeholder, label, disabled, value, type, options, rows }) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [selectedTime, setSelectedTime] = React.useState(new Date('2014-08-18T21:11:54'));
    const [radioValue, setRadioValue] = React.useState('Single lesson');



    React.useEffect(() => {
        // setFormValues(prevState => ({
        //     ...prevState,
        //     [name]: value
        // }));

    }, [])

    // const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFormValues(prevState => ({
    //     ...prevState,
    //     [name]: value
    // }));
    // }

    const TextInput = () => {
        return (
            <>
                <label className={classes.labelWrapper}>{label}</label>
                <div className={classes.inputWrapper}>
                    <InputBase
                        name={name}
                        fullWidth
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                        className={classes.inputBase}
                        placeholder={placeholder}
                    />
                </div>
            </>
        )
    }

    const TextareaInput = () => {

        return (
            <>
                <label className={classes.labelWrapper}>{label}</label>
                <div className={classes.inputWrapper}>
                    <InputBase
                        name={name}
                        fullWidth
                        onChange={handleChange}
                        value={value}
                        multiline
                        rows={rows}
                        className={classes.inputBase}
                        placeholder={placeholder}
                    />
                </div>
            </>
        )
    }

    const DatePicker = () => {

        const handleDateChange = (date) => {
            setSelectedDate(date);
        }

        return (
            <>
                <label className={classes.labelWrapper}>{label}</label>
                <div className={classes.inputWrapper}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        fullWidth
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        InputProps={{
                            disableUnderline: true
                        }}
                        className={classes.inputBase}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        value={selectedDate}
                        name={name}
                        onChange={handleDateChange}
                    />
                </div>
            </>
        )
    }

    const TimePicker = () => {

        const handleTimeChange = (time) => {
            setSelectedTime(time)
        }

        return (
            <>
                <label className={classes.labelWrapper}>{label}</label>
                <div className={classes.inputWrapper}>
                    <KeyboardTimePicker
                        margin="normal"
                        fullWidth
                        name={name}
                        keyboardIcon={<AccessTimeIcon />}
                        InputProps={{
                            disableUnderline: true
                        }}
                        id="time-picker"
                        className={classes.inputBase}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        value={selectedTime}
                        onChange={handleTimeChange}
                    />
                </div>
            </>
        )
    }

    const RadioGroupInput = () => {
        const handleChange = (event) => {
            setRadioValue(event.target.value)
        }
        return (
            <FormControl component="fieldset">
                <label className={classes.labelWrapper}>{label}</label>
                <RadioGroup row aria-label="course-type" name={name} value={radioValue} onChange={handleChange}>
                    {
                        options.map(option => (
                            <FormControlLabel className={classes.labelWrapper} value={option} control={<Radio />} label={option} />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        )
    }

    return (
        <>
            {type == 'text' && <TextInput />}
            {type == 'textarea' && <TextareaInput />}
            {type == 'radio-group' && <RadioGroupInput />}
            {type == 'date' && <MuiPickersUtilsProvider utils={DateFnsUtils}><DatePicker /></MuiPickersUtilsProvider>}
            {type == 'time' && <MuiPickersUtilsProvider utils={DateFnsUtils}><TimePicker /></MuiPickersUtilsProvider>}
        </>
    )
}