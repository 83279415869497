import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AdminService from '../services/admin';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { downloadAndZip } from '../components/helper/jsZip';

import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import { Dialog, DialogContent, Snackbar, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddProduct from '../components/teacher/AddProduct';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
// Added By Waleed Umer
import * as Mui from '@material-ui/core';
import * as Mul from '@material-ui/lab';
import './css/admin.css';
import SearchBar from '../components/admin/userSearch';
import Slide from '@material-ui/core/Slide';
import SingleProduct from '../components/products/singleProduct';
import PdfViewer from '../components/teacher/modals/PdfViewer'
import ImageViewer from '../components/teacher/modals/ImageViewer';

import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";
import { Redirect, useHistory } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    cartWrapper: {
        backgroundColor: 'white',
        marginTop: 0,
        marginBottom: 50,
        padding: 20,
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    itemImageWrapper: {
        paddingRight: 40,
        height: 50
    },
    itemImageHeader: {
        paddingRight: 40,
        height: 35
    },
    itemImage: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
        border: 'solid 1px #b4b4b4'
    },
    itemContainer: {
        paddingTop: 10,
        paddingBottom: 0,
        marginBottom: 30,
        minHeight: '60vh',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        }
    },

    singleItem: {
        marginBottom: 4,
        backgroundColor: '#f4f4f4',
        borderRadius: 10,
        width: '100%',
        padding: 5,
    },
    headerItem: {
        marginBottom: 4,
        backgroundColor: '#e2edef',
        borderRadius: 10,
        width: '100%',
        padding: 5,
    },
    courseName: {
        // fontSize: 18,
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        },
        padding: 10
    },
    headerName: {
        // fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.3,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        },
        padding: 10
    },
    searchLink: {
        textDecoration: 'none',
        color: 'black'
    },
    tag: {
        backgroundColor: '#dadada',
        marginRight: 2,
        marginBottom: 2,
        fontSize: 9
    },
    pageTitles: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 5
        }
    },
    noProductWrapper: {
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    addProductPopupDiv: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '100%'
        }
    },
    containerCustomHieght: {
        // height: '50%'
        flexDirection: 'row',
        display: 'flex'
    },
    iconSpacing: {
        marginRight: theme.spacing(2)
    },
    navAction: {
        minWidth: 10,
        height: 25,
        width: 25,
        borderRadius: 30,
        backgroundColor: '#1a95ac',
        margin: 5,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    viewAction: {
        minWidth: 10,
        height: 26,
        width: 26,
        borderRadius: 30,
        backgroundColor: '#225bc2',
        margin: 5,
        fontSize: 20,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    badge: {
        fontSize: 1,
        color: 'primary'
    },
    viewedAction: {
        minWidth: 10,
        height: 26,
        width: 26,
        borderRadius: 30,
        backgroundColor: '#1caea7',
        margin: 5,
        fontSize: 20,
        '&:hover': {
            backgroundColor: '#188f89'
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            transform: 'scale(0.6) translate(50%, -50%)'
        }
    },
    editAction: {
        minWidth: 10,
        height: 26,
        width: 26,
        borderRadius: 30,
        backgroundColor: '#f6ac24',
        margin: 5,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    dangerAction: {
        minWidth: 10,
        height: 26,
        width: 26,
        borderRadius: 30,
        backgroundColor: '#d3342c',
        margin: 5,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    successAction: {
        minWidth: 10,
        height: 26,
        width: 26,
        borderRadius: 30,
        backgroundColor: '#56B056',
        margin: 5,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    deleteAction: {
        minWidth: 10,
        height: 25,
        width: 25,
        borderRadius: 30,
        backgroundColor: '#d3342c',
        margin: 5,
        '&:hover': {
            backgroundColor: '#24646F'
        }
    },
    actionIcon: {
        fontSize: 16
    },
    sortIcon: {
        backgroundColor: '#cddcdf',
        borderRadius: 20,
        marginLeft: 10,
        fontSize: 14,
        padding: 3,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#a6b3b6'
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    creationBtn: {
        backgroundColor: '#60974a',
        borderRadius: 20,
        color: 'white',
        textTransform: 'capitalize',
        marginLeft: 10,
        fontSize: 14,
        padding: '5px 10px',
        "&:hover": {
            backgroundColor: '#4f803c'
        }
    },
    pagination: {
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: 'rgb(26 149 172)',
            color: 'white'
        }
    },
    iconsView: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: 'gray',
        marginRight: 6,
        cursor: 'pointer'
    },
    active: {
        color: '#1A95AC',
        marginRight: 6,
        cursor: 'pointer'
    },
    activeChip: {

        backgroundColor: '#56b056',
        color: 'white',
        padding: 0,
        height: 22
    },
    inactiveChip: {

        backgroundColor: '#f6ac25',
        color: 'white',
        padding: 0,
        height: 22
    },
}));


export default function MyProducts({ location }) {
    const requestTextRef = React.useRef('')
    const productSuccessMessage = "Product added successfully."
    const productUpdateMessage = "Product updated successfully."
    const productDeleteMessage = "Product deleted successfully."
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [inactiveItems, setInactiveItems] = React.useState([]);
    const [deletedItems, setDeletedItems] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState(null);
    const [requestText, setRequestText] = React.useState(null);
    const [totalUsers, setTotalUsers] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState('all');
    const [editMaterialItems, setEditMaterialItems] = React.useState([]);
    const [editCourseCover, setEditCourseCover] = React.useState([]);
    const [recordForEdit, setRecordForEdit] = React.useState(null);
    const [docName, setDocName] = React.useState(null);
    const [requesting, setRequesting] = React.useState(false);

    const [teacherDocuments, setTeacherDocuments] = React.useState(null)

    const { addNew } = useParams()
    const [showAdd, setShowAdd] = React.useState(addNew)
    const [productPage, setProductPage] = React.useState(1);
    const [viewStyle, setViewStyle] = React.useState('list');
    let history = useHistory();
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [teacherID, setTeacherID] = React.useState(0);
    const [totalPage, setTotalPage] = React.useState(10);
    const [openRequestModal, setRequestDocumentModal] = React.useState(false);

    const [viewDoc, setViewDoc] = React.useState(false);
    const [docType, setDocType] = React.useState(null);
    const [docPath, setDocPath] = React.useState(null);
    const [userProfile, setUserProfile] = React.useState(null);

    const [sortOrder, setSortOrder] = React.useState('ASC');
    const [addProductPopup, setAddProductPopup] = React.useState(false);
    const [openDocumentModal, setOpenDocumentModal] = React.useState(false);
    const [addEditProduct, setAddEditProduct] = React.useState(false);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [deletePopupId, setDeletePopupId] = React.useState(null);
    const [productAddSuccess, setProductAddSuccess] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(productSuccessMessage);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        getTeachers('all')
    }, [])

    const handleAddProductPopupClose = () => {
        setAddProductPopup(false);
        setAddEditProduct(false);
    }

    const handleDeletePopupClose = () => {
        setDeletePopup(false);
    }



    const handleViewChnage = (style) => {
        setViewStyle(style)
    }

    const deleteIconClick = (e, itemId) => {
        e.preventDefault();
        setDeletePopupId(itemId);
        setDeletePopup(true);
    }

    const handleAddProductBtnClick = () => {
        setAddProductPopup(true);
        setRecordForEdit(null);
    }

    const editIconClick = (e, itemId) => {
        e.preventDefault();

        const newTagNamesCollection = [];
        itemId['tags'].split(",").map((itemdata, index) => {
            const newTagData = {
                label: itemdata,
                value: itemdata
            };
            newTagNamesCollection.push(newTagData);
        });

        const updateList = {
            courseId: itemId['course_id'],
            productName: itemId['course_name'],
            productTags: JSON.stringify(newTagNamesCollection),
            productCategory: itemId['course_category'],
            productGrad: itemId['course_grade_level'],
            productLevel: itemId['course_level'],
            price: itemId['course_tution_fee'],
            discountedPrice: itemId['discounted_price'],
            worksheetPreviewMode: itemId['pdf_preview_mode'],
            productDescription: itemId['course_description'],
        }

        const matList = [];
        itemId['material'].map((item) => {
            matList.push(item['org_name']);
        });

        setEditMaterialItems(itemId['material'])
        setEditCourseCover(itemId['course_cover']);
        setRecordForEdit(updateList)
        setAddEditProduct(true);
        setAddProductPopup(true);
    }

    // Code Added by Waleed Umer -----------------------------------------------
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClose = () => {
        setAddProductPopup(false);
    };

    const handleUpdateStatus = (user, status) => {
        let data = {
            'user_id': user,
            'status': status
        }



        console.log(activeTab)
        AdminService.updateUserStatus(data).then(res => {
            var statuses = {
                'all': 'all',
                'inactive': 'inactive',
                'request': 'request',
            };
            if (activeTab == 'all' && status == 1) {
                userActivated()
            }
            else if (activeTab == 'all' && status == 3) {
                userDeactivated()
            }
            else if (activeTab == 'inactive') {
                userActivated()
            }
            else {
                requestAccepted()
            }
            var productOffset = (productPage * limit) - limit
            // setOffset(productOffset)
            setTimeout(() => {
                getTeachers(statuses[activeTab], productOffset)
            }, 2000);
        })
    }

    const userDeactivated = () => toast.success("User deactivated!");
    const userActivated = () => toast.success("User activated!");
    const requestAccepted = () => toast.success("Request accepted!");

    const ProductPopup = () => {
        return (
            <Dialog fullScreen open={addProductPopup} onClose={handleAddProductPopupClose} className={classes.addProductPopupDiv} TransitionComponent={Transition}>
                <DialogContent>
                    <AddProduct updateProductList={updateProductList} setEditCourseCover={setEditCourseCover} editCourseCover={editCourseCover} editMaterialItems={editMaterialItems} recordForEdit={recordForEdit} addEditProduct={addEditProduct} handleAddProductPopupClose={handleAddProductPopupClose} addProductPopup={addProductPopup} />
                </DialogContent>
            </Dialog>
        )
    }

    const Products = (props) => {
        return (
            <>
                <ToastContainer position="top-right" autoClose={6000} />
                {
                    <Grid container className={classes.itemContainer} alignItems="flex-start">
                        <Grid item className={classes.headerItem}>
                            <Grid container flexdirection="row" alignItems="center">
                                <Grid item xs={12} sm={10}>
                                    <Grid container flexdirection="row" justifyContent="space-between" className={classes.containerCustomHieght}>
                                        <Grid item xs={3} className={classes.header}>
                                            <Typography className={classes.headerName} variant="body1">First Name</Typography>
                                            {/* <Icon onClick={() => handleSorting('first_name')} className={classes.sortIcon}>unfold_more</Icon> */}
                                        </Grid>
                                        <Grid item xs={2} className={classes.header}>
                                            <Typography className={classes.headerName} variant="body1">Last Name</Typography>
                                            {/* <Icon onClick={() => handleSorting('last_name')} className={classes.sortIcon}>unfold_more</Icon> */}
                                        </Grid>
                                        <Grid item xs={4} className={classes.header}>
                                            <Typography className={classes.headerName} variant="body1">Email</Typography>
                                            {/* <Icon onClick={() => handleSorting('course_tution_fee')} className={classes.sortIcon}>unfold_more</Icon> */}
                                        </Grid>
                                        <Grid item xs={2} className={classes.header}>
                                            <Typography className={classes.headerName} variant="body1">Contact</Typography>
                                            {/* <Icon onClick={() => handleSorting('discounted_price')} className={classes.sortIcon}>unfold_more</Icon> */}
                                        </Grid>
                                        <Grid item xs={1} className={classes.header}>
                                            <Typography className={classes.headerName} variant="body1">Status</Typography>
                                            {/* <Icon onClick={() => handleSorting('course_level')} className={classes.sortIcon}>unfold_more</Icon> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography style={{ textAlign: 'center' }} className={classes.headerName} variant="body1">View Documents</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            items.length == 0 &&
                            <Grid item className={classes.noProductWrapper}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Typography variant="body1" className={classes.centerText}>
                                        No user found
                                    </Typography>
                                </Grid>
                            </Grid>
                        }

                        {items.map((item, index) => {
                            let courseCover = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + item.course_cover;

                            return (
                                <Grid key={index} item className={classes.singleItem}>


                                    <Grid container flexdirection="row" alignItems="center">
                                        <Grid item xs={12} sm={10}>
                                            <Grid container flexdirection="row" alignItems="center" justifyContent="space-between" className={classes.containerCustomHieght}>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.courseName} variant="body2">{item.first_name}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography className={classes.courseName} variant="body2">{item.last_name}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.courseName} variant="body2">{item.email}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography className={classes.courseName} variant="body2">{item.contact_no}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Chip className={item.status == 1 ? classes.activeChip : classes.inactiveChip} label={item.status == 1 ? 'Active' : (item.status == 2 || item.status == 3 || item.status == 0) ? 'Inactive' : 'Request Pending'} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Typography variant="body2" align="right">{item.registered_at}</Typography>
                                            <Grid container flexdirection="column" justifyContent="flex-end" alignItems="center">
                                                {
                                                    item.viewed.length > 0 &&
                                                    <Mui.Tooltip placement="top" title={item.viewed.length + " Document(s) sent"}>
                                                        <Button className="notification-badge" onClick={() => handleOpenDocumentModal(true, item.teacherId)} style={{ color: 'white', textDecoration: 'none' }} className={classes.viewedAction}>
                                                            <Badge color="secondary" badgeContent={<span className={classes.badge}>!</span>}>
                                                                <Icon className={classes.actionIcon}>notifications</Icon>
                                                            </Badge>
                                                        </Button>
                                                    </Mui.Tooltip>
                                                }
                                                <Mui.Tooltip placement="top" title="View Documents">
                                                    <Button onClick={() => handleOpenDocumentModal(true, item.teacherId)} style={{ color: 'white', textDecoration: 'none' }} className={classes.viewAction}>
                                                        {
                                                            item.requests.length > 0 ?
                                                                <Badge color="secondary" badgeContent={item.requests.length}>
                                                                    <Icon className={classes.actionIcon}>description</Icon>
                                                                </Badge>
                                                                :
                                                                <Icon className={classes.actionIcon}>description</Icon>
                                                        }
                                                    </Button>
                                                </Mui.Tooltip>
                                                <Mui.Tooltip placement="top" title="Request more Documents">
                                                    <Button onClick={() => handleRequestDocumentModal(true, item)} style={{ color: 'white', textDecoration: 'none' }} className={classes.editAction}>
                                                        <Icon className={classes.actionIcon}>send</Icon>
                                                    </Button>
                                                </Mui.Tooltip>
                                                {
                                                    props.type != 'users' ?
                                                        item.status == 1 ?
                                                            <Mui.Tooltip placement="top" title="Deactivate this user">
                                                                <Button onClick={() => handleUpdateStatus(item.user_id, 3)} style={{ color: 'white', textDecoration: 'none' }} className={classes.dangerAction}>
                                                                    <Icon className={classes.actionIcon}>close</Icon>
                                                                </Button>
                                                            </Mui.Tooltip>
                                                            :
                                                            (item.status == 2 || item.status == 3 || item.status == 0) ?
                                                                <Mui.Tooltip placement="top" title="Activate this user">
                                                                    <Button onClick={() => handleUpdateStatus(item.user_id, 1)} style={{ color: 'white', textDecoration: 'none' }} className={classes.successAction}>
                                                                        <Icon className={classes.actionIcon}>check</Icon>
                                                                    </Button>
                                                                </Mui.Tooltip>
                                                                :
                                                                <Mui.Tooltip placement="top" title="Accept user's request">
                                                                    <Button onClick={() => handleUpdateStatus(item.user_id, 1)} style={{ color: 'white', textDecoration: 'none' }} className={classes.successAction}>
                                                                        <Icon className={classes.actionIcon}>check</Icon>
                                                                    </Button>
                                                                </Mui.Tooltip>
                                                        :
                                                        <Mui.Tooltip placement="top" title="Accept user's request">
                                                            <Button onClick={() => handleUpdateStatus(item.user_id, 1)} style={{ color: 'white', textDecoration: 'none' }} className={classes.successAction}>
                                                                <Icon className={classes.actionIcon}>check</Icon>
                                                            </Button>
                                                        </Mui.Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                }
            </>
        )
    }

    const ViewButtons = () => {
        return (
            <>
                <Mui.Tooltip placement="top" title="Table View">
                    <Mui.Icon onClick={() => handleViewChnage('list')} className={`${viewStyle == 'list' && classes.active} ${classes.icon}`}>view_headline</Mui.Icon>
                </Mui.Tooltip>
                <Mui.Tooltip placement="top" title="Grid View">
                    <Mui.Icon onClick={() => handleViewChnage('grid')} className={`${viewStyle == 'grid' && classes.active} ${classes.icon}`}>grid_on</Mui.Icon>
                </Mui.Tooltip>
            </>
        )
    }

    const handleTabChange = (event, tab) => {
        var statuses = {
            'all': 'all',
            'inactive': 'inactive',
            'request': 'request',
        };
        console.log(statuses[tab])
        getTeachers(statuses[tab], 0)
        setActiveTab(tab)
    }

    const handleSearch = (e) => {
        console.log(e.target.value)
        var statuses = {
            'active': 1,
            'inactive': 0,
            'deleted': -1
        };
        if (e.keyCode == 13) {
            getTeachers(statuses[activeTab], 0)
        }
        else if (e.target.value.length == 0) {
            getTeachers(statuses[activeTab], 0)
        }
    }

    const getTeachers = (status = 'all', offset = 0) => {
        console.log(offset)
        AdminService.getTeachers(status, limit, offset).then((res) => {
            console.log(res.data.teachers)
            setTotalPage(Math.ceil(res.data.count / limit))
            setTotalUsers(res.data.count)
            setItems(res.data.teachers)
        })
    }

    const updateProductList = () => {
        var statuses = {
            'active': 1,
            'inactive': 0,
            'deleted': -1
        };
        var productOffset = (productPage * limit) - limit
        history.push("/my-products/all");
        // setShowAdd('all')
        setAddProductPopup(false);
        getTeachers(statuses[activeTab], productOffset)
        // setActiveTab(statuses[activeTab])
        // return <Redirect to='/my-products/all' />
        // getTeachers(1, null)
    }

    const AlertMessage = () => {
        return (
            <Snackbar
                open={productAddSuccess} onClose={() => { setProductAddSuccess(false) }}
                autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setProductAddSuccess(false) }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        )
    }

    const DeleteConfirmation = () => {
        return (
            <Dialog onBackdropClick={handleDeletePopupClose} open={deletePopup} onClose={handleDeletePopupClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle style={{ color: '#cc2626' }} id="alert-dialog-title">
                    {"Delete Confirmation?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#ebebeb' }} onClick={handleDeletePopupClose}>Cancel</Button>
                    <Button style={{ backgroundColor: '#cc2626', color: 'white' }} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>

        )
    }

    const handlePageChange = (event, value) => {
        setProductPage(value)
        var statuses = {
            'all': 'all',
            'inactive': 'inactive',
        };
        var productOffset = (value * limit) - limit
        // setOffset(productOffset)
        getTeachers(statuses[activeTab], productOffset)
    }

    const handleOpenDocumentModal = (open, teacherId = null) => {

        // if (!open) {
        //     setViewDoc(false)
        //     setDocName(null)
        //     setTeacherDocuments(null)
        //     let data = {
        //         "fulfilled": 1,
        //         "teacher_id": teacherId
        //     }
        //     AdminService.updateRequest(data).then(res => {
        //         var statuses = {
        //             'all': 'all',
        //             'inactive': 'inactive',
        //         };
        //         var productOffset = (productPage * limit) - limit
        //         // setOffset(productOffset)
        //         getTeachers(statuses[activeTab], productOffset)
        //     })
        //     setOpenDocumentModal(open)
        // }
        // else {
        getTeacherDocuments(teacherId)
        // }

    }

    const handleCloseDocumentModal = (teacherId = null) => {

        setViewDoc(false)
        setDocName(null)
        setTeacherDocuments(null)
        let data = {
            "fulfilled": 1,
            "teacher_id": teacherId
        }
        AdminService.updateRequest(data).then(res => {
            var statuses = {
                'all': 'all',
                'inactive': 'inactive',
            };
            var productOffset = (productPage * limit) - limit
            // setOffset(productOffset)
            getTeachers(statuses[activeTab], productOffset)
        })
        setOpenDocumentModal(false)

    }

    const getTeacherDocuments = (teacher) => {
        setTeacherID(teacher)
        AdminService.getTeacherDocuments(teacher).then(res => {
            console.log(res)
            if (res.data.length > 0) {
                setTeacherDocuments(res.data)
                setOpenDocumentModal(true)
            }
            else {
                setTeacherDocuments(null)
                setOpenDocumentModal(true)
            }
        })
    }

    const handleViewDocument = (path, type, title) => {

        setViewDoc(true)
        setDocType(type)
        setDocName(title)
        setDocPath(path)
    }




    const TeacherDocuments = (props) => {
        let image = "/assets/icons/" + props.product.doc_type + ".png"
        return (
            <Grid container className="file-wrapper doc">
                <img src={image} />
                <Typography variant="body1" onClick={() => handleViewDocument(props.product.doc_path, props.product.doc_type, props.product.title)} className="file-name">{props.product.title}</Typography>
            </Grid>
        )
    }

    const handleDownloadAllDocs = (files) => {
        // // files.map(async (item, index) => {
        // //     // let response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_QUALIFICATION_PATH + item.doc_path);
        // //     let response = await fetch('https://blog.hubspot.com/hubfs/image-file-extensions.jpg');
        // //     let data = await response.blob();
        // //     let metadata = {
        // //         type: item.doc_type
        // //     };
        // //     let file = new File([data], item.title, metadata);
        // //     files[index].file = file;
        // //     files[index].title = item.title;
        // //     files[index].teacher = teacherID;
        // // })
        // files = ['https://blog.hubspot.com/hubfs/image-file-extensions.jpg']
        // generateZip(files, teacherID)
        downloadAndZip(files, teacherID)
    }

    const ViewDocumentModal = () => {

        return (
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={openDocumentModal}
                onClose={handleCloseDocumentModal}
            >
                <DialogTitle>Submitted documents</DialogTitle>
                <DialogContent className="no-scroll">
                    <div style={{ height: '70vh' }}>
                        {
                            teacherDocuments == null ?
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                    <Grid item>
                                        <Typography variant="body1">No Docuemnt Available</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container style={{ height: '100%' }}>
                                    <Grid style={{ height: '100%', backgroundColor: '#ededed', borderRadius: 10, padding: 10, overflowY: 'scroll' }} item xs={12} xs={3}>
                                        <Button style={{ marginBottom: 10, width: '100%', border: 'solid', borderWidth: 1, borderColor: 'primary' }} color="primary" onClick={() => { handleDownloadAllDocs(teacherDocuments) }}>
                                            <Icon>download</Icon> Download all documents
                                        </Button>
                                        {
                                            teacherDocuments != null &&
                                            teacherDocuments.map(document => {
                                                return (
                                                    <TeacherDocuments product={document} />
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid item className={docType == 'pdf' ? 'no-scroll' : 'scroll-y'} style={{ height: '100%', width: '100%' }} xs={12} xs={9}>
                                        <Grid container style={{ height: '100%', width: '100%' }} justifyContent="center" alignItems="center">
                                            <Grid item style={{ height: '100%', width: '100%', paddingLeft: 10 }}>
                                                <h4 className='doc-name'>{docName}</h4>
                                                {
                                                    viewDoc ?
                                                        docType == 'pdf' ?
                                                            <PdfViewer style={{ width: '100%', height: '100%' }} url={docPath} />
                                                            :
                                                            <ImageViewer style={{ overflowY: 'scroll' }} url={docPath} />
                                                        :
                                                        <Typography variant="h6" style={{ textAlign: 'center', color: 'gray' }}>Click the file to view it</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDocumentModal}>Close</Button>
                </DialogActions>
            </Dialog >
        )
    }

    const handleRequestDocumentModal = (open, user) => {
        // let profile = {
        //     first_name: 'user.first_name',
        //     email: 'user.email',
        //     contact: 'user.contact_no'
        // }
        setRequestText(null)
        if (open) {
            let profile = {
                first_name: user.first_name + ' ' + user.last_name,
                email: user.email,
                contact: user.contact_no,
                teacher: user.teacherId
            }

            setUserProfile(profile)
        }
        else
            setUserProfile(null)

        setRequestDocumentModal(open)

    }

    const submitRequest = (teacher, email) => {
        // setRequesting(true)
        let data = {
            teacher_id: teacher,
            email: email,
            request_text: requestTextRef.current.value.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }

        AdminService.createTeacherDocumentRequest(data).then(res => {
            console.log(res)
            setUserProfile(null)
            setRequestText(null)
            setRequestDocumentModal(false)
            // setRequesting(false)
        }, error => {
            // setRequesting(false)
        })
    }

    const RequestDocumentModal = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openRequestModal}
                onClose={handleRequestDocumentModal}
            >
                <DialogTitle>Request More Documents</DialogTitle>
                <DialogContent className="no-scroll req-dialog">
                    {
                        userProfile != null &&
                        <>
                            <h5>Full Name: {userProfile.first_name}</h5>
                            <h5>Email: {userProfile.email}</h5>
                            <h5>Contact: {userProfile.contact}</h5>
                        </>
                    }
                    <div>
                        <TextField variant="outlined" multiline rows={6} inputRef={requestTextRef} className="requestField" id="standard-basic" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{ marginRight: 10, background: '#ededed' }} onClick={() => handleRequestDocumentModal(false)}>Close</Button>
                    {
                        requesting ?
                            <Button disabled="true" style={{ marginRight: 15, background: 'gray', color: "black" }} >Sending...</Button>
                            :
                            <Button style={{ marginRight: 15, background: 'green', color: "white" }} onClick={() => submitRequest(userProfile.teacher, userProfile.email)}>Send</Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }

    return (

        <Grid container alignItems="flex-start">
            <RequestDocumentModal />
            <ViewDocumentModal />
            <ProductPopup />
            <DeleteConfirmation />
            <AlertMessage />

            <Grid item xs={12} className={classes.cartWrapper}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <h3 className={classes.pageTitles}>User Management</h3>
                    <Grid item >
                        <Grid container justifyContent="center" alignItems="center">
                            <h4 className={classes.pageTitles}>{totalUsers} {totalUsers > 1 ? 'Users' : 'User'}</h4>
                        </Grid>
                    </Grid>
                </Grid>
                {/* TabControls */}
                <Mul.TabContext value={activeTab}>
                    <Mui.Box style={{ borderBottom: 'solid 1px #e7e7e7', marginTop: 20 }}>
                        <Mul.TabList onChange={handleTabChange}>
                            <Mui.Tab label="All teachers" style={{ borderBottom: 'solid 3px divider', fontSize: 14, textTransform: 'Capitalize' }} value="all"></Mui.Tab>
                            <Mui.Tab label="Inactive teachers" style={{ fontSize: 14, textTransform: 'Capitalize' }} value="inactive"></Mui.Tab>
                            <Mui.Tab label="Teacher Requests" style={{ fontSize: 14, textTransform: 'Capitalize' }} value="request"></Mui.Tab>
                        </Mul.TabList>
                    </Mui.Box>
                    <Mul.TabPanel value="all">
                        {/* <SearchBar handleSearch={handleSearch} /> */}
                        {/* Active Products Tab */}
                        <Products type="teachers" />
                        <Grid style={{ marginTop: 15, marginBottom: 8 }} container justifyContent="flex-end" alignItems="center">
                            <Mul.Pagination className={classes.pagination} count={totalPage} page={productPage} onChange={handlePageChange} />
                        </Grid>
                    </Mul.TabPanel>
                    <Mul.TabPanel value="inactive">
                        {/* <SearchBar handleSearch={handleSearch} /> */}
                        {/* In Active Products Tab */}
                        <Products type="teachers" />
                        <Grid style={{ marginTop: 15, marginBottom: 8 }} container justifyContent="flex-end" alignItems="center">
                            <Mul.Pagination className={classes.pagination} count={totalPage} page={productPage} onChange={handlePageChange} />
                        </Grid>
                    </Mul.TabPanel>
                    <Mul.TabPanel value="request">
                        {/* <SearchBar handleSearch={handleSearch} /> */}
                        {/* In Active Products Tab */}
                        <Products type="users" />
                        <Grid style={{ marginTop: 15, marginBottom: 8 }} container justifyContent="flex-end" alignItems="center">
                            <Mul.Pagination className={classes.pagination} count={totalPage} page={productPage} onChange={handlePageChange} />
                        </Grid>
                    </Mul.TabPanel>
                </Mul.TabContext>
            </Grid>
        </Grid>

    );
}