import React, { useState, createContext } from 'react';
import ProductService from '../services/products';

export const CartContext = createContext();

export const CartProvider = props => {
    const mounted = React.useRef()
    const [cartItem, setCartItem] = useState([]);
    const [isLoading, setLoading] = useState([]);

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            var localItems = localStorage.getItem('cartItems')
            if (localItems && localItems != null)
                setCartItem(JSON.parse(localItems))
        }
        else {
            localStorage.setItem('cartItems', JSON.stringify(cartItem))
        }
    })

    return (
        <CartContext.Provider value={[cartItem, setCartItem]}>
            {props.children}
        </CartContext.Provider>
    )
}
