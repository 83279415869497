import React from 'react'
import VerifyEmail from '../components/auth/VerifyEmail'

export default function verifyEmail() {
    return (
        <>
            <VerifyEmail />
        </>
    )
}
