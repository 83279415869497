import React,{ useContext, useRef, useState, useImperativeHandle } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Grid
} from '@material-ui/core'
import Auth from '../../services/auth';


export default function DeleteMyAccount({loggedInUser}) {

    // const { jwt } = React.useContext(UserContext)
    // const { role } = React.useContext(UserContext)
    // const { user } = React.useContext(UserContext)
    // const { notification } = React.useContext(UserContext)
    // const { accntNotification } = React.useContext(UserContext)
    // const { favouriteItem } = React.useContext(FavouriteContext);
    
    // const [token, setToken] = jwt;
    // const [userRole, setUserRole] = role;
    // const [currentUser, setCurrentUser] = user;
    // const [teacherNotification, setTeacherNotification] = notification;
    // const [accountNotification, setAccountNotification] = accntNotification;
    // const [favouriteItems, setFavouriteItems] = favouriteItem;

    const [open, setOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [deleting, setDeleting] = React.useState(false);

    const handleClickOpen = () => {
    setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setPasswordOpen(false)
    };

    const handlePasswordClose = () => {
      setPasswordOpen(false)
    };

    const handleAccountDelete = () => {
        setOpen(true)
    }

    const handlePasswordConfirm = (loggedInUser) => {
      setDeleting(true)
        let data = {
          'user_id':loggedInUser.user_id,
          'email':loggedInUser.email,
          'username':loggedInUser.username,
          'pwd':password,
        }
        Auth.deleteUser(data).then(res => {
          console.log(res)
          setDeleting(false)
          if(res.data.success){
            localStorage.removeItem('user_id')
            localStorage.removeItem('jwt_token')
            localStorage.removeItem('subscriber_id')
            // setUserRole(null)
            // setToken('')
            // setCurrentUser({})
            // setFavouriteItems([])
            // setTeacherNotification(false)
            // setAccountNotification(false)
            window.location.reload();
          }
          else{
            alert('Can\'t delete user account.')
            setPasswordOpen(false)
          }
        })
    }

    return (
        <>
          <Button onClick={handleAccountDelete} variant="outlined" color="secondary">Delete my account</Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{color:'#cf0000'}} id="alert-dialog-title">{"Do you agree?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Doing so will delete your account and all of your personal information. You won't be able to access your account anymore. All of your created courses may be deleted as well and won’t be accessible by anyone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Disagree
              </Button>
              <Button style={{color:'#cf0000'}} variant="outlined" onClick={() => {setPasswordOpen(true);setOpen(false)}} color="secondary" autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={passwordOpen}
            onClose={handlePasswordClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>{"Enter password"}</Grid>
                <Grid style={{cursor:'pointer',color:'light-gray'}} item>
                  <Button style={{padding:4,width:30,minWidth:30}} onClick={handlePasswordClose}>X</Button>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TextField type="password" value={password} onChange={(event) => setPassword(event.target.value)} fullWidth id="outlined-basic" label="Enter password" variant="outlined" />
                {
                  deleting ? 
                  <Button disabled style={{marginTop:20}} fullWidth variant="contained" color="primary" autoFocus>
                    Deleting...
                  </Button>
                  :
                  <Button style={{marginTop:20}} fullWidth variant="contained" onClick={() => handlePasswordConfirm(loggedInUser)} color="primary" autoFocus>
                    Confirm Deletion
                  </Button>
                }
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
    )
}
