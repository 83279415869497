import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Select,
  message,
  SelectProps,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import LessonsCalendar from "./LessonsCalendar";
import dayjs from "dayjs";
import "./style.css";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import TeacherService from "../../../services/teacher";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Icon,
  Switch,
  FormControlLabel,
  Box,
} from "@mui/material";

const Calender = ({
  user,
  onSave,
  monthWiseLessons,
  handleSaveSchedules,
  trials,
  title,
  handleEditSlot,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Slot added successfuly!",
    });
  };
  const { Option } = Select;
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [listTimeValue, setListTimeValue] = useState([]);
  const [listPrevValue, setListPrevValue] = useState([]);
  const [listAllSchedules, setListAllSchedules] = useState({});
  const [selectedTimes, setSelectedTimes] = useState([]);
  const zoomInputRef = useRef(null);
  const venueInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const titleInputRef = useRef(null);

  const timings = [
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const options = [
    {
      label: "09:00",
      value: "09:00",
    },
    {
      label: "09:15",
      value: "09:15",
    },
    {
      label: "09:30",
      value: "09:30",
    },
    {
      label: "09:45",
      value: "09:45",
    },
    {
      label: "10:00",
      value: "10:00",
    },
    {
      label: "10:15",
      value: "10:15",
    },
    {
      label: "10:30",
      value: "10:30",
    },
    {
      label: "10:45",
      value: "10:45",
    },
    {
      label: "11:00",
      value: "11:00",
    },
    {
      label: "11:15",
      value: "11:15",
    },
    {
      label: "11:30",
      value: "11:30",
    },
    {
      label: "11:45",
      value: "11:45",
    },
    {
      label: "12:00",
      value: "12:00",
    },
    {
      label: "12:15",
      value: "12:15",
    },
    {
      label: "12:30",
      value: "12:30",
    },
    {
      label: "12:45",
      value: "12:45",
    },
    {
      label: "13:00",
      value: "13:00",
    },
    {
      label: "13:15",
      value: "13:15",
    },
    {
      label: "13:30",
      value: "13:30",
    },
    {
      label: "13:45",
      value: "13:45",
    },
    {
      label: "14:00",
      value: "14:00",
    },
    {
      label: "14:15",
      value: "14:15",
    },
    {
      label: "14:30",
      value: "14:30",
    },
    {
      label: "14:45",
      value: "14:45",
    },
    {
      label: "15:00",
      value: "15:00",
    },
    {
      label: "15:15",
      value: "15:15",
    },
    {
      label: "15:30",
      value: "15:30",
    },
    {
      label: "15:45",
      value: "15:45",
    },
    {
      label: "16:00",
      value: "16:00",
    },
    {
      label: "16:15",
      value: "16:15",
    },
    {
      label: "16:30",
      value: "16:30",
    },
    {
      label: "16:45",
      value: "16:45",
    },
    {
      label: "17:00",
      value: "17:00",
    },
    {
      label: "17:15",
      value: "17:15",
    },
    {
      label: "17:30",
      value: "17:30",
    },
    {
      label: "17:45",
      value: "17:45",
    },
    {
      label: "18:00",
      value: "18:00",
    },
    {
      label: "18:15",
      value: "18:15",
    },
    {
      label: "18:30",
      value: "18:30",
    },
    {
      label: "18:45",
      value: "18:45",
    },
    {
      label: "19:00",
      value: "19:00",
    },
    {
      label: "19:15",
      value: "19:15",
    },
    {
      label: "19:30",
      value: "19:30",
    },
    {
      label: "19:45",
      value: "19:45",
    },
    {
      label: "20:00",
      value: "20:00",
    },
    {
      label: "20:15",
      value: "20:15",
    },
    {
      label: "20:30",
      value: "20:30",
    },
    {
      label: "20:45",
      value: "20:45",
    },
    {
      label: "21:00",
      value: "21:00",
    },
    {
      label: "21:15",
      value: "21:15",
    },
    {
      label: "21:30",
      value: "21:30",
    },
    {
      label: "21:45",
      value: "21:45",
    },
    {
      label: "22:00",
      value: "22:00",
    },
    {
      label: "22:15",
      value: "22:15",
    },
    {
      label: "22:30",
      value: "22:30",
    },
    {
      label: "22:45",
      value: "22:45",
    },
    {
      label: "23:00",
      value: "23:00",
    },
    {
      label: "23:15",
      value: "23:15",
    },
    {
      label: "23:30",
      value: "23:30",
    },
    {
      label: "23:45",
      value: "23:45",
    },
  ];

  // useEffect(() => {
  //   timings.forEach((time) => {
  //     options.push({
  //       label: time,
  //       value: time,
  //     });
  //   });
  //   console.log(options);
  // }, []);
  // const handleChangeOptions = (value) => {
  //   console.log(`selected ${value}`);
  // };

  const handleChange = (date) => {
    setListTimeValue([]); //initially list is empty
    setListTimeObj([]);
    setDate(dayjs(date).format("YYYY-MM-DD"));

    Object.entries(listAllSchedules).filter((ele) => {
      if (ele[0] == dayjs(date).format("YYYY-MM-DD")) {
        setListTimeValue([...ele[1].slots]);
      }
    });
    monthWiseLessons.filter((lesson) => {
      if (lesson.date == dayjs(date).format("YYYY-MM-DD")) {
        setListPrevValue([...lesson.slots.map((time) => time.trial_time)]);
      }
    });
  };

  const addSchedularHandler = () => {
    setListAllSchedules({
      ...listAllSchedules,
      [date]: {
        is_trial: trials,
        slots: listTimeValue,
        slotsObj: listTimeObj,
        duration: titleInputRef.current.input.defaultValue,
        venue: zoomInputRef.current.input.defaultValue,
        pVenue: venueInputRef.current.input.defaultValue,
        price: priceInputRef.current.input.defaultValue,
      },
    });
    // console.log(listAllSchedules);
    // console.log(priceInputRef);
    // console.log(titleInputRef);
    // console.log(zoomInputRef);
    // console.log(venueInputRef);

    // setListTimeObj([]);
    // setListAllSchedules([]);
    // setListTimeValue([]);
    // priceInputRef.current.input.value = "";
    // titleInputRef.current.input.value = "";
    // zoomInputRef.current.input.value = "";
    // venueInputRef.current.input.value = "";

    // console.log('After');
    // console.log(listAllSchedules);
    // console.log(priceInputRef);
    // console.log(titleInputRef);
    // console.log(zoomInputRef);
    // console.log(venueInputRef);
  };

  const handleSaveSlots = () => {
    const data = Object.entries(listAllSchedules).map((schedule, index) => {
      // console.log(schedule);
      return {
        teacher_id: user.auth_id,
        is_trial: schedule[1].is_trial,
        date: new Date(schedule[0]),
        ...schedule[1],
      };
    });
    TeacherService.addLessons(data).then((res) => {
      // console.log(data);
      onSave(data);
      // handleSaveSchedules(listAllSchedules);
      setAddNew(!addNew);
      setListAllSchedules([]);
      setListTimeValue([]);
      setListPrevValue([]);
      priceInputRef.current.input.value = "";
      titleInputRef.current.input.value = "";
      zoomInputRef.current.input.value = "";
      venueInputRef.current.input.value = "";
      success();
      window.location.reload();
    });
  };

  const filterDate = (time) => {
    let selectedSchedule = listAllSchedules;
    const filteredtime = selectedSchedule[date].slots.filter(
      (tim) => tim !== time
    );
    selectedSchedule[date].slots = filteredtime;
    setListTimeValue([...filteredtime]);
    setListAllSchedules({ ...selectedSchedule });
  };
  const [addNew, setAddNew] = useState(false);
  const [count, setcount] = useState(0);

  const inccount = (value, key, time, date) => {
    let schedules = listAllSchedules;
    schedules[date].slots[key].seats = value;
    // console.log(schedules);
    setListAllSchedules({ ...schedules });
  };
  const safe = (value, data) => {
    let schedules = listAllSchedules;
    // console.log(schedules, value);
    schedules[data].is_trial = schedules[data].is_trial === "1" ? "0" : "1";
    setListAllSchedules({ ...schedules });
  };
  const [listTimeObj, setListTimeObj] = useState([]);
  const addTimeToList = (times) => {
    // console.log("times", times);
    setListTimeValue(
      times.map((time) => ({ time: time, duration: "60mins", seats: "1" }))
    );

    setListTimeObj(
      times.map((time) => ({ time: time, duration: "60mins", seats: "1" }))
    );

    // times.map((time) => {
    //   console.log("times", times.includes(time));
    //   // const isTime = listTimeValue.map((slot)=>slot.time)
    //   // console.log("istime",isTime.includes(time))
    //   if (listTimeValue.includes(time)) {
    //     filterDate(time);
    //     console.log("ibjbkv");
    //     setListTimeValue([
    //       ...listTimeValue.filter((slot) => slot.time !== time),
    //     ]);
    //   } else {
    //     setListTimeValue([
    //       // ...listTimeValue,
    //       { time: time, duration: "60mins", seats: "1" },
    //     ]);
    //     //  setListTimeValue([...listTimeValue, time])

    //     setListTimeObj([
    //       // ...listTimeObj,
    //       { time: time, duration: "60mins", seats: "1" },
    //     ]);
    //   }
    // });
    // console.log(listTimeValue);
    // console.log(listTimeObj);
  };
  // useEffect(() => {
  //   console.log(listTimeValue);
  // }, [listTimeValue]);

  const renderTimeInterval = () => {
    const options = [];
    for (let index = 30; index <= 360; index += 15) {
      options.push(`${index}mins.`);
    }
    return options;
  };

  useEffect(() => {
    // console.log(handleEditSlot);

    if (handleEditSlot) {
      setAddNew(true);
      setDate(handleEditSlot.date);
      // const times = handleEditSlot.slots.map((slot) => ({
      //   time: slot.trial_time,
      //   duration: slot.duration,
      //   seats: slot.seats,
      // }));
      // setListTimeObj(times);
      // setListTimeValue(times);
    }
  }, [handleEditSlot]);

  return (
    <>
      {contextHolder}
      <Accordion
        style={{
          boxShadow: "none",
          border: "solid 1px #e9e9e9",
          marginTop: 10,
        }}
        expanded={addNew}
        onChange={() => setAddNew(!addNew)}
      >
        <AccordionSummary
          expandIcon={
            <Icon
              style={{
                color: "white",
                backgroundColor: "#f69b25",
                borderRadius: 5,
              }}
            >
              add
            </Icon>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: 10, display: "flex" }} className="calendar">
            {!handleEditSlot && (
              <div
                style={{
                  padding: 10,
                  borderRadius: 10,
                  border: "solid 1px gray",
                  width: "30%",
                }}
              >
                <LessonsCalendar onChange={handleChange} />
              </div>
            )}
            <div style={{ width: "40%" }}>
              <h3
                style={{
                  fontWeight: 500,
                  textAlign: "center",
                  marginTop: 0,
                  marginBottom: "25px",
                }}
              >
                {dayjs(date).get("D")} {months[dayjs(date).get("M")]}{" "}
                {dayjs(date).get("year")}
              </h3>

              <>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select time slots"
                  // defaultValue={["a10", "c12"]}
                  // ={handleEditSlot
                  //       ? [...listTimeValue.map((timeObj) => timeObj.time)]
                  //       : []}
                  // value={
                  //   value
                  // }
                  // defaultValue={listTimeValue.map((timeObj) => timeObj.time)}

                  onChange={addTimeToList}
                  options={options}
                />
              </>

              {/* {timings.map((time, key) => {
                  return (
                    <Button
                      style={{ width: "23%", padding: "4px" }}
                      key={key}
                      type={
                        listTimeValue.some((value) => value.time === time) ||
                        listPrevValue.some((prev) => prev.time === time)
                          ? "outlined"
                          : "default"
                      }
                      onClick={() => addTimeToList(time)}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {listTimeValue.some((value) => value.time === time) ||
                        listPrevValue.some((prev) => prev.time === time) ? (
                          <RadioButtonCheckedIcon
                            style={{
                              fontSize: 20,
                              marginRight: 5,
                              color: "#1890ff",
                            }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon
                            style={{
                              fontSize: 20,
                              marginRight: 5,
                            }}
                          />
                        )}
                        <span>{time}</span>
                      </span>
                    </Button>
                  );
                })} */}

              {/* <Button>Custom</Button> */}

              <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                {handleEditSlot ? (
                  <Input
                    style={{ marginTop: 10, width: "48%" }}
                    placeholder="Add lesson title"
                    ref={titleInputRef}
                    value={handleEditSlot.slots[0].duration}
                    disabled
                  />
                ) : (
                  <Input
                    style={{ marginTop: 10, width: "48%" }}
                    placeholder="Add lesson title"
                    ref={titleInputRef}
                  />
                )}

                {handleEditSlot ? (
                  <Input
                    style={{ marginTop: 10, width: "48%", marginLeft: "4%" }}
                    placeholder="Add lesson price"
                    addonAfter={<span>$HKD</span>}
                    ref={priceInputRef}
                    value={
                      handleEditSlot.slots[0].price || handleEditSlot.price
                    }
                    disabled
                  />
                ) : (
                  <Input
                    style={{ marginTop: 10, width: "48%", marginLeft: "4%" }}
                    placeholder="Add lesson price"
                    addonAfter={<span>$HKD</span>}
                    ref={priceInputRef}
                  />
                )}
                {/* <Input
                  style={{ marginTop: 10, width: "100%" }}
                  placeholder="URL"
                  ref={zoomInputRef}
                /> */}
                {handleEditSlot ? (
                  <Input
                    style={{ marginTop: 10, width: "100%" }}
                    placeholder="URL"
                    ref={zoomInputRef}
                    value={handleEditSlot.slots[0].url || handleEditSlot.url}
                    disabled
                  />
                ) : (
                  <Input
                    style={{ marginTop: 10, width: "100%" }}
                    placeholder="URL"
                    ref={zoomInputRef}
                  />
                )}
                {handleEditSlot ? (
                  <Input
                    style={{ marginTop: 10, width: "100%" }}
                    placeholder="Venue"
                    ref={venueInputRef}
                    value={
                      handleEditSlot.slots[0].venue || handleEditSlot.venue
                    }
                    disabled
                  />
                ) : (
                  <Input
                    style={{ marginTop: 10, width: "100%" }}
                    placeholder="Venue"
                    ref={venueInputRef}
                  />
                )}
                <Button
                  type="primary"
                  onClick={addSchedularHandler}
                  style={{ marginTop: 10, width: "100%" }}
                >
                  {handleEditSlot ? "Update Schedule" : "Add Schedule"}
                </Button>
              </div>
            </div>
            <Col style={{ width: "75%" }}>
              {Object.keys(listAllSchedules).length !== 0 ? (
                Object.entries(listAllSchedules).map((schedule, index) => {
                  const date = schedule[0];
                  return (
                    <div
                      key={index}
                      style={{ marginTop: "30px", marginBottom: "30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 key={index} style={{ marginBottom: "10px" }}>
                          {dayjs(date).get("D")} {months[dayjs(date).get("M")]}{" "}
                          {dayjs(date).get("year")} <EditOutlined />
                        </h4>
                        <Box>
                          <FormControlLabel
                            sx={{ display: "none" }}
                            label="Trial"
                            control={
                              <Switch
                                checked={
                                  schedule[1].is_trial === "1" ? true : false
                                }
                                onChange={(e) => safe(e, date)}
                                name="Trial"
                              />
                            }
                          />
                        </Box>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {schedule[1].slots.map((slot, key) => {
                          return (
                            <Input.Group
                              key={key}
                              style={{
                                width: "33.3%",
                                marginRight: 15,
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <Button
                                disabled
                                style={{ borderRight: "none", color: "black" }}
                              >
                                {" "}
                                {slot.time}
                              </Button>
                              <Select
                                style={{ border: "none", width: "109px" }}
                                defaultValue={slot.duration}
                              >
                                {renderTimeInterval().map((interval, index) => {
                                  return (
                                    <Option key={index} values={interval}>
                                      {interval}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <Button
                                style={{ borderRight: "none", color: "black" }}
                                placeholder="No. of Seats"
                              >
                                No. of Seats
                              </Button>
                              <Input
                                value={slot.seats}
                                style={{ width: "25%" }}
                                type="number"
                                placeholder="Seats"
                                onChange={(e) =>
                                  inccount(e.target.value, key, slot.time, date)
                                }
                              />
                            </Input.Group>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p style={{ marginTop: 15, color: "gray" }}>
                  No Slots Available
                </p>
              )}
            </Col>
            <Col style={{ width: "75%", textAlign: "right" }}>
              <Button
                onClick={handleSaveSlots}
                style={{
                  backgroundColor: "#4BB543",
                  color: "white",
                  padding: "0 20px",
                }}
              >
                Update
              </Button>
            </Col>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Calender;
