import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container, Typography, Box, Chip } from "@material-ui/core";
import TeacherService from "../../services/teacher";
import { UserContext } from "../../context/UserContext";
import { EmptyOverlay } from "./";
import "./styles.scss";
const rows = [
  { id: 1, col1: "Hello", col2: "World" },
  { id: 2, col1: "DataGridPro", col2: "is Awesome" },
  { id: 3, col1: "MUI", col2: "is Amazing" },
];

export default function Teachers() {
  const columns = [
    {
      flex: 1,
      field: "display_name",
      headerName: "Student Name",
      headerClassName: "table-header-custom",
      valueGetter: getStudentName,
    },
    {
      flex: 1,
      field: "duration",
      headerName: "Trial name",
      headerClassName: "table-header-custom",
      valueGetter: getTrialName,
    },
    {
      flex: 1,
      field: "is_trial",
      headerName: "Type",
      headerClassName: "table-header-custom",
      renderCell: getTrialType,
    },
    {
      flex: 1,
      field: "price",
      headerName: "Price",
      headerClassName: "table-header-custom",
      valueGetter: getTrialPrice,
    },
    {
      flex: 1,
      field: "trial_date",
      headerName: "Date",
      headerClassName: "table-header-custom",
      valueGetter: getTrialDate,
    },
    {
      flex: 1,
      field: "trial_time",
      headerName: "Time",
      headerClassName: "table-header-custom",
      valueGetter: getTrialTime,
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      headerClassName: "table-header-custom",
      renderCell: getStatus,
    },
  ];
  const { user } = React.useContext(UserContext);
  const [currentUser] = user;
  const [bookings, setBookings] = React.useState([]);
  useEffect(() => {
    const data = {
      teacher_id: currentUser?.auth_id,
    };
    TeacherService.getTrialBookings(data).then((res) => {
      if (res.data.bookings != null)
        setBookings(
          res.data.bookings.filter((booking) => booking.trial.price_id)
        );
      console.log(res.data.bookings);
    });
  }, []);

  function getStudentName(params) {
    return `${params.row.student.display_name}`;
  }

  function getTrialType(params) {
    return params.row.trial.is_trial === "1" ? (
      <Chip size="small" label="Trial" />
    ) : (
      <Chip
        style={{ backgroundColor: "#f69a24", color: "white" }}
        size="small"
        label="Lesson"
      />
    );
  }

  function getTrialName(params) {
    return `${params.row.trial.duration}`;
  }
  function getTrialDate(params) {
    return `${params.row.trial_date}`;
  }
  function getTrialTime(params) {
    return `${params.row.trial_time}`;
  }
  function getTrialPrice(params) {
    return `HKD $${params.row.trial.price}`;
  }
  function getStatus(params) {
    return <Chip size="small" label={params.row.status.toUpperCase()} />;
  }

  return (
    <Container maxWidth="lg" style={{ paddingTop: 40, paddingBottom: 40 }}>
      <Box style={{ padding: 30, backgroundColor: "white", borderRadius: 10 }}>
        <Typography style={{ marginBottom: 15, fontSize: 22 }}>
          My Bookings
        </Typography>
        <div style={{ height: "auto", width: "100%" }}>
          <DataGrid
            disableSelectionOnClick
            autoHeight
            getRowId={(row) => row.booking_id}
            components={{
              NoRowsOverlay: EmptyOverlay,
            }}
            sx={{
              "& .table-header-custom": {
                backgroundColor: "#1b95ac",
                color: "white",
              },
            }}
            rows={bookings}
            columns={columns}
          />
        </div>
      </Box>
    </Container>
  );
}
